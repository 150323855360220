import { createSlice } from "@reduxjs/toolkit";
import { getActivePOSPaymentMode } from "apis/restApis";
import { POSPaymentMode, STATUSES } from "utils";

const initialState = {
  payment_mode: [] as POSPaymentMode[],
  status: STATUSES.IDLE as string,
  error: null,
};

const posPaymentModeSlice = createSlice({
  name: "POSPaymentMode",
  initialState,
  reducers: {
    getAllPOSPaymentModes: (state, action) => {
      state.payment_mode = action.payload;
    },

    setPOSPaymentModeStatus: (state, action) => {
      state.status = action.payload;
    },
    setPOSPaymentModeError: (state, action) => {
      state.error = action.payload;
    },
    resetPOSPaymentModeState: (state) => {
      state.payment_mode = [] as POSPaymentMode[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllPOSPaymentModes,
  setPOSPaymentModeStatus,
  setPOSPaymentModeError,
  resetPOSPaymentModeState,
} = posPaymentModeSlice.actions;

export default posPaymentModeSlice.reducer;

export function FetchAllActivePOSPaymentModes() {
  return async function FetchAllActivePOSPaymentModesThunk(dispatch: any) {
    dispatch(setPOSPaymentModeStatus(STATUSES.LOADING));
    getActivePOSPaymentMode()
      .then((response: any) => {
        dispatch(getAllPOSPaymentModes(response.result));
      })
      .catch((error: any) => {
        dispatch(setPOSPaymentModeError(error.message));
        dispatch(setPOSPaymentModeStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setPOSPaymentModeStatus(STATUSES.IDLE));
      });
  };
}
