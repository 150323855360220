import { createSlice } from "@reduxjs/toolkit";
import { error } from "console";
import { STATUSES, TBlog } from "utils";

const initialState = {
  blogs: [] as TBlog[],
  blog_status: STATUSES.IDLE as string,
  error: null as string | null,
};

const blogslice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    getAllBlogs: (state, action) => {
      state.blogs = action.payload;
    },

    addBlog: (state, action) => {
      state.blogs.unshift(action.payload);
    },

    editBlog: (
      state,
      action: {
        payload: {
          index: number;
          blog: TBlog;
        };
      }
    ) => {
      state.blogs[action.payload.index] = action.payload.blog;
    },

    removeBlog: (
      state,
      action: {
        payload: number;
      }
    ) => {
      state.blogs.splice(action.payload, 1);
    },

    setBlogStatus: (state, action) => {
      state.blog_status = action.payload;
    },

    setBlogError: (state, action) => {
      state.error = action.payload;
    },

    resetBlogState: (state) => {
      state.blogs = [] as TBlog[];
      state.blog_status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllBlogs,
  addBlog,
  editBlog,
  removeBlog,
  setBlogStatus,
  setBlogError,
  resetBlogState,
} = blogslice.actions;

export default blogslice.reducer;
