import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { FontIcon } from "./FontIcon";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";
import { PaginationProps, useAppDispatch, useAppSelector } from "utils";
import { defaultState } from "store/commonSlice";

const Pagination = ({
  loading,
  prevClickHandler,
  nextClickHandler,
  pageChangeHandler,
  currentPage,
  totalItems,
  totalPages,
  pageSize,
}: PaginationProps) => {
  const {
    common: { current_page, total_items, total_pages, page_size },
  } = useAppSelector((state) => state.root);

  const path = useLocation();

  const [pages, setPages] = useState<number[]>();

  const updatePage = () => {
    const maxPage = 5;
    const minPage = 1;

    if (current_page <= maxPage - 2) {
      const test = Array.from({ length: total_pages })
        .map((_, index) => index + 1)
        .filter((page) => page >= minPage && page <= maxPage);
      setPages(test);
    } else {
      const startPage = Math.max(current_page - 2, minPage);
      const endPage = Math.min(
        current_page + 2,
        Math.ceil(total_items / page_size)
      );

      const test = Array.from({ length: endPage - startPage + 1 })
        .map((_, index) => startPage + index)
        .filter(
          (page) =>
            page >= minPage && page <= Math.ceil(total_items / page_size)
        );
      setPages(test);
    }
  };

  useEffect(() => {
    updatePage();
    return () => {
      setPages([]);
    };
  }, [current_page, total_items, total_pages, page_size]);

  // const [searchParams, setSearchParams] = useSearchParams();
  // let searchPageNo = searchParams.get("page") ? searchParams.get("page") : 1;

  return (
    <ul
      className="pagination pagination-sm m-0 float-right"
      data-testid="pagination"
    >
      <li
        data-testid="pagination-prev"
        className={`page-item ${
          current_page <= 1 || loading ? "disabled " : ""
        }`}
      >
        <Link
          to={`${path.pathname}?page=${current_page - 1}`}
          className="page-link btn-sm"
          onClick={() => {
            prevClickHandler();
          }}
        >
          <FontIcon icon={faAnglesLeft} iconSize="xs" />
        </Link>
      </li>
      {pages?.map((page) => (
        <li
          data-testid={`pagination-page-${page}`}
          key={page}
          className={`page-item ${
            Number(current_page) === page ? "active" : ""
          } ${loading ? "disabled" : ""}`}
        >
          <Link
            to={`${path.pathname}?page=${page}`}
            className="page-link btn-sm"
            onClick={() => {
              current_page !== page && pageChangeHandler(page);
            }}
          >
            {page}
          </Link>
        </li>
      ))}
      <li
        data-testid="pagination-next"
        className={`page-item ${
          current_page === total_pages || total_pages === 0 || loading
            ? "disabled "
            : ""
        }`}
      >
        <Link
          to={`${path.pathname}?page=${current_page + 1}`}
          className="page-link btn-sm"
          onClick={() => {
            nextClickHandler();
          }}
        >
          <FontIcon icon={faAnglesRight} iconSize="xs" />
        </Link>
      </li>
    </ul>
  );
};

export { Pagination };
