import { useField } from "formik";
import React, { useEffect, useState } from "react";

interface CheckboxProps {
  name: string;
  checkClassName?: string;
  checkboxId?: string;
  label?: string;
  isChecked: boolean;
  isSwitch?: boolean;
  onClickHandler?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onBlurHandler?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checkClassName,
  checkboxId,
  label,
  isChecked,
  isSwitch,
  name,
  onClickHandler,

  onBlurHandler,
  ...props
}) => {
  const [Checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    setChecked(!Checked);
  };
  const [field, meta] = useField(name);
  return (
    // <div
    //   className={`${checkClassName}form-group form-check ${
    //     isSwitch ? "form-switch" : ""
    //   }`}
    // >
    <>
      <label
        className="form-check-label d-flex justify-content-start ml-4"
        htmlFor={checkboxId}
      >
        <span>{label}</span>
        <input
          {...field}
          {...props}
          className="form-check-input mr-5 "
          type="checkbox"
          id={checkboxId}
          checked={Checked}
          onChange={handleCheckboxChange}
          onClick={onClickHandler}
          onBlur={onBlurHandler}
        />
      </label>
    </>
    // </div>
  );
};

export { Checkbox };
