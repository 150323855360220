import React, { useRef, useState } from "react";

import { Formik, FormikProps } from "formik";

import {
  addNewSubCategory,
  deleteCatSubCategory,
  deleteMultipleCategoryMapping,
  deleteSubCategory,
  updateExistingSubCategory,
} from "store/ManageProducts/productSubCategorySlice";

import {
  ContentHeader,
  OffCanvas,
  Container,
  ConfirmModal,
  Loader,
} from "components";

import {
  useAppDispatch,
  ProductSubCategorySchema,
  PRODUCT_SUB_CATEGORY_CONSTANTS,
  handleOptionDelete,
  useAppSelector,
  STATUSES,
} from "utils";
import { SubCategoryForm } from "./SubCategoryForm";
import { SubCategoryList } from "./SubCategoryList";
import { setCommonStatus } from "store/commonSlice";

const ProductSubCategory = () => {
  const dispatch = useAppDispatch();

  const {
    common: { status: commonStatus },
  } = useAppSelector((state) => state.root);

  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState(false || {});

  const [currentSubCategory, setCurrentSubCategory] = useState<number>(0);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const PropsRef = useRef<FormikProps<any>>();

  const ActionsRef = useRef<any>();

  const [confirmModal, setConfirmModal] = useState(false);

  const [deleteField, setDeleteField] = useState("categories");

  const [initialCategories, setInitialCategories] = useState([]);

  const [editId, setEditId] = useState(0);

  const categoriesToSend = [];

  const initialValues = {
    categories: [],
    sub_category_name: "",
    sub_category_code: "",
    description: "",
    is_active: "",
  };

  const handleSubmit = (values, action) => {
    let dataToSend = {
      ...values,
      is_active: values.is_active[0]?.value,
      categories: values.categories.map((item) => item.value),
    };
    if (!edit) {
      dispatch(addNewSubCategory(dataToSend));
    } else {
      values.categories.forEach((value) => {
        if (!initialCategories.some((item) => item.value === value.value)) {
          categoriesToSend.push(value);
        }
      });

      dataToSend = {
        ...dataToSend,
        categories: categoriesToSend.map((item) => item.value),
      };

      dispatch(updateExistingSubCategory(values.id, dataToSend));
      setInitialCategories([]);
    }
    setShow(false);
    action.resetForm();
  };

  return (
    <div className="content-wrapper">
      <ContentHeader
        pageHeader={PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_HEADER}
      />
      <Container>
        <div className="col-12">
          <SubCategoryList
            setEdit={setEdit}
            setEditId={setEditId}
            setShow={setShow}
            setInitialCategories={setInitialCategories}
            setCurrentSubCategory={setCurrentSubCategory}
            setConfirmDeleteModal={setConfirmDeleteModal}
          />
          <OffCanvas
            title={
              edit
                ? PRODUCT_SUB_CATEGORY_CONSTANTS.EDIT_HEADER
                : PRODUCT_SUB_CATEGORY_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
              setEdit(false);
            }}
          >
            {show && (
              <>
                {commonStatus.state === STATUSES.LOADING && (
                  <div className="text-center">
                    <Loader />
                  </div>
                )}
                <Formik
                  enableReinitialize={true}
                  initialValues={edit ? edit : initialValues}
                  validationSchema={ProductSubCategorySchema}
                  onSubmit={handleSubmit}
                >
                  {(props) => {
                    PropsRef.current = props;
                    return (
                      <SubCategoryForm
                        props={PropsRef.current}
                        actionsRef={ActionsRef}
                        edit={edit}
                        editId={editId}
                        setConfirmModal={setConfirmModal}
                        setDeleteField={setDeleteField}
                      />
                    );
                  }}
                </Formik>
              </>
            )}
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={""}
        message={"Are you sure you want to delete the option ?"}
        confirmClick={() => {
          handleOptionDelete(
            deleteField,
            PropsRef.current,
            ActionsRef.current,
            (value) => {
              ActionsRef.current.action !== "clear"
                ? dispatch(
                    deleteCatSubCategory({
                      category: value,
                      sub_category: Number(editId),
                    })
                  )
                : dispatch(
                    deleteMultipleCategoryMapping(value, Number(editId))
                  );
            },
            (value) => {
              ActionsRef.current.action !== "clear"
                ? setInitialCategories((prev) => {
                    return prev.filter((item) => item.value !== value);
                  })
                : setInitialCategories([]);
            },
            (value) => {
              dispatch(
                setCommonStatus({ state: value, type: "deleteCategory" })
              );
            },
            true
          );
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
      <ConfirmModal
        modal={confirmDeleteModal}
        setModal={setConfirmDeleteModal}
        title={PRODUCT_SUB_CATEGORY_CONSTANTS.DELETE_SUB_CATEGORY}
        message={PRODUCT_SUB_CATEGORY_CONSTANTS.DELETE_SUB_CATEGORY_MSG}
        confirmClick={() => {
          dispatch(deleteSubCategory(currentSubCategory));
          setConfirmDeleteModal(false);
        }}
        rejectClick={() => {
          setConfirmDeleteModal(false);
        }}
      />
    </div>
  );
};

export { ProductSubCategory };
