import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  Table,
  TableBody,
  Tabs,
} from "components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADVANCE_ORDERS_CONSTANTS,
  BUTTON_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  GENERAL_CONSTANTS,
  IGetOrder,
  ORDER_CONSTANTS,
  PERMISSION_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { OrderProductDetails } from "./OrderProductDetails";
import { OrderPaymentDetails } from "./OrderPaymentDetails";
import { OrderCreditNotes } from "./OrderCreditNotes";
import {
  CancelExistingOrder,
  FetchCurrentOrderDetails,
  LoadCartForCancelOrder,
  PrintLastBill,
  setCurrentReturn,
  setReturn,
  setReturnOrder,
} from "store/POS";
import { OrderSummary } from "./OrderSummary";
import moment from "moment";

/**
 * Renders the order details page.
 *
 * @returns The JSX element representing the order details page.
 */

const OrderDetails = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    auth: { permissions: permissionData },
    branch: { selectedStore },
    common: { status: commonStatus },
    cart: { return: returnRefunds, currentReturn },
    order: { status: orderStatus, currentOrder: orderDetails },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const [activeTab, setActiveTab] = useState<string>(
    ORDER_CONSTANTS.PRODUCT_DETAILS
  );

  const [refundItems, setRefundItems] = useState<
    {
      product_group: {
        id: number;
        print_hamper_name: string;
        hamper_code: string;
      };
      batch: number;
      product: number;
      price: number;
      price_with_discount: number;
      quantity: number;
      remarks: string;
      igst: number;
      cgst: number;
      sgst: number;
      cess: number;
      discount_type: string;
      discount_value: number;
      total_tax: number;
      total_amount: number;
      total_discount: number;
    }[]
  >([]);

  // const [masterPasswordModal, setMasterPasswordModal] = useState(false);

  const isPayLater = orderDetails && orderDetails?.payment_type?.id === 5;

  const canReturnOrder = permissionData.find(
    (p) => p.module_name === "Manage Returns/Refunds"
  )?.perm_authorize;

  const containsCreditNote =
    orderDetails &&
    orderDetails?.payments &&
    orderDetails?.payments?.some(
      (payment) => payment.payment_mode.id === POS_CONSTANTS.CREDIT_NOTE_ID
    );

  const containsPayments =
    orderDetails &&
    orderDetails?.payments &&
    orderDetails?.payments?.filter(
      (payment) => payment.payment_mode.id !== POS_CONSTANTS.CREDIT_NOTE_ID
    )?.length > 0;

  const containsAdvanceOrder =
    orderDetails &&
    orderDetails?.returns &&
    orderDetails?.returns?.length === 1 &&
    orderDetails?.returns?.some(
      (item) => item.credit_type === ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE
    );

  const canCancelOrder =
    permissionData.find(
      (item) => item.module_name === PERMISSION_CONSTANTS.CANCEL_ORDER
    )?.perm_authorize &&
    moment(new Date()).isBefore(
      moment(orderDetails?.created_at).add(1, "days")
    ) &&
    !isPayLater;

  const tabs = [
    ORDER_CONSTANTS.PRODUCT_DETAILS,
    orderDetails?.payments?.length > 0 ? ORDER_CONSTANTS.PAYMENT_DETAILS : null,
    orderDetails?.returns?.length > 0 && !containsAdvanceOrder
      ? ORDER_CONSTANTS.CREDIT_NOTE
      : null,
  ];

  useEffect(() => {
    id && dispatch(FetchCurrentOrderDetails(Number(id), selectedStore));
    if (returnRefunds) {
      dispatch(setReturnOrder(orderDetails));
    }
    return () => {
      dispatch(setReturnOrder({} as IGetOrder));
      dispatch(setReturn(false));
    };
  }, []);

  return (
    <div className="content-wrapper text-sm">
      <Container>
        <ContentHeader
          pageHeader={
            orderDetails?.invoice_code ? `# ${orderDetails?.invoice_code}` : ""
          }
          editValue={orderDetails?.invoice_code}
        />
        <div className="d-flex flex-column flex-md-row">
          {orderDetails?.status && (
            <div className="col-md-6">
              <Card cardClassNames="card-dark">
                <CardHeader>
                  <h6 className="card-title">Order Details</h6>
                </CardHeader>
                <CardBody bodyClassNames="p-0">
                  <Table tableType="table-borderless">
                    <TableBody>
                      <tr>
                        <th>Invoice No.:</th>
                        <td>{orderDetails?.invoice_code}</td>
                      </tr>
                      <tr>
                        <th>Status:</th>
                        <td>
                          <Badge
                            badgeSize="sm"
                            badgeType={
                              orderDetails.status ===
                              GENERAL_CONSTANTS.COMPLETED
                                ? GENERAL_CONSTANTS.SUCCESS
                                : orderDetails.status ===
                                  GENERAL_CONSTANTS.PENDING
                                ? GENERAL_CONSTANTS.WARNING
                                : GENERAL_CONSTANTS.DANGER
                            }
                            badgeText={orderDetails.status?.toLocaleUpperCase()}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Created At:</th>
                        <td>
                          {moment(orderDetails?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <th>Biller Name:</th>
                        <td>
                          {orderDetails?.employee?.first_name}{" "}
                          {orderDetails?.employee?.last_name}
                        </td>
                      </tr>
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          )}
          {orderDetails?.customer && (
            <div className="col-md-6">
              <Card cardClassNames="card-dark">
                <CardHeader>
                  <h6 className="card-title">Customer Details</h6>
                </CardHeader>
                <CardBody bodyClassNames="p-0">
                  <Table tableType="table-borderless">
                    <TableBody>
                      <tr>
                        <th>{CUSTOMERS_CONSTANTS.CUSTOMER_NAME_LABEL}:</th>
                        <td>
                          {orderDetails?.customer?.designation}{" "}
                          {orderDetails?.customer?.name}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER_LABEL}:
                        </th>
                        <td>
                          {orderDetails?.customer?.contact_number
                            ? orderDetails?.customer?.contact_number
                            : GENERAL_CONSTANTS.NA}
                        </td>
                      </tr>
                      <tr>
                        <th>{CUSTOMERS_CONSTANTS.CUSTOMER_EMAIL_LABEL}:</th>
                        <td>
                          {orderDetails?.customer?.email
                            ? orderDetails?.customer?.email
                            : GENERAL_CONSTANTS.NA}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {CUSTOMERS_CONSTANTS.CUSTOMER_COMPANY_NAME_LABEL}:
                        </th>
                        <td>
                          {orderDetails?.customer?.company_name
                            ? orderDetails?.customer?.company_name
                            : GENERAL_CONSTANTS.NA}
                        </td>
                      </tr>
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          )}
        </div>
        {orderDetails?.payment_type && (
          <div className="d-flex flex-row">
            <div className="col-md-12">
              <Card cardClassNames="card-dark">
                <CardHeader>
                  <h6 className="card-title">Payment Details</h6>
                </CardHeader>
                <CardBody bodyClassNames="p-0">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="col-md-6">
                      <Table tableType="table-borderless">
                        <TableBody>
                          <tr>
                            <th>Payment Type:</th>
                            <td>{orderDetails?.payment_type?.payment_type}</td>
                          </tr>
                          {orderDetails?.delivery_type ===
                            POS_CART_CONSTANTS.DELIVERY && (
                            <tr>
                              <th>{POS_CART_CONSTANTS.DELIVERY_TIME_LABEL}:</th>
                              <td>
                                {orderDetails?.delivery_date}
                                {" at "}
                                {orderDetails?.delivery_time}
                              </td>
                            </tr>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                    <div className="col-md-6">
                      <Table tableType="table-borderless">
                        <TableBody>
                          <tr>
                            <th>{POS_CART_CONSTANTS.DELIVERY_TYPE_LABEL}:</th>
                            <td>
                              <Badge
                                badgeSize="sm"
                                badgeType={
                                  orderDetails?.delivery_type ===
                                  POS_CART_CONSTANTS.PICKUP
                                    ? GENERAL_CONSTANTS.INFO
                                    : GENERAL_CONSTANTS.SECONDARY
                                }
                                badgeText={orderDetails?.delivery_type?.toLocaleUpperCase()}
                              />
                            </td>
                          </tr>
                          {orderDetails?.delivery_type ===
                            POS_CART_CONSTANTS.DELIVERY &&
                            (orderDetails?.addresses?.[0]?.address_line_1
                              ?.length > 1 ||
                              orderDetails?.addresses?.[0]?.address_line_2
                                ?.length > 1) && (
                              <tr>
                                <th>
                                  {POS_CART_CONSTANTS.DELIVERY_ADDRESS_TITLE}:
                                </th>
                                <td className="text-wrap">
                                  {`${orderDetails?.addresses?.[0]?.address_line_1}, ${orderDetails?.addresses?.[0]?.address_line_2}, ${orderDetails?.addresses?.[0]?.city?.city}, ${orderDetails?.addresses?.[0]?.state?.state}, ${orderDetails?.addresses?.[0]?.country?.country}, ${orderDetails?.addresses?.[0]?.pincode}`}
                                </td>
                              </tr>
                            )}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        {orderDetails?.status && (
          <div className="d-flex flex-row">
            <div className="col-12">
              <Card cardClassNames="card-dark">
                <CardHeader>
                  <h6 className="card-title">Order Actions</h6>
                </CardHeader>
                <CardBody>
                  <div
                    className={`col-12 d-flex flex-md-row flex-wrap gap-2 align-items-start px-0`}
                  >
                    <Button
                      text={POS_CART_CONSTANTS.BILL_LABEL}
                      type={BUTTON_CONSTANTS.BUTTON}
                      loading={
                        commonStatus.type ===
                        POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE
                      }
                      btnClassNames="btn btn-dark"
                      onClickHandler={() => {
                        dispatch(PrintLastBill({ bill_id: Number(id) }));
                      }}
                    />
                    {moment(orderDetails?.created_at).format(
                      GENERAL_CONSTANTS.DATE_FORMAT
                    ) === moment().format(GENERAL_CONSTANTS.DATE_FORMAT) &&
                      orderDetails?.returns?.length === 0 &&
                      orderDetails?.status !== GENERAL_CONSTANTS.CANCELLLED &&
                      containsPayments && (
                        <Button
                          text={POS_CART_CONSTANTS.EDIT_PAYMENT_DETAILS}
                          type={BUTTON_CONSTANTS.BUTTON}
                          btnClassNames="btn btn-dark"
                          onClickHandler={() => {
                            navigate(
                              `/admin/sales/cashier/pending-orders/orders/${orderDetails.id}/`,
                              {
                                state: { edit: true },
                              }
                            );
                          }}
                        />
                      )}
                    {activeTab === ORDER_CONSTANTS.PRODUCT_DETAILS ? (
                      orderDetails?.status === GENERAL_CONSTANTS.COMPLETED ? (
                        canReturnOrder ? (
                          <Button
                            text={`${
                              !returnRefunds
                                ? ORDER_CONSTANTS.REFUND_MODE
                                : ORDER_CONSTANTS.EXIT_REFUND
                            }`}
                            type={BUTTON_CONSTANTS.BUTTON}
                            btnClassNames={`btn btn-${
                              !returnRefunds
                                ? GENERAL_CONSTANTS.DARK
                                : GENERAL_CONSTANTS.DANGER
                            }`}
                            onClickHandler={() => {
                              if (!returnRefunds) {
                                dispatch(setReturn(true));
                                dispatch(setReturnOrder(orderDetails));
                              } else {
                                setRefundItems([]);
                                dispatch(setReturn(false));
                                dispatch(setReturnOrder({} as IGetOrder));
                                dispatch(setCurrentReturn(null));
                              }
                            }}
                          />
                        ) : null
                      ) : orderDetails?.status === GENERAL_CONSTANTS.PENDING &&
                        canCancelOrder ? (
                        <div className="d-flex flex-column justify-content-center">
                          <div className="d-flex flex-row gap-2 align-items-center">
                            <Button
                              text={ORDER_CONSTANTS.CANCEL_ENTIRE_ORDER}
                              type={BUTTON_CONSTANTS.BUTTON}
                              btnClassNames={`btn btn-dark`}
                              onClickHandler={() => {
                                if (canCancelOrder) {
                                  dispatch(
                                    CancelExistingOrder(
                                      Number(orderDetails?.id)
                                    )
                                  );
                                } else {
                                  // setMasterPasswordModal(true);
                                }
                              }}
                            />
                            <Button
                              text={ORDER_CONSTANTS.CANCEL_SELECTED_PRODUCTS}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={containsCreditNote}
                              btnClassNames={`btn btn-dark`}
                              onClickHandler={() => {
                                if (canCancelOrder) {
                                  dispatch(
                                    LoadCartForCancelOrder(
                                      orderDetails,
                                      navigate
                                    )
                                  );
                                } else {
                                  // setMasterPasswordModal(true);
                                }
                              }}
                            />
                          </div>
                          {containsCreditNote && (
                            <div className="d-flex flex-row text-sm align-items-center justify-content-center text-muted mt-2">
                              Partial cancellation not possible; credit note
                              applied.
                            </div>
                          )}
                        </div>
                      ) : null
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        <div className="d-flex flex-row">
          <div className="col-12">
            <Card cardClassNames="card-dark">
              <CardHeader>
                <div className="d-flex flex-column flex-md-row">
                  <div className="col-md-4 align-self-md-end mb-2 mb-md-0">
                    <Tabs
                      tabs={tabs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div
                  className="d-flex flex-row overflow-y-scroll mt-2"
                  style={{
                    maxHeight: `calc(100vh - ${
                      activeTab === ORDER_CONSTANTS.PRODUCT_DETAILS
                        ? "400px)"
                        : "200px)"
                    }`,
                  }}
                >
                  <div className="col-12 px-0">
                    {activeTab === ORDER_CONSTANTS.PRODUCT_DETAILS && (
                      <OrderProductDetails
                        orderDetails={orderDetails}
                        refundItems={refundItems}
                        setRefundItems={setRefundItems}
                      />
                    )}
                    {activeTab === ORDER_CONSTANTS.PAYMENT_DETAILS && (
                      <OrderPaymentDetails orderDetails={orderDetails} />
                    )}
                    {activeTab === ORDER_CONSTANTS.CREDIT_NOTE && (
                      <OrderCreditNotes orderDetails={orderDetails} />
                    )}
                  </div>
                </div>
                {activeTab === ORDER_CONSTANTS.PRODUCT_DETAILS && (
                  <OrderSummary
                    orderDetails={orderDetails}
                    refundItems={refundItems}
                    setRefundItems={setRefundItems}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        {/* <MasterPasswordModal
          modal={masterPasswordModal}
          setModal={setMasterPasswordModal}
        /> */}
      </Container>
    </div>
  );
};

export { OrderDetails };
