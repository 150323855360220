import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import { deleteBank, getBanks } from "store/ManageBanks/bankSlice";

import {
  ContentHeader,
  NoData,
  TableTools,
  Loader,
  Status,
  ConfirmModal,
} from "components";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  faAdd,
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BANK_CONSTANTS,
  BUTTON_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { updatePageNo } from "store/commonSlice";

const Banks = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    bank: { banks: bankData, status },
    common: { current_page, total_items, total_pages, page_size },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [currentBank, setCurrentBank] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const bankPermission = permissionData.find(
    (p) => p.module_name === "Manage Banks"
  );

  const tableCols = BANK_CONSTANTS.BANK_COLS;

  const update_page = (pageNo: number) => {
    dispatch(getBanks({ active: false, pageNo, query }));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={BANK_CONSTANTS.BANK_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            {/* The Card header is a component that contains the page title and the breadcrumb. */}
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={bankPermission?.perm_add}
                path={BANK_CONSTANTS.PATH}
                label={BANK_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table testId={"banks-table"}>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : bankData?.length ? (
                    bankData.map((bank, index) => (
                      <tr key={index}>
                        <td>{page_size * (current_page - 1) + index + 1}</td>
                        <td>{bank.bank_name}</td>
                        <td data-testid={`status-${index + 1}`}>
                          <Status is_active={bank.is_active} />
                        </td>
                        <td>
                          <Button
                            testId={`edit-${index + 1}`}
                            text={<FontIcon icon={faPenToSquare} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            isDisabled={!bankPermission?.perm_edit}
                            btnClassNames={"btn btn-info btn-sm mr-2"}
                            onClickHandler={() =>
                              navigate(ROUTES_CONSTANTS.EDIT + "/" + bank.id)
                            }
                          />
                          {bank.is_active ? (
                            <Button
                              testId={`deactivate-${index + 1}`}
                              text={<FontIcon icon={faBan} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={!bankPermission?.perm_delete}
                              btnClassNames={"btn btn-danger btn-sm"}
                              onClickHandler={() => {
                                setConfirmModal(true);
                                setCurrentBank(Number(bank.id));
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={BANK_CONSTANTS.DELETE_BANK}
        message={BANK_CONSTANTS.DELETE_BANK_MSG}
        confirmClick={() => {
          dispatch(deleteBank(currentBank));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Banks };
