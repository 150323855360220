import { createSlice } from "@reduxjs/toolkit";

import { STATUSES, PaymentTermType, Toast } from "utils";

import {
  delPaymentTerm,
  getActivePaymentTerm,
  getPaymentTerm,
  setPaymentTerm,
  updatePaymentTerm,
} from "apis/restApis";

import { updateValues } from "store/commonSlice";
import { FormikHelpers } from "formik";

const initialState = {
  paymentTerms: [] as PaymentTermType[],
  status: STATUSES.IDLE as string,
  error: null,
};

const paymentTermSlice = createSlice({
  name: "paymentTerm",
  initialState,
  reducers: {
    getAllPaymentTerms(state, action) {
      state.paymentTerms = action.payload;
    },
    addPaymentTerm(state, action) {
      state.paymentTerms.push(action.payload);
    },
    removePaymentTerm(state, action) {
      state.paymentTerms = state.paymentTerms.filter(
        (term) => term.id !== action.payload
      );
    },
    editPaymentTerm(state, action) {
      const { id } = action.payload;
      const index = state.paymentTerms.findIndex((term) => {
        return term.id === id;
      });
      if (index !== -1) {
        state.paymentTerms[index] = action.payload;
      }
    },

    setPaymentTermStatus(state, action) {
      state.status = action.payload;
    },
    setPaymentTermError(state, action) {
      state.error = action.payload;
    },
    resetPaymentTermState: (state) => {
      state.paymentTerms = [] as PaymentTermType[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllPaymentTerms,
  addPaymentTerm,
  removePaymentTerm,
  editPaymentTerm,

  setPaymentTermStatus,
  setPaymentTermError,
  resetPaymentTermState,
} = paymentTermSlice.actions;

export default paymentTermSlice.reducer;

export function getPaymentTerms(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getPaymentTermsThunk(dispatch, getState) {
    dispatch(setPaymentTermStatus(STATUSES.LOADING));
    active
      ? getActivePaymentTerm()
          .then((response: any) => {
            dispatch(setPaymentTermStatus(STATUSES.IDLE));
            dispatch(getAllPaymentTerms(response.result));
          })
          .catch((error) => {
            dispatch(setPaymentTermError(error.message));
            dispatch(setPaymentTermStatus(STATUSES.ERROR));
          })
      : getPaymentTerm(pageNo, query)
          .then((response: any) => {
            dispatch(setPaymentTermStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllPaymentTerms(response.result.results));
          })
          .catch((error) => {
            dispatch(setPaymentTermError(error.message));
            dispatch(setPaymentTermStatus(STATUSES.ERROR));
          });
  };
}

export function addNewPaymentTerm(
  termParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function addNewPaymentTermThunk(dispatch, getState) {
    dispatch(setPaymentTermStatus(STATUSES.LOADING));
    setPaymentTerm(termParams)
      .then((response: any) => {
        setShow(false);
        actions.resetForm();
        dispatch(addPaymentTerm(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setPaymentTermStatus(STATUSES.ERROR));
        dispatch(setPaymentTermError(error.message));
      })
      .finally(() => {
        dispatch(setPaymentTermStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingPaymentTerm(
  id: number,
  termParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function updateExistingPaymentTermThunk(dispatch, getState) {
    dispatch(setPaymentTermStatus(STATUSES.LOADING));
    updatePaymentTerm(id, termParams)
      .then((response: any) => {
        dispatch(editPaymentTerm(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setPaymentTermStatus(STATUSES.ERROR));
        dispatch(setPaymentTermError(error.message));
      })
      .finally(() => {
        dispatch(setPaymentTermStatus(STATUSES.IDLE));
      });
  };
}

export function deletePaymentTerm(id: number) {
  return async function deletePaymentTermThunk(dispatch, getState) {
    dispatch(setPaymentTermStatus(STATUSES.LOADING));
    delPaymentTerm(id)
      .then((response: any) => {
        dispatch(editPaymentTerm(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setPaymentTermError(error.message));
        dispatch(setPaymentTermStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setPaymentTermStatus(STATUSES.IDLE));
      });
  };
}
