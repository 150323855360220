import React, { useEffect, useState } from "react";

import { faBan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";

import {
  BUTTON_CONSTANTS,
  PAYMENT_MODES_CONSTANTS,
  PaymentMode,
  STATUSES,
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

import {
  deletePaymentMode,
  getPaymentModes,
} from "store/ManagePayments/paymentModeSlice";
import { updatePageNo } from "store/commonSlice";

const PaymentModeList = ({
  setEdit,
  setShow,
  setCurrentMode,
  setConfirmModal,
}) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    paymentMode: { paymentMode: paymentModeData, status },
  } = useAppSelector((state) => state.root);

  const paymentModePermission = permissions.find(
    (p) => p.module_name === "Manage Payment Mode"
  );

  const [query, setQuery] = useState("");

  const update_page = (pageNo: number) => {
    dispatch(getPaymentModes(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PAYMENT_MODES_CONSTANTS.PAYMENT_MODES_COLS;

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={paymentModePermission?.perm_add}
          path={PAYMENT_MODES_CONSTANTS.PATH}
          label={PAYMENT_MODES_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : paymentModeData.length > 0 ? (
              paymentModeData.map((paymentMode: PaymentMode, index) => {
                return (
                  <tr key={index}>
                    <td>{page_size * (current_page - 1) + index + 1}</td>
                    <td>{paymentMode.payment_mode}</td>
                    <td>
                      <Status is_active={paymentMode.is_active} />
                    </td>
                    <td>
                      <Button
                        text={<FontIcon icon={faPenToSquare} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        isDisabled={!paymentModePermission?.perm_edit}
                        btnClassNames={"btn btn-info btn-sm mr-2"}
                        onClickHandler={() => {
                          let editData = {
                            ...paymentMode,
                            is_active: [
                              {
                                value: paymentMode.is_active,
                                label:
                                  paymentMode.is_active === 1
                                    ? "Active"
                                    : "Inactive",
                              },
                            ],
                          };
                          setShow(true);
                          setEdit(editData);
                        }}
                      />
                      {paymentMode.is_active ? (
                        <Button
                          text={<FontIcon icon={faBan} />}
                          type={BUTTON_CONSTANTS.BUTTON}
                          isDisabled={!paymentModePermission?.perm_delete}
                          btnClassNames={"btn btn-danger btn-sm"}
                          onClickHandler={() => {
                            setConfirmModal(true);
                            setCurrentMode(Number(paymentMode.id));
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={status === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { PaymentModeList };
