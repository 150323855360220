import React from "react";
import { useAppDispatch, useAppSelector } from "utils/helper";

import { Dropdown, FontIcon } from "components/core-components";

import { Link, useLocation } from "react-router-dom";
import { logout } from "store/authSlice";
import {
  faAddressBook,
  faBars,
  faPowerOff,
  faRectangleList,
  faShop,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "utils";
import { NavbarAdditions } from "./POS";

const Navbar = ({ onClickHandler }) => {
  const dispatch = useAppDispatch();

  const {
    auth: { logged_in_user },
  } = useAppSelector((state) => state.root);

  const location = useLocation();
  const pathName = location.pathname.split("/").splice(2);

  const isPOS = pathName.includes("pos");

  const isMobile = window.mobileAndTabletCheck();

  const dropdownItems = [
    {
      path: `#`,
      icon: null,
      label: (
        <div className="d-flex flex-column justify-content-center align-items-center border-bottom border-dark-subtle">
          <p className="row rounded-5 bg-secondary p-2">
            {logged_in_user?.first_name?.[0]} {logged_in_user?.last_name?.[0]}
          </p>
          <p className="row">
            {logged_in_user?.first_name} {logged_in_user?.last_name}
          </p>
        </div>
      ),
    },
    {
      path: "#",
      icon: faPowerOff,
      label: "Logout",
      dropdownClickHandler: () => {
        dispatch(logout());
      },
    },
  ];

  return (
    // <!-- Navbar -->
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* <!-- Left navbar links --> */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            className="nav-link"
            data-widget="pushmenu"
            role="button"
            onClick={onClickHandler}
          >
            <FontIcon iconClassNames={"nav-icon"} icon={faBars} />
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to={ROUTES.DASHBOARD} className="nav-link">
            Home
          </Link>
        </li>
      </ul>
      {/* <!-- Right navbar links --> */}
      <ul className="navbar-nav ml-auto">
        {/* <!-- Navbar Search --> */}
        {/* <li className="nav-item">
          <Link to="/admin/sales/pos" className="nav-link">
            POS
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/sales/cashier" className="nav-link">
            Cashier
          </Link>
        </li> */}
        {isMobile && isPOS && <NavbarAdditions />}
        <li className="nav-item">
          <Dropdown
            items={dropdownItems}
            dropdownIcon={
              <p className="rounded-5 bg-secondary p-2 m-0">
                {logged_in_user?.first_name?.[0]}{" "}
                {logged_in_user?.last_name?.[0]}
              </p>
            }
            dropdownBtnClassNames={"shadow-none border-0"}
          />
          {/* <Link
            to={ROUTES.LOGIN}
            className="nav-link fw-bold text-primary "
            onClick={() => {
              dispatch(logout());
            }}
          >
            {isMobile && isPOS ? (
              <FontIcon icon={faPowerOff} iconClassNames="text-danger" />
            ) : (
              "Logout"
            )}
          </Link> */}
        </li>
      </ul>
    </nav>
    //<!-- /.navbar -->
  );
};

export { Navbar };
