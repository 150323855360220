import { createSlice } from "@reduxjs/toolkit";
import { getKPIReport } from "apis/restApis";
import { STATUSES, Toast, TOAST_CONSTANTS } from "utils";

const initialState = {
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

const KPIReportSlice = createSlice({
  name: "KPIReport",
  initialState,
  reducers: {
    setKPIReportStatus: (state, action) => {
      state.status = action.payload;
    },
    setKPIReportError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setKPIReportStatus, setKPIReportError } = KPIReportSlice.actions;

export default KPIReportSlice.reducer;

export function GenerateKPIReport({
  selected_store,
  start_date,
  end_date,
}: {
  selected_store: number[];
  start_date: string;
  end_date: string;
}) {
  return async function GenerateKPIReportThunk(dispatch: any) {
    try {
      dispatch(setKPIReportStatus(STATUSES.LOADING));
      // API call to generate KPI report
      let request_url = `?start_date=${start_date}&end_date=${end_date}`;
      for (let i = 0; i < selected_store.length; i++) {
        request_url += `&store[]=${selected_store[i]}`;
      }
      const response: any = await getKPIReport({
        url: request_url,
      });
      const url = URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `KPI_${start_date}-${end_date}_Reports.zip`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(setKPIReportError(error.message));
      dispatch(setKPIReportStatus(STATUSES.ERROR));
      Toast(`Error: ${error.message}`, TOAST_CONSTANTS.ERROR);
    } finally {
      dispatch(setKPIReportStatus(STATUSES.IDLE));
    }
  };
}
