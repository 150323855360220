import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  MultiSelect,
} from "components";
import { ErrorMessage } from "formik";
import React, { memo } from "react";
import { getDeliveryPolicies } from "store/ManageVendors/deliveryPolicySlice";
import {
  FORM_CONSTANTS,
  STATUSES,
  VENDORS_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

const VendorContactDetailsForm = () => {
  const dispatch = useAppDispatch();

  const {
    deliveryPolicy: {
      deliveryPolicy: deliveryPolicyData,
      status: deliveryPolicyStatus,
    },
  } = useAppSelector((state) => state.root);
  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h3 className="card-title">{VENDORS_CONSTANTS.CARD_TITLE_2}</h3>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NAME_LABEL}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NAME}
                placeholder={
                  VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NAME_PLACEHOLDER
                }
                type={FORM_CONSTANTS.TEXT}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_EMAIL_LABEL}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_EMAIL}
                placeholder={
                  VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_EMAIL_PLACEHOLDER
                }
                type={FORM_CONSTANTS.EMAIL}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_EMAIL}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_COMMISSION_LABEL}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_COMMISSION}
                placeholder={VENDORS_CONSTANTS.VENDOR_COMMISSION_PLACEHOLDER}
                type={FORM_CONSTANTS.NUMBER}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_COMMISSION}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NUMBER_LABEL}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NUMBER}
                placeholder={
                  VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NUMBER_PLACEHOLDER
                }
                type={FORM_CONSTANTS.NUMBER}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_CONTACT_PERSON_NUMBER}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 ">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_DELIVERY_POLICY_LABEL}
                select={false}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_DELIVERY_POLICY}
                onFocusHandler={() => {
                  deliveryPolicyData.length === 0 &&
                    dispatch(getDeliveryPolicies(true));
                }}
                options={deliveryPolicyData.map((item) => ({
                  value: item.id,
                  label: item.policy,
                }))}
                isLoading={deliveryPolicyStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_DELIVERY_POLICY}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VendorContactDetailsForm);
