import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delDiscountApplicableType,
  getActiveDiscountApplicableType,
  getDiscountApplicableType,
  setDiscountApplicableType,
  updateDiscountApplicableType,
} from "apis/restApis";

interface DiscountApplicableType {
  id: number;
  type: string;
  is_active: number;
}

const initialState = {
  discountApplicableType: [] as DiscountApplicableType[],
  status: STATUSES.IDLE,
  error: null,
};

const discountApplicableTypeSlice = createSlice({
  name: "discountApplicableType",
  initialState,
  reducers: {
    getAllDiscountApplicableType(state, action) {
      state.discountApplicableType = action.payload;
    },
    addDiscountApplicableType(state, action) {
      state.discountApplicableType.push(action.payload);
    },
    removeDiscountApplicableType(state, action) {
      state.discountApplicableType = state.discountApplicableType.filter(
        (discountApplicableType) => discountApplicableType.id !== action.payload
      );
    },
    editDiscountApplicableType(state, action) {
      const { id } = action.payload;
      const index = state.discountApplicableType.findIndex(
        (discountApplicableType) => discountApplicableType.id === id
      );
      if (index !== -1) {
        state.discountApplicableType[index] = action.payload;
      }
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getAllDiscountApplicableType,
  addDiscountApplicableType,
  removeDiscountApplicableType,
  editDiscountApplicableType,
  setStatus,
  setError,
} = discountApplicableTypeSlice.actions;

export default discountApplicableTypeSlice.reducer;

export function getDiscountApplicableTypes(active: boolean) {
  return async function getDiscountApplicableTypesThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveDiscountApplicableType()
          .then((response: any) => {
            dispatch(getAllDiscountApplicableType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getDiscountApplicableType()
          .then((response: any) => {
            dispatch(getAllDiscountApplicableType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export const addDiscountApplicableTypes = (
  discountApplicableTypeParams: DiscountApplicableType
) => {
  return async function addDiscountApplicableTypeRequestThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    setDiscountApplicableType(discountApplicableTypeParams)
      .then((response: any) => {
        dispatch(addDiscountApplicableType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
};

export const updateDiscountApplicableTypes = (
  id: number,
  discountApplicableTypeParams: DiscountApplicableType
) => {
  return async function updateDiscountApplicableTypeRequestThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    updateDiscountApplicableType(id, discountApplicableTypeParams)
      .then((response: any) => {
        dispatch(editDiscountApplicableType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
};

export const deleteDiscountApplicableType = (id: number) => {
  return async function deleteDiscountApplicableTypeRequestThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    delDiscountApplicableType(id)
      .then((response: any) => {
        dispatch(editDiscountApplicableType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
};
