import { createSlice } from "@reduxjs/toolkit";
import {
  delPrinter,
  getActivePrinters,
  getPrinters,
  getSpecificPrinter,
  setPrinter,
  updatePrinter,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { getIndividual } from "store/ManageProducts/barcodeSlice";
import { IPrinterState, STATUSES, Toast } from "utils";

// Printer Name
// Target
// Series Name
// Language

const initialState = {
  printer: [] as IPrinterState[],
  printerToEdit: {} as IPrinterState,
  status: STATUSES.IDLE as string,
  error: null,
};

const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    getPrinter(state, action) {
      state.printer = action.payload;
    },
    addPrinter(state, action) {
      state.printer.push(action.payload);
    },
    removePrinter(state, action) {
      state.printer = state.printer.filter(
        (printer) => printer.id !== action.payload
      );
    },
    editPrinter(state, action) {
      const { id } = action.payload;
      const index = state.printer.findIndex((printer) => printer.id === id);
      if (index !== -1) {
        state.printer[index] = action.payload;
      }
    },
    setPrinterToEdit(state, action) {
      state.printerToEdit = action.payload;
    },

    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getPrinter,
  addPrinter,
  removePrinter,
  editPrinter,
  setPrinterToEdit,

  setStatus,
  setError,
} = printerSlice.actions;

export default printerSlice.reducer;

export function getAllPrinters(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getAllPrintersThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActivePrinters()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getPrinter(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getPrinters(pageNo, query)
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getPrinter(response.result.results));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function getIndividualPrinter(id: number) {
  return async function getIndividualPrinterThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    getSpecificPrinter(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(setPrinterToEdit(response.result));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function addNewPrinter(
  printer: IPrinterState,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function addNewPrinterThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    setPrinter(printer)
      .then((response: any) => {
        dispatch(addPrinter(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingPrinter(
  id: number,
  printer: IPrinterState,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function updateExistingPrinterThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    updatePrinter(id, printer)
      .then((response: any) => {
        dispatch(editPrinter(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function deletePrinter(id: number) {
  return async function deletePrinterThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delPrinter(id)
      .then((response: any) => {
        dispatch(editPrinter(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}
