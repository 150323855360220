import React from "react";

interface AccordionItem {
  id: any;
  title: any;
  body: React.ReactNode;
  key?: number;
}

interface AccordionProps {
  accordionItems: AccordionItem[];
  activeItem: number | null;
  id?: string;
  compact?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  accordionItems,
  activeItem,
  id,
  compact,
}) => {
  return (
    <div className="accordion m-0" id="accordionParentId">
      {accordionItems &&
        accordionItems.length > 0 &&
        accordionItems.map((item, index) => {
          return (
            item && (
              <div className={`accordion-item `} key={index}>
                <h2
                  className={`accordion-header ${compact ? "" : ""}  `}
                  id={`heading${item.id}`}
                >
                  <button
                    className={`accordion-button ${
                      compact ? "d-block m-0 p-0" : ""
                    } ${activeItem === item.id * 1 ? "active" : ""}`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#${id}${item.id}`}
                    type="button"
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`${id}${item.id}`}
                  className={`accordion-collapse collapse ${
                    activeItem === item.id ? "show" : ""
                  }`}
                >
                  <div
                    className={`accordion-body  ${
                      compact ? "p-0 m-0 ml-2" : ""
                    }`}
                  >
                    {item.body}
                  </div>
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};

export { Accordion };
