import React, { useState } from "react";

import { Formik } from "formik";

import { useAppDispatch } from "utils/helper";

import {
  addNewTax,
  deleteTax,
  updateExistingTax,
} from "store/ManageProducts/productTaxSlice";

import { ConfirmModal, ContentHeader } from "components";

import { OffCanvas, Container } from "components/core-components";

import { ProductTaxSchema } from "utils/schema";

import { PRODUCT_TAX_CONSTANTS } from "utils/constants";

import { TaxForm } from "./TaxForm";
import { TaxList } from "./TaxList";

const ProductTax = () => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState(false || {});

  const [currentTax, setCurrentTax] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const [editId, setEditId] = useState(0);

  const initialValues = {
    tax_name: "",
    tax_rate: "",
    is_active: "",
  };

  const handleSubmit = (values, action) => {
    let dataToSend = {
      ...values,
      is_active: values.is_active[0]?.value,
    };

    if (!edit) {
      dispatch(addNewTax(dataToSend, action));
    } else {
      dispatch(updateExistingTax(values.id, dataToSend, action));
    }
    setShow(false);
  };

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_HEADER} />
      <Container>
        <div className="col-12">
          <TaxList
            setEdit={setEdit}
            setShow={setShow}
            setEditId={setEditId}
            setCurrentTax={setCurrentTax}
            setConfirmModal={setConfirmModal}
          />
          <OffCanvas
            title={
              edit
                ? PRODUCT_TAX_CONSTANTS.EDIT_HEADER
                : PRODUCT_TAX_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
              setEdit(false);
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={edit ? edit : initialValues}
              validationSchema={ProductTaxSchema}
              onSubmit={handleSubmit}
            >
              {(props) => <TaxForm {...props} edit={edit} editId={editId} />}
            </Formik>
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={PRODUCT_TAX_CONSTANTS.DELETE_TAX}
        message={PRODUCT_TAX_CONSTANTS.DELETE_TAX_MSG}
        confirmClick={() => {
          dispatch(deleteTax(currentTax));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { ProductTax };
