import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import { deleteBranch, getBranches } from "store/ManageBranches/branchSlice";

import {
  ConfirmModal,
  ContentHeader,
  Loader,
  NoData,
  Status,
  TableTools,
} from "components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BRANCH_CONSTANTS,
  BUTTON_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { updatePageNo } from "store/commonSlice";

const Branches = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    branch: { branch: branchData, status },
    common: { current_page, total_items, total_pages, page_size },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [currentBranch, setCurrentBranch] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const branchPermission = permissions.find(
    (p) => p.module_name === "Manage Branches/Store"
  );

  const tableCols = BRANCH_CONSTANTS.BRANCH_COLS;

  const update_page = (pageNo: number) => {
    dispatch(getBranches({ active: false, pageNo, query }));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });
  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={BRANCH_CONSTANTS.BRANCH_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={branchPermission?.perm_add}
                path={BRANCH_CONSTANTS.PATH}
                label={BRANCH_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : branchData.length > 0 ? (
                    branchData.map((branch, index) => (
                      <tr key={index}>
                        <td>{page_size * (current_page - 1) + index + 1}</td>
                        <td>{branch?.store_name}</td>
                        <td>{branch?.store_code}</td>
                        <td>{branch?.outlet_type?.outlet_type}</td>
                        <td>
                          <Status is_active={branch?.is_active} />
                        </td>
                        <td>
                          <Button
                            text={<FontIcon icon={faPenToSquare} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            isDisabled={!branchPermission?.perm_edit}
                            btnClassNames={"btn btn-info btn-sm mr-2"}
                            onClickHandler={() =>
                              navigate(ROUTES_CONSTANTS.EDIT + "/" + branch?.id)
                            }
                          />
                          {branch?.is_active ? (
                            <Button
                              text={<FontIcon icon={faBan} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={!branchPermission?.perm_delete}
                              btnClassNames={"btn btn-danger btn-sm"}
                              onClickHandler={() => {
                                {
                                  setConfirmModal(true);
                                  setCurrentBranch(Number(branch?.id));
                                }
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={BRANCH_CONSTANTS.DELETE_BRANCH}
        message={BRANCH_CONSTANTS.DELETE_BRANCH_MSG}
        confirmClick={() => {
          dispatch(deleteBranch(currentBranch));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Branches };
