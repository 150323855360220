import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faCircleCheck,
  faCircleNotch,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  IconButton,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import { CustomInput } from "components/CustomInput";
import { Loader } from "components/Loader";
import { NoData } from "components/NoData";
import { Search } from "components/Search";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import {
  AddOrderPaymentMode,
  FetchCurrentOrderDetails,
  FilterOrder,
  resetOrderState,
  SendPayLaterWhatsAppReminder,
} from "store/POS";
import {
  CASHIER_CONSTANTS,
  displayValue,
  ERROR_CONSTANTS,
  financialYear,
  FORM_CONSTANTS,
  formatDateTime,
  GENERAL_CONSTANTS,
  IGetOrder,
  LOCAL_STORAGE_CONSTANTS,
  ORDER_CONSTANTS,
  POS_CONSTANTS,
  ROUTES,
  ScrollToTop,
  secondsToMidnight,
  setItemWithExpiry,
  STATUSES,
  TModalSize,
  to2Decimal,
  Toast,
  TOAST_CONSTANTS,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const PendingPayLaterList = ({ update_page, PropsRef, query, setQuery }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    branch: { branch: stores, selectedStore },
    common: { status: commonStatus },
    order: { orders: orderData, status: orderStatus },
    common: { current_page, total_items, total_pages, page_size },
  } = useAppSelector((state) => state.root);

  const [complete, setComplete] = useState<number>();

  const renderOrders = () => {
    return orderStatus === STATUSES.LOADING &&
      commonStatus.type === ORDER_CONSTANTS.FETCH_ORDER_LOADING_STATE &&
      commonStatus.state === STATUSES.LOADING ? (
      <tr>
        <td
          colSpan={CASHIER_CONSTANTS.PAY_LATER_HEADER.length}
          className="text-center"
        >
          <Loader />
        </td>
      </tr>
    ) : orderData.length > 0 ? (
      orderData.map((order: any, index) => {
        const paidAmount = order?.payments?.reduce(
          (acc, payment) => acc + payment.amount,
          0
        );
        return (
          <tr key={index}>
            <td>{page_size * (current_page - 1) + index + 1}</td>
            <td>{order.invoice_code}</td>
            <td>{order?.customer_name}</td>
            <td>
              {POS_CONSTANTS.RUPEE_SIGN}
              {order.payable_amount}
            </td>
            <td>{formatDateTime(order.created_at.toString())}</td>
            <td className="align-middle">
              <Button
                btnClassNames="btn table-button mx-1 btn-secondary"
                text={<FontIcon icon={faEye} />}
                onClickHandler={() => {
                  dispatch(
                    FetchCurrentOrderDetails(Number(order?.id), selectedStore)
                  );
                  navigate(`/admin/sales/orders/${order?.id}`);
                }}
              />
              <Button
                text={
                  commonStatus.type ===
                    ORDER_CONSTANTS.COMPLETE_ORDER_LOADING_STATE &&
                  commonStatus.state === STATUSES.LOADING &&
                  complete === index ? (
                    <FontIcon
                      icon={faCircleNotch}
                      iconClassNames={"fa-solid fa-circle-notch fa-spin"}
                    />
                  ) : (
                    <FontIcon icon={faCheck} />
                  )
                }
                btnClassNames="btn table-button mx-1 btn-success"
                onClickHandler={() => {
                  if (to2Decimal(order?.payable_amount - paidAmount)) {
                    setComplete(index);
                    localStorage.setItem(
                      LOCAL_STORAGE_CONSTANTS.PENDING_PAY_LATER_ORDERS,
                      JSON.stringify(PropsRef.current?.values)
                    );
                    const dataToSend = {
                      order_id: Number(order.id),
                      // employee: logged_in_user.id,
                      paid_amount: order?.payable_amount,
                      payments: [
                        {
                          order: Number(order.id),
                          payment_mode: POS_CONSTANTS.CASH_ID,
                          amount: to2Decimal(
                            order?.payable_amount - paidAmount
                          ),
                          remarks: GENERAL_CONSTANTS.EMPTY_STRING,
                          credit_note: GENERAL_CONSTANTS.EMPTY_STRING,
                        },
                      ],
                    };
                    dispatch(
                      AddOrderPaymentMode({
                        order: dataToSend,
                        navigate: navigate,
                        cashier_search: true,
                      })
                    );
                  } else {
                    Toast(
                      ERROR_CONSTANTS.SOMETHING_WENT_WRONG,
                      TOAST_CONSTANTS.ERROR
                    );
                  }
                }}
              />
              <Button
                text={
                  commonStatus.type ===
                    CASHIER_CONSTANTS.WHATSAPP_REMINDER_LOADING_STATE &&
                  commonStatus.state === STATUSES.LOADING &&
                  complete === index ? (
                    <FontIcon
                      icon={faCircleNotch}
                      iconClassNames={"fa-solid fa-circle-notch fa-spin"}
                    />
                  ) : (
                    <FontIcon icon={faWhatsapp} />
                  )
                }
                isDisabled={
                  order?.updated_at &&
                  moment(order?.updated_at).format(
                    GENERAL_CONSTANTS.DATE_FORMAT
                  ) === moment().format(GENERAL_CONSTANTS.DATE_FORMAT)
                }
                btnClassNames="btn table-button mx-1 btn-info"
                onClickHandler={() => {
                  setComplete(index);
                  dispatch(SendPayLaterWhatsAppReminder(Number(order?.id)));
                }}
              />
            </td>
          </tr>
        );
      })
    ) : (
      <NoData len={CASHIER_CONSTANTS.PAY_LATER_HEADER.length} />
    );
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  const store_code = selectedStore
    ? stores?.find((store) => store?.id === selectedStore)?.store_code
    : null;

  useEffect(() => {
    return () => {
      dispatch(resetOrderState());
      resetState();
    };
  }, [dispatch]);

  return (
    <Card>
      <CardHeader>
        <Formik
          initialValues={{
            start_date: moment()
              .subtract(1, "days")
              .format(GENERAL_CONSTANTS.DATE_FORMAT),
            end_date: moment().format(GENERAL_CONSTANTS.DATE_FORMAT),
          }}
          onSubmit={() => {}}
          enableReinitialize={true}
        >
          {(props) => {
            PropsRef.current = props;
            return (
              <Form>
                <div className="row">
                  <div className="col-12 d-flex flex-column flex-md-row align-items-center px-0">
                    <div className="col-md-3 mt-1">
                      <CustomInput
                        label={ORDER_CONSTANTS.START_DATE_LABEL}
                        // isRequired={true}
                        name={ORDER_CONSTANTS.START_DATE}
                        type={FORM_CONSTANTS.DATE}
                        max={
                          props?.values?.end_date ||
                          moment().format(GENERAL_CONSTANTS.DATE_FORMAT)
                        }
                        onChangeHandler={(e) => {
                          props.setFieldValue(
                            ORDER_CONSTANTS.START_DATE,
                            e.target.value
                          );
                          update_page(
                            1,
                            selectedStore,
                            query,
                            e.target.value,
                            props.values.end_date
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-3 mt-1">
                      <CustomInput
                        label={ORDER_CONSTANTS.END_DATE_LABEL}
                        // isRequired={true}
                        name={ORDER_CONSTANTS.END_DATE}
                        type={FORM_CONSTANTS.DATE}
                        min={props?.values?.start_date}
                        max={moment().format(GENERAL_CONSTANTS.DATE_FORMAT)}
                        onChangeHandler={(e) => {
                          props.setFieldValue(
                            ORDER_CONSTANTS.END_DATE,
                            e.target.value
                          );
                          update_page(
                            1,
                            selectedStore,
                            query,
                            props.values.start_date,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-6 px-md-0 mt-3">
                      <Search
                        placeholder={"Search by Customer Name"}
                        setQuery={setQuery}
                        searchByQuery={(value) => {
                          update_page(
                            1,
                            selectedStore,
                            value,
                            props.values.start_date,
                            props.values.end_date
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={CASHIER_CONSTANTS.PAY_LATER_HEADER} />
          <TableBody>{renderOrders()}</TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={
            orderStatus === STATUSES.LOADING &&
            commonStatus.type === ORDER_CONSTANTS.FETCH_ORDER_LOADING_STATE &&
            commonStatus.state === STATUSES.LOADING
          }
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { PendingPayLaterList };
