import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontIcon } from "components/core-components";

import { SidebarItem } from "./core-components/SidebarItem";
import {
  CustomRouteProps,
  ROUTES,
  SIDEBAR_CONSTANTS,
  STATUSES,
  useAppSelector,
} from "utils";

import logo from "assets/images/avaryaLogo.png";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "./Loader";
import { generatePermissionRoutes } from "routes";

const Sidebar = () => {
  const {
    auth: { permissions: routePermissions, status },
  } = useAppSelector((state) => state.root);

  const [openMenu, setOpenMenu] = useState("");

  const handleMenuClick = (menuName: string) => {
    setOpenMenu(menuName === openMenu ? "" : menuName);
  };

  const { customRouterObject } = generatePermissionRoutes(routePermissions);

  return (
    <aside
      key={"main-sidebar"}
      className="main-sidebar sidebar-dark-primary elevation-2"
      style={{ position: "sticky" }}
    >
      <Link to={ROUTES.DASHBOARD} className="brand-link text-center">
        <img src={logo} alt="Logo" className="img-responsive w-100 h-100" />
      </Link>
      <nav className="sidebar" style={{ paddingBottom: "8rem" }}>
        <ul
          className="nav nav-pills nav-sidebar d-flex flex-column nav-compact nav-child-indent nav-collapse-hide-child"
          role="menu"
        >
          {/* menu-open   active for active link */}
          <li className="nav-item" key={"dashboard"}>
            <Link
              to={ROUTES.DASHBOARD}
              className="nav-link d-flex flex-row align-items-center"
            >
              <FontIcon iconClassNames={"nav-icon"} icon={faTachometerAlt} />
              <p className="ml-1">{SIDEBAR_CONSTANTS.DASHBOARD}</p>
            </Link>
          </li>
          <li className="nav-header" key={"masters"}>
            {SIDEBAR_CONSTANTS.MASTERS}
          </li>
          {status !== STATUSES.LOADING ? (
            customRouterObject &&
            customRouterObject[2] &&
            customRouterObject[2].children
              .slice(1)
              .map((route: CustomRouteProps, index) => {
                return route?.permission?.perm_view ? (
                  <>
                    {route.ecommerce && (
                      <li className="nav-header" key={`ecommerce`}>
                        {SIDEBAR_CONSTANTS.ONLINE_STORE}
                      </li>
                    )}
                    <SidebarItem
                      key={`${route.route_name}-${index}`}
                      isOpen={route.route_name === openMenu}
                      onClickHandler={() => handleMenuClick(route.route_name)}
                      path={route.path}
                      icon={route.icon}
                      route_name={route.route_name}
                      subChildExists={route.subChildExists}
                      children={route.children}
                      ecommerce={route.ecommerce}
                    />
                  </>
                ) : null;
              })
          ) : (
            <li
              className=" align-self-center align-items-center"
              key={"loader"}
            >
              <Loader />
            </li>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export { Sidebar };
