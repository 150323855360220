import React, { memo, useEffect } from "react";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { ErrorMessage, FieldArray, Form } from "formik";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  MultiSelect,
} from "components";

import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  LABEL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { getAllPrinters } from "store/Utilities/printerSlice";
import {
  deleteLabelPrinterMapping,
  getAllLabelTemplates,
  getAllPrintSize,
  getAllPrintType,
} from "store/Utilities/labelSLice";

const LabelForm = ({ props, actionsRef, edit, editId, setConfirmModal }) => {
  const dispatch = useAppDispatch();

  const {
    label: {
      print_type: printTypedata,
      print_size: printSizedata,
      label_template: labelTemplateData,
    },
    printer: { printer: printerData },
  } = useAppSelector((state) => state.root);

  useEffect(() => {
    dispatch(getAllPrinters(true));
    dispatch(getAllPrintType(true));
    dispatch(getAllPrintSize(true));
    dispatch(getAllLabelTemplates(true));
  }, []);

  return (
    <Form>
      <div className="row">
        {/* Label Details */}
        <div className="col-12">
          <Card>
            <CardHeader>
              <h3 className="card-title">{LABEL_CONSTANTS.CARD_TITLE_1}</h3>
            </CardHeader>
            <CardBody>
              <div className="row">
                <MultiSelect
                  label={LABEL_CONSTANTS.LABEL_TEMPLATE_NAME_LABEL}
                  name={LABEL_CONSTANTS.LABEL_TEMPLATE_NAME}
                  options={labelTemplateData.map((item, index) => ({
                    value: item.id,
                    label: item.template_name,
                  }))}
                  value={props.values.template}
                  isRequired={true}
                />
                <ErrorMessage
                  name={LABEL_CONSTANTS.LABEL_TEMPLATE_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <MultiSelect
                  label={LABEL_CONSTANTS.PRINT_TYPE_LABEL}
                  name={LABEL_CONSTANTS.PRINT_TYPE}
                  value={props.values.print_type}
                  options={
                    printTypedata &&
                    printTypedata.length > 0 &&
                    printTypedata.map((item, index) => ({
                      value: item.id,
                      label: item.type,
                    }))
                  }
                  isRequired={true}
                />
                <ErrorMessage
                  name={LABEL_CONSTANTS.PRINT_TYPE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <MultiSelect
                  label={LABEL_CONSTANTS.LABEL_SIZE_LABEL}
                  name={LABEL_CONSTANTS.LABEL_SIZE}
                  value={props.values.size}
                  options={
                    printSizedata &&
                    printSizedata.length > 0 &&
                    printSizedata.map((item, index) => ({
                      value: item.id,
                      label: item.size,
                    }))
                  }
                  isRequired={true}
                />
                <ErrorMessage
                  name={LABEL_CONSTANTS.LABEL_SIZE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <MultiSelect
                  select={true}
                  label={LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER_LABEL}
                  name={LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER}
                  value={props.values.printers}
                  options={printerData.map((item, index) => ({
                    value: item.id,
                    label: item.printer_name,
                  }))}
                  isRequired={true}
                  onChangeHandler={(e, actions) => {
                    if (
                      (editId && actions.action == "remove-value") ||
                      actions.action == "pop-value" ||
                      actions.action == "clear"
                    ) {
                      actionsRef.current = actions;
                      setConfirmModal(true);
                    } else {
                      const selectedOptions = Array.isArray(e) ? e : [e];
                      props.setFieldValue(
                        LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER,
                        selectedOptions
                      );
                    }
                  }}
                />
                <ErrorMessage
                  name={LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {edit ? (
                <div className="row">
                  <MultiSelect
                    label={LABEL_CONSTANTS.LABEL_STATUS_LABEL}
                    name={LABEL_CONSTANTS.LABEL_STATUS}
                    options={LABEL_CONSTANTS.LABEL_STATUS_OPTIONS}
                    isRequired={true}
                  />
                  <ErrorMessage
                    name={LABEL_CONSTANTS.LABEL_STATUS}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </div>
        {/* Label Printer Mapping */}
        {/* <div className="col-md-12">
          <Card>
            <CardHeader>
              <h3 className="card-title">{LABEL_CONSTANTS.CARD_TITLE_2}</h3>
            </CardHeader>
            <CardBody>
              <div className="row">
                <FieldArray
                  name={LABEL_CONSTANTS.LABEL_PRINT_MAPPING}
                  render={({ remove, push }) =>
                    props.values.printer_mapping &&
                    props.values.printer_mapping.length > 0 &&
                    props.values.printer_mapping.map((printer, index) => (
                      <>
                        <div className="row">
                          <div className="col-md-10">
                            <MultiSelect
                              label={
                                LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER_LABEL
                              }
                              name={`${LABEL_CONSTANTS.LABEL_PRINT_MAPPING}.${index}.${LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER}`}
                              options={printerData.map((item, index) => ({
                                value: item.id,
                                label: item.printer_name,
                              }))}
                              isRequired={true}
                            />
                            <ErrorMessage
                              name={`${LABEL_CONSTANTS.LABEL_PRINT_MAPPING}.${index}.${LABEL_CONSTANTS.LABEL_PRINT_MAPPING_PRINTER}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-2 align-self-center mt-3">
                            <Button
                              type={BUTTON_CONSTANTS.BUTTON}
                              text={<FontIcon icon={faTrash} />}
                              btnClassNames={"btn btn-danger mr-2"}
                              isDisabled={
                                index === 0 &&
                                props.values.printer_mapping.length === 1
                              }
                              onClickHandler={() => {
                                remove(index);
                              }}
                            />
                          </div>
                        </div>
                        {props.values.printer_mapping.length - 1 === index ? (
                          <div className="row">
                            <Button
                              type={BUTTON_CONSTANTS.BUTTON}
                              text={LABEL_CONSTANTS.ADD_PRINTER}
                              btnClassNames={"btn btn-outline-primary"}
                              onClickHandler={() => {
                                push({
                                  printer: "",
                                  new: true,
                                });
                              }}
                            />
                          </div>
                        ) : null}
                      </>
                    ))
                  }
                />
              </div>
            </CardBody>
          </Card>
        </div> */}
        {/* Submit */}
        <div className="col-12">
          <Button
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export default memo(LabelForm);
