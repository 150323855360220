import React, { useEffect, useState } from "react";

import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { deleteTax, getTaxes } from "store/ManageProducts/productTaxSlice";

import { getHSN } from "store/hsnCodesSlice";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";

import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  PRODUCT_TAX_CONSTANTS,
  ProductTaxType,
  STATUSES,
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";
import { updatePageNo } from "store/commonSlice";

const TaxList = ({
  setEdit,
  setShow,
  setEditId,
  setConfirmModal,
  setCurrentTax,
}) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    productTax: { productTax: taxData, status },
  } = useAppSelector((state) => state.root);

  const taxPermission = permissions.find(
    (p) => p.module_name === "Manage Product Tax"
  );

  const [query, setQuery] = useState("");

  const update_page = (pageNo: number) => {
    dispatch(getTaxes(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_COLS;

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={taxPermission?.perm_add}
          path={PRODUCT_TAX_CONSTANTS.PATH}
          label={PRODUCT_TAX_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : taxData && taxData.length > 0 ? (
              taxData.map((tax: ProductTaxType, index) => {
                return (
                  <tr key={index}>
                    <td>{page_size * (current_page - 1) + index + 1}</td>
                    <td>{tax.tax_name}</td>
                    <td>{tax.tax_rate}</td>
                    <td>
                      <Status is_active={tax.is_active} />
                    </td>
                    <td>
                      <Button
                        text={<FontIcon icon={faPenToSquare} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        isDisabled={!taxPermission?.perm_edit}
                        btnClassNames={"btn btn-info btn-sm mr-2"}
                        onClickHandler={() => {
                          let editData = {
                            ...tax,
                            is_active: [
                              {
                                label:
                                  tax.is_active === 1
                                    ? GENERAL_CONSTANTS.ACTIVE
                                    : GENERAL_CONSTANTS.INACTIVE,
                                value: tax.is_active,
                              },
                            ],
                          };

                          setEditId(tax.id);
                          setShow(true);
                          setEdit(editData);
                        }}
                      />
                      {tax.is_active ? (
                        <Button
                          text={<FontIcon icon={faBan} />}
                          type={BUTTON_CONSTANTS.BUTTON}
                          isDisabled={!taxPermission?.perm_delete}
                          btnClassNames={"btn btn-danger btn-sm"}
                          onClickHandler={() => {
                            setCurrentTax(Number(tax.id));
                            setConfirmModal(true);
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={status === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { TaxList };
