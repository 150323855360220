import { CustomMultiSelect, Modal } from "components/core-components";
import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getBranches,
  setSelectedStore,
} from "store/ManageBranches/branchSlice";
import { LastOrderDetails } from "store/POS";
import {
  POS_MODAL_CONSTANTS,
  ROUTES,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  update?: (value) => void;
}

const SelectStoreModal = ({ modal, setModal, update }: IProps) => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    branch: { branch: branchData, selectedStore, status: branchStatus },
  } = useAppSelector((state) => state.root);

  const navigate = useNavigate();

  // Construct options for store
  const getStoreOptions = useMemo(() => {
    return branchData && branchData.length > 0
      ? branchData.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : [];
  }, [branchData]);

  return (
    <Modal
      testId="select-store"
      modalHeight={POS_MODAL_CONSTANTS.MODAL_HEIGHT_SM}
      centered={true}
      title={POS_MODAL_CONSTANTS.SELECT_STORE}
      showModal={modal}
      onClickHandler={() => {
        // If store is not selected, navigate to dashboard and close modal
        if (selectedStore === 0) {
          navigate(ROUTES.DASHBOARD);
        }
        setSearchParams({});
        selectedStore !== null && setModal(false);
      }}
    >
      <div className="col-12" data-testid={"select-store-modal"}>
        {/* CustomMultiSelect component is used to select store. */}
        <CustomMultiSelect
          name={"select-store"}
          placeholder={POS_MODAL_CONSTANTS.SELECT_STORE}
          options={getStoreOptions}
          onChangeHandler={(e) => {
            // If store is selected, set selected store and close modal
            e && e.value && dispatch(setSelectedStore(Number(e.value)));
            e && e.value && dispatch(LastOrderDetails(Number(e.value)));
            //if store is ecommerce store set is_ecommerce_store to true
            setSearchParams({});
            setModal(false);
            update && update(Number(e.value));
          }}
          onFocusHandler={() => {
            // If branchData is empty, fetch branches
            branchData.length === 0 && dispatch(getBranches({ active: true }));
          }}
          isLoading={branchStatus === STATUSES.LOADING}
        />
      </div>
    </Modal>
  );
};

export { SelectStoreModal };
