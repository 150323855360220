import React, { memo, useState } from "react";

import { ErrorMessage, FieldArray } from "formik";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
  VENDORS_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import {
  Accordion,
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  MultiSelect,
} from "components";
import VendorProductAlias from "./VendorProductAlias";
import { faAdd, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  deleteOrderDeliveryDays,
  deleteStoreSpecificProduct,
  deleteVendorStore,
} from "store/ManageVendors/vendorSlice";
import { useParams } from "react-router-dom";
import { getBranches } from "store/ManageBranches/branchSlice";
import { getUOMs } from "store/uomSlice";

const VendorProductDetailsForm = ({
  props,
  setCurrentStore,
  setCurrentFilledStore,
  setSelectedProduct,
  setStoreModal,
  DeletedOrderValues,
  DeletedDeliveryValues,
}) => {
  const dispatch = useAppDispatch();

  const {
    branch: { branch: branchData, status: branchStatus },
    product: { products: productData, status: productStatus },
    productTax: { productTax: productTaxData, status: productTaxStatus },
    uom: { uom: uomData, status: uomStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();
  const currentId = Number(id);

  const [activeProductItem, setActiveProductItem] = useState(null);
  const [activeStoreItem, setActiveStoreItem] = useState(null);

  const deletedDeliverOrderValues = [];

  const handleProductToggle = (index) => {
    setActiveProductItem((prevItem) => (prevItem === index ? null : index));
  };

  const handleStoreToggle = (index) => {
    setActiveStoreItem((prevItem) => (prevItem === index ? null : index));
  };

  const compareStore = (stores, props) => {
    const selectedStore = [];
    props.values.stores &&
      props.values.stores.length > 0 &&
      props.values.stores.map((item) => {
        selectedStore.push(item?.store[0]?.value);
      });
    return stores.filter((item) => !selectedStore.includes(item.id));
  };

  const daysOfWeek = VENDORS_CONSTANTS.DAYS;

  return (
    props.values.stores &&
    props.values.stores.length > 0 && (
      <div className="col-md-12">
        <Card>
          <CardHeader>
            <h5 className="card-title">{VENDORS_CONSTANTS.CARD_TITLE_6}</h5>
          </CardHeader>
          <CardBody>
            <FieldArray
              name={VENDORS_CONSTANTS.STORES}
              render={({ push: pushStore, remove: removeStore }) => {
                const accordionStoreItems =
                  props.values.stores &&
                  props.values?.stores?.map((store, index) => ({
                    id: index,
                    title:
                      props.values.stores[index]?.store[0]?.label || `Store`,
                    body: (
                      <div className="row">
                        <div className="col-11">
                          <div className="row">
                            <MultiSelect
                              label={VENDORS_CONSTANTS.PRODUCT_STORE_LABEL}
                              select={false}
                              isRequired={true}
                              name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_STORE}`}
                              onFocusHandler={() => {
                                branchData.length === 0 &&
                                  dispatch(getBranches({ active: true }));
                              }}
                              options={compareStore(branchData, props).map(
                                (item) => ({
                                  label: item.store_name,
                                  value: item.id,
                                })
                              )}
                              isLoading={branchStatus === STATUSES.LOADING}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_STORE}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <FieldArray
                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}`}
                                render={({
                                  push: pushProduct,
                                  remove: removeProduct,
                                }) => {
                                  const accordionProductItems = props.values?.stores[
                                    index
                                  ]?.products?.map((product, productIndex) => ({
                                    id: productIndex,
                                    title:
                                      (product &&
                                        product.product &&
                                        product.product[0]?.label) ||
                                      `Choose Product `,
                                    body: (
                                      <div className="row">
                                        <div className="col-md-11">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <MultiSelect
                                                label={
                                                  VENDORS_CONSTANTS.PRODUCT_NAME_LABEL
                                                }
                                                select={false}
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PRODUCT_NAME}`}
                                                options={productData.map(
                                                  (item) => ({
                                                    value: item.id,
                                                    label: item.print_name,
                                                  })
                                                )}
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PRODUCT_NAME}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <MultiSelect
                                                select={false}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PRODUCT_GST_INCLUDED}`}
                                                label={
                                                  VENDORS_CONSTANTS.PRODUCT_GST_INCLUDED_LABEL
                                                }
                                                options={
                                                  VENDORS_CONSTANTS.PRODUCT_GST_INCLUDED_OPTIONS
                                                }
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PRODUCT_GST_INCLUDED}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <MultiSelect
                                                select={false}
                                                label={
                                                  VENDORS_CONSTANTS.PRODUCT_UOM_LABEL
                                                }
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PRODUCT_UOM}`}
                                                onFocusHandler={() => {
                                                  uomData.length === 0 &&
                                                    dispatch(getUOMs(true));
                                                }}
                                                options={uomData.map(
                                                  (item) => ({
                                                    value: item.id,
                                                    label: item.uom_name,
                                                  })
                                                )}
                                                isLoading={
                                                  uomStatus === STATUSES.LOADING
                                                }
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PRODUCT_UOM}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <CustomInput
                                                label={
                                                  VENDORS_CONSTANTS.PURCHASE_PRICE_LABEL
                                                }
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PURCHASE_PRICE}`}
                                                placeholder={
                                                  VENDORS_CONSTANTS.PURCHASE_PRICE_PLACEHOLDER
                                                }
                                                type={FORM_CONSTANTS.NUMBER}
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PURCHASE_PRICE}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <CustomInput
                                                label={
                                                  VENDORS_CONSTANTS.LANDING_PRICE_LABEL
                                                }
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.LANDING_PRICE}`}
                                                placeholder={
                                                  VENDORS_CONSTANTS.LANDING_PRICE_PLACEHOLDER
                                                }
                                                type={FORM_CONSTANTS.NUMBER}
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.LANDING_PRICE}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                          </div>
                                          {/* <div className="row">
                                                  <div className="col-md-6">
                                                    <CustomInput
                                                      label={
                                                        VENDORS_CONSTANTS.TAT_MIN_DAYS_LABEL
                                                      }
                                                      isRequired={true}
                                                      name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ESTIMATE_TAT}.${VENDORS_CONSTANTS.TAT_MIN_DAYS}`}
                                                      placeholder={
                                                        VENDORS_CONSTANTS.TAT_MIN_DAYS_PLACEHOLDER
                                                      }
                                                      type={
                                                        FORM_CONSTANTS.NUMBER
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ESTIMATE_TAT}.${VENDORS_CONSTANTS.TAT_MIN_DAYS}`}
                                                      component={
                                                        FORM_CONSTANTS.ERROR_PARENT
                                                      }
                                                      className={
                                                        FORM_CONSTANTS.ERROR
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-6">
                                                    <CustomInput
                                                      label={
                                                        VENDORS_CONSTANTS.TAT_MAX_DAYS_LABEL
                                                      }
                                                      isRequired={true}
                                                      name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ESTIMATE_TAT}.${VENDORS_CONSTANTS.TAT_MAX_DAYS}`}
                                                      placeholder={
                                                        VENDORS_CONSTANTS.TAT_MAX_DAYS_PLACEHOLDER
                                                      }
                                                      type={
                                                        FORM_CONSTANTS.NUMBER
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ESTIMATE_TAT}.${VENDORS_CONSTANTS.TAT_MAX_DAYS}`}
                                                      component={
                                                        FORM_CONSTANTS.ERROR_PARENT
                                                      }
                                                      className={
                                                        FORM_CONSTANTS.ERROR
                                                      }
                                                    />
                                                  </div>
                                                </div> */}
                                          <div className="row">
                                            {/* <div className="col-md-6">
                                                    <MultiSelect
                                                      label={
                                                        VENDORS_CONSTANTS.PURCHASE_TAX_LABEL
                                                      }
                                                      select={false}
                                                      isRequired={true}
                                                      name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PURCHASE_TAX}`}
                                                      options={[
                                                        {
                                                          value: 1,
                                                          label: "Yes",
                                                        },
                                                        {
                                                          value: 0,
                                                          label: "No",
                                                        },
                                                      ]}
                                                    />
                                                    <ErrorMessage
                                                      name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PURCHASE_TAX}`}
                                                      component={
                                                        FORM_CONSTANTS.ERROR_PARENT
                                                      }
                                                      className={
                                                        FORM_CONSTANTS.ERROR
                                                      }
                                                    />
                                                  </div> */}
                                            <div className="col-md-6">
                                              <CustomInput
                                                label={
                                                  VENDORS_CONSTANTS.MOQ_LABEL
                                                }
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.MOQ}`}
                                                placeholder={
                                                  VENDORS_CONSTANTS.MOQ_PLACEHOLDER
                                                }
                                                type={FORM_CONSTANTS.NUMBER}
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.MOQ}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <CustomInput
                                                label={
                                                  VENDORS_CONSTANTS.MOQ_PIECES_LABEL
                                                }
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.MOQ_PIECES}`}
                                                placeholder={
                                                  VENDORS_CONSTANTS.MOQ_PIECES_PLACEHOLDER
                                                }
                                                type={FORM_CONSTANTS.NUMBER}
                                              />
                                              <ErrorMessage
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.MOQ_PIECES}`}
                                                component={
                                                  FORM_CONSTANTS.ERROR_PARENT
                                                }
                                                className={FORM_CONSTANTS.ERROR}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <MultiSelect
                                                label={
                                                  VENDORS_CONSTANTS.PREFERRED_DAYS_OF_ORDERING_LABEL
                                                }
                                                select={true}
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PREFERRED_DAYS_OF_ORDERING}`}
                                                options={daysOfWeek.map(
                                                  (day, dayIndex) => ({
                                                    value: dayIndex,
                                                    label: day,
                                                  })
                                                )}
                                                onChangeHandler={(
                                                  e,
                                                  actions
                                                ) => {
                                                  const selectedOptions = Array.isArray(
                                                    e
                                                  )
                                                    ? e
                                                    : [e];
                                                  let currentName = `${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PREFERRED_DAYS_OF_ORDERING}`;
                                                  props.setFieldValue(
                                                    currentName,
                                                    selectedOptions
                                                  );
                                                  if (
                                                    currentId &&
                                                    !props.values.stores[index]
                                                      .products[productIndex]
                                                      .new
                                                  ) {
                                                    if (
                                                      actions.action ==
                                                        "remove-value" ||
                                                      actions.action ==
                                                        "pop-value"
                                                    ) {
                                                      dispatch(
                                                        deleteOrderDeliveryDays(
                                                          actions.removedValue
                                                        )
                                                      );
                                                      DeletedOrderValues.push(
                                                        actions.removedValue
                                                      );
                                                    } else if (
                                                      actions.action == "clear"
                                                    ) {
                                                      actions.removedValues.forEach(
                                                        (item) => {
                                                          dispatch(
                                                            deleteOrderDeliveryDays(
                                                              actions.removedValue
                                                            )
                                                          );

                                                          DeletedOrderValues.push(
                                                            item
                                                          );
                                                        }
                                                      );
                                                    }
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <MultiSelect
                                                label={
                                                  VENDORS_CONSTANTS.PREFERRED_DAYS_OF_DELIVERY_LABEL
                                                }
                                                select={true}
                                                isRequired={true}
                                                name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PREFERRED_DAYS_OF_DELIVERY}`}
                                                options={daysOfWeek.map(
                                                  (day, dayIndex) => ({
                                                    value: dayIndex,
                                                    label: day,
                                                  })
                                                )}
                                                onChangeHandler={(
                                                  e,
                                                  actions
                                                ) => {
                                                  const selectedOptions = Array.isArray(
                                                    e
                                                  )
                                                    ? e
                                                    : [e];
                                                  let currentName = `${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.PREFERRED_DAYS_OF_DELIVERY}`;
                                                  props.setFieldValue(
                                                    currentName,
                                                    selectedOptions
                                                  );
                                                  if (
                                                    currentId &&
                                                    !props.values.stores[index]
                                                      .products[productIndex]
                                                      .new
                                                  ) {
                                                    if (
                                                      actions.action ==
                                                        "remove-value" ||
                                                      actions.action ==
                                                        "pop-value"
                                                    ) {
                                                      dispatch(
                                                        deleteOrderDeliveryDays(
                                                          actions.removedValue
                                                        )
                                                      );
                                                      DeletedDeliveryValues.push(
                                                        actions.removedValue
                                                      );
                                                    } else if (
                                                      actions.action == "clear"
                                                    ) {
                                                      actions.removedValues.forEach(
                                                        (item) => {
                                                          dispatch(
                                                            deleteOrderDeliveryDays(
                                                              actions.removedValue
                                                            )
                                                          );

                                                          DeletedDeliveryValues.push(
                                                            item
                                                          );
                                                        }
                                                      );
                                                    }
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <VendorProductAlias
                                              id={id}
                                              product={product}
                                              productIndex={productIndex}
                                              index={index}
                                              props={props}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-1">
                                          <Button
                                            isDisabled={
                                              !props.values.stores[index]
                                                .store[0]
                                            }
                                            type={BUTTON_CONSTANTS.BUTTON}
                                            text={<FontIcon icon={faCopy} />}
                                            btnClassNames="btn btn-secondary m-1"
                                            onClickHandler={() => {
                                              setStoreModal(true);
                                              setSelectedProduct(
                                                props.values?.stores[index]
                                                  ?.products[productIndex]
                                              );
                                              setCurrentStore(
                                                props.values?.stores[index]
                                              );
                                              setCurrentFilledStore(
                                                props.values.stores
                                              );
                                            }}
                                          />
                                          <Button
                                            type={BUTTON_CONSTANTS.BUTTON}
                                            text={<FontIcon icon={faTrash} />}
                                            btnClassNames={"btn btn-danger m-1"}
                                            isDisabled={
                                              props.values.stores[index]
                                                .products.length === 1
                                            }
                                            onClickHandler={() => {
                                              removeProduct(productIndex);
                                              if (
                                                currentId &&
                                                !props.values.stores[index]
                                                  .products[productIndex].new
                                              ) {
                                                dispatch(
                                                  deleteStoreSpecificProduct({
                                                    store:
                                                      props.values.stores[index]
                                                        .id,
                                                    product:
                                                      product?.product[0]
                                                        ?.value,
                                                  })
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ),
                                  }));
                                  return (
                                    <>
                                      <Accordion
                                        accordionItems={accordionProductItems}
                                        activeItem={activeProductItem}
                                        id={"product-toggle"}
                                      />
                                      <div className="d-grid my-2">
                                        <Button
                                          type={BUTTON_CONSTANTS.BUTTON}
                                          text={<FontIcon icon={faAdd} />}
                                          btnClassNames={
                                            "btn btn-outline-primary btn-block"
                                          }
                                          onClickHandler={() => {
                                            pushProduct({
                                              product: "",
                                              purchase_price: "",
                                              landing_cost: "",
                                              purchase_tax_included: [
                                                { value: 1, label: "Yes" },
                                              ],
                                              // turn_around_time: {
                                              //   min_days: "",
                                              //   max_days: "",
                                              // },
                                              moq: 1,
                                              moq_pieces: "",
                                              preferred_ordering_days: [],
                                              preferred_delivery_days: [],
                                              alias: [
                                                {
                                                  product_alias_name: "",
                                                  new: true,
                                                },
                                              ],
                                              new: true,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <Button
                            type={BUTTON_CONSTANTS.BUTTON}
                            text={<FontIcon icon={faCopy} />}
                            btnClassNames={"btn btn-secondary table-button m-1"}
                            onClickHandler={() => {
                              const newRow = {
                                store: "",
                                new: true,
                                products: [
                                  {
                                    product: "",
                                    purchase_price: "",
                                    landing_cost: "",
                                    purchase_tax_included: [
                                      { value: 1, label: "Yes" },
                                    ],
                                    gst_included: "",
                                    uom: "",
                                    moq: 1,
                                    moq_pieces: "",
                                    preferred_ordering_days: [],
                                    preferred_delivery_days: [],
                                    alias: [
                                      {
                                        product_alias_name: "",
                                        new: true,
                                      },
                                    ],
                                    // turn_around_time: {
                                    //   min_days: "",
                                    //   max_days: "",
                                    // },
                                    new: true,
                                  },
                                ],
                              };
                              newRow.products =
                                props.values.stores[index].products;
                              pushStore(newRow);
                            }}
                          />
                          <Button
                            type={BUTTON_CONSTANTS.BUTTON}
                            text={<FontIcon icon={faTrash} />}
                            btnClassNames={"btn btn-danger table-button m-1"}
                            isDisabled={
                              index * 1 === 0 &&
                              props.values.stores.length === 1
                            }
                            onClickHandler={() => {
                              removeStore(index);
                              if (
                                currentId &&
                                !props.values.stores[index].new
                              ) {
                                dispatch(
                                  deleteVendorStore({
                                    vendor: currentId,
                                    store: store?.store[0]?.value,
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    ),
                  }));
                return (
                  <>
                    <Accordion
                      accordionItems={accordionStoreItems}
                      activeItem={activeStoreItem}
                      id={"store-toggle"}
                    />
                    <div className="d-grid my-2">
                      <Button
                        type={BUTTON_CONSTANTS.BUTTON}
                        text={<FontIcon icon={faAdd} />}
                        btnClassNames={"btn btn-outline-primary btn-block"}
                        onClickHandler={() =>
                          pushStore({
                            store: "",
                            new: true,
                            products: [
                              {
                                product: "",
                                purchase_price: "",
                                landing_cost: "",
                                purchase_tax_included: [
                                  { value: 1, label: "Yes" },
                                ],
                                // turn_around_time: {
                                //   min_days: "",
                                //   max_days: "",
                                // },
                                moq: 1,
                                moq_pieces: "",
                                preferred_ordering_days: [],
                                preferred_delivery_days: [],

                                alias: [
                                  {
                                    product_alias_name: "",
                                    new: true,
                                  },
                                ],
                                new: true,
                              },
                            ],
                          })
                        }
                      />
                    </div>
                  </>
                );
              }}
            />
          </CardBody>
        </Card>
      </div>
    )
  );
};

export default memo(VendorProductDetailsForm);
