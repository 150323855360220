import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  Table,
  TableBody,
} from "components";
import { Field, FieldArray } from "formik";
import React, { memo } from "react";
import { FORM_CONSTANTS, PRODUCTS_CONSTANTS, useAppSelector } from "utils";

const ProductMSQDetailsForm = memo(function ProductMSQDetailsForm({
  props,
}: any) {
  const {
    branch: { branch: branchData },
  } = useAppSelector((state) => state.root);

  const daysOfWeek = FORM_CONSTANTS.DAYS_OF_WEEK;
  return (
    <div className="col-md-12 table-responsive ">
      <Card>
        <CardHeader>
          <h5 className="card-title">MSQ</h5>
        </CardHeader>
        <CardBody>
          <FieldArray
            name={PRODUCTS_CONSTANTS.PRODUCT_MSQ}
            render={(arrayHelpers) => {
              const bulk = branchData.map((branch, index) => ({
                ...props.values?.minimum_selling_quantity?.[index],
                store: branch.store_name,
                days: {
                  Mon: props.values?.bulk_msq_Mon,
                  Tue: props.values?.bulk_msq_Mon,
                  Wed: props.values?.bulk_msq_Mon,
                  Thu: props.values?.bulk_msq_Mon,
                  Fri: props.values?.bulk_msq_Mon,
                  Sat: props.values?.bulk_msq_Mon,
                  Sun: props.values?.bulk_msq_Mon,
                },
              }));
              return (
                <Table>
                  <thead>
                    <tr>
                      <th
                        rowSpan={2}
                        className="col-2 align-middle text-center"
                      >
                        {PRODUCTS_CONSTANTS.PRODUCT_MSQ_STORE_PLACEHOLDER}
                      </th>
                      <th colSpan={7} className="col-8 text-center">
                        {PRODUCTS_CONSTANTS.PRODUCT_MSQ_DAYS_PLACEHOLDER}
                      </th>
                    </tr>
                    <tr>
                      {daysOfWeek.map((day, dayIndex) => (
                        <th scope="col" key={dayIndex} className="text-center">
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <TableBody>
                    <tr>
                      <td className="align-middle text-center">All</td>
                      {daysOfWeek.map((day, dayIndex) => (
                        <td key={dayIndex} className="align-middle">
                          <Field
                            as={CustomInput}
                            name={`bulk_msq_${day}`}
                            type={FORM_CONSTANTS.NUMBER}
                            placeholder={day}
                            onBlurHandler={() => {
                              if (day === "Mon") {
                                daysOfWeek.map((item) => {
                                  props.setFieldValue(
                                    `bulk_msq_${item}`,
                                    props.values[`bulk_msq_Mon`]
                                  );
                                });
                                props.setFieldValue(
                                  "minimum_selling_quantity",
                                  bulk
                                );
                              } else {
                                branchData.map((item, index) => {
                                  props.setFieldValue(
                                    `minimum_selling_quantity[${index}].days.${day}`,
                                    props.values[`bulk_msq_${day}`]
                                  );
                                });
                              }
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                    {props.values?.minimum_selling_quantity?.map(
                      (msq, index) => (
                        <tr key={index}>
                          <td>
                            <CustomInput
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_MSQ}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_MSQ_STORE}`}
                              isDisabled={true}
                              type={FORM_CONSTANTS.TEXT}
                            />
                          </td>
                          {daysOfWeek.map((day, dayIndex) => (
                            <td key={dayIndex} className="align-middle">
                              <Field
                                as={CustomInput}
                                name={`${PRODUCTS_CONSTANTS.PRODUCT_MSQ}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_MSQ_DAYS}.${day}`}
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={day}
                              />
                            </td>
                          ))}
                        </tr>
                      )
                    )}
                  </TableBody>
                </Table>
              );
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
});

export { ProductMSQDetailsForm };
