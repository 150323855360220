import { ConfirmModal } from "components/ConfirmModal";
import { Button, FontIcon, Modal } from "components/core-components";
import React, { useEffect, useState } from "react";
import { setSelectedStore } from "store/ManageBranches/branchSlice";
import { LastOrderDetails, ResetWholeCart } from "store/POS";
import {
  ACCESS_CONSTANTS,
  POS_CART_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { SelectedStoreDetails } from "./SelectedStoreDetails";
import {
  faCartShopping,
  faListCheck,
  faPause,
  faReceipt,
  faStore,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CustomerDetails from "./CustomerDetails";
import { PrintOptionsModal } from "./PrintOptionsModal";
import LastBillDetails from "./LastBillDetails";
import { OrdersModal } from "./OrdersModal";
import { HoldBillModal } from "./HoldBillModal";

const NavbarAdditions = () => {
  const dispatch = useAppDispatch();

  const {
    customer: { customerToEdit: customer_details },
  } = useAppSelector((state) => state.root);

  const [dropdown, setDropdown] = useState<boolean>(false);

  const dropdownRef = React.createRef<HTMLLIElement>();

  const [ordersModal, setOrdersModal] = useState<boolean>(false);

  const [holdBillModal, setHoldBillModal] = useState<boolean>(false);

  const [store, setStore] = useState<number>(0);

  const [confirmChangeStore, setConfirmChangeStore] = useState<boolean>(false);

  const [selectedStoreModal, setSelectedStoreModal] = useState<boolean>(false);

  const [customerDetailsModal, setCustomerDetailsModal] = useState<boolean>(
    false
  );

  const [lastBillDetailsModal, setLastBillDetailsModal] = useState<boolean>(
    false
  );

  const [printOptionsModal, setPrintOptionsModal] = useState<boolean>(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  return (
    <>
      <li className="nav-item btn-group" ref={dropdownRef}>
        <Button
          text={<FontIcon icon={faListCheck} iconClassNames="text-dark" />}
          btnClassNames="btn nav-link border-0"
          onClickHandler={() => {
            setDropdown(!dropdown);
          }}
        />
        <ul
          className={`dropdown-menu dropdown-menu-right ${
            dropdown ? "show" : ""
          }`}
        >
          <li className="dropdown-item">
            <div
              className="row text-sm"
              onClick={() => {
                setOrdersModal(true);
                setDropdown(false);
              }}
            >
              <div className="col-3 d-flex align-self-center justify-content-center">
                <FontIcon icon={faCartShopping} />
              </div>
              <div className="col-9">{POS_CART_CONSTANTS.ORDERS}</div>
            </div>
          </li>
          <li className="dropdown-item">
            <div
              className="row text-sm"
              onClick={() => {
                setHoldBillModal(true);
                setDropdown(false);
              }}
            >
              <div className="col-3 d-flex align-self-center justify-content-center">
                <FontIcon icon={faPause} />
              </div>
              <div className="col-9">{POS_CART_CONSTANTS.HOLD_BILL}</div>
            </div>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <Button
          text={<FontIcon icon={faStore} iconClassNames="text-dark" />}
          btnClassNames="btn nav-link border-0"
          onClickHandler={() => {
            setSelectedStoreModal(true);
          }}
        />
      </li>
      {customer_details.id !== ACCESS_CONSTANTS.CASH_SALES_ID && (
        <li className="nav-item">
          <Button
            text={<FontIcon icon={faUser} iconClassNames="text-dark" />}
            btnClassNames="btn nav-link border-0"
            onClickHandler={() => {
              setCustomerDetailsModal(true);
            }}
          />
        </li>
      )}
      <li className="nav-item">
        <Button
          text={<FontIcon icon={faReceipt} iconClassNames="text-dark" />}
          btnClassNames="btn nav-link border-0"
          onClickHandler={() => {
            setLastBillDetailsModal(true);
          }}
        />
      </li>
      <li className="nav-item">
        <Button
          text={<FontIcon icon={faTrash} iconClassNames="text-dark" />}
          btnClassNames="btn nav-link border-0"
          onClickHandler={() => {
            dispatch(ResetWholeCart());
          }}
        />
      </li>
      {selectedStoreModal && (
        <Modal
          modalClassNames="w-100"
          title={POS_CART_CONSTANTS.SELECTED_STORE_DETAILS}
          showModal={selectedStoreModal}
          onClickHandler={() => {
            setSelectedStoreModal(false);
          }}
        >
          <SelectedStoreDetails
            setStore={setStore}
            setConfirmChangeStore={setConfirmChangeStore}
            modal={true}
          />
        </Modal>
      )}
      {customerDetailsModal && (
        <Modal
          modalClassNames="w-100"
          title={POS_CART_CONSTANTS.CUSTOMER_DETAILS}
          showModal={customerDetailsModal}
          onClickHandler={() => {
            setCustomerDetailsModal(false);
          }}
        >
          <CustomerDetails modal={true} />
        </Modal>
      )}
      {lastBillDetailsModal && (
        <Modal
          modalClassNames="w-100"
          title={POS_CART_CONSTANTS.LAST_BILL_DETAILS}
          showModal={lastBillDetailsModal}
          onClickHandler={() => {
            setLastBillDetailsModal(false);
          }}
        >
          <LastBillDetails
            setPrintOptionsModal={setPrintOptionsModal}
            modal={true}
          />
        </Modal>
      )}
      {ordersModal && (
        <OrdersModal
          modal={ordersModal}
          setModal={setOrdersModal}
          fullScreenSize="xxl"
        />
      )}
      {holdBillModal && (
        <HoldBillModal modal={holdBillModal} setModal={setHoldBillModal} />
      )}
      <ConfirmModal
        modal={confirmChangeStore}
        setModal={setConfirmChangeStore}
        title=""
        message={`${POS_CART_CONSTANTS.RESET_MESSAGE} ${POS_CART_CONSTANTS.CHANGE_STORE_MESSAGE}`}
        confirmClick={() => {
          dispatch(LastOrderDetails(store));
          dispatch(setSelectedStore(store));
          dispatch(ResetWholeCart());
          setConfirmChangeStore(false);
          setStore(0);
        }}
      />
      <PrintOptionsModal
        modal={printOptionsModal}
        setModal={setPrintOptionsModal}
      />
    </>
  );
};

export { NavbarAdditions };
