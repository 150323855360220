import { Button, CustomInput, OffCanvas } from "components";
import { ErrorMessage, Formik } from "formik";
import React from "react";
import {
  BUTTON_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { CustomerAddressDetailsForm } from "./CustomersForm";
import { RootState } from "store/store";
import { manageEcommerceCustomerAddresses } from "store/Ecommerce";

const AddAddressOffCanvas = ({ show, setShow }) => {
  const dispatch = useAppDispatch();

  const {
    root: {
      customer: { customers: customerData, customerToEdit: customer_details },
    },
  }: RootState = useAppSelector((state) => state);

  return (
    <OffCanvas
      title={`Add ${show.type} Address`}
      show={show.state}
      onClickHandler={() => setShow({ state: false, type: "" })}
    >
      {show.state && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            address: {
              first_name: "",
              last_name: "",
              address_line_1: "",
              address_line_2: "",
              landmark: "",
              city: FORM_CONSTANTS.DEFAULT_CITY,
              state: FORM_CONSTANTS.DEFAULT_STATE,
              country: FORM_CONSTANTS.DEFAULT_COUNTRY,
              pincode: FORM_CONSTANTS.DEFAULT_PINCODE,
            },
          }}
          onSubmit={(values, action) => {
            const address: any = {
              ...values.address,
              first_name: values.address.first_name,
              last_name: values.address.last_name,
              contact_number: customer_details?.contact_number,
              landmark: "",
              set_as_default: 0,
              city: values.address?.city?.[0]?.value
                ? values.address?.city?.[0]?.value
                : FORM_CONSTANTS.DEFAULT_CITY[0].value,
              state: values.address?.state?.[0]?.value
                ? values.address?.state?.[0]?.value
                : FORM_CONSTANTS.DEFAULT_STATE[0].value,
              country: values.address?.country?.[0]?.value
                ? values.address?.country?.[0]?.value
                : FORM_CONSTANTS.DEFAULT_COUNTRY[0].value,
              is_active: 1,
            };
            dispatch(
              manageEcommerceCustomerAddresses({
                customer_id: customer_details?.id,
                data: address,
              })
            );
            setShow({ state: false, type: "" });
            action.resetForm();
          }}
        >
          {(subProps) => {
            return (
              <>
                <div className="row">
                  <CustomerAddressDetailsForm props={subProps} />
                </div>
                <div className="row">
                  <div className="col-6">
                    <Button
                      loading={subProps.isSubmitting}
                      type={BUTTON_CONSTANTS.BUTTON}
                      text={FORM_CONSTANTS.SUBMIT}
                      btnClassNames={"btn btn-primary align-self-center w-50"}
                      onClickHandler={() => {
                        subProps.handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </OffCanvas>
  );
};

export { AddAddressOffCanvas };
