import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  displayValue,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import {
  deleteDiscount,
  getDiscounts,
  removeDiscount,
} from "store/ManageProducts/ManageDiscounts/discountSlice";

import {
  ContentHeader,
  NoData,
  TableTools,
  Loader,
  Status,
  ConfirmModal,
} from "components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BUTTON_CONSTANTS,
  DISCOUNTS_CONSTANTS,
  POS_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { updatePageNo } from "store/commonSlice";
import { getDiscountTypes } from "store/ManageProducts/ManageDiscounts/discountTypeSlice";
import {
  fetchCouponUsage,
  getAllCouponUsage,
} from "store/ManageProducts/ManageDiscounts/discountCouponUsageSlice";

const Discounts = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    discount: { discounts: discountData, status },
    discountCouponUsage: { couponUsage: couponUsageData },
    discountType: { discountType: discountTypeData },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [currentDiscount, setCurrentDiscount] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const discountPermission = permissions.find(
    (p) => p.module_name === "Manage Discount"
  );

  const update_page = (pageNo: number) => {
    dispatch(getDiscounts(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    dispatch(getDiscountTypes(true));
    dispatch(fetchCouponUsage(true));
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = DISCOUNTS_CONSTANTS.DISCOUNT_COLS;

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={DISCOUNTS_CONSTANTS.DISCOUNT_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={discountPermission?.perm_add}
                path={DISCOUNTS_CONSTANTS.PATH}
                label={DISCOUNTS_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : discountData && discountData.length > 0 ? (
                    discountData.map((discount, index) => (
                      <tr key={index}>
                        <td>{page_size * (current_page - 1) + index + 1}</td>
                        <td>{discount.discount_code}</td>
                        <td>
                          {
                            discountTypeData
                              .filter(
                                (item) => item.id === discount.discount_type
                              )
                              .map((item) => item.type)[0]
                          }
                        </td>
                        <td className="align-middle">
                          {`${POS_CONSTANTS.RUPEE_SIGN}${displayValue(
                            to2Decimal(discount.discount_value)
                          )}`}
                        </td>
                        <td>
                          <Status is_active={discount.is_active} />
                        </td>
                        <td>
                          <Button
                            text={<FontIcon icon={faPenToSquare} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            isDisabled={!discountPermission?.perm_edit}
                            btnClassNames={"btn btn-info btn-sm mr-2"}
                            onClickHandler={() =>
                              navigate(
                                ROUTES_CONSTANTS.EDIT + "/" + discount.id
                              )
                            }
                          />
                          {discount.is_active ? (
                            <Button
                              text={<FontIcon icon={faBan} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={!discountPermission?.perm_delete}
                              btnClassNames={"btn btn-danger btn-sm"}
                              onClickHandler={() => {
                                setCurrentDiscount(Number(discount.id));
                                setConfirmModal(true);
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={DISCOUNTS_CONSTANTS.DELETE_DISCOUNT}
        message={DISCOUNTS_CONSTANTS.DELETE_DISCOUNT_MSG}
        confirmClick={() => {
          dispatch(deleteDiscount(currentDiscount));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Discounts };
