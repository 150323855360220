import {
  Accordion,
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
} from "components";
import { ErrorMessage, FieldArray } from "formik";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  PRODUCTS_CONSTANTS,
  makeid,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { BUTTON_CONSTANTS, FORM_CONSTANTS } from "../../utils/constants";
import ProductPricingDetailsForm from "./ProductPricingDetailsForm";
import {
  faAdd,
  faBan,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { title } from "process";
import { deleteProductBatchMapping } from "store/ManageProducts/productSlice";

const ProductBatchWisePricingForm = ({ props }) => {
  const dispatch = useAppDispatch();
  const {
    branch: { branch: branchData },
  } = useAppSelector((state) => state.root);

  const [activeBatchItem, setActiveBatchItem] = useState(null);

  const checkBatchStatus = (is_active, index) => {
    return is_active !== 1;
  };

  const checkStatus = useCallback(checkBatchStatus, []);

  return (
    props.values?.price?.length > 0 && (
      <div className="col-md-12">
        <Card>
          <CardHeader>
            <h4 className="card-title">Batch Wise Pricing</h4>
          </CardHeader>
          <CardBody>
            <FieldArray
              name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}`}
              render={({ push, remove }) => {
                const batchItem = props.values?.price?.map(
                  (batch, batchIndex) => {
                    return {
                      id: batchIndex,
                      // title: batch.batch_name
                      //   ? batch.batch_name[0]?.label
                      //   : `Batch ${batchIndex + 1}`,
                      title: props.values.price[batchIndex].batch_name,
                      body: (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex flex-row align-items-center">
                              <div className="col-md-11">
                                <CustomInput
                                  isDisabled={true}
                                  label={PRODUCTS_CONSTANTS.BATCH_NAME_LABEL}
                                  name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.BATCH_NAME}`}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    PRODUCTS_CONSTANTS.BATCH_NAME_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.BATCH_NAME}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-1 text-end mt-3">
                                <Button
                                  type={BUTTON_CONSTANTS.BUTTON}
                                  isDisabled={
                                    props.values.price.length === 1 &&
                                    props.values.price[batchIndex].id ===
                                      undefined
                                  }
                                  text={
                                    <FontIcon
                                      icon={
                                        props.values.price[batchIndex].id &&
                                        checkStatus(
                                          props.values.price[batchIndex]
                                            .is_active,
                                          batchIndex
                                        )
                                          ? faCheck
                                          : faBan
                                      }
                                    />
                                  }
                                  btnClassNames={`btn btn-${
                                    props.values.price[batchIndex].id &&
                                    checkStatus(
                                      props.values.price[batchIndex].is_active,
                                      batchIndex
                                    )
                                      ? "success"
                                      : "danger"
                                  } table-button`}
                                  onClickHandler={() => {
                                    if (
                                      props.values.price[batchIndex].id &&
                                      !props.values.price[batchIndex].new
                                    ) {
                                      dispatch(
                                        deleteProductBatchMapping(
                                          props.values.price[batchIndex].id,
                                          props.values.price[batchIndex]
                                            .is_active === 0
                                        )
                                      );
                                      props.setFieldValue(
                                        `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].is_active`,
                                        props.values.price[batchIndex]
                                          .is_active === 0
                                          ? 1
                                          : 0
                                      );
                                    } else {
                                      remove(batchIndex);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <ProductPricingDetailsForm
                                  props={props}
                                  batch={batch}
                                  batchIndex={batchIndex}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  }
                );
                return (
                  <>
                    <Accordion
                      accordionItems={batchItem}
                      activeItem={activeBatchItem}
                      id={"batch-toggle"}
                    />
                    <div className="d-grid my-2">
                      <Button
                        type={BUTTON_CONSTANTS.BUTTON}
                        text={<FontIcon icon={faAdd} />}
                        btnClassNames={"btn btn-outline-primary btn-block"}
                        onClickHandler={() => {
                          push({
                            batch_name: `Batch ${makeid(10)}`,
                            store_prices: branchData.map((branch) => ({
                              store: branch.store_name,
                              mrp: 0,
                              selling_discount: 0,
                              selling_price: 0,
                              minimum_selling_quantity: 0,
                              selling_discount_type: "",
                              ceil: true,
                            })),
                            bulk_mrp: "",
                            bulk_selling_discount: "",
                            bulk_selling_price: "",
                            bulk_minimum_selling_quantity: "",
                            bulk_selling_discount_type: "",
                            bulk_ceil: true,
                          });
                        }}
                      />
                    </div>
                  </>
                );
              }}
            />
          </CardBody>
        </Card>
      </div>
    )
  );
};

export default memo(ProductBatchWisePricingForm);
