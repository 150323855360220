import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import { getAddressType, setAddressType } from "apis/restApis";

interface AddressType {
  id: number;
  type: string;
}

const initialState = {
  addressTypes: [] as AddressType[],
  status: STATUSES.IDLE as string,
  error: null,
};

const addressTypeSlice = createSlice({
  name: "addressType",
  initialState,
  reducers: {
    getAllAddressTypes: (state, action) => {
      state.addressTypes = action.payload;
    },
    addAddressType: (state, action) => {
      state.addressTypes.push(action.payload);
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllAddressTypes,
  addAddressType,
  setStatus,
  setError,
} = addressTypeSlice.actions;

export default addressTypeSlice.reducer;

export function getAddressTypes() {
  return async function getAddressTypesThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    getAddressType()
      .then((response: any) => {
        dispatch(getAllAddressTypes(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error: any) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function addNewAddressType(addressType: AddressType) {
  return async function addNewAddressTypeThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    setAddressType(addressType)
      .then((response: any) => {
        dispatch(addAddressType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error: any) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
