import React, { useEffect, useState } from "react";

import { Formik, Form, ErrorMessage } from "formik";

import { ScrollToTop, useAppDispatch, useAppSelector } from "utils/helper";
import {
  getHSN,
  addNewHSN,
  deleteHSN,
  updateExistingHSN,
  deleteHSNCategory,
} from "store/hsnCodesSlice";

import {
  ConfirmModal,
  ContentHeader,
  CustomInput,
  Loader,
  NoData,
  Status,
  TableTools,
} from "components";
import {
  Button,
  Card,
  OffCanvas,
  MultiSelect,
  Table,
  TableBody,
  TableHeader,
  FontIcon,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Pagination,
  Badge,
} from "components/core-components";
import { HSNCodeSchema } from "utils/schema";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  HSN_CODES_CONSTANTS,
  STATUSES,
  usePagination,
} from "utils";
import { getCategories } from "store/ManageProducts/productCategorySlice";
import { HSNCode } from "utils/types";
import { updatePageNo, updatePages } from "store/commonSlice";

const HSNCodes = () => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permsissionData },
    common: { current_page, total_items, total_pages, page_size },
    hsnCode: { hsnCodes: hsnData, status },
    productCategory: { productCategory: categoryData },
  } = useAppSelector((state) => state.root);

  const hsnPermission = permsissionData.find(
    (p) => p.module_name === "Manage HSN Codes"
  );

  const [query, setQuery] = useState("");

  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState(false || {});

  const [currentHSN, setCurrentHSN] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const [editId, setEditId] = useState(0);

  const initialValues = {
    hsn_name: "",
    hsn_code: "",
    is_active: "",
  };

  const tableCols = HSN_CODES_CONSTANTS.HSN_CODES_COLS;

  const update_page = (pageNo: number) => {
    dispatch(getHSN(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    dispatch(getCategories(true));
    return () => {
      resetState();
    };
  }, [dispatch]);

  const handleSubmit = (values, actions) => {
    let dataToSend = {
      ...values,
      is_active: values.is_active[0]?.value,
    };
    if (!edit) {
      dispatch(addNewHSN(dataToSend, actions, setShow));
    } else {
      dispatch(updateExistingHSN(values.id, dataToSend, actions, setShow));
    }
  };

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={HSN_CODES_CONSTANTS.HSN_CODES_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={hsnPermission?.perm_add}
                path={HSN_CODES_CONSTANTS.PATH}
                label={HSN_CODES_CONSTANTS.LABEL}
                setEdit={setEdit}
                setShow={setShow}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : hsnData.length > 0 ? (
                    hsnData.map((hsn: HSNCode, index) => {
                      return (
                        <tr key={index}>
                          <td>{page_size * (current_page - 1) + index + 1}</td>
                          <td>{hsn.hsn_name}</td>
                          <td>{hsn.hsn_code}</td>
                          <td>
                            <Status is_active={hsn.is_active} />
                          </td>
                          <td>
                            <Button
                              text={<FontIcon icon={faPenToSquare} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={!hsnPermission?.perm_edit}
                              btnClassNames={"btn btn-info btn-sm mr-2"}
                              onClickHandler={() => {
                                let editData = {
                                  ...hsn,
                                  is_active: [
                                    {
                                      value: hsn.is_active,
                                      label:
                                        hsn.is_active === 1
                                          ? GENERAL_CONSTANTS.ACTIVE
                                          : GENERAL_CONSTANTS.INACTIVE,
                                    },
                                  ],
                                };
                                setEditId(hsn.id);
                                setShow(true);
                                setEdit(editData);
                              }}
                            />
                            {hsn.is_active ? (
                              <Button
                                text={<FontIcon icon={faBan} />}
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={!hsnPermission?.perm_delete}
                                btnClassNames={"btn btn-danger btn-sm"}
                                onClickHandler={() => {
                                  setConfirmModal(true);
                                  setCurrentHSN(Number(hsn.id));
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
              <OffCanvas
                title={
                  edit
                    ? HSN_CODES_CONSTANTS.EDIT_HEADER
                    : HSN_CODES_CONSTANTS.ADD_HEADER
                }
                show={show}
                onClickHandler={() => {
                  setShow(false);
                }}
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={edit ? edit : initialValues}
                  validationSchema={HSNCodeSchema}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <Card>
                            <CardBody>
                              <div>
                                <CustomInput
                                  type={FORM_CONSTANTS.TEXT}
                                  isRequired={true}
                                  label={HSN_CODES_CONSTANTS.HSN_NAME_LABEL}
                                  name={HSN_CODES_CONSTANTS.HSN_NAME}
                                  placeholder={
                                    HSN_CODES_CONSTANTS.HSN_NAME_PLACEHOLDER
                                  }
                                  value={props.values.hsn_name}
                                />
                                <ErrorMessage
                                  name={HSN_CODES_CONSTANTS.HSN_NAME}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div>
                                <CustomInput
                                  type={FORM_CONSTANTS.TEXT}
                                  isRequired={true}
                                  label={HSN_CODES_CONSTANTS.HSN_CODE_LABEL}
                                  name={HSN_CODES_CONSTANTS.HSN_CODE}
                                  placeholder={
                                    HSN_CODES_CONSTANTS.HSN_CODE_PLACEHOLDER
                                  }
                                  value={props.values.hsn_code}
                                />
                                <ErrorMessage
                                  name={HSN_CODES_CONSTANTS.HSN_CODE}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              {edit ? (
                                <MultiSelect
                                  select={false}
                                  label={
                                    HSN_CODES_CONSTANTS.HSN_CODE_STATUS_LABEL
                                  }
                                  name={HSN_CODES_CONSTANTS.HSN_CODE_STATUS}
                                  options={
                                    HSN_CODES_CONSTANTS.HSN_CODE_STATUS_OPTIONS
                                  }
                                  value={props.values.is_active}
                                />
                              ) : null}
                            </CardBody>
                          </Card>
                        </div>
                        <div className="col-12">
                          <Button
                            type={BUTTON_CONSTANTS.SUBMIT}
                            text={FORM_CONSTANTS.SUBMIT}
                            btnClassNames={
                              "btn btn-primary align-self-center w-25"
                            }
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </OffCanvas>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={HSN_CODES_CONSTANTS.DELETE_HSN}
        message={HSN_CODES_CONSTANTS.DELETE_HSN_MSG}
        confirmClick={() => {
          dispatch(deleteHSN(currentHSN));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { HSNCodes };
