import React from "react";

type InputProps = {
  type: string;
  id: string;
  placeholder?: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void | null;
  onBlurHandler?: (e: React.FocusEvent<HTMLInputElement>) => void | null;
  name?: string;
  role?: string;
  inputClassNames: string;
  value?: string;
};

const Input: React.FC<InputProps> = ({
  type,
  id,
  placeholder = "",
  onChangeHandler,
  onBlurHandler,
  name,
  role,
  value,
  inputClassNames,
}) => {
  return (
    <input
      id={id}
      className={`${inputClassNames} rounded-lg p-2 my-2`}
      type={type}
      placeholder={placeholder}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      name={name}
      value={value}
    />
  );
};

export { Input };
