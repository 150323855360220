import React, { memo } from "react";

import { ErrorMessage, Form } from "formik";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  MultiSelect,
} from "components";

import { BUTTON_CONSTANTS, FORM_CONSTANTS, PRINTER_CONSTANTS } from "utils";

const PrinterForm = ({ edit, props }) => {
  return (
    <Form>
      <div className="row">
        {/* Printer Details */}
        <div className="col-md-12">
          <Card>
            <CardHeader>
              <h3 className="card-title">{PRINTER_CONSTANTS.CARD_TITLE_1}</h3>
            </CardHeader>
            <CardBody>
              <div className="row">
                <CustomInput
                  type="text"
                  value={props.values.printer_name}
                  label={PRINTER_CONSTANTS.PRINTER_NAME_LABEL}
                  name={PRINTER_CONSTANTS.PRINTER_NAME}
                  placeholder={PRINTER_CONSTANTS.PRINTER_NAME_PLACEHOLDER}
                  isRequired={true}
                />
                <ErrorMessage
                  name={PRINTER_CONSTANTS.PRINTER_NAME}
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="row">
                <CustomInput
                  type="text"
                  value={props.values.target}
                  label={PRINTER_CONSTANTS.PRINTER_TARGET_LABEL}
                  name={PRINTER_CONSTANTS.PRINTER_TARGET}
                  placeholder={PRINTER_CONSTANTS.PRINTER_TARGET_PLACEHOLDER}
                  isRequired={true}
                />
                <ErrorMessage
                  name={PRINTER_CONSTANTS.PRINTER_TARGET}
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="row">
                <CustomInput
                  type="text"
                  value={props.values.series_name}
                  label={PRINTER_CONSTANTS.PRINTER_SERIES_NAME_LABEL}
                  name={PRINTER_CONSTANTS.PRINTER_SERIES_NAME}
                  placeholder={
                    PRINTER_CONSTANTS.PRINTER_SERIES_NAME_PLACEHOLDER
                  }
                  isRequired={true}
                />
                <ErrorMessage
                  name={PRINTER_CONSTANTS.PRINTER_SERIES_NAME}
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="row">
                <CustomInput
                  type="text"
                  value={props.values.language}
                  label={PRINTER_CONSTANTS.PRINTER_LANGUAGE_LABEL}
                  name={PRINTER_CONSTANTS.PRINTER_LANGUAGE}
                  placeholder={PRINTER_CONSTANTS.PRINTER_LANGUAGE_PLACEHOLDER}
                  isRequired={true}
                />
                <ErrorMessage
                  name={PRINTER_CONSTANTS.PRINTER_LANGUAGE}
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              {edit ? (
                <div className="row">
                  <MultiSelect
                    select={false}
                    label={PRINTER_CONSTANTS.PRINTER_STATUS_LABEL}
                    name={PRINTER_CONSTANTS.PRINTER_STATUS}
                    options={PRINTER_CONSTANTS.PRINTER_STATUS_OPTIONS}
                    isRequired={true}
                  />
                  <ErrorMessage
                    name={PRINTER_CONSTANTS.PRINTER_STATUS}
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </div>
        {/* Submit */}
        <div className="col-12">
          <Button
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export default memo(PrinterForm);
