import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

type FontIconProps = {
  icon: IconDefinition;
  iconClassNames?: string;
  iconSize?: "2xs" | "xs" | "sm" | "lg" | "xl" | "2xl";
  onClickHandler?: React.MouseEventHandler<SVGSVGElement>;
};

const FontIcon: React.FC<FontIconProps> = ({
  icon,
  iconClassNames,
  iconSize,
  onClickHandler,
}) => {
  return (
    <FontAwesomeIcon
      className={`${iconClassNames}`}
      icon={icon}
      size={iconSize}
      onClick={onClickHandler}
    />
  );
};

export { FontIcon };
