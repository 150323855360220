import { Button, Card } from "components/core-components";
import React from "react";
import { PrintLastBill } from "store/POS";
import {
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

/**
 * Renders the last bill details component.
 * @param {Function} setPrintOptionsModal - Function to set the print options modal.
 * @param {boolean} full - Flag indicating whether to display the component in full mode or not.
 * @returns {JSX.Element} The last bill details component.
 */
const LastBillDetails = ({
  setPrintOptionsModal,
  full = false,
  modal = false,
}) => {
  const dispatch = useAppDispatch();

  const {
    branch: { selectedStore },
    common: { status: commonStatus },
    creditNote: { status: creditNoteStatus },
    holdBill: { status: holdBillStatus },
    order: { status: orderStatus, lastOrder },
  } = useAppSelector((state) => state.root);

  const orderInProgress =
    holdBillStatus === STATUSES.LOADING ||
    orderStatus === STATUSES.LOADING ||
    creditNoteStatus === STATUSES.LOADING ||
    commonStatus.type === POS_CART_CONSTANTS.WHATSAPP_BILL_LOADING_STATE;

  return (
    selectedStore > 0 && (
      <Card cardClassNames={`row ${full ? "my-2 py-2" : "my-1 py-1"}`}>
        {!modal && (
          <h6 className="text-start">{POS_CART_CONSTANTS.LAST_BILL_DETAILS}</h6>
        )}
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h6 className="text-sm">{POS_CART_CONSTANTS.LAST_BILL}:</h6>
          <h6 className="text-sm text-end">
            {lastOrder.payable_amount !== undefined
              ? lastOrder.invoice_code
              : "-"}
          </h6>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h6 className="text-sm">{POS_CART_CONSTANTS.LAST_BILL_AMOUNT}:</h6>
          <h6 className="text-sm text-end">
            {lastOrder.payable_amount !== undefined
              ? `${POS_CONSTANTS.RUPEE_SIGN} ${lastOrder.payable_amount}`
              : "-"}
          </h6>
        </div>
        <div className="row">
          <Button
            btnClassNames="btn btn-dark btn-sm m-2"
            isDisabled={orderInProgress}
            loading={
              commonStatus.state === STATUSES.LOADING &&
              commonStatus.type ===
                POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE
            }
            onClickHandler={() => {
              dispatch(
                PrintLastBill({
                  setPrintOptionsModal: setPrintOptionsModal,
                })
              );
            }}
            text={POS_CART_CONSTANTS.PRINT_LAST_BILL}
          />
        </div>
      </Card>
    )
  );
};

export default LastBillDetails;
