import React from "react";
import { MultiSelect } from "./core-components";
import { FORM_CONSTANTS, GENERAL_CONSTANTS } from "utils";
import { ErrorMessage, FormikProps } from "formik";

interface IProps {
  props: FormikProps<any>;
  name: string;
  label: string;
  placeholder?: string;
}

const SelectStatus = ({
  props,
  name,
  label,
  placeholder = "Select Status",
}: IProps) => {
  return (
    <>
      <MultiSelect
        select={false}
        name={name}
        label={label}
        placeholder={placeholder}
        options={GENERAL_CONSTANTS.STATUS_OPTIONS}
        onChangeHandler={(e, actions) => {
          if (actions.action === "clear") {
            e = [
              {
                value: 1,
                label: GENERAL_CONSTANTS.ACTIVE,
              },
            ];
          }
          props.setFieldValue(name, Array.isArray(e) ? e : [e]);
        }}
      />
      <ErrorMessage
        name={name}
        component={FORM_CONSTANTS.ERROR_PARENT}
        className={FORM_CONSTANTS.ERROR}
      />
    </>
  );
};

export { SelectStatus };
