import { combineReducers } from "redux";

import authSlice from "./authSlice";
import roleSlice from "./ManageRoles/roleSlice";
import employeeSlice from "./ManageEmployee/employeeSlice";
import bankSlice from "./ManageBanks/bankSlice";
import customerSlice from "./ManageCustomers/customerSlice";
import branchSlice from "./ManageBranches/branchSlice";
import storeDeptSlice from "./storeDeptSlice";
import hsnCodesSlice from "./hsnCodesSlice";
import uomSlice from "./uomSlice";
import paymentModeSlice from "./ManagePayments/paymentModeSlice";
import paymentTermSlice from "./ManagePayments/paymentTermSlice";
import vendorSlice from "./ManageVendors/vendorSlice";
import discountSlice from "./ManageProducts/ManageDiscounts/discountSlice";
import productSlice from "./ManageProducts/productSlice";
import productCategorySlice from "./ManageProducts/productCategorySlice";
import productSubCategorySlice from "./ManageProducts/productSubCategorySlice";
import productBrandSlice from "./ManageProducts/productBrandSlice";
import productTaxSlice from "./ManageProducts/productTaxSlice";
import productGroupSlice from "./ManageProducts/productGroupSlice";
import locationSlice from "./locationSlice";
import barcodeSlice from "./ManageProducts/barcodeSlice";
import discountTypeSlice from "./ManageProducts/ManageDiscounts/discountTypeSlice";
import discountApplicableTypeSlice from "./ManageProducts/ManageDiscounts/discountApplicableTypeSlice";
import deliveryPolicySlice from "./ManageVendors/deliveryPolicySlice";
import supplierTypeSlice from "./ManageVendors/supplierTypeSlice";
import productIngredients from "./ManageProducts/productIngredients";
import bankAccountTypeSlice from "./ManageBanks/bankAccountTypeSlice";
import addressTypeSlice from "./addressTypeSlice";
import moduleSlice from "./ManageRoles/moduleSlice";
import commonSlice from "./commonSlice";
import labelSLice from "./Utilities/labelSLice";
import printerSlice from "./Utilities/printerSlice";
import discountCouponUsageSlice from "./ManageProducts/ManageDiscounts/discountCouponUsageSlice";
import discountCustomerEligibilitySlice from "./ManageProducts/ManageDiscounts/discountCustomerEligibilitySlice";
import cartSlice from "./POS/cartSlice";
import holdBillSlice from "./POS/holdBillSlice";
import orderSlice from "./POS/orderSlice";
import posPaymentModeSlice from "./POS/posPaymentModeSlice";
import creditNoteSlice from "./POS/creditNoteSlice";
import nutrientSlice from "./ManageProducts/nutrientSlice";
import posPaymentTypeSlice from "./POS/posPaymentTypeSlice";
import barcodeGeneratorSlice from "./Utilities/barcodeGeneratorSlice";
import KPIReportSlice from "./Reports/KPIReportSlice";
import blogSlice from "./Ecommerce/blogSlice";
import menuSlice from "./Ecommerce/menuSlice";
import ecommerceProductSlice from "./Ecommerce/ecommerceProductSlice";
import faqSlice from "./Ecommerce/faqSlice";
import testimonialSlice from "./Ecommerce/testimonialSlice";
import pagesSlice from "./Ecommerce/pagesSlice";
import emailTemplateSlice from "./Ecommerce/emailTemplateSlice";
import ecommerceCategorySlice from "./Ecommerce/ecommerceCategorySlice";
import ecommerceSubCategorySlice from "./Ecommerce/ecommerceSubCategorySlice";
import tasteSlice from "./ManageProducts/tasteSlice";
import siteManagementSlice from "./Ecommerce/siteManagementSlice";
import widgetSlice from "./Ecommerce/widgetSlice";
import ecommerceOrderSlice from "./Ecommerce/ecommerceOrderSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  addressType: addressTypeSlice,
  bank: bankSlice,
  bankAccountType: bankAccountTypeSlice,
  barcode: barcodeSlice,
  barcodeGenerator: barcodeGeneratorSlice,
  branch: branchSlice,
  common: commonSlice,
  customer: customerSlice,
  deliveryPolicy: deliveryPolicySlice,
  discount: discountSlice,
  discountApplicableType: discountApplicableTypeSlice,
  discountCouponUsage: discountCouponUsageSlice,
  discountCustomerEligibility: discountCustomerEligibilitySlice,
  discountType: discountTypeSlice,
  employee: employeeSlice,
  hsnCode: hsnCodesSlice,
  kpiReport: KPIReportSlice,
  label: labelSLice,
  location: locationSlice,
  module: moduleSlice,
  nutrients: nutrientSlice,
  paymentMode: paymentModeSlice,
  paymentTerm: paymentTermSlice,
  printer: printerSlice,
  product: productSlice,
  productBrand: productBrandSlice,
  productCategory: productCategorySlice,
  productGroup: productGroupSlice,
  productIngredient: productIngredients,
  productSubCategory: productSubCategorySlice,
  taste: tasteSlice,
  productTax: productTaxSlice,
  role: roleSlice,
  storeDept: storeDeptSlice,
  supplierType: supplierTypeSlice,
  uom: uomSlice,
  vendor: vendorSlice,
  cart: cartSlice,
  order: orderSlice,
  creditNote: creditNoteSlice,
  holdBill: holdBillSlice,
  posPaymentMode: posPaymentModeSlice,
  posPaymentType: posPaymentTypeSlice,
});

const ecommerceReducer = combineReducers({
  blog: blogSlice,
  menu: menuSlice,
  product: ecommerceProductSlice,
  category: ecommerceCategorySlice,
  subCategory: ecommerceSubCategorySlice,
  faq: faqSlice,
  testimonial: testimonialSlice,
  pages: pagesSlice,
  emailTemplate: emailTemplateSlice,
  siteManagement: siteManagementSlice,
  widget: widgetSlice,
  order: ecommerceOrderSlice,
});

export { rootReducer, ecommerceReducer };
