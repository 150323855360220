import { Modal } from "components/core-components";
import { OrdersList } from "pages";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { updatePageNo } from "store/commonSlice";
import {
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  TModalSize,
  useAppDispatch,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  fullScreenSize?: TModalSize;
}

const OrdersModal = ({ modal, setModal, fullScreenSize }: IProps) => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Modal
      modalSize={GENERAL_CONSTANTS.EXTRA_LARGE}
      centered={false}
      title={POS_CART_CONSTANTS.ORDERS}
      modalClassNames="w-100"
      fullScreenSize={fullScreenSize}
      showModal={modal}
      onClickHandler={() => {
        setSearchParams({});
        dispatch(updatePageNo(1));
        setModal(false);
      }}
    >
      <OrdersList modal={modal} />
    </Modal>
  );
};

export { OrdersModal };
