import { createRoot } from "react-dom/client";
import React from "react";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.js";
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte.min.js";
import "./assets/styles/main.scss";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./store/store";
import { App } from "App";

declare global {
  interface Window {
    Popper: any;
    $: any;
    jQuery: any;
    mobileAndTabletCheck: () => boolean;
    opera: any;
  }
}

window.Popper = require("@popperjs/core").default;
window.$ = window.jQuery = require("jquery");
require("bootstrap");

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
);
