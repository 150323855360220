import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delProductIngredients,
  delProductIngredientsMapping,
  getActiveProductIngredients,
  // getActiveProductIngredients,
  getProductIngredients,
  setProductIngredients,
  updateProductIngredients,
} from "apis/restApis";
import { Ingredient, Toast } from "utils";

const initialState = {
  productIngredients: [] as Ingredient[],
  status: STATUSES.IDLE as string,
  error: null,
};

const productIngredients = createSlice({
  name: "productIngredients",
  initialState,
  reducers: {
    getAllIngredients(state, action) {
      state.productIngredients = action.payload;
    },
    addIngredient(state, action) {
      state.productIngredients.push(action.payload);
    },
    removeIngredient(state, action) {
      state.productIngredients = state.productIngredients.filter(
        (ingredient) => ingredient.id !== action.payload
      );
    },
    editIngredient(state, action) {
      const { id } = action.payload;
      const index = state.productIngredients.findIndex(
        (ingredient) => ingredient.id === id
      );
      if (index !== -1) {
        state.productIngredients[index] = action.payload;
      }
    },
    clearIngredients(state) {
      state.productIngredients = [];
    },

    setIngredientStatus(state, action) {
      state.status = action.payload;
    },
    setIngredientError(state, action) {
      state.error = action.payload;
    },
    resetIngredientState: (state) => {
      state.productIngredients = [] as Ingredient[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllIngredients,
  addIngredient,
  removeIngredient,
  editIngredient,
  clearIngredients,

  setIngredientStatus,
  setIngredientError,
  resetIngredientState,
} = productIngredients.actions;

export default productIngredients.reducer;

export function getIngredients(active?: boolean, query?: string) {
  return async function getIngredientsThunk(dispatch) {
    dispatch(setIngredientStatus(STATUSES.LOADING));
    active
      ? getActiveProductIngredients(query)
          .then((response: any) => {
            if (response.result.length === 0) {
              Toast("No Ingredients found", "warning");
            }
            dispatch(getAllIngredients(response.result));
          })
          .catch((error) => {
            dispatch(setIngredientError(error.message));
            dispatch(setIngredientStatus(STATUSES.ERROR));
          })
          .finally(() => {
            dispatch(setIngredientStatus(STATUSES.IDLE));
          })
      : getProductIngredients(query)
          .then((response: any) => {
            dispatch(getAllIngredients(response.result.results));
          })
          .catch((error) => {
            dispatch(setIngredientError(error.message));
            dispatch(setIngredientStatus(STATUSES.ERROR));
          })
          .finally(() => {
            dispatch(setIngredientStatus(STATUSES.IDLE));
          });
  };
}

export function addNewIngredient(ingredient: Ingredient) {
  return async function addNewIngredientThunk(dispatch) {
    dispatch(setIngredientStatus(STATUSES.LOADING));
    setProductIngredients(ingredient)
      .then((response: any) => {
        dispatch(addIngredient(response.result));
        dispatch(setIngredientStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setIngredientError(error.message));
        dispatch(setIngredientStatus(STATUSES.ERROR));
      });
  };
}

export function updateExistingIngredient(id: number, ingredient: Ingredient) {
  return async function updateExistingIngredientThunk(dispatch) {
    dispatch(setIngredientStatus(STATUSES.LOADING));
    updateProductIngredients(id, ingredient)
      .then((response: any) => {
        dispatch(editIngredient(response.result));
        dispatch(setIngredientStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setIngredientError(error.message));
        dispatch(setIngredientStatus(STATUSES.ERROR));
      });
  };
}

export function deleteIngredient(id: number) {
  return async function deleteIngredientThunk(dispatch) {
    dispatch(setIngredientStatus(STATUSES.LOADING));
    delProductIngredients(id)
      .then((response: any) => {
        dispatch(editIngredient(response.result));
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setIngredientStatus(STATUSES.ERROR));
        dispatch(setIngredientError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setIngredientStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProductIngredientMapping(delParams: {
  product: number;
  ingredient: number;
}) {
  return async function deleteProductIngredientMappingThunk(dispatch) {
    dispatch(setIngredientStatus(STATUSES.LOADING));
    delProductIngredientsMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setIngredientStatus(STATUSES.ERROR));
        dispatch(setIngredientError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setIngredientStatus(STATUSES.IDLE));
      });
  };
}
