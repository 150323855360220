import { createSlice } from "@reduxjs/toolkit";
import { PRODUCT_GROUPS_CONSTANTS, STATUSES } from "utils/constants";

import {
  delProductGroup,
  delProductGroupBarcodeMapping,
  delProductGroupProductMapping,
  fetchProductGroupHamperName,
  getActiveProductGroup,
  getProductGroup,
  getSpecificProductGroup,
  setProductGroup,
  updateProductGroup,
} from "apis/restApis";
import { ProductGroup, Toast, transformProduct } from "utils";
import { updateValues } from "store/commonSlice";
import { FormikHelpers, FormikProps } from "formik";
import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "store/store";
import { AddProduct } from "store/POS";

const initialState = {
  productGroups: [] as ProductGroup[],
  productGroupToEdit: {} as ProductGroup,
  status: STATUSES.IDLE as string,
  error: null,
};

const productGroupSlice = createSlice({
  name: "productGroup",
  initialState,
  reducers: {
    getAllProductGroup(state, action) {
      state.productGroups = action.payload;
    },
    addProductGroup(state, action) {
      state.productGroups.unshift(action.payload);
    },
    removeProductGroup(state, action) {
      state.productGroups = state.productGroups.filter(
        (productGroup) => productGroup.id !== action.payload
      );
    },
    editProductGroup(state, action) {
      const { id } = action.payload;
      const index = state.productGroups.findIndex(
        (productGroup) => productGroup.id === id
      );
      if (index !== -1) {
        state.productGroups[index] = action.payload;
      }
    },
    setProductGroupToEdit(state, action) {
      state.productGroupToEdit = action.payload;
    },

    setProductGroupStatus(state, action) {
      state.status = action.payload;
    },
    setProductGroupError(state, action) {
      state.error = action.payload;
    },
    resetProductGroupState: (state) => {
      state.productGroups = [] as ProductGroup[];
      state.status = STATUSES.IDLE;
      state.error = null;
      state.productGroupToEdit = {} as ProductGroup;
    },
  },
});

export const {
  getAllProductGroup,
  addProductGroup,
  removeProductGroup,
  editProductGroup,
  setProductGroupToEdit,

  setProductGroupStatus,
  setProductGroupError,
  resetProductGroupState,
} = productGroupSlice.actions;

export default productGroupSlice.reducer;

export function getProductGroups(active?: boolean, pageNo = 1, query = "") {
  return async function getProductGroupThunk(dispatch: AppDispatch) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    active
      ? getActiveProductGroup()
          .then((response: any) => {
            dispatch(setProductGroupStatus(STATUSES.IDLE));
            dispatch(getAllProductGroup(response.result));
          })
          .catch((error) => {
            dispatch(setProductGroupStatus(STATUSES.ERROR));
            dispatch(setProductGroupError(error.message));
          })
      : getProductGroup(pageNo, query)
          .then((response: any) => {
            dispatch(setProductGroupStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllProductGroup(response.result.results));
          })
          .catch((error) => {
            dispatch(setProductGroupStatus(STATUSES.ERROR));
            dispatch(setProductGroupError(error.message));
          });
  };
}

interface IIndividualProductGroup {
  id: number;
  manualAdd?: boolean;
  selectedStore?: number;
  setBatches?: React.Dispatch<React.SetStateAction<any[]>>;
}

export function getIndividualProductGroup({
  id,
  manualAdd = false,
  selectedStore,
  setBatches,
}: IIndividualProductGroup) {
  return async function getIndividualProductGroupThunk(dispatch: AppDispatch) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    getSpecificProductGroup(id, selectedStore)
      .then((response: any) => {
        if (manualAdd) {
          for (let i = 0; i < response.result.products.length; i++) {
            dispatch(
              AddProduct(
                transformProduct(
                  response.result.products[i],
                  selectedStore,
                  response.result.products[i].quantity,
                  {
                    id: response.result.id,
                    product_group_code: response.result.hamper_code,
                    product_group_name: response.result.hamper_name,
                    selling_price:
                      response.result.products[i].price.store_prices[0]
                        .selling_price,
                    products_quantity: response.result.count,
                  }
                ),
                {
                  batch_id: response.result.products[i].price.id,
                  store_id:
                    response.result.products[i].price.store_prices[0].store,
                  batch_name: response.result.products[i].price.batch_name,
                  batch_mrp:
                    response.result.products[i].price.store_prices[0].mrp,
                  batch_selling_price:
                    response.result.products[i].price.store_prices[0]
                      .selling_price,
                }
              )
            );
          }
        } else {
          dispatch(setProductGroupToEdit(response.result));
          response.result.products.forEach((product: any) => {
            product.price.length > 0 &&
              setBatches &&
              setBatches((prev) => {
                return [
                  ...prev,
                  product.price.map((item) => ({
                    value: item.id,
                    label: item.batch_name,
                  })),
                ];
              });
          });
        }
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
      })
      .finally(() => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      });
  };
}

export function addNewProductGroup(
  productGroup: ProductGroup,
  actions: FormikHelpers<ProductGroup>,
  navigate: NavigateFunction
) {
  return async function addNewProductGroupThunk(dispatch: AppDispatch) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    setProductGroup(productGroup)
      .then((response: any) => {
        dispatch(addProductGroup(response.result));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingProductGroup(
  id: number,
  productGroup: ProductGroup,
  actions: FormikHelpers<ProductGroup>,
  navigate: NavigateFunction
) {
  return async function updateExistingProductGroupThunk(dispatch: AppDispatch) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    updateProductGroup(id, productGroup)
      .then((response: any) => {
        dispatch(editProductGroup(response.result));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProductGroup(id: number) {
  return async function deleteProductGroupThunk(dispatch: AppDispatch) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    delProductGroup(id)
      .then((response: any) => {
        dispatch(editProductGroup(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProductGroupBarcodeMapping(id: number) {
  return async function deleteProductGroupBarcodeMappingThunk(
    dispatch: AppDispatch
  ) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    delProductGroupBarcodeMapping(id)
      .then((response: any) => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
      });
  };
}

export function deleteProductGroupProductMapping(delObject: {
  product: number;
  product_group: number;
}) {
  return async function deleteProductGroupProductMappingThunk(
    dispatch: AppDispatch
  ) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    delProductGroupProductMapping(delObject)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      });
  };
}

export function FetchProductGroupHamperName(
  setPrintHamperName: React.Dispatch<any>
) {
  return async function FetchProductGroupHamperNameThunk(
    dispatch: AppDispatch
  ) {
    dispatch(setProductGroupStatus(STATUSES.LOADING));
    fetchProductGroupHamperName()
      .then((response: any) => {
        setPrintHamperName(response.result);
      })
      .catch((error) => {
        dispatch(setProductGroupStatus(STATUSES.ERROR));
        dispatch(setProductGroupError(error.message));
      })
      .finally(() => {
        dispatch(setProductGroupStatus(STATUSES.IDLE));
      });
  };
}
