import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delDiscountType,
  getActiveDiscountType,
  getDiscountType,
  setDiscountType,
  updateDiscountType,
} from "apis/restApis";
import { DiscountType } from "utils";

const initialState = {
  discountType: [] as DiscountType[],
  status: STATUSES.IDLE as string,
  error: null,
};

const discountTypeSlice = createSlice({
  name: "discountType",
  initialState,
  reducers: {
    getAllDiscountType(state, action) {
      state.discountType = action.payload;
    },
    addDiscountType(state, action) {
      state.discountType.push(action.payload);
    },
    removeDiscountType(state, action) {
      state.discountType = state.discountType.filter(
        (discountType) => discountType.id !== action.payload
      );
    },
    editDiscountType(state, action) {
      const { id } = action.payload;
      const index = state.discountType.findIndex(
        (discountType) => discountType.id === id
      );
      if (index !== -1) {
        state.discountType[index] = action.payload;
      }
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getAllDiscountType,
  addDiscountType,
  removeDiscountType,
  editDiscountType,
  setStatus,
  setError,
} = discountTypeSlice.actions;

export default discountTypeSlice.reducer;

export function getDiscountTypes(active: boolean) {
  return async function getDiscountTypesThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveDiscountType()
          .then((response: any) => {
            dispatch(getAllDiscountType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getDiscountType()
          .then((response: any) => {
            dispatch(getAllDiscountType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function addDiscountTypes(discountTypeParams: DiscountType) {
  return async function addDiscountTypesThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    setDiscountType(discountTypeParams)
      .then((response: any) => {
        dispatch(addDiscountType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function updateDiscountTypes(
  id: number,
  discountTypeParams: DiscountType
) {
  return async function updateDiscountTypesThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    updateDiscountType(id, discountTypeParams)
      .then((response: any) => {
        dispatch(editDiscountType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteDiscountType(id: number) {
  return async function deleteDiscountTypeThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    delDiscountType(id)
      .then((response: any) => {
        dispatch(editDiscountType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
