import {
  Card,
  CardBody,
  CardHeader,
  TextArea,
} from "components/core-components";
import { CustomInput } from "components/CustomInput";
import { ErrorMessage, FormikProps } from "formik";
import React, { useEffect } from "react";
import { FORM_CONSTANTS, SEO_CONSTANTS } from "utils";

interface IProps {
  props?: FormikProps<any>;
  compact?: boolean;
  slugHelper?: string;
}

const SEODetailsform = ({ props, compact, slugHelper }: IProps) => {
  return (
    <div className="col-12">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h4 className="card-title">SEO Details</h4>
          </div>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className={`col-md-${compact ? "12" : "4"}`}>
              <CustomInput
                type={FORM_CONSTANTS.TEXT}
                name={SEO_CONSTANTS.TITLE}
                label={SEO_CONSTANTS.TITLE_LABEL}
                placeholder={SEO_CONSTANTS.TITLE_PLACEHOLDER}
                value={props?.values?.meta_title || ""}
              />
              <ErrorMessage
                name={SEO_CONSTANTS.TITLE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className={`col-md-${compact ? "12" : "4"}`}>
              <CustomInput
                appendText={slugHelper}
                type={FORM_CONSTANTS.TEXT}
                value={props?.values?.meta_slug || ""}
                name={SEO_CONSTANTS.SLUG}
                label={SEO_CONSTANTS.SLUG_LABEL}
                placeholder={SEO_CONSTANTS.SLUG_PLACEHOLDER}
              />
              <ErrorMessage
                name={SEO_CONSTANTS.SLUG}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className={`col-md-${compact ? "12" : "4"}`}>
              <CustomInput
                type={FORM_CONSTANTS.TEXT}
                name={SEO_CONSTANTS.KEYWORDS}
                label={SEO_CONSTANTS.KEYWORDS_LABEL}
                placeholder={SEO_CONSTANTS.KEYWORDS_PLACEHOLDER}
              />
              <ErrorMessage
                name={SEO_CONSTANTS.KEYWORDS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-12">
              <TextArea
                rows={2}
                name={SEO_CONSTANTS.DESCRIPTION}
                label={SEO_CONSTANTS.DESCRIPTION_LABEL}
                placeholder={SEO_CONSTANTS.DESCRIPTION_PLACEHOLDER}
              />
              <ErrorMessage
                name={SEO_CONSTANTS.DESCRIPTION}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { SEODetailsform };
