import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  Loader,
  MultiSelect,
  SelectStatus,
  Summernote,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewFAQ,
  fetchFAQs,
  resetFAQState,
  setFAQToEdit,
  updateExistingFAQ,
} from "store/Ecommerce";
import {
  FAQ_CONSTANTS,
  BUTTON_CONSTANTS,
  FAQSchema,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
  ScrollToTop,
  STATUSES,
  ROLE_CONSTANTS,
} from "utils";

const FAQForm = () => {
  const {
    ecommerce: {
      faq: { faq, status: faqStatus },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const initialValues = {
    question: id ? faq?.question : "",
    answer: id ? faq?.answer : "",
    is_active: id
      ? [
          {
            value: faq?.is_active,
            label: faq?.is_active
              ? GENERAL_CONSTANTS.ACTIVE
              : GENERAL_CONSTANTS.INACTIVE,
          },
        ]
      : [
          {
            value: 1,
            label: GENERAL_CONSTANTS.ACTIVE,
          },
        ],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: values?.is_active?.[0]?.value,
    };
    if (id) {
      dispatch(
        updateExistingFAQ({
          id: Number(id),
          faqParams: { ...dataToSend, id: Number(id) },
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewFAQ({
          faqParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    ScrollToTop();
    id && dispatch(fetchFAQs({ id: Number(id) }));
    return () => {
      dispatch(setFAQToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${FAQ_CONSTANTS.ADD_HEADER}`}
          editValue={faq?.question}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={FAQSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Form>
                  <ScrollToFieldError />
                  {faqStatus === STATUSES.LOADING && !props.isSubmitting && (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <Card>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <h4 className="card-title">
                              {FAQ_CONSTANTS.CARD_TITLE_1}
                            </h4>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-6">
                              <CustomInput
                                type={FORM_CONSTANTS.TEXT}
                                name={FAQ_CONSTANTS.QUESTION}
                                value={props.values.question}
                                label={FAQ_CONSTANTS.QUESTION_LABEL}
                                placeholder={FAQ_CONSTANTS.QUESTION_PLACEHOLDER}
                              />
                              <ErrorMessage
                                name={FAQ_CONSTANTS.QUESTION}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-6">
                              <SelectStatus
                                props={props}
                                name={FAQ_CONSTANTS.STATUS}
                                label={FAQ_CONSTANTS.STATUS_LABEL}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Summernote
                                name={FAQ_CONSTANTS.ANSWER}
                                label={FAQ_CONSTANTS.ANSWER_LABEL}
                                // onInit={() => {
                                //   if (id) {
                                //     props.setFieldValue(
                                //       FAQ_CONSTANTS.ANSWER,
                                //       faq.answer
                                //     );
                                //   }
                                // }}
                                value={props?.values?.answer || ""}
                                onChange={(value) =>
                                  props.setFieldValue(
                                    FAQ_CONSTANTS.ANSWER,
                                    value
                                  )
                                }
                                summerNoteHeight={250}
                                ImageFolderName={
                                  FAQ_CONSTANTS.IMAGE_FOLDER_NAME
                                }
                              />
                              <ErrorMessage
                                name={FAQ_CONSTANTS.ANSWER}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="row">
                            <div className="col-md-6">
                              <Button
                                loading={
                                  faqStatus === STATUSES.LOADING &&
                                  props.isSubmitting
                                }
                                type={BUTTON_CONSTANTS.SUBMIT}
                                text={FORM_CONSTANTS.SUBMIT}
                                btnClassNames={
                                  "btn btn-primary align-self-center w-25"
                                }
                              />
                            </div>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { FAQForm };
