import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delBrand,
  getActiveBrand,
  getBrand,
  getSpecificBrand,
  setBrand,
  updateBrand,
} from "apis/restApis";
import { PaginationState, ProductBrand, Toast } from "utils";
import { updateValues } from "store/commonSlice";
import { FormikHelpers } from "formik";

const initialState = {
  productBrand: [] as ProductBrand[],

  status: STATUSES.IDLE as string,
  error: null,
};

const productBrandSlice = createSlice({
  name: "productBrand",
  initialState,
  reducers: {
    //Brand
    addProductBrand(state, action) {
      state.productBrand.unshift(action.payload);
    },
    getAllBrands(state, action) {
      state.productBrand = action.payload;
    },
    removeProductBrand(state, action) {
      state.productBrand = state.productBrand.filter(
        (brand) => brand.id !== action.payload
      );
    },
    editProductBrand(state, action) {
      const { id } = action.payload;
      const index = state.productBrand.findIndex((brand) => brand.id === id);
      if (index !== -1) {
        state.productBrand[index] = action.payload;
      }
    },

    setBrandStatus(state, action) {
      state.status = action.payload;
    },
    setBrandError(state, action) {
      state.error = action.payload;
    },
    resetBrandState: (state) => {
      state.productBrand = [] as ProductBrand[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllBrands,
  addProductBrand,
  removeProductBrand,
  editProductBrand,

  setBrandStatus,
  setBrandError,
  resetBrandState,
} = productBrandSlice.actions;

export default productBrandSlice.reducer;

export function getBrands(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getBrandsThunk(dispatch) {
    dispatch(setBrandStatus(STATUSES.LOADING));
    active
      ? getActiveBrand()
          .then((response: any) => {
            dispatch(setBrandStatus(STATUSES.IDLE));
            dispatch(getAllBrands(response.result));
            // return Promise.resolve(true);
          })
          .catch((error) => {
            dispatch(setBrandError(error.message));
            dispatch(setBrandStatus(STATUSES.ERROR));
            // return Promise.reject(false);
          })
      : getBrand(pageNo, query)
          .then((response: any) => {
            dispatch(setBrandStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllBrands(response.result.results));
            // return Promise.resolve(true);
          })
          .catch((error) => {
            dispatch(setBrandError(error.message));
            dispatch(setBrandStatus(STATUSES.ERROR));
            // return Promise.reject(false);
          });
  };
}

export function getIndividualBrand(id: number) {
  return async function getIndividualBrandThunk(dispatch, getState) {
    dispatch(setBrandStatus(STATUSES.LOADING));
    getSpecificBrand(id)
      .then((response: any) => {
        dispatch(setBrandStatus(STATUSES.IDLE));
        return response.result;
      })
      .catch((error) => {
        dispatch(setBrandError(error.message));
        dispatch(setBrandStatus(STATUSES.ERROR));
      });
  };
}

export function addNewBrand(brandParams: Object, action: FormikHelpers<any>) {
  return async function addNewBrandThunk(dispatch, getState) {
    dispatch(setBrandStatus(STATUSES.LOADING));
    setBrand(brandParams)
      .then((response: any) => {
        dispatch(addProductBrand(response.result));
        Toast(`${response.message}`, "success");
        action.resetForm();
      })
      .catch((error) => {
        dispatch(setBrandStatus(STATUSES.ERROR));
        dispatch(setBrandError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setBrandStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingBrand(
  id: number,
  brandParams: Object,
  action: FormikHelpers<any>
) {
  return async function updateExistingBrandThunk(dispatch, getState) {
    dispatch(setBrandStatus(STATUSES.LOADING));
    updateBrand(id, brandParams)
      .then((response: any) => {
        dispatch(editProductBrand(response.result));
        Toast(`${response.message}`, "success");
        action.resetForm();
      })
      .catch((error) => {
        dispatch(setBrandStatus(STATUSES.ERROR));
        dispatch(setBrandError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setBrandStatus(STATUSES.IDLE));
      });
  };
}

export function deleteBrand(id: number) {
  return async function deleteBrandThunk(dispatch, getState) {
    dispatch(setBrandStatus(STATUSES.LOADING));
    delBrand(id)
      .then((response: any) => {
        dispatch(editProductBrand(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setBrandStatus(STATUSES.ERROR));
        dispatch(setBrandError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setBrandStatus(STATUSES.IDLE));
      });
  };
}
