import React, { ReactNode } from "react";

interface CardProps {
  children: ReactNode;
  cardClassNames?: string;
  styles?: React.CSSProperties;
  onclickHandler?: () => void;
  onBlurHandler?: () => void;
}

const Card: React.FC<CardProps> = ({
  children,
  styles,
  cardClassNames,
  onclickHandler,
  onBlurHandler,
}) => {
  return (
    <div
      className={`card card-outline ${cardClassNames ? cardClassNames : ""}`}
      style={styles}
      onClick={onclickHandler}
      onBlur={onBlurHandler}
    >
      <>{children}</>
    </div>
  );
};

export { Card };
