import React, { useState } from "react";

import { Formik } from "formik";

import { ContentHeader, OffCanvas, Container, ConfirmModal } from "components";

import {
  useAppDispatch,
  PaymentTermsSchema,
  PAYMENT_TERMS_CONSTANTS,
  PaymentTermType,
} from "utils";
import {
  addNewPaymentTerm,
  deletePaymentTerm,
  updateExistingPaymentTerm,
} from "store/ManagePayments/paymentTermSlice";

import { PaymentTermForm } from "./PaymentTermForm";
import { PaymentTermList } from "./PaymentTermList";

const PaymentTerms = () => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState<false | PaymentTermType>();

  const [currentTerm, setCurrentTerm] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const initialValues = {
    term_type: "",
    term_name: "",
    term_days_after_delivery: "",
    is_active: [{ value: 1, label: "Active" }],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      term_type: values.term_type[0]?.value,
      term_days_after_delivery:
        values.term_type[0].label === "Onspot Payment"
          ? ""
          : values.term_days_after_delivery,
      is_active: values.is_active[0]?.value,
    };

    if (!edit) {
      dispatch(addNewPaymentTerm(dataToSend, actions, setShow));
    } else {
      dispatch(
        updateExistingPaymentTerm(values.id, dataToSend, actions, setShow)
      );
    }
  };

  return (
    <div className="content-wrapper">
      <ContentHeader
        pageHeader={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERMS_HEADER}
      />
      <Container>
        <div className="col-12">
          <PaymentTermList
            setEdit={setEdit}
            setShow={setShow}
            setConfirmModal={setConfirmModal}
            setCurrentTerm={setCurrentTerm}
          />
          <OffCanvas
            title={
              edit
                ? PAYMENT_TERMS_CONSTANTS.EDIT_HEADER
                : PAYMENT_TERMS_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
            }}
          >
            <Container>
              <Formik
                enableReinitialize={true}
                initialValues={edit ? edit : initialValues}
                validationSchema={PaymentTermsSchema}
                onSubmit={handleSubmit}
              >
                {(props) => <PaymentTermForm props={props} edit={edit} />}
              </Formik>
            </Container>
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={PAYMENT_TERMS_CONSTANTS.DELETE_TERM}
        message={PAYMENT_TERMS_CONSTANTS.DELETE_TERM_MSG}
        confirmClick={() => {
          dispatch(deletePaymentTerm(currentTerm));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { PaymentTerms };
