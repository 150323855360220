import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import { getAccountType, setAccountType } from "apis/restApis";

interface BankAccountTypeState {
  id: number;
  type: string;
}

const initialState = {
  bankAccountTypes: [] as BankAccountTypeState[],
  status: STATUSES.IDLE as string,
  error: null,
};

const bankAccountTypeSlice = createSlice({
  name: "bankAccountType",
  initialState,
  reducers: {
    getAllAccountTypes: (state, action) => {
      state.bankAccountTypes = action.payload;
    },
    addAccountType: (state, action) => {
      state.bankAccountTypes.push(action.payload);
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllAccountTypes,
  addAccountType,
  setStatus,
  setError,
} = bankAccountTypeSlice.actions;

export default bankAccountTypeSlice.reducer;

export function getAccountTypes() {
  return async function getAccountTypesThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    getAccountType()
      .then((response: any) => {
        dispatch(getAllAccountTypes(response.result.results));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error: any) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function addNewAccountType(accountTypeParams: BankAccountTypeState) {
  return async function addNewAccountTypeThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    setAccountType(accountTypeParams)
      .then((response: any) => {
        dispatch(addAccountType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error: any) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
