import {
  Button,
  CustomInput,
  MultiSelect,
  OffCanvas,
  TextArea,
} from "components";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { updateEcommerceOrder } from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  capitalizeFirstLetter,
  ECOMMERCE_ORDER_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
  UpdateEcommerceOrderSchema,
  useAppDispatch,
  useAppSelector,
} from "utils";

const UpdateOrderOffCanvas = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();

  const {
    common: { status: commonStatus },
  } = useAppSelector((state) => state.root);

  const {
    order: { status: orderStatus, orderToEdit },
  } = useAppSelector((state) => state.ecommerce);

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      order_status: values?.order_status?.[0]?.value,
      shipping_date:
        values?.order_status?.[0]?.value === GENERAL_CONSTANTS.SHIPPED
          ? values.shipping_date
          : null,
      delivery_date:
        values?.order_status?.[0]?.value === GENERAL_CONSTANTS.SHIPPED
          ? values.delivery_date
          : null,
      tracking_number:
        values?.order_status?.[0]?.value === GENERAL_CONSTANTS.SHIPPED
          ? values.tracking_number
          : null,
      shipping_carrier:
        values?.order_status?.[0]?.value === GENERAL_CONSTANTS.SHIPPED
          ? values.shipping_carrier
          : null,
    };

    dispatch(
      updateEcommerceOrder({
        bill_id: orderToEdit?.id,
        data: dataToSend,
        setModal: setShow,
        actions: actions,
      })
    );
  };

  return (
    <OffCanvas
      title={`Update Order`}
      show={show}
      onClickHandler={() => {
        setShow(false);
      }}
    >
      <Formik
        initialValues={{
          order_status: [
            {
              value: orderToEdit?.order_status,
              label: capitalizeFirstLetter(orderToEdit?.order_status),
            },
          ],
          order_notes: "",
          shipping_carrier: "",
          tracking_number: "",
          shipping_date: new Date().toISOString().split("T")[0],
          delivery_date: new Date().toISOString().split("T")[0],
        }}
        validationSchema={UpdateEcommerceOrderSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form>
              <div className="row">
                <div className="col-12">
                  {show && (
                    <MultiSelect
                      select={false}
                      name="order_status"
                      label="Order Status"
                      options={ECOMMERCE_ORDER_CONSTANTS.ORDER_STATUS_OPTIONS.filter(
                        (item) =>
                          item.value !== GENERAL_CONSTANTS.ALL &&
                          orderToEdit?.order_status !== item.value
                      )}
                      onChangeHandler={(e, actions) => {
                        if (actions.action === GENERAL_CONSTANTS.CLEAR) {
                          props.setFieldValue("order_status", [
                            {
                              value: orderToEdit?.order_status,
                              label: capitalizeFirstLetter(
                                orderToEdit?.order_status || ""
                              ),
                            },
                          ]);
                        } else {
                          props.setFieldValue("order_status", [e]);
                        }
                      }}
                    />
                  )}
                  <ErrorMessage
                    name={"order_status"}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
                {props.values.order_status?.[0]?.value ===
                  GENERAL_CONSTANTS.SHIPPED && (
                  <>
                    <div className="col-12">
                      <CustomInput
                        label={"Shipping Carrier"}
                        placeholder="Enter shipping carrier"
                        // isRequired={true}
                        name={"shipping_carrier"}
                        type={FORM_CONSTANTS.TEXT}
                      />
                      <ErrorMessage
                        name={"shipping_carrier"}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                    <div className="col-12">
                      <CustomInput
                        label={"Tracking Number"}
                        placeholder="Enter tracking number"
                        // isRequired={true}
                        name={"tracking_number"}
                        type={FORM_CONSTANTS.TEXT}
                      />
                      <ErrorMessage
                        name={"tracking_number"}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                    <div className="col-12">
                      <CustomInput
                        label={"Shipping Date"}
                        // isRequired={true}
                        name={"shipping_date"}
                        type={FORM_CONSTANTS.DATE}
                        min={
                          props?.values?.shipping_date ||
                          new Date().toISOString().split("T")[0]
                        }
                      />
                      <ErrorMessage
                        name={"shipping_date"}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                    <div className="col-12">
                      <CustomInput
                        label={"Delivery Date"}
                        // isRequired={true}
                        name={"delivery_date"}
                        type={FORM_CONSTANTS.DATE}
                        min={
                          props?.values?.delivery_date ||
                          new Date().toISOString().split("T")[0]
                        }
                      />
                      <ErrorMessage
                        name={"delivery_date"}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                  </>
                )}
                <div className="col-12">
                  <TextArea
                    rows={5}
                    name={"order_notes"}
                    label={"Order Notes"}
                    placeholder={"Enter any notes for the Order"}
                  />
                  <ErrorMessage
                    name={"order_notes"}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
                <div className="col-12">
                  <Button
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={FORM_CONSTANTS.SUBMIT}
                    loading={
                      commonStatus.state === STATUSES.LOADING &&
                      commonStatus.type === "update-order"
                    }
                    btnClassNames={"btn btn-primary align-self-center w-25"}
                    onClickHandler={() => props.submitForm()}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </OffCanvas>
  );
};

export { UpdateOrderOffCanvas };
