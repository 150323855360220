import moment from "moment";
import React from "react";

const Footer = () => {
  const pjson = require("../../package.json");
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> {pjson.version}
      </div>
      <strong>
        Copyright © {moment().get("year")} <a href="#">Avarya Retail</a>.
      </strong>
      All rights reserved.
    </footer>
  );
};

export { Footer };
