import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Component, ErrorInfo, ReactNode } from "react";
import { Link, useRouteError } from "react-router-dom";
import { ERROR_CONSTANTS, ROUTES } from "utils";
import { FontIcon } from "./FontIcon";

type ErrorBoundaryProps = {
  children?: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: undefined;
};

function ErrorFallbackUI() {
  const routerError: {
    message?: string;
  } = useRouteError();

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="error-page m-0 d-flex flex-column w-100">
          <h1 className="col-12 headline text-warning">
            {ERROR_CONSTANTS.SOMETHING_WENT_WRONG}
          </h1>
          <div className="error-content m-0 w-100">
            <h3 className="text-wrap w-50 p-4">
              <FontIcon
                iconClassNames={"text-warning"}
                icon={faTriangleExclamation}
              />{" "}
              {routerError.message}
            </h3>
            <h5 className="p-4">
              {ERROR_CONSTANTS.RUNTIME_ERROR_MSG}
              <Link to={ROUTES.DASHBOARD}>
                {ERROR_CONSTANTS.RETURN_TO_DASHBOARD}
              </Link>
            </h5>
          </div>
        </div>
      </section>
    </div>
  );
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
  }

  render(): ReactNode {
    const { hasError, error } = this.state;
    const { children } = this.props;

    return <ErrorFallbackUI />;
  }
}

export { ErrorBoundary, ErrorFallbackUI };
