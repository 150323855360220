import { ErrorMessage, Form } from "formik";
import React from "react";
import { Button, Card, CardBody, CustomInput, MultiSelect } from "components";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PAYMENT_MODES_CONSTANTS,
} from "utils";

const PaymentModeForm = ({ props }) => {
  return (
    <Form>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardBody>
              <div>
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  label={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_NAME_LABEL}
                  isRequired={true}
                  name={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_NAME}
                  placeholder={
                    PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_NAME_PLACEHOLDER
                  }
                  value={props.values.payment_mode}
                />
                <ErrorMessage
                  name={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div>
                <MultiSelect
                  select={false}
                  name={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_STATUS}
                  label={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_STATUS_LABEL}
                  options={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_STATUS_OPTIONS}
                  value={props.values.is_active}
                />
                <ErrorMessage
                  name={PAYMENT_MODES_CONSTANTS.PAYMENT_MODE_STATUS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-12">
          <Button
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export { PaymentModeForm };
