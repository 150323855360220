import React, { memo } from "react";

import { ErrorMessage, FormikProps } from "formik";

import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  MultiSelect,
  TextArea,
} from "components";

import {
  FORM_CONSTANTS,
  PaymentMode,
  PaymentTermType,
  STATUSES,
  SupplierType,
  TVendor,
  VENDORS_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { getPaymentModes } from "store/ManagePayments/paymentModeSlice";
import { getPaymentTerms } from "store/ManagePayments/paymentTermSlice";
import { getSupplierTypes } from "store/ManageVendors/supplierTypeSlice";

interface IProps {
  props: FormikProps<TVendor>;
}

const VendorDetailsForm = ({ props }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    paymentMode: { paymentMode: paymentModeData, status: paymentModeStatus },
    paymentTerm: { paymentTerms: paymentTermData, status: paymentTermStatus },
    supplierType: {
      supplierType: supplierTypeData,
      status: supplierTypeStatus,
    },
  } = useAppSelector((state) => state.root);

  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h3 className="card-title">{VENDORS_CONSTANTS.CARD_TITLE_1}</h3>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                isRequired={true}
                label={VENDORS_CONSTANTS.VENDOR_COMPANY_NAME_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_COMPANY_NAME}
                placeholder={VENDORS_CONSTANTS.VENDOR_COMPANY_NAME_PLACEHOLDER}
                type={FORM_CONSTANTS.TEXT}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_COMPANY_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                isRequired={true}
                label={VENDORS_CONSTANTS.VENDOR_NAME_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_NAME}
                placeholder={VENDORS_CONSTANTS.VENDOR_NAME_PLACEHOLDER}
                type={FORM_CONSTANTS.TEXT}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                isRequired={true}
                label={VENDORS_CONSTANTS.VENDOR_EMAIL_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_EMAIL}
                placeholder={VENDORS_CONSTANTS.VENDOR_EMAIL_PLACEHOLDER}
                type={FORM_CONSTANTS.EMAIL}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_EMAIL}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_WHATSAPP_NUMBER_LABEL}
                placeholder={
                  VENDORS_CONSTANTS.VENDOR_WHATSAPP_NUMBER_PLACEHOLDER
                }
                name={VENDORS_CONSTANTS.VENDOR_WHATSAPP_NUMBER}
                type={FORM_CONSTANTS.NUMBER}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_WHATSAPP_NUMBER}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                isRequired={true}
                label={VENDORS_CONSTANTS.VENDOR_GSTN_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_GSTN}
                type={FORM_CONSTANTS.TEXT}
                placeholder={VENDORS_CONSTANTS.VENDOR_GSTN_PLACEHOLDER}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_GSTN}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                isRequired={true}
                label={VENDORS_CONSTANTS.VENDOR_PAN_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_PAN}
                type={FORM_CONSTANTS.TEXT}
                placeholder={VENDORS_CONSTANTS.VENDOR_PAN_PLACEHOLDER}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_PAN}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 ">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_TYPE_LABEL}
                select={false}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_TYPE}
                onFocusHandler={() => {
                  supplierTypeData.length === 0 &&
                    dispatch(getSupplierTypes(true));
                }}
                options={supplierTypeData.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                isLoading={supplierTypeStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_TYPE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_APPLY_TDS_LABEL}
                select={false}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_APPLY_TDS}
                options={VENDORS_CONSTANTS.VENDOR_APPLY_TDS_OPTIONS}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_APPLY_TDS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 ">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_PAYMENT_MODE_LABEL}
                select={false}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_PAYMENT_MODE}
                onFocusHandler={() => {
                  paymentModeData.length === 0 &&
                    dispatch(getPaymentModes(true));
                }}
                options={paymentModeData?.map((paymentMode) => ({
                  value: paymentMode.id,
                  label: paymentMode.payment_mode,
                }))}
                isLoading={paymentModeStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_PAYMENT_MODE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6 ">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_PAYMENT_TERMS_LABEL}
                select={false}
                isRequired={true}
                name={VENDORS_CONSTANTS.VENDOR_PAYMENT_TERMS}
                onFocusHandler={() => {
                  paymentTermData.length === 0 &&
                    dispatch(getPaymentTerms(true));
                }}
                options={paymentTermData?.map((paymentTerm) => ({
                  value: paymentTerm.id,
                  label: paymentTerm.term_name,
                }))}
                isLoading={paymentTermStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_PAYMENT_TERMS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            {props.values?.payment_terms?.[0]?.label === "Credit" ? (
              <div className="col-md-6">
                <CustomInput
                  label={VENDORS_CONSTANTS.VENDOR_CREDIT_LIMIT_LABEL}
                  name={VENDORS_CONSTANTS.VENDOR_CREDIT_LIMIT}
                  placeholder={
                    VENDORS_CONSTANTS.VENDOR_CREDIT_LIMIT_PLACEHOLDER
                  }
                  type={FORM_CONSTANTS.NUMBER}
                />
                <ErrorMessage
                  name={VENDORS_CONSTANTS.VENDOR_CREDIT_LIMIT}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={VENDORS_CONSTANTS.VENDOR_STATUS_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_STATUS}
                options={VENDORS_CONSTANTS.VENDOR_STATUS_OPTIONS}
              />
              <ErrorMessage
                name={VENDORS_CONSTANTS.VENDOR_STATUS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextArea
                label={VENDORS_CONSTANTS.VENDOR_REMARKS_LABEL}
                name={VENDORS_CONSTANTS.VENDOR_REMARKS}
                placeholder={VENDORS_CONSTANTS.VENDOR_REMARKS_PLACEHOLDER}
                rows={2}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VendorDetailsForm);
