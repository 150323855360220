import React from "react";

import { Card, CardBody, Container, ContentHeader } from "components";

const Dashboard = () => {
  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader="Ecommerce Dashboard" />
        <div className="col-12">
          <Card>
            <CardBody>
              <h5>Coming Soon</h5>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export { Dashboard as Ecommerce_Dashboard };
