import {
  faBan,
  faCircleNotch,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ConfirmModal,
  Container,
  ContentHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteCMSCategory,
  fetchCMSCategories,
  resetCategoryState,
} from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  ECOMMERCE_CATEGORY_CONSTANTS,
  PERMISSION_CONSTANTS,
  ROUTES_CONSTANTS,
  ScrollToTop,
  STATUSES,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const Category = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    root: {
      auth: { permissions: permissionData },
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
    },
    ecommerce: {
      category: { categories: CMSCategories, status: CMSCategoryStatus },
    },
  } = useAppSelector((state) => state);

  const [print, setPrint] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const [query, setQuery] = useState("");

  const CMSCategoryPermission = permissionData.find(
    (p) => p.module_name === "Manage Category CMS"
  );

  const update_page = (pageNo: number) => {
    dispatch(
      fetchCMSCategories({
        active: false,
        pageNo,
        query,
      })
    );
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
      dispatch(resetCategoryState());
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader={ECOMMERCE_CATEGORY_CONSTANTS.HEADER} />
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={CMSCategoryPermission.perm_add}
                path={ECOMMERCE_CATEGORY_CONSTANTS.PATH}
                label={ECOMMERCE_CATEGORY_CONSTANTS.ADD_HEADER}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={ECOMMERCE_CATEGORY_CONSTANTS.COLS} />
                <TableBody>
                  {CMSCategoryStatus === STATUSES.LOADING ? (
                    <tr>
                      <td
                        colSpan={ECOMMERCE_CATEGORY_CONSTANTS.COLS.length}
                        className="text-center"
                      >
                        <Loader />
                      </td>
                    </tr>
                  ) : CMSCategories && CMSCategories.length > 0 ? (
                    CMSCategories.map((category: any, index) => {
                      const deleteItem =
                        commonStatus.type ===
                          ECOMMERCE_CATEGORY_CONSTANTS.DELETE_STATE &&
                        commonStatus.state === STATUSES.LOADING;
                      return (
                        <tr key={index}>
                          <td>{page_size * (current_page - 1) + index + 1}</td>
                          <td>{category.display_name}</td>
                          <td>
                            <Status is_active={category.is_active} />
                          </td>
                          <td>
                            <Button
                              text={<FontIcon icon={faPenToSquare} />}
                              isDisabled={!CMSCategoryPermission?.perm_edit}
                              type={BUTTON_CONSTANTS.BUTTON}
                              btnClassNames={"btn btn-info btn-sm mr-2"}
                              onClickHandler={() =>
                                navigate(
                                  ROUTES_CONSTANTS.EDIT + "/" + category.id
                                )
                              }
                            />
                            {category.is_active ? (
                              <Button
                                text={
                                  deleteItem && print === index ? (
                                    <FontIcon
                                      icon={faCircleNotch}
                                      iconClassNames={
                                        "fa-solid fa-circle-notch fa-spin"
                                      }
                                    />
                                  ) : (
                                    <FontIcon icon={faBan} />
                                  )
                                }
                                isDisabled={
                                  !CMSCategoryPermission?.perm_delete ||
                                  deleteItem
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                btnClassNames={"btn btn-danger btn-sm"}
                                onClickHandler={() => {
                                  setPrint(index);
                                  setConfirmModal(true);
                                  setCurrentPage(Number(category.id));
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoData len={ECOMMERCE_CATEGORY_CONSTANTS.COLS.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={CMSCategoryStatus === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={ECOMMERCE_CATEGORY_CONSTANTS.DELETE_CATEGORY}
        message={ECOMMERCE_CATEGORY_CONSTANTS.DELETE_CATEGORY_MSG}
        confirmClick={() => {
          dispatch(deleteCMSCategory(currentPage));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Category as Ecommerce_Category };
