import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delCMSSubCategory,
  getCMSSubCategories,
  setCMSSubCategory,
  updateCMSSubCategory,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { updateValues } from "store/commonSlice";
import { getIndividualSubCategory } from "store/ManageProducts/productSubCategorySlice";
import {
  ECOMMERCE_SUB_CATEGORY_CONSTANTS,
  STATUSES,
  TEcommerceSubCategory,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
} from "utils";

const initialState = {
  sub_categories: [] as TEcommerceSubCategory[],
  sub_category: null as
    | null
    | (TEcommerceSubCategory & { product_sub_category: any }),
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchCMSSubCategories = createAsyncThunk(
  "ecommerce_sub_category/fetchCMSSubCategories",
  async (
    { id, active = false, pageNo = 1, query = "", extra }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response: any = await getCMSSubCategories({
        id,
        active,
        pageNo,
        query,
        extra,
      });
      // if (id) {
      //   dispatch(
      //     getIndividualSubCategory(response.result.product_sub_category.id)
      //   );
      // }
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewCMSSubCategory = createAsyncThunk(
  "ecommerce_sub_category/addNewCMSSubCategory",
  async (
    {
      subCategoryParams,
      actions,
      navigate,
    }: {
      subCategoryParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setCMSSubCategory(subCategoryParams);
      actions.resetForm();
      navigate(ECOMMERCE_SUB_CATEGORY_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingCMSSubCategory = createAsyncThunk(
  "ecommerce_sub_category/updateExistingCMSSubCategory",
  async (
    {
      id,
      subCategoryParams,
      actions,
      navigate,
    }: {
      id: number;
      subCategoryParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateCMSSubCategory(id, subCategoryParams);
      actions.resetForm();
      navigate(ECOMMERCE_SUB_CATEGORY_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCMSSubCategory = createAsyncThunk(
  "ecommerce_sub_category/deleteCMSSubCategory",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delCMSSubCategory(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const ecommerceSubCategorySlice = createSlice({
  name: "ecommerce_sub_category",
  initialState,
  reducers: {
    setCMSSubCategoryToEdit: (state, action) => {
      state.sub_category = action.payload;
    },
    resetSubCategoryState: (state) => {
      state.sub_categories = [] as TEcommerceSubCategory[];
      (state.sub_category = null as
        | null
        | (TEcommerceSubCategory & {
            product_sub_category: any;
          })),
        (state.status = STATUSES.IDLE);
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCMSSubCategories.fulfilled, (state, action: any) => {
        state.sub_categories = [];
        if (action.meta.arg.id) {
          state.sub_category = action.payload.result;
        } else if (action.meta.arg.active) {
          state.sub_categories = action.payload.result;
        } else {
          state.sub_categories = action.payload.result.results;
        }
      })
      .addCase(addNewCMSSubCategory.fulfilled, (state, action: any) => {
        state.sub_categories.unshift(action.payload.result);
        state.sub_categories = state.sub_categories.slice(0, 10);
      })
      .addCase(updateExistingCMSSubCategory.fulfilled, (state, action: any) => {
        // Update the existing sub_category in the sub_categories array
        const index = state.sub_categories.findIndex(
          (sub_category) => sub_category.id === action.payload.result.id
        );
        if (index !== -1) {
          state.sub_categories[index] = action.payload.result;
        }
      })
      .addCase(deleteCMSSubCategory.fulfilled, (state, action: any) => {
        // update the deleted sub_category from the sub_categories array
        const index = state.sub_categories.findIndex(
          (sub_category) => sub_category.id === action.meta.arg
        );
        if (index !== -1) {
          state.sub_categories[index] = {
            ...state.sub_categories[index],
            is_active: action.payload.result.is_active,
          };
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewCMSSubCategory.fulfilled,
          updateExistingCMSSubCategory.fulfilled,
          deleteCMSSubCategory.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCMSSubCategories.pending,
          addNewCMSSubCategory.pending,
          updateExistingCMSSubCategory.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setCMSSubCategoryToEdit,
  resetSubCategoryState,
} = ecommerceSubCategorySlice.actions;

export default ecommerceSubCategorySlice.reducer;
