import { createSlice } from "@reduxjs/toolkit";
import { BRANCH_CONSTANTS, GENERAL_CONSTANTS, STATUSES } from "utils/constants";
import {
  delBranch,
  getActiveBranch,
  getActiveOutletType,
  getBranch,
  getOutletType,
  getSpecificBranch,
  setBranch,
  setOutletType,
  updateBranch,
} from "apis/restApis";
import { Branch, OutletType, TGetParams } from "utils/types";
import { updateValues } from "store/commonSlice";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { Toast } from "utils";
import { LastOrderDetails } from "store/POS";

const initialState = {
  branch: [] as Branch[],
  branchToEdit: {} as Branch,
  outletTypes: [] as OutletType[],
  selectedStore: 0,
  status: STATUSES.IDLE as string,
  error: null,
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    getAllBranch: (state, action) => {
      state.branch = action.payload;
    },
    addBranch: (state, action) => {
      state.branch.push(action.payload);
    },
    removeBranch: (state, action) => {
      state.branch = state.branch.filter(
        (branch) => branch.id !== action.payload
      );
    },
    editBranch: (state, action) => {
      const { id } = action.payload;
      const index = state.branch.findIndex((branch) => branch.id === id);
      if (index !== -1) {
        state.branch[index] = action.payload;
      }
    },

    setBranchToEdit: (state, action) => {
      state.branchToEdit = action.payload;
    },

    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },
    clearSelectedStore: (state) => {
      state.selectedStore = 0;
    },

    addOutletType: (state, action) => {
      state.outletTypes.push(action.payload);
    },
    getAllOutletType: (state, action) => {
      state.outletTypes = action.payload;
    },

    setBranchStatus(state, action) {
      state.status = action.payload;
    },
    setBranchError(state, action) {
      state.error = action.payload;
    },
    resetBranchState: (state) => {
      state.branch = [] as Branch[];
      state.branchToEdit = {} as Branch;
      state.outletTypes = [] as OutletType[];
      state.selectedStore = 0;
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  addBranch,
  getAllBranch,
  removeBranch,
  editBranch,

  setBranchToEdit,

  setSelectedStore,
  clearSelectedStore,

  getAllOutletType,
  addOutletType,

  setBranchStatus,
  setBranchError,
  resetBranchState,
} = branchSlice.actions;

export default branchSlice.reducer;

export function getBranches({
  active,
  pageNo = 1,
  query = "",
  edit,
}: TGetParams) {
  return async function getBranchThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    active
      ? getActiveBranch()
          .then((response: any) => {
            if (response?.result?.length === 1) {
              dispatch(setSelectedStore(response?.result?.[0]?.id));
              dispatch(LastOrderDetails(response?.result?.[0]?.id));
              edit &&
                edit.setCurrentStore &&
                edit.setCurrentStore(response?.result?.[0]?.id);
            } else if (edit && edit.setStoreModal) {
              edit.setStoreModal(true);
            }
            dispatch(getAllBranch(response.result));
            dispatch(setBranchStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setBranchStatus(STATUSES.ERROR));
            dispatch(setBranchError(error.message));
          })
      : getBranch(pageNo, query)
          .then((response: any) => {
            dispatch(setBranchStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllBranch(response.result.results));
          })
          .catch((error) => {
            dispatch(setBranchStatus(STATUSES.ERROR));
            dispatch(setBranchError(error.message));
          });
  };
}

export function getIndividualBranch(id: number, edit: boolean = false) {
  return async function getIndividualBranchThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    getSpecificBranch(id)
      .then((response: any) => {
        if (edit) {
          const dataToEdit = {
            store_name: response.result?.store_name,
            store_code: response.result?.store_code,
            contact_name: response.result?.contact_name,
            contact_number: response.result?.contact_number,
            email: response.result?.email,
            gstn: response.result?.gstn,
            pan: response.result?.pan,
            fssai_number: response.result?.fssai_number,
            outlet_type: response.result?.outlet_type
              ? [
                  {
                    value: response.result.outlet_type.id,
                    label: response.result.outlet_type.outlet_type,
                  },
                ]
              : [],
            address: response.result?.address
              ? {
                  ...response.result?.address,
                  address_line_1: response.result.address.address_line_1,
                  address_line_2: response.result.address.address_line_2,
                  city: [
                    {
                      value: response.result?.address.city.id,
                      label: response.result?.address.city.city,
                    },
                  ],
                  state: [
                    {
                      value: response.result?.address.state.id,
                      label: response.result?.address.state.state,
                    },
                  ],
                  country: [
                    {
                      value: response.result?.address.country.id,
                      label: response.result?.address.country.country,
                    },
                  ],
                  pincode: response.result?.address.pincode,
                }
              : {
                  address_line_1: "",
                  address_line_2: "",
                  city: "",
                  state: "",
                  country: "",
                  pincode: 0,
                },
            bank_name: response.result?.bank_name,
            account_no: response.result?.account_no,
            account_holder_name: response.result?.account_holder_name,
            purchase_billing_address: response.result?.purchase_billing_address
              ?.address
              ? {
                  ...response.result?.purchase_billing_address?.address,
                  address_line_1:
                    response.result?.purchase_billing_address.address
                      .address_line_1,
                  address_line_2:
                    response.result?.purchase_billing_address.address
                      .address_line_2,
                  city: [
                    {
                      value:
                        response.result?.purchase_billing_address.address.city
                          .id,
                      label:
                        response.result?.purchase_billing_address.address.city
                          .city,
                    },
                  ],
                  state: [
                    {
                      value:
                        response.result?.purchase_billing_address.address.state
                          .id,
                      label:
                        response.result?.purchase_billing_address.address.state
                          .state,
                    },
                  ],
                  country: [
                    {
                      value:
                        response.result?.purchase_billing_address.address
                          .country.id,
                      label:
                        response.result?.purchase_billing_address.address
                          .country.country,
                    },
                  ],
                  pincode:
                    response.result?.purchase_billing_address.address.pincode,
                }
              : {
                  address_line_1: "",
                  address_line_2: "",
                  city: "",
                  state: "",
                  country: "",
                  pincode: 0,
                },
            is_ecommerce: [
              {
                value: response.result?.is_ecommerce,
                label:
                  response.result?.is_ecommerce === 1
                    ? GENERAL_CONSTANTS.YES
                    : GENERAL_CONSTANTS.NO,
              },
            ],
            is_active: [
              {
                value: response.result?.is_active,
                label:
                  response.result?.is_active === 1
                    ? GENERAL_CONSTANTS.ACTIVE
                    : GENERAL_CONSTANTS.INACTIVE,
              },
            ],
          };
          dispatch(setBranchToEdit(dataToEdit));
        } else {
          dispatch(setBranchToEdit(response.result));
        }
      })
      .catch((error) => {
        dispatch(setBranchStatus(STATUSES.ERROR));
        dispatch(setBranchError(error.message));
      })
      .finally(() => {
        dispatch(setBranchStatus(STATUSES.IDLE));
      });
  };
}

export function addNewBranch(
  branchParams: Object,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function addNewBranchThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    setBranch(branchParams)
      .then((response: any) => {
        dispatch(addBranch(response.result));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(BRANCH_CONSTANTS.BRANCH_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setBranchStatus(STATUSES.ERROR));
        dispatch(setBranchError(error.message));
      })
      .finally(() => {
        dispatch(setBranchStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingBranch(
  id: number,
  branchParams: Object,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function updateExistingBranchThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    updateBranch(id, branchParams)
      .then((response: any) => {
        dispatch(editBranch(response.result));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(BRANCH_CONSTANTS.BRANCH_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setBranchStatus(STATUSES.ERROR));
        dispatch(setBranchError(error.message));
      })
      .finally(() => {
        dispatch(setBranchStatus(STATUSES.IDLE));
      });
  };
}

export function deleteBranch(id: number) {
  return async function deleteBranchThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    delBranch(id)
      .then((response: any) => {
        dispatch(editBranch(response.result));
        dispatch(setBranchStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setBranchStatus(STATUSES.ERROR));
        dispatch(setBranchError(error.message));
      });
  };
}

export function getOutletTypes(active: boolean) {
  return async function getOutletTypeThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    active
      ? getActiveOutletType()
          .then((response: any) => {
            dispatch(getAllOutletType(response.result));
            dispatch(setBranchStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setBranchStatus(STATUSES.ERROR));
            dispatch(setBranchError(error.message));
          })
      : getOutletType()
          .then((response: any) => {
            dispatch(getAllOutletType(response.result));
            dispatch(setBranchStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setBranchStatus(STATUSES.ERROR));
            dispatch(setBranchError(error.message));
          });
  };
}

export function addNewOutletType(outletTypeParams: OutletType) {
  return async function addNewOutletTypeThunk(dispatch, getState) {
    dispatch(setBranchStatus(STATUSES.LOADING));
    setOutletType(outletTypeParams)
      .then((response: any) => {
        dispatch(addOutletType(response.result));
        dispatch(setBranchStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setBranchStatus(STATUSES.ERROR));
        dispatch(setBranchError(error.message));
      });
  };
}
