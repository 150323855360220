import React from "react";

interface IProps {
  cols: string[] | React.ReactNode[];
  tableHeaderClassNames?: string;
}

const TableHeader = ({ cols, tableHeaderClassNames }: IProps) => {
  return (
    <thead className={`${tableHeaderClassNames}`}>
      <tr>
        {cols.map(
          (col: string | React.ReactNode, index: number) =>
            col && (
              <th key={index} scope="col">
                {col}
              </th>
            )
        )}
      </tr>
    </thead>
  );
};

export { TableHeader };
