import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  FormikProps,
} from "formik";

import {
  filterNullElements,
  useAppDispatch,
  useAppSelector,
} from "utils/helper";
import {
  addNewProductGroup,
  deleteProductGroupBarcodeMapping,
  deleteProductGroupProductMapping,
  FetchProductGroupHamperName,
  getIndividualProductGroup,
  updateExistingProductGroup,
} from "store/ManageProducts/productGroupSlice";

import {
  ContentHeader,
  CustomInput,
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Container,
  FontIcon,
  MultiSelect,
  Table,
  TableBody,
  TableHeader,
  TextArea,
  Loader,
} from "components";

import {
  ProductGroupSchema,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCT_GROUPS_CONSTANTS,
  ProductGroupBarcodeTypeEdit,
  ProductGroupItemEdit,
  STATUSES,
  Toast,
  calculateSellingPrice,
  calculateDiscount,
  GENERAL_CONSTANTS,
  POS_CONSTANTS,
} from "utils";

import { faAdd, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";

import { getCategories } from "store/ManageProducts/productCategorySlice";
import { getSubCategories } from "store/ManageProducts/productSubCategorySlice";
import { getBranches } from "store/ManageBranches/branchSlice";
import { getBarcodeTypes } from "store/ManageProducts/barcodeSlice";
import { clearProducts, getProducts } from "store/ManageProducts/productSlice";
import { getHSN } from "store/hsnCodesSlice";
import { getDiscountTypes } from "store/ManageProducts/ManageDiscounts/discountTypeSlice";
import { ProductTableDetails } from "./ProductTableDetails";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ProductGroupDetails } from "./ProductGroupDetails";

const ProductsGroupsForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    barcode: { barcodeTypes: barCodeTypeData },
    branch: { branch: branchData },
    discountType: { discountType: discountTypeData },
    productCategory: { productCategory: productCategoryData },
    productSubCategory: { productSubCategory: productSubCategoryData },
    productGroup: { productGroupToEdit, status: productGroupStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const daysOfWeek = FORM_CONSTANTS.DAYS_OF_WEEK;
  const pricingCols = FORM_CONSTANTS.PRODUCT_GRP_PRICING_COLS;

  const PropRef = useRef<FormikProps<any>>();

  const [batches, setBatches] = useState([]);

  const [printHamperName, setPrintHamperName] = useState<string>("");

  // const [additionalCharges, setAdditionalCharges] = useState<number>(0);

  const initialValues = {
    print_hamper_name: printHamperName,
    hamper_name: "",
    // hamper_code: "",
    category: "",
    sub_category: "",
    // hsn: "",
    barcode_type: "",
    description: "",
    store_price: branchData.map((item) => ({
      store: item.store_name,
      mrp: 0,
      selling_discount: 0,
      selling_price: 0,
      selling_discount_type: "",
      ceil: true,
    })),

    minimum_selling_quantity: branchData.map((item) => ({
      store: item.store_name,
      Mon: "",
      Tue: "",
      Wed: "",
      Thu: "",
      Fri: "",
      Sat: "",
      Sun: "",
    })),

    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],

    // barcodes: [],

    bulk_mrp: "",
    bulk_selling_discount: "",
    bulk_selling_price: "",
    bulk_selling_discount_type: "",
    bulk_ceil: true,

    bulk_msq_Mon: "",
    bulk_msq_Tue: "",
    bulk_msq_Wed: "",
    bulk_msq_Thu: "",
    bulk_msq_Fri: "",
    bulk_msq_Sat: "",
    bulk_msq_Sun: "",
  };

  const generateEditPrices = (productPrices) => {
    const productPriceItem = productPrices.product.price;
    let prices = [];
    for (let i = 0; i < productPriceItem.length; i++) {
      if (productPriceItem[i].id === productPrices.product_batch) {
        for (let j = 0; j < productPriceItem[i].store_prices.length; j++) {
          prices.push({
            store: productPriceItem[i].store_prices[j].store,
            batch: productPriceItem[i].store_prices[j].batch,
            selling_price: productPriceItem[i].store_prices[j].selling_price,
            mrp: productPriceItem[i].store_prices[j].mrp,
          });
        }
      }
    }
    return prices;
  };

  const setEditValues = (productGroupToEdit) => {
    return {
      print_hamper_name: productGroupToEdit.print_hamper_name,
      hamper_name: productGroupToEdit.hamper_name,
      hamper_code: productGroupToEdit.hamper_code,
      category:
        productGroupToEdit.category &&
        productCategoryData
          .filter((item) => item.id === productGroupToEdit.category)
          .map((res) => ({ value: res.id, label: res.category_name })),

      sub_category:
        productGroupToEdit.sub_category &&
        productSubCategoryData
          .filter((item) => item.id === productGroupToEdit.sub_category)
          .map((res) => ({ value: res.id, label: res.sub_category_name })),

      // hsn:
      //   productGroupToEdit.hsn &&
      //   hsnCodeData
      //     .filter((item) => item.id === productGroupToEdit.hsn)
      //     .map((res) => ({ value: res.id, label: res.hsn_name })),

      barcode_type:
        productGroupToEdit.barcode_type &&
        barCodeTypeData
          .filter((item) => item.id === productGroupToEdit.barcode_type)
          .map((res) => ({ value: res.id, label: res.barcode_type })),

      description: productGroupToEdit.description,
      // barcodes: [],
      // barcodes:
      //   productGroupToEdit.barcodes &&
      //   productGroupToEdit.barcodes.length > 0 &&
      //   productGroupToEdit.barcodes.map((item, index) => ({
      //     ...productGroupToEdit.barcodes[index],
      //     barcode: item.barcode,
      //     id: item.id,
      //   })),

      products: productGroupToEdit?.products?.map((item, index) => {
        return {
          ...productGroupToEdit.products[index],

          // tax: item.tax,
          prices: generateEditPrices(item),
          product_id: item.product.id,
          product: item.product.print_name,
          net_weight: item.product.net_weight
            ? `${item.product.net_weight} ${
                item.product?.uom ? item.product?.uom?.uom_code : ""
              }`
            : "",
          batch:
            item.product.price && item.product.price.length > 0
              ? [
                  item.product.price.find(
                    (batchItem) => batchItem.id === item.product_batch
                  ),
                ].map((res) => ({
                  value: res.id,
                  label: res.batch_name,
                }))
              : [],
          batches: item.product.price.map((res) => ({
            value: res.id,
            label: res.batch_name,
          })),
          quantity: item.quantity,
          total_amount: `${POS_CONSTANTS.RUPEE_SIGN} ${
            item.product.price.find(
              (batchItem) => batchItem.id === item.product_batch
            ).store_prices[0].selling_price * item.quantity
          }`,
        };
      }),

      store_price:
        productGroupToEdit.store_price &&
        productGroupToEdit.store_price.length > 0 &&
        productGroupToEdit.store_price.map((item, index) => ({
          ...productGroupToEdit.store_price[index],
          store: branchData
            .filter((res) => res.id === item.store)
            .map((res) => res.store_name)[0],
          mrp: item.mrp,
          selling_discount: item.selling_discount,
          selling_price: item.selling_price,
          selling_discount_type:
            item.selling_discount_type &&
            discountTypeData
              .filter((res) => res.id === item.selling_discount_type)
              .map((res) => ({ value: res.id, label: res.type })),
          ceil: item.ceil ? true : false,
        })),

      minimum_selling_quantity:
        productGroupToEdit.minimum_selling_quantity &&
        productGroupToEdit.minimum_selling_quantity.length > 0 &&
        productGroupToEdit.minimum_selling_quantity.map((item, index) => ({
          ...productGroupToEdit.minimum_selling_quantity[index],
          store: branchData
            .filter((res) => res.id === item.store)
            .map((res) => res.store_name)[0],
          Mon: item.monday,
          Tue: item.tuesday,
          Wed: item.wednesday,
          Thu: item.thursday,
          Fri: item.friday,
          Sat: item.saturday,
          Sun: item.sunday,
        })),

      is_active: [
        {
          value: productGroupToEdit?.is_active,
          label:
            productGroupToEdit?.is_active === 1
              ? GENERAL_CONSTANTS.ACTIVE
              : GENERAL_CONSTANTS.INACTIVE,
        },
      ],
    };
  };

  const handleDelete = (data) => {
    const updatedValues = { ...data };

    updatedValues.products.forEach((item) => {
      delete item.new;
      delete item.batch;
      delete item.prices;
    });

    delete updatedValues.bulk_mrp;
    delete updatedValues.bulk_selling_discount;
    delete updatedValues.bulk_selling_price;
    delete updatedValues.bulk_selling_discount_type;
    delete updatedValues.bulk_ceil;

    delete updatedValues.bulk_msq_Mon;
    delete updatedValues.bulk_msq_Tue;
    delete updatedValues.bulk_msq_Wed;
    delete updatedValues.bulk_msq_Thu;
    delete updatedValues.bulk_msq_Fri;
    delete updatedValues.bulk_msq_Sat;
    delete updatedValues.bulk_msq_Sun;

    return updatedValues;
  };

  const handleSubmit = (values, actions) => {
    // const productData = values.products.map((item) =>
    //   Number(id)
    //     ? {
    //         // ...item,
    //         id: item.id,
    //         product_group: Number(id),
    //         product: item.product_id,
    //         quantity: item.quantity,
    //         product_batch: item.batch[0].value,
    //       }
    //     : {
    //         product: item.product_id,
    //         quantity: item.quantity,
    //         product_batch: item.batch[0].value,
    //       }
    // );

    const productData = values.products.map((item) => {
      const productItem: any = {
        product: item.product_id,
        quantity: item.quantity,
        product_batch: item.batch[0].value,
      };
      if (Number(id)) {
        productItem.id = item.id;
        productItem.product_group = Number(id);
      }
      return productItem;
    });

    // const storePrice = branchData.map((item, index) =>
    //   Number(id)
    //     ? {
    //         // ...values.store_price[index],
    //         id: values.store_price[index].id,
    //         product_group: Number(id),
    //         store: item.id,
    //         mrp: values.store_price[index].mrp,
    //         selling_discount: values.store_price[index].selling_discount
    //           ? values.store_price[index].selling_discount
    //           : 0,
    //         selling_discount_type: values.store_price[index]
    //           .selling_discount_type
    //           ? values.store_price[index].selling_discount_type[0].value
    //           : null,
    //         selling_price: values.store_price[index].selling_price,
    //         ceil: values.store_price[index].ceil ? 1 : 0,
    //       }
    //     : {
    //         store: item.id,
    //         mrp: values.store_price[index].mrp,
    //         selling_discount: values.store_price[index].selling_discount
    //           ? values.store_price[index].selling_discount
    //           : 0,
    //         selling_discount_type: values.store_price[index]
    //           .selling_discount_type
    //           ? values.store_price[index].selling_discount_type[0].value
    //           : null,
    //         selling_price: values.store_price[index].selling_price,
    //         ceil: values.store_price[index].ceil ? 1 : 0,
    //       }
    // );

    const storePrice = branchData.map((item, index) => {
      const storePriceData: any = {
        store: item.id,
        mrp: values?.store_price?.[index]?.mrp,
        selling_discount: values?.store_price[index]?.selling_discount || 0,
        selling_discount_type: values?.store_price?.[index]
          ?.selling_discount_type
          ? values?.store_price?.[index]?.selling_discount_type[0].value
          : null,
        selling_price: values?.store_price?.[index]?.selling_price,
        ceil: values?.store_price?.[index]?.ceil ? 1 : 0,
      };
      if (Number(id)) {
        storePriceData.id = values?.store_price?.[index]?.id;
        storePriceData.product_group = Number(id);
      }
      return storePriceData;
    });

    // const MSQData = branchData.map((item, index) =>
    //   Number(id)
    //     ? {
    //         // ...values.minimum_selling_quantity[index],
    //         id: values.minimum_selling_quantity[index].id,
    //         product_group: values.minimum_selling_quantity[index].product_group,
    //         store: item.id,
    //         monday: values.minimum_selling_quantity[index].Mon,
    //         tuesday: values.minimum_selling_quantity[index].Tue,
    //         wednesday: values.minimum_selling_quantity[index].Wed,
    //         thursday: values.minimum_selling_quantity[index].Thu,
    //         friday: values.minimum_selling_quantity[index].Fri,
    //         saturday: values.minimum_selling_quantity[index].Sat,
    //         sunday: values.minimum_selling_quantity[index].Sun,
    //       }
    //     : {
    //         store: item.id,
    //         monday: values.minimum_selling_quantity[index].Mon,
    //         tuesday: values.minimum_selling_quantity[index].Tue,
    //         wednesday: values.minimum_selling_quantity[index].Wed,
    //         thursday: values.minimum_selling_quantity[index].Thu,
    //         friday: values.minimum_selling_quantity[index].Fri,
    //         saturday: values.minimum_selling_quantity[index].Sat,
    //         sunday: values.minimum_selling_quantity[index].Sun,
    //       }
    // );

    const MSQData = branchData.map((item, index) => {
      const msq = values?.minimum_selling_quantity?.[index];
      if (msq !== undefined) {
        const { Mon, Tue, Wed, Thu, Fri, Sat, Sun } = msq;
        const msqData: any = {
          store: item.id,
          monday: Mon,
          tuesday: Tue,
          wednesday: Wed,
          thursday: Thu,
          friday: Fri,
          saturday: Sat,
          sunday: Sun,
        };
        if (Number(id)) {
          msqData.id = msq.id;
          msqData.product_group = Number(id);
        }
        return msqData;
      }
    });

    const modifiedData = {
      ...values,
      barcode_type: values?.barcode_type?.[0]?.value,
      category: values?.category?.[0]?.value,
      sub_category: values?.sub_category?.[0]?.value,
      // hsn: values.hsn[0].value,
      count: productData.length,
      products: productData,
      minimum_selling_quantity: filterNullElements(MSQData),
      store_price: storePrice,
      is_active: values?.is_active?.[0]?.value,
    };

    const dataToSend = handleDelete(modifiedData);

    if (id) {
      dispatch(
        updateExistingProductGroup(
          productGroupToEdit.id,
          dataToSend,
          actions,
          navigate
        )
      );
    } else {
      dispatch(addNewProductGroup(dataToSend, actions, navigate));
    }
    // props.actions.resetForm();
  };

  const handlePriceChange = (index: number, ceil?: boolean) => {
    const discount_value = PropRef.current.values?.store_price?.[index]
      ?.selling_discount_type
      ? calculateSellingPrice(
          PropRef.current.values?.store_price?.[index]?.mrp,
          PropRef.current.values?.store_price?.[index]?.selling_discount,
          ceil
            ? ceil
            : PropRef.current.values?.store_price?.[index]
                ?.selling_discount_type?.[0]?.value,
          PropRef.current.values?.store_price?.[index]?.ceil
        )
      : PropRef.current.values?.store_price?.[index]?.mrp;

    if (discount_value <= 0) {
      Toast("Selling price cannot be negative", "error");
      PropRef.current.setFieldValue(
        `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_DISCOUNT}`,
        0
      );
      PropRef.current.setFieldValue(
        `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_DISCOUNT_TYPE}`,
        PropRef.current.values?.store_price?.[index]?.selling_discount_type
      );
      PropRef.current.setFieldValue(
        `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_PRICE}`,
        PropRef.current.values.store_price[index].mrp
      );
    } else {
      PropRef.current.setFieldValue(
        `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_PRICE}`,
        discount_value
      );
    }
  };

  useEffect(() => {
    const getFormData = async () => {
      await Promise.all([
        id &&
          dispatch(
            getIndividualProductGroup({
              id: Number(id),
              manualAdd: false,
              setBatches: setBatches,
            })
          ),
        !id && dispatch(FetchProductGroupHamperName(setPrintHamperName)),
        // id && setBatches(generateBatcheOptions(productGroupToEdit)),
        // id &&
        //   productGroupToEdit?.products?.map((item) =>
        //     generateEditBatches(item)
        //   ),
        dispatch(getCategories(true)),
        dispatch(getSubCategories(true)),
        dispatch(getBranches({ active: true })),
        dispatch(getBarcodeTypes(true)),
        dispatch(getDiscountTypes(true)),
        dispatch(getHSN(true)),
      ]);
    };
    getFormData();
  }, []);

  return (
    <div className="content-wrapper">
      <ContentHeader
        editValue={productGroupToEdit?.print_hamper_name}
        pageHeader={
          id
            ? PRODUCT_GROUPS_CONSTANTS.EDIT_HEADER
            : PRODUCT_GROUPS_CONSTANTS.ADD_HEADER
        }
      />
      <Container>
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={
              id ? setEditValues(productGroupToEdit) : initialValues
            }
            validationSchema={ProductGroupSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropRef.current = props;
              return (
                <Form>
                  <ScrollToFieldError />
                  {productGroupStatus === STATUSES.LOADING && (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div className="row">
                    {/* Product Group Details */}
                    <ProductGroupDetails props={props} />
                    {/* Product Details */}
                    <ProductTableDetails
                      props={props}
                      batches={batches}
                      setBatches={setBatches}
                      branchData={branchData}
                    />
                    {/* Pricing Details */}
                    <div className="col-md-12 table-responsive">
                      <FieldArray
                        name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}
                        render={(arrayHelpers) => {
                          const newPrice =
                            props.values?.store_price &&
                            props.values?.store_price?.length > 0 &&
                            branchData.map((item, index) => {
                              const bulkSellingPrice = props.values
                                ?.bulk_selling_discount_type
                                ? calculateSellingPrice(
                                    props.values?.store_price?.[index]?.mrp,
                                    props.values?.bulk_selling_discount,
                                    props.values
                                      ?.bulk_selling_discount_type?.[0]?.value,
                                    props.values?.bulk_ceil
                                  )
                                : props.values?.store_price?.[index]?.mrp || 0;
                              return {
                                ...props.values?.store_price?.[index],
                                store: item.store_name,
                                mrp:
                                  props?.values?.store_price?.[index]?.mrp || 0,
                                selling_discount:
                                  props.values?.bulk_selling_discount,
                                selling_price: bulkSellingPrice,
                                selling_discount_type:
                                  props.values?.bulk_selling_discount_type,
                                ceil: props.values?.bulk_ceil,
                              };
                            });

                          return (
                            <Table>
                              <TableHeader cols={pricingCols} />
                              <TableBody>
                                <tr>
                                  <td className="col-2 align-middle text-center">
                                    All
                                  </td>
                                  <td className="col-2 align-middle text-center">
                                    {/* <Field
                                      as={CustomInput}
                                      step="0.01"
                                      name="bulk_mrp"
                                      type={FORM_CONSTANTS.NUMBER}
                                      placeholder={
                                        PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_MRP_PLACEHOLDER
                                      }
                                      onBlurHandler={() => {
                                        props.setFieldValue(
                                          "store_price",
                                          newPrice
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name="bulk_mrp"
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    /> */}
                                    MRP
                                  </td>
                                  <td className="col-4">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <MultiSelect
                                          name="bulk_selling_discount_type"
                                          options={discountTypeData.map(
                                            (item) => ({
                                              value: item.id,
                                              label: item.type,
                                            })
                                          )}
                                          onBlurHandler={() => {
                                            props.setFieldValue(
                                              "store_price",
                                              newPrice
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <Field
                                          as={CustomInput}
                                          step="0.01"
                                          name="bulk_selling_discount"
                                          type={FORM_CONSTANTS.NUMBER}
                                          discountType={
                                            props.values
                                              ?.bulk_selling_discount_type?.[0]
                                              ?.label
                                          }
                                          onBlurHandler={() => {
                                            const discount_value = calculateSellingPrice(
                                              props.values?.bulk_mrp,
                                              props.values
                                                ?.bulk_selling_discount,
                                              props.values
                                                ?.bulk_selling_discount_type?.[0]
                                                ?.value,
                                              props.values?.bulk_ceil
                                            );

                                            if (discount_value <= 0) {
                                              Toast(
                                                "Selling price cannot be negative",
                                                "error"
                                              );
                                              props.setFieldValue(
                                                "bulk_selling_discount",
                                                0
                                              );
                                              props.setFieldValue(
                                                "bulk_selling_discount_type",
                                                props.values
                                                  ?.bulk_selling_discount_type
                                              );
                                              props.setFieldValue(
                                                "bulk_selling_price",
                                                props.values.bulk_mrp
                                              );
                                            } else {
                                              props.setFieldValue(
                                                "bulk_selling_price",
                                                discount_value
                                              );
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name="bulk_selling_discount"
                                          component={
                                            FORM_CONSTANTS.ERROR_PARENT
                                          }
                                          className={FORM_CONSTANTS.ERROR}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="col-2">
                                    <div className="row text-center">
                                      <Field
                                        as={CustomInput}
                                        isDisabled={true}
                                        name="bulk_selling_price"
                                        step="0.01"
                                        type={FORM_CONSTANTS.NUMBER}
                                        placeholder={
                                          PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_PRICE_PLACEHOLDER
                                        }
                                      />
                                      <ErrorMessage
                                        name="bulk_selling_price"
                                        component={FORM_CONSTANTS.ERROR_PARENT}
                                        className={FORM_CONSTANTS.ERROR}
                                      />
                                    </div>
                                    <div className="row">
                                      <Checkbox
                                        name="bulk_ceil"
                                        label="Ceil"
                                        isChecked={
                                          props.values.bulk_ceil &&
                                          props.values.bulk_ceil
                                        }
                                        isSwitch={true}
                                        onClickHandler={() => {
                                          const updatedValues = [
                                            ...props.values.store_price,
                                          ];

                                          updatedValues.forEach(
                                            (pricingItem, index) => {
                                              updatedValues[index].ceil = !props
                                                .values.bulk_ceil;
                                            }
                                          );

                                          props.setFieldValue(
                                            "bulk_ceil",
                                            !props.values.bulk_ceil
                                          );
                                          props.setFieldValue(
                                            "store_price",
                                            updatedValues
                                          );
                                        }}
                                        onBlurHandler={() => {
                                          props.setFieldValue(
                                            "store_price",
                                            newPrice
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name="bulk_ceil"
                                        component={FORM_CONSTANTS.ERROR_PARENT}
                                        className={FORM_CONSTANTS.ERROR}
                                      />
                                    </div>
                                  </td>
                                </tr>
                                {props.values?.store_price?.map(
                                  (pricing, index) => (
                                    <tr key={index}>
                                      <td className="col-2">
                                        <CustomInput
                                          name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_STORE}`}
                                          isDisabled={true}
                                          type={FORM_CONSTANTS.TEXT}
                                          // value={props.values.store_price[index].store}
                                        />
                                      </td>
                                      <td className="col-2">
                                        <Field
                                          as={CustomInput}
                                          step="0.01"
                                          isDisabled={true}
                                          name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_MRP}`}
                                          type={FORM_CONSTANTS.NUMBER}
                                          placeholder={
                                            PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_MRP_PLACEHOLDER
                                          }
                                          value={
                                            props.values.store_price[index].mrp
                                          }
                                          onBlurHandler={() => {
                                            handlePriceChange(index);
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_MRP}`}
                                          component={
                                            FORM_CONSTANTS.ERROR_PARENT
                                          }
                                          className={FORM_CONSTANTS.ERROR}
                                        />
                                      </td>
                                      <td className="col-4">
                                        <div className="d-flex flex-row">
                                          <div className="col-md-8">
                                            <MultiSelect
                                              name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_DISCOUNT_TYPE}`}
                                              options={discountTypeData.map(
                                                (item) => ({
                                                  value: item.id,
                                                  label: item.type,
                                                })
                                              )}
                                              onBlurHandler={() => {
                                                handlePriceChange(index);
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_DISCOUNT_TYPE}`}
                                              component={
                                                FORM_CONSTANTS.ERROR_PARENT
                                              }
                                              className={FORM_CONSTANTS.ERROR}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <Field
                                              as={CustomInput}
                                              step="0.01"
                                              name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_DISCOUNT}`}
                                              type={FORM_CONSTANTS.NUMBER}
                                              discountType={
                                                props.values?.store_price?.[
                                                  index
                                                ]?.selling_discount_type?.[0]
                                                  ?.label
                                              }
                                              onBlurHandler={() => {
                                                handlePriceChange(index);
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_DISCOUNT}`}
                                              component={
                                                FORM_CONSTANTS.ERROR_PARENT
                                              }
                                              className={FORM_CONSTANTS.ERROR}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td className="col-2">
                                        <div className="d-flex flex-row">
                                          <Field
                                            as={CustomInput}
                                            step="0.01"
                                            isDisabled={true}
                                            name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_PRICE}`}
                                            type={FORM_CONSTANTS.NUMBER}
                                            placeholder={
                                              PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_PRICE_PLACEHOLDER
                                            }
                                          />
                                          <ErrorMessage
                                            name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_SELLING_PRICE}`}
                                            component={
                                              FORM_CONSTANTS.ERROR_PARENT
                                            }
                                            className={FORM_CONSTANTS.ERROR}
                                          />
                                        </div>
                                        <div className="d-flex flex-row">
                                          <Checkbox
                                            name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_CEIL}`}
                                            label={
                                              PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_CEIL_PLACEHOLDER
                                            }
                                            isChecked={
                                              props.values.store_price &&
                                              props.values.store_price.length >
                                                0 &&
                                              props.values.store_price[index]
                                                ?.ceil
                                            }
                                            isSwitch={true}
                                            onClickHandler={() => {
                                              props.setFieldValue(
                                                `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_CEIL}`,
                                                !props.values.store_price[index]
                                                  .ceil
                                              );
                                            }}
                                            onBlurHandler={() => {
                                              handlePriceChange(index);
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_CEIL}`}
                                            component={
                                              FORM_CONSTANTS.ERROR_PARENT
                                            }
                                            className={FORM_CONSTANTS.ERROR}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          );
                        }}
                      />
                    </div>
                    {/* MSQ Details */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h5 className="card-title">
                            {PRODUCT_GROUPS_CONSTANTS.CARD_TITLE_4}
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <FieldArray
                            name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_MSQ}
                            render={(arrayHelpers) => {
                              const bulk =
                                props.values?.minimum_selling_quantity &&
                                props.values?.minimum_selling_quantity.length >
                                  0 &&
                                branchData.map((item, index) => ({
                                  ...props.values.minimum_selling_quantity[
                                    index
                                  ],
                                  store: item.store_name,
                                  Mon: props.values?.bulk_msq_Mon,
                                  Tue: props.values?.bulk_msq_Mon,
                                  Wed: props.values?.bulk_msq_Mon,
                                  Thu: props.values?.bulk_msq_Mon,
                                  Fri: props.values?.bulk_msq_Mon,
                                  Sat: props.values?.bulk_msq_Mon,
                                  Sun: props.values?.bulk_msq_Mon,
                                }));

                              return (
                                <Table>
                                  <thead>
                                    <tr>
                                      <th
                                        rowSpan={2}
                                        className="col-2 align-middle text-center"
                                      >
                                        {
                                          PRODUCT_GROUPS_CONSTANTS.MSQ_STORE_LABEL
                                        }
                                      </th>
                                      <th
                                        colSpan={7}
                                        className="col-8 text-center"
                                      >
                                        {
                                          PRODUCT_GROUPS_CONSTANTS.MSQ_DAYS_LABEL
                                        }
                                      </th>
                                    </tr>
                                    <tr>
                                      {daysOfWeek.map((day, dayIndex) => (
                                        <th
                                          scope="col"
                                          key={dayIndex}
                                          className="text-center"
                                        >
                                          {day}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <TableBody>
                                    <tr>
                                      <td className="align-middle text-center">
                                        All
                                      </td>
                                      {daysOfWeek.map((day, dayIndex) => (
                                        <td
                                          key={dayIndex}
                                          className="align-middle"
                                        >
                                          <Field
                                            as={CustomInput}
                                            name={`bulk_msq_${day}`}
                                            type={FORM_CONSTANTS.NUMBER}
                                            placeholder={day}
                                            onBlurHandler={() => {
                                              if (day === "Mon") {
                                                daysOfWeek.map((item) => {
                                                  props.setFieldValue(
                                                    `bulk_msq_${item}`,
                                                    props.values[`bulk_msq_Mon`]
                                                  );
                                                });
                                                props.setFieldValue(
                                                  "minimum_selling_quantity",
                                                  bulk
                                                );
                                              } else {
                                                branchData.map(
                                                  (item, index) => {
                                                    props.setFieldValue(
                                                      `minimum_selling_quantity[${index}].${day}`,
                                                      props.values[
                                                        `bulk_msq_${day}`
                                                      ]
                                                    );
                                                  }
                                                );
                                              }
                                            }}
                                          />
                                        </td>
                                      ))}
                                    </tr>
                                    {props.values.minimum_selling_quantity &&
                                      props.values.minimum_selling_quantity &&
                                      props.values.minimum_selling_quantity
                                        .length > 0 &&
                                      props.values.minimum_selling_quantity.map(
                                        (msq, index) => (
                                          <tr key={index}>
                                            <td>
                                              <CustomInput
                                                name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_MSQ}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRICING_STORE}`}
                                                isDisabled={true}
                                                type={FORM_CONSTANTS.TEXT}
                                              />
                                            </td>
                                            {daysOfWeek.map((day, dayIndex) => (
                                              <td
                                                key={dayIndex}
                                                className="align-middle"
                                              >
                                                <Field
                                                  as={CustomInput}
                                                  name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_MSQ}[${index}].${day}`}
                                                  type={FORM_CONSTANTS.NUMBER}
                                                  placeholder={day}
                                                />
                                              </td>
                                            ))}
                                          </tr>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              );
                            }}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    {/* Submit */}
                    <div className="col-md-8">
                      <Button
                        type={BUTTON_CONSTANTS.BUTTON}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary "}
                        loading={productGroupStatus === STATUSES.LOADING}
                        onClickHandler={() => {
                          props.submitForm();
                        }}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { ProductsGroupsForm };
