import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import {
  deleteVendor,
  getVendors,
  removeVendor,
} from "store/ManageVendors/vendorSlice";

import { ContentHeader, Loader, NoData, Status, TableTools } from "components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BUTTON_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
  VENDORS_CONSTANTS,
} from "utils/constants";
import { updatePageNo } from "store/commonSlice";

const Vendors = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    vendor: { vendors: vendorData, status },
  } = useAppSelector((state) => state.root);

  const vendorPermission = permissions.find(
    (p) => p.module_name === "Manage Vendors"
  );

  const [query, setQuery] = useState("");

  const tableCols = VENDORS_CONSTANTS.VENDORS_COLS;

  const update_page = (pageNo: number) => {
    dispatch(getVendors(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={VENDORS_CONSTANTS.VENDORS_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={vendorPermission?.perm_add}
                path={VENDORS_CONSTANTS.PATH}
                label={VENDORS_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : vendorData.length > 0 ? (
                    vendorData.map((vendor, index) => (
                      <tr key={vendor.id}>
                        <td>{page_size * (current_page - 1) + index + 1}</td>
                        <td>{vendor.name}</td>
                        <td>{vendor.company_name}</td>
                        <td>
                          <Status is_active={vendor.is_active} />
                        </td>
                        <td>
                          <Button
                            text={<FontIcon icon={faPenToSquare} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            // isDisabled={!vendorPermission?.perm_edit}
                            btnClassNames={"btn btn-info btn-sm mr-2"}
                            onClickHandler={() =>
                              navigate(ROUTES_CONSTANTS.EDIT + "/" + vendor.id)
                            }
                          />
                          {vendor.is_active ? (
                            <Button
                              text={<FontIcon icon={faBan} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              // isDisabled={!vendorPermission?.perm_delete}
                              btnClassNames={"btn btn-danger btn-sm"}
                              onClickHandler={() =>
                                dispatch(deleteVendor(vendor.id))
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export { Vendors };
