import { createSlice } from "@reduxjs/toolkit";
import {
  DISCOUNTS_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
} from "utils/constants";
import {
  delDiscount,
  delDiscountBrandMapping,
  delDiscountCategoryMapping,
  delDiscountProductMapping,
  delDiscountStoreMapping,
  delDiscountSubCategoryMapping,
  getActiveDiscount,
  getDiscount,
  getSpecificDiscount,
  setDiscount,
  updateDiscount,
} from "apis/restApis";
import { Discount, PaginationState, Toast, filterNullElements } from "utils";
import { setCommonStatus, updateValues } from "store/commonSlice";
import { getBranches } from "store/ManageBranches/branchSlice";
import {
  getCategories,
  getMultipleCategoriesDetails,
} from "../productCategorySlice";
import { getSubCategories } from "../productSubCategorySlice";
import { getBrands } from "../productBrandSlice";
import { getDiscountTypes } from "./discountTypeSlice";
import { getDiscountApplicableTypes } from "./discountApplicableTypeSlice";
import { fetchCustomerEligibility } from "./discountCustomerEligibilitySlice";
import { fetchCouponUsage } from "./discountCouponUsageSlice";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";

const initialState = {
  discounts: [] as Discount[],
  discountToEdit: {} as Discount,
  status: STATUSES.IDLE as string,
  error: null,
};

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    getAllDiscount: (state, action) => {
      state.discounts = action.payload;
    },
    addDiscount: (state, action) => {
      state.discounts.unshift(action.payload);
    },
    removeDiscount: (state, action) => {
      state.discounts = state.discounts.filter(
        (discount) => discount.id !== action.payload
      );
    },
    editDiscount: (state, action) => {
      const { id } = action.payload;
      const index = state.discounts.findIndex((discount) => discount.id === id);
      if (index !== -1) {
        state.discounts[index] = action.payload;
      }
    },
    setDiscountToEdit: (state, action) => {
      state.discountToEdit = action.payload;
    },

    setDiscountStatus: (state, action) => {
      state.status = action.payload;
    },
    setDiscountError: (state, action) => {
      state.error = action.payload;
    },
    resetDiscountState: (state) => {
      state.discounts = [] as Discount[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllDiscount,
  addDiscount,
  removeDiscount,
  editDiscount,
  setDiscountToEdit,

  setDiscountStatus,
  setDiscountError,
  resetDiscountState,
} = discountSlice.actions;

export default discountSlice.reducer;

export function getDiscounts(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getDiscountsThunk(dispatch, getState) {
    dispatch(setDiscountStatus(STATUSES.LOADING));
    active
      ? getActiveDiscount()
          .then((response: any) => {
            dispatch(setDiscountStatus(STATUSES.IDLE));
            dispatch(getAllDiscount(response.result));
          })
          .catch((error) => {
            dispatch(setDiscountStatus(STATUSES.ERROR));
            dispatch(setDiscountError(error.message));
          })
      : getDiscount(pageNo, query)
          .then((response: any) => {
            dispatch(setDiscountStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllDiscount(response.result.results));
          })
          .catch((error) => {
            dispatch(setDiscountStatus(STATUSES.ERROR));
            dispatch(setDiscountError(error.message));
          });
  };
}

export function FetchDiscountFormOptionData(
  id?: number,
  setInitialData?: React.Dispatch<
    React.SetStateAction<{
      categories: any[];
      sub_categories: any[];
      brands: any[];
      products: any[];
      stores: any[];
    }>
  >
) {
  return async function FetchDiscountFormOptionDataThunk(dispatch, getState) {
    dispatch(setDiscountStatus(STATUSES.LOADING));
    const data = await Promise.all([
      dispatch(getBranches({ active: true })),
      dispatch(getCategories(true)),
      dispatch(getSubCategories(true)),
      dispatch(getBrands(true)),
      dispatch(getDiscountTypes(true)),
      dispatch(getDiscountApplicableTypes(true)),
      dispatch(fetchCustomerEligibility(true)),
      dispatch(fetchCouponUsage(true)),
    ])
      .then(() => {
        if (id) {
          dispatch(getIndividualDiscount(Number(id), true, setInitialData));
        }
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function getIndividualDiscount(
  id: number,
  edit?: boolean,
  setInitialData?: React.Dispatch<
    React.SetStateAction<{
      categories: any[];
      sub_categories: any[];
      brands: any[];
      products: any[];
      stores: any[];
    }>
  >
) {
  return async function getIndividualDiscountThunk(dispatch, getState) {
    const {
      branch: { branch: branchData },
      discountApplicableType: {
        discountApplicableType: discountApplicableTypeData,
      },
      discountCouponUsage: { couponUsage: couponUsageData },
      discountCustomerEligibility: {
        customerEligibility: customerEligibilityData,
      },
      discountType: { discountType: discountTypeData },
      productBrand: { productBrand: brandData },
      productCategory: { productCategory: categoryData },
      productSubCategory: { productSubCategory: subCategoryData },
    } = getState().root;
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "individualDiscount" })
    );
    dispatch(setDiscountStatus(STATUSES.LOADING));
    getSpecificDiscount(id)
      .then((response: any) => {
        if (edit) {
          // dispatch(FetchDiscountFormOptionData());

          const dataToEdit = {
            discount_code: response.result?.discount_code,
            discount_value: response.result?.discount_value,

            discount_applicable: discountApplicableTypeData
              .filter(
                (item) => item.id === response.result?.discount_applicable
              )
              .map((res) => ({ value: res.id, label: res.type })),

            discount_type: response.result?.discount_type
              ? discountTypeData
                  .filter((item) => item.id === response.result?.discount_type)
                  .map((res) => ({ value: res.id, label: res.type }))
              : [],

            discount_auto_apply: [
              {
                value: response.result?.discount_auto_apply,
                label:
                  response.result?.discount_auto_apply === 1
                    ? GENERAL_CONSTANTS.YES
                    : GENERAL_CONSTANTS.NO,
              },
            ],

            exclude_products_with_discount: [
              {
                value: response.result?.exclude_products_with_discount,
                label:
                  response.result?.exclude_products_with_discount === 1
                    ? GENERAL_CONSTANTS.YES
                    : GENERAL_CONSTANTS.NO,
              },
            ],
            categories:
              response.result.categories.length > 0
                ? filterNullElements(
                    response.result?.categories.map(
                      (item) =>
                        categoryData
                          .filter((category) => category.id === item.category)
                          .map((res) => ({
                            id: res.id,
                            value: res.id,
                            label: res.category_name,
                          }))[0]
                    )
                  )
                : [],

            sub_categories:
              response.result.sub_category.length > 0
                ? filterNullElements(
                    response.result?.sub_category.map(
                      (item) =>
                        subCategoryData
                          .filter(
                            (sub_category) =>
                              sub_category.id === item.sub_category
                          )
                          .map((res) => ({
                            id: res.id,
                            value: res.id,
                            label: res.sub_category_name,
                          }))[0]
                    )
                  )
                : [],

            brands:
              response.result.brands.length > 0
                ? filterNullElements(
                    response.result?.brands.map(
                      (item) =>
                        brandData
                          .filter((brand) => brand.id === item.brand)
                          .map((res) => ({
                            id: res.id,
                            value: res.id,
                            label: res.brand_name,
                          }))[0]
                    )
                  )
                : [],

            products:
              response.result.products.length > 0
                ? filterNullElements(
                    response.result?.products.map((item) => ({
                      id: item.product.id,
                      value: item.product.id,
                      label: item.product.print_name,
                    }))
                  )
                : [],

            stores:
              response.result.stores.length > 0
                ? filterNullElements(
                    response.result?.stores.map(
                      (item) =>
                        branchData
                          .filter((store) => store.id === item.store)
                          .map((res) => ({
                            id: res.id,
                            value: res.id,
                            label: res.store_name,
                          }))[0]
                    )
                  )
                : [],

            minimum_requirement_type: [
              {
                value: response.result?.minimum_requirement_type,
                label:
                  response.result?.minimum_requirement_type === "amount"
                    ? "Purchase Amount Range"
                    : "Quantity Range",
              },
            ],
            minimum_range: response.result?.minimum_range,
            maximum_range: response.result?.maximum_range,

            customer_eligibility:
              response.result.customer_eligibility !== null
                ? customerEligibilityData
                    .filter(
                      (item) =>
                        item.id === response.result?.customer_eligibility
                    )
                    .map((res) => ({ value: res.id, label: res.eligibility }))
                : [],

            coupon_usage:
              response.result.coupon_usage !== null
                ? couponUsageData
                    .filter((item) => item.id === response.result?.coupon_usage)
                    .map((res) => ({ value: res.id, label: res.usage }))
                : [],

            coupon_usage_time: response.result?.coupon_usage_time,

            coupon_start_date: response.result?.coupon_start_date,
            coupon_end_date: response.result?.coupon_end_date,

            is_active: [
              {
                value: response.result?.is_active,
                label:
                  response.result?.is_active === 1
                    ? GENERAL_CONSTANTS.ACTIVE
                    : GENERAL_CONSTANTS.INACTIVE,
              },
            ],
          };

          // dispatch(
          //   getMultipleCategoriesDetails(
          //     response.result.categories.map((item) => item.category)
          //   )
          // );
          // console.log("response", response.result);
          // console.log("dataToEdit", dataToEdit);

          setInitialData({
            categories: dataToEdit.categories,
            sub_categories: dataToEdit.sub_categories,
            brands: dataToEdit.brands,
            products: dataToEdit.products,
            stores: dataToEdit.stores,
          });

          dispatch(setDiscountToEdit(dataToEdit));
        } else {
          dispatch(setDiscountToEdit(response.result));
        }
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
      })
      .finally(() => {
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "individualDiscount" })
        );
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function addDiscounts(
  discountParams: Discount,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function addDiscountThunk(dispatch) {
    dispatch(setDiscountStatus(STATUSES.LOADING));
    setDiscount(discountParams)
      .then((response: any) => {
        dispatch(addDiscount(response.result));
        Toast(`${response.message}`, "success");
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(DISCOUNTS_CONSTANTS.DISCOUNT_NAVIGATE);
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function updateDiscounts(
  id: number,
  discountParams: Discount,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function updateDiscountThunk(dispatch, getState) {
    dispatch(setDiscountStatus(STATUSES.LOADING));
    updateDiscount(id, discountParams)
      .then((response: any) => {
        dispatch(editDiscount(response.result));
        Toast(`${response.message}`, "success");
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(DISCOUNTS_CONSTANTS.DISCOUNT_NAVIGATE);
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteDiscount(id: number) {
  return async function deleteDiscountThunk(dispatch, getState) {
    dispatch(setDiscountStatus(STATUSES.LOADING));
    delDiscount(id)
      .then((response: any) => {
        dispatch(editDiscount(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteDiscountStoreMapping(delParams: {
  discount: number;
  store: number;
}) {
  return async function deleteDiscountStoreMappingThunk(dispatch, getState) {
    // dispatch(setDiscountStatus(STATUSES.LOADING));
    delDiscountStoreMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteMultipleDiscountStoreMapping(
  ids: number[],
  discountID: number
) {
  return async function deleteMultipleDiscountStoreMappingThunk(
    dispatch,
    getState
  ) {
    dispatch(setDiscountStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: "deleteDiscountStoreMapping",
      })
    );
    Promise.all(
      ids.map((id) => {
        dispatch(
          deleteDiscountStoreMapping({
            store: id,
            discount: discountID,
          })
        );
      })
    )
      .then((response: any) => {
        Toast("Deleted all options successfully", "success");
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: "deleteDiscountStoreMapping",
          })
        );
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteDiscountCategoryMapping(delParams: {
  discount: number;
  category: number;
}) {
  return async function deleteDiscountCategoryMappingThunk(dispatch, getState) {
    // dispatch(setDiscountStatus(STATUSES.LOADING));
    delDiscountCategoryMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteDiscountSubCategoryMapping(delParams: {
  discount: number;
  sub_category: number;
}) {
  return async function deleteDiscountSubCategoryMappingThunk(
    dispatch,
    getState
  ) {
    // dispatch(setDiscountStatus(STATUSES.LOADING));
    delDiscountSubCategoryMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteDiscountBrandMapping(delParams: {
  discount: number;
  brand: number;
}) {
  return async function deleteDiscountBrandMappingThunk(dispatch, getState) {
    // dispatch(setDiscountStatus(STATUSES.LOADING));
    delDiscountBrandMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}

export function deleteDiscountProductMapping(delParams: {
  discount: number;
  product: number;
}) {
  return async function deleteDiscountProductMappingThunk(dispatch, getState) {
    // dispatch(setDiscountStatus(STATUSES.LOADING));
    delDiscountProductMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setDiscountStatus(STATUSES.ERROR));
        dispatch(setDiscountError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setDiscountStatus(STATUSES.IDLE));
      });
  };
}
