import { createSlice } from "@reduxjs/toolkit";
import { getModule } from "apis/restApis";
import { TRoleModule } from "utils";
import { STATUSES } from "utils/constants";

const initialState = {
  modules: [] as TRoleModule[],
  status: STATUSES.IDLE as string,
  error: null,
};

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    getAllModules: (state, action) => {
      state.modules = action.payload;
    },
    addModule: (state, action) => {
      state.modules.push(action.payload);
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllModules,
  addModule,
  setStatus,
  setError,
} = moduleSlice.actions;

export default moduleSlice.reducer;

export function getModules() {
  return async function getModulesThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    getModule()
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(getAllModules(response.result));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
