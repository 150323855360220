import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delHSNProductTax,
  delProductTax,
  getActiveProductTax,
  getProductTax,
  setProductTax,
  updateProductTax,
} from "apis/restApis";
import { PaginationState, ProductTaxType } from "utils/types";
import { updateValues } from "store/commonSlice";
import { Toast } from "utils";
import { FormikHelpers } from "formik";

const initialState = {
  productTax: [] as ProductTaxType[],

  status: STATUSES.IDLE as string,
  error: null,
};

const productTaxSlice = createSlice({
  name: "productTax",
  initialState,
  reducers: {
    getAllProductTax(state, action) {
      state.productTax = action.payload;
    },
    addProductTax(state, action) {
      state.productTax.unshift(action.payload);
    },
    removeProductTax(state, action) {
      state.productTax = state.productTax.filter(
        (tax) => tax.id !== action.payload
      );
    },
    editProductTax(state, action) {
      const { id } = action.payload;
      const index = state.productTax.findIndex((tax) => tax.id === id);
      if (index !== -1) {
        state.productTax[index] = action.payload;
      }
    },

    setTaxStatus(state, action) {
      state.status = action.payload;
    },
    setTaxError(state, action) {
      state.error = action.payload;
    },
    resetTaxState: (state) => {
      state.productTax = [] as ProductTaxType[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllProductTax,
  addProductTax,
  removeProductTax,
  editProductTax,

  setTaxStatus,
  setTaxError,
  resetTaxState,
} = productTaxSlice.actions;

export default productTaxSlice.reducer;

export function getTaxes(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getTaxesThunk(dispatch, getState) {
    dispatch(setTaxStatus(STATUSES.LOADING));
    active
      ? getActiveProductTax()
          .then((response: any) => {
            dispatch(setTaxStatus(STATUSES.IDLE));
            dispatch(getAllProductTax(response.result));
          })
          .catch((error) => {
            dispatch(setTaxError(error.message));
            dispatch(setTaxStatus(STATUSES.ERROR));
          })
      : getProductTax(pageNo, query)
          .then((response: any) => {
            dispatch(setTaxStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllProductTax(response.result.results));
          })
          .catch((error) => {
            dispatch(setTaxError(error.message));
            dispatch(setTaxStatus(STATUSES.ERROR));
          });
  };
}

export function addNewTax(taxParams: Object, action: FormikHelpers<any>) {
  return async function addNewTaxThunk(dispatch, getState) {
    dispatch(setTaxStatus(STATUSES.LOADING));
    setProductTax(taxParams)
      .then((response: any) => {
        dispatch(addProductTax(response.result));
        Toast(`${response.message}`, "success");
        action.resetForm();
      })
      .catch((error) => {
        dispatch(setTaxStatus(STATUSES.ERROR));
        dispatch(setTaxError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setTaxStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingTax(
  id: number,
  taxParams: Object,
  action: FormikHelpers<any>
) {
  return async function updateExistingTaxThunk(dispatch, getState) {
    dispatch(setTaxStatus(STATUSES.LOADING));
    updateProductTax(id, taxParams)
      .then((response: any) => {
        dispatch(setTaxStatus(STATUSES.IDLE));
        dispatch(editProductTax(response.result));
        Toast(`${response.message}`, "success");
        action.resetForm();
      })
      .catch((error) => {
        dispatch(setTaxStatus(STATUSES.ERROR));
        dispatch(setTaxError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setTaxStatus(STATUSES.IDLE));
      });
  };
}

export function deleteTax(id: number) {
  return async function deleteTaxThunk(dispatch, getState) {
    dispatch(setTaxStatus(STATUSES.LOADING));
    delProductTax(id)
      .then((response: any) => {
        dispatch(editProductTax(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setTaxStatus(STATUSES.ERROR));
        dispatch(setTaxError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setTaxStatus(STATUSES.IDLE));
      });
  };
}

export function deleteHSNTax(delParams: Object) {
  return async function deleteHSNTaxThunk(dispatch, getState) {
    dispatch(setTaxStatus(STATUSES.LOADING));
    delHSNProductTax(delParams)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setTaxStatus(STATUSES.ERROR));
        dispatch(setTaxError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setTaxStatus(STATUSES.IDLE));
      });
  };
}
