import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";

import React, { useEffect, useState } from "react";

import { getCategories } from "store/ManageProducts/productCategorySlice";

import {
  deleteSubCategory,
  getSubCategories,
} from "store/ManageProducts/productSubCategorySlice";
import { updatePageNo } from "store/commonSlice";

import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  PRODUCT_SUB_CATEGORY_CONSTANTS,
  ProductSubCategoryType,
  STATUSES,
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const SubCategoryList = ({
  setEdit,
  setEditId,
  setShow,
  setInitialCategories,
  setCurrentSubCategory,
  setConfirmDeleteModal,
}) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    common: { current_page, total_items, total_pages, page_size },
    productSubCategory: { productSubCategory: subCategoryData, status },
  } = useAppSelector((state) => state.root);

  const subCategoryPermission = permissionData.find(
    (p) => p.module_name === "Manage Product Sub Category"
  );

  const [query, setQuery] = useState("");

  const update_page = (pageNo: number) => {
    dispatch(getSubCategories(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    dispatch(getCategories(true));
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_COLS;

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={subCategoryPermission?.perm_add}
          path={PRODUCT_SUB_CATEGORY_CONSTANTS.PATH}
          label={PRODUCT_SUB_CATEGORY_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : subCategoryData.length > 0 ? (
              subCategoryData.map(
                (subCategory: ProductSubCategoryType, index) => {
                  return (
                    <tr key={subCategory.id}>
                      <td>{page_size * (current_page - 1) + index + 1}</td>
                      <td>{subCategory.sub_category_name}</td>

                      <td>
                        {subCategory.categories?.map((cat, index) => {
                          return (
                            <h6 key={index}>
                              <Badge
                                badgeType={GENERAL_CONSTANTS.INFO}
                                badgeText={cat.category.category_name}
                              />
                            </h6>
                          );
                        })}
                      </td>
                      <td>
                        <Status is_active={subCategory.is_active} />
                      </td>
                      <td>
                        <Button
                          text={<FontIcon icon={faPenToSquare} />}
                          type={BUTTON_CONSTANTS.BUTTON}
                          isDisabled={!subCategoryPermission?.perm_edit}
                          btnClassNames={"btn btn-info btn-sm mr-2"}
                          onClickHandler={() => {
                            let editData = {
                              ...subCategory,
                              categories: subCategory.categories.map((cat) => {
                                return {
                                  id: cat.category.id,
                                  value: cat.category.id,
                                  label: cat.category.category_name,
                                };
                              }),
                              is_active: [
                                {
                                  label:
                                    subCategory.is_active === 1
                                      ? GENERAL_CONSTANTS.ACTIVE
                                      : GENERAL_CONSTANTS.INACTIVE,
                                  value: subCategory.is_active,
                                },
                              ],
                            };
                            editData.categories.forEach((subCategory) => {
                              setInitialCategories((prev) => [
                                ...prev,
                                subCategory,
                              ]);
                            });
                            setEditId(subCategory.id);
                            setShow(true);
                            setEdit(editData);
                          }}
                        />
                        {subCategory.is_active ? (
                          <Button
                            text={<FontIcon icon={faBan} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            isDisabled={!subCategoryPermission?.perm_delete}
                            btnClassNames={"btn btn-danger btn-sm"}
                            onClickHandler={() => {
                              setConfirmDeleteModal(true);
                              setCurrentSubCategory(Number(subCategory.id));
                            }}
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={status === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { SubCategoryList };
