import { Card } from "components/core-components";
import { Loader } from "components/Loader";
import React from "react";
import {
  ACCESS_CONSTANTS,
  displayValue,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  useAppSelector,
} from "utils";

/**
 * Renders the customer details component.
 * @param {boolean} full - Determines whether to display the full customer details or not.
 * @returns {JSX.Element} The rendered customer details component.
 */
const CustomerDetails = ({ full = false, modal = false }) => {
  const {
    common: { status },
    customer: { order_details, customerToEdit: customer_details },
  } = useAppSelector((state) => state.root);

  return (
    customer_details &&
    customer_details.id !== ACCESS_CONSTANTS.CASH_SALES_ID && (
      <Card cardClassNames={`row ${full ? "my-2 py-2" : "my-1 py-1"}`}>
        {!modal && (
          <h6 className="text-start">{POS_CART_CONSTANTS.CUSTOMER_DETAILS}</h6>
        )}
        {status.state === STATUSES.LOADING &&
        status.type === POS_CART_CONSTANTS.CUSTOMER_DETAILS_LOADING_STATE ? (
          <div className="text-center">
            <Loader small={true} />
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <h6 className="text-sm text-muted text-start">
                {POS_CART_CONSTANTS.LAST_VISITED}:
              </h6>
              <h6 className="text-sm text-end">{order_details.last_visited}</h6>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <h6 className="text-sm text-muted text-start">
                {POS_CART_CONSTANTS.CUSTOMER_LAST_BILL_AMOUNT}:
              </h6>
              <h6 className="text-sm text-end">
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(order_details?.last_bill_amount)}
              </h6>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <h6 className="text-sm text-muted text-start">
                {POS_CART_CONSTANTS.TOTAL_PURCHASE}:
              </h6>
              <h6 className="text-sm text-end">
                {order_details?.total_purchase}
              </h6>
            </div>
          </div>
        )}
      </Card>
    )
  );
};

export default CustomerDetails;
