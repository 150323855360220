import { Card, CustomMultiSelect } from "components/core-components";
import React, { useEffect, useMemo } from "react";
import {
  getBranches,
  setSelectedStore,
} from "store/ManageBranches/branchSlice";
import { LastOrderDetails } from "store/POS";
import {
  findStore,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_MODAL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

/**
 * Component for displaying the details of the selected store in the POS.
 * @param {Object} props - The component props.
 * @param {Function} props.setStore - The function to set the selected store.
 * @param {Function} props.setConfirmChangeStore - The function to set whether to confirm changing the store.
 * @param {boolean} [props.full=false] - Whether to display the full details or not.
 * @returns {JSX.Element|null} The JSX element representing the selected store details.
 */
const SelectedStoreDetails = ({
  setStore,
  setConfirmChangeStore,
  full = false,
  modal = false,
}) => {
  const dispatch = useAppDispatch();

  const {
    branch: { branch: stores, selectedStore },
    cart: { cart },
  } = useAppSelector((state) => state.root);

  const getStoreOptions = useMemo(() => {
    return stores && stores.length > 0
      ? stores.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : GENERAL_CONSTANTS.EMPTY_ARRAY;
  }, [stores]);

  useEffect(() => {
    dispatch(getBranches({ active: true }));
  }, []);

  return (
    selectedStore > 0 && (
      <Card cardClassNames={`row ${full ? "my-2 py-2" : "my-1 py-1"}`}>
        {!modal && (
          <h6 className="text-start">
            {POS_CART_CONSTANTS.SELECTED_STORE_DETAILS}
          </h6>
        )}
        <div className="d-flex flex-row" style={{ marginTop: -16 }}>
          <CustomMultiSelect
            small={true}
            valueToShow={selectedStore}
            placeholder={POS_MODAL_CONSTANTS.SELECT_STORE}
            options={getStoreOptions}
            value={
              selectedStore
                ? {
                    value: selectedStore,
                    label: findStore(selectedStore, stores)?.store_name,
                  }
                : null
            }
            onChangeHandler={(e, actions) => {
              if (actions.action === GENERAL_CONSTANTS.CLEAR) {
                return;
              } else if (
                cart.cartItems.length > 0 &&
                e.value !== selectedStore
              ) {
                setStore(e.value);
                setConfirmChangeStore(true);
              } else {
                dispatch(LastOrderDetails(e.value));
                dispatch(setSelectedStore(e.value));
              }
            }}
            onEndReached={() => {
              dispatch(getBranches({ active: true }));
            }}
          />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <h6 className="font-weight-light text-xs">
            {POS_CART_CONSTANTS.STORE_NAME} :
          </h6>
          <h6 className="font-weight-bold text-xs">
            {selectedStore ? findStore(selectedStore, stores)?.store_name : ""}
          </h6>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <h6 className="font-weight-light text-xs">
            {POS_CART_CONSTANTS.STORE_PHONE}:
          </h6>
          <h6 className="font-weight-bold text-xs">
            {selectedStore
              ? findStore(selectedStore, stores)?.contact_number
              : ""}
          </h6>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <h6 className="font-weight-light text-xs">
            {POS_CART_CONSTANTS.STORE_GSTIN}:
          </h6>{" "}
          <h6 className="font-weight-bold text-xs">
            {selectedStore ? findStore(selectedStore, stores)?.gstn : ""}
          </h6>
        </div>
      </Card>
    )
  );
};

export { SelectedStoreDetails };
