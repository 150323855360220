import { Card, CardBody, Modal } from "components/core-components";
import React from "react";
import { AddNewCreditNote } from "store/POS";
import { useAppDispatch } from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  setRefundItems: React.Dispatch<React.SetStateAction<any>>;
}

const RefundTypeModal = ({ modal, setModal, data, setRefundItems }: IProps) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      modalClassNames="w-100"
      modalSize="lg"
      title={"Print"}
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <Card>
        <CardBody>
          <div className="d-flex flex-row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-sm text-center p-1">
                  <button
                    className="btn btn-dark btn-primary text-sm w-100"
                    onClick={() => {
                      dispatch(
                        AddNewCreditNote({
                          creditNote: {
                            ...data,
                            credit_type: "return",
                          },
                          setRefundItems: setRefundItems,
                        })
                      );
                      setModal(false);
                    }}
                  >
                    Credit Return
                  </button>
                </div>
                <div className="col-6 text-sm text-center p-1">
                  <button
                    className="btn btn-dark btn-primary text-sm w-100"
                    onClick={() => {
                      dispatch(
                        AddNewCreditNote({
                          creditNote: {
                            ...data,
                            credit_type: "cash",
                          },
                          setRefundItems: setRefundItems,
                        })
                      );
                      setModal(false);
                    }}
                  >
                    Cash Return
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export { RefundTypeModal };
