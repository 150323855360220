import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delTestimonial,
  getTestimonial,
  setTestimonial,
  updateTestimonial,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  CUSTOMERS_CONSTANTS,
  formatDate,
  GENERAL_CONSTANTS,
  STATUSES,
  TESTIMONIAL_CONSTANTS,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
  TTestimonial,
} from "utils";

const initialState = {
  testimonials: [] as TTestimonial[],
  testimonial: {} as TTestimonial,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchTestimonials = createAsyncThunk(
  "testimonial/fetchTestimonials",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response: any = await getTestimonial({
        id,
        active,
        pageNo,
        query,
      });

      if (!id && !active) dispatch(updateValues(response));

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewTestimonial = createAsyncThunk(
  "testimonial/addNewTestimonial",
  async (
    {
      testimonialParams,
      actions,
      navigate,
    }: {
      testimonialParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setTestimonial(testimonialParams);
      actions.resetForm();
      navigate(TESTIMONIAL_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingTestimonial = createAsyncThunk(
  "testimonial/updateExistingTestimonial",
  async (
    {
      id,
      testimonialParams,
      actions,
      navigate,
    }: {
      id: number;
      testimonialParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateTestimonial(id, testimonialParams);
      actions.resetForm();
      navigate(TESTIMONIAL_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTestimonial = createAsyncThunk(
  "testimonial/deleteTestimonial",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "testimonial-delete" })
    );
    try {
      const response = await delTestimonial(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({ state: STATUSES.IDLE, type: "testimonial-delete" })
      );
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    setTestimonialToEdit: (state, action) => {
      state.testimonial = action.payload;
    },
    resetTestimonialState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTestimonials.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.testimonial = action.payload.result;
        } else if (action.meta.arg.active) {
          state.testimonials = action.payload.result;
        } else {
          state.testimonials = action.payload.result.results;
        }
      })
      .addCase(addNewTestimonial.fulfilled, (state, action: any) => {
        state.testimonials.unshift(action.payload.result);
        state.testimonials = state.testimonials.slice(0, 10);
      })
      .addCase(updateExistingTestimonial.fulfilled, (state, action: any) => {
        // Update the existing testimonial in the testimonials array
        const index = state.testimonials.findIndex(
          (testimonial) => testimonial.id === action.payload.result.id
        );
        if (index !== -1) {
          state.testimonials[index] = action.payload.result;
        }
      })
      .addCase(deleteTestimonial.fulfilled, (state, action: any) => {
        // update the deleted testimonial from the testimonials array
        const index = state.testimonials.findIndex(
          (testimonial) => testimonial.id === action.meta.arg
        );
        if (index !== -1) {
          state.testimonials[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewTestimonial.fulfilled,
          updateExistingTestimonial.fulfilled,
          deleteTestimonial.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchTestimonials.pending,
          addNewTestimonial.pending,
          updateExistingTestimonial.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setTestimonialToEdit,
  resetTestimonialState,
} = testimonialSlice.actions;

export default testimonialSlice.reducer;
