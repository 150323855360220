import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  MultiSelect,
} from "components";
import { ErrorMessage } from "formik";
import React, { memo } from "react";
import { getAccountTypes } from "store/ManageBanks/bankAccountTypeSlice";
import { getBanks } from "store/ManageBanks/bankSlice";
import {
  FORM_CONSTANTS,
  STATUSES,
  VENDORS_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

const VendorBankDetailsForm = () => {
  const dispatch = useAppDispatch();

  const {
    bank: { banks: bankData, status: bankStatus },
    bankAccountType: {
      bankAccountTypes: bankAccountTypeData,
      status: bankAccountTypeStatus,
    },
  } = useAppSelector((state) => state.root);

  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h3 className="card-title">{VENDORS_CONSTANTS.CARD_TITLE_3}</h3>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_ACCOUNT_NUMBER_LABEL}
                isRequired={true}
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_ACCOUNT_NUMBER}`}
                placeholder={
                  VENDORS_CONSTANTS.VENDOR_ACCOUNT_NUMBER_PLACEHOLDER
                }
                type={FORM_CONSTANTS.NUMBER}
              />
              <ErrorMessage
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_ACCOUNT_NUMBER}`}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col -md-6">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_ACCOUNT_TYPE_LABEL}
                select={false}
                isRequired={true}
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_ACCOUNT_TYPE}`}
                onFocusHandler={() => {
                  dispatch(getAccountTypes());
                }}
                options={bankAccountTypeData?.map((item) => ({
                  value: item.id,
                  label: item.type,
                }))}
                isLoading={bankAccountTypeStatus === STATUSES.LOADING}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                label={VENDORS_CONSTANTS.VENDOR_BANK_NAME_LABEL}
                select={false}
                isRequired={true}
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_BANK_NAME}`}
                onFocusHandler={() => {
                  bankData.length === 0 && dispatch(getBanks({ active: true }));
                }}
                options={bankData?.map((bank) => ({
                  value: bank.id,
                  label: bank.bank_name,
                }))}
                isLoading={bankStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_BANK_NAME}`}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_IFSC_CODE_LABEL}
                isRequired={true}
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_IFSC_CODE}`}
                placeholder={VENDORS_CONSTANTS.VENDOR_IFSC_CODE_PLACEHOLDER}
                type={FORM_CONSTANTS.TEXT}
              />
              <ErrorMessage
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_IFSC_CODE}`}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label={VENDORS_CONSTANTS.VENDOR_BRANCH_NAME_LABEL}
                isRequired={true}
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_BRANCH_NAME}`}
                placeholder={VENDORS_CONSTANTS.VENDOR_BRANCH_NAME_PLACEHOLDER}
                type={FORM_CONSTANTS.TEXT}
              />
              <ErrorMessage
                name={`${VENDORS_CONSTANTS.BANK_DETAILS}.${VENDORS_CONSTANTS.VENDOR_BRANCH_NAME}`}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VendorBankDetailsForm);
