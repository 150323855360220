import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, ErrorMessage } from "formik";

import {
  ContentHeader,
  CustomInput,
  Loader,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  MultiSelect,
  TextArea,
  Tabs,
} from "components";

import {
  useAppDispatch,
  useAppSelector,
  CustomerSchema,
  BUTTON_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
  ACCESS_CONSTANTS,
} from "utils";

import {
  addNewCustomer,
  getIndividualCustomer,
  setCustomerToEdit,
  updateExistingCustomer,
} from "store/ManageCustomers/customerSlice";

import {
  fetchCity,
  fetchState,
  getCity,
  getCountry,
  getState,
} from "store/locationSlice";
import { ScrollToFieldError } from "components/ScollToFieldError";

interface IProps {
  addNew?: boolean;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

// Customer General Details

const CustomerGeneralDetailsForm = ({ id }) => {
  return (
    <div className="col-12" key={"customer-general-details"}>
      <div className="row">
        <div className="col-md-2">
          <MultiSelect
            label={CUSTOMERS_CONSTANTS.DESIGNATION_LABEL}
            name={CUSTOMERS_CONSTANTS.DESIGNATION}
            options={CUSTOMERS_CONSTANTS.DESIGNATION_OPTIONS}
            // isRequired={true}
            designation={true}
          />
        </div>
        <div className="col-md-5">
          <CustomInput
            titleCase={true}
            label={CUSTOMERS_CONSTANTS.CUSTOMER_NAME_LABEL}
            isDisabled={Number(id) === ACCESS_CONSTANTS.CASH_SALES_ID}
            isRequired={GENERAL_CONSTANTS.TRUE}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_NAME}
            type={FORM_CONSTANTS.TEXT}
            placeholder={CUSTOMERS_CONSTANTS.CUSTOMER_NAME_PLACEHOLDER}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_NAME}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
        <div className="col-md-5">
          <CustomInput
            titleCase={true}
            label={CUSTOMERS_CONSTANTS.CUSTOMER_COMPANY_NAME_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_COMPANY_NAME}
            type={FORM_CONSTANTS.TEXT}
            placeholder={CUSTOMERS_CONSTANTS.CUSTOMER_COMPANY_NAME_PLACEHOLDER}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_COMPANY_NAME}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.CUSTOMER_EMAIL_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_EMAIL}
            type={FORM_CONSTANTS.EMAIL}
            placeholder={CUSTOMERS_CONSTANTS.CUSTOMER_EMAIL_PLACEHOLDER}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_EMAIL}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
        <div className="col-md-6">
          <MultiSelect
            select={false}
            label={CUSTOMERS_CONSTANTS.CUSTOMER_STATUS_LABEL}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_STATUS}
            disabled={Number(id) === ACCESS_CONSTANTS.CASH_SALES_ID}
            options={CUSTOMERS_CONSTANTS.CUSTOMER_STATUS_OPTIONS}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_STATUS}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER_LABEL}
            isRequired={GENERAL_CONSTANTS.TRUE}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER}
            type={FORM_CONSTANTS.NUMBER}
            placeholder={CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER_PLACEHOLDER}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
        <div className="col-md-6">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.CUSTOMER_WHATSAPP_NUMBER_LABEL}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_WHATSAPP_NUMBER}
            type={FORM_CONSTANTS.NUMBER}
            placeholder={
              CUSTOMERS_CONSTANTS.CUSTOMER_WHATSAPP_NUMBER_PLACEHOLDER
            }
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_WHATSAPP_NUMBER}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.CUSTOMER_DOB_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_DOB}
            type={FORM_CONSTANTS.DATE}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_DOB}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
        <div className="col-md-6">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.CUSTOMER_ANNIVERSARY_DATE_LABEL}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_ANNIVERSARY_DATE}
            type={FORM_CONSTANTS.DATE}
          />
          <ErrorMessage
            name={CUSTOMERS_CONSTANTS.CUSTOMER_ANNIVERSARY_DATE}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TextArea
            rows={2}
            label={CUSTOMERS_CONSTANTS.CUSTOMER_REMARKS_LABEL}
            name={CUSTOMERS_CONSTANTS.CUSTOMER_REMARKS}
            placeholder={CUSTOMERS_CONSTANTS.CUSTOMER_REMARKS_PLACEHOLDER}
            id={CUSTOMERS_CONSTANTS.CUSTOMER_REMARKS}
          />
        </div>
      </div>
    </div>
  );
};

// Customer Address Details

const CustomerAddressDetailsForm = ({ props }) => {
  const dispatch = useAppDispatch();

  const {
    location: { cities, states, countries, status: locationStatus },
  } = useAppSelector((state) => state.root);

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-12">
          <CustomInput
            label={"First Name"}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.first_name`}
            type={FORM_CONSTANTS.TEXT}
            placeholder={"Enter your first name"}
            onChangeHandler={(e) => {
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.first_name`,
                e.target.value
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.first_name`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomInput
            label={"Last Name"}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.last_name`}
            type={FORM_CONSTANTS.TEXT}
            placeholder={"Enter your last name"}
            onChangeHandler={(e) => {
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.last_name`,
                e.target.value
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.last_name`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomInput
            label={"contact Number"}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.contact_number`}
            type={FORM_CONSTANTS.NUMBER}
            placeholder={"Enter your contact number"}
            onChangeHandler={(e) => {
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.contact_number`,
                e.target.value
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.contact_number`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.ADDRESS_LINE_1_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_1}`}
            type={FORM_CONSTANTS.TEXT}
            placeholder={CUSTOMERS_CONSTANTS.ADDRESS_LINE_1_PLACEHOLDER}
            onChangeHandler={(e) => {
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_1}`,
                e.target.value
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_1}`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.ADDRESS_LINE_2_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_2}`}
            type={FORM_CONSTANTS.TEXT}
            placeholder={CUSTOMERS_CONSTANTS.ADDRESS_LINE_2_PLACEHOLDER}
            onChangeHandler={(e) => {
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_2}`,
                e.target.value
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_2}`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <MultiSelect
            label={CUSTOMERS_CONSTANTS.COUNTRY_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.COUNTRY}`}
            // disabled={!addressFilled}
            isLoading={locationStatus === STATUSES.LOADING}
            onFocusHandler={() => {
              countries.length === 0 && dispatch(getCountry());
            }}
            options={countries?.map((country) => ({
              value: country.id,
              label: country.country,
            }))}
            onChangeHandler={(e) => {
              e && dispatch(getState(e.value));

              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.COUNTRY}`,
                [e]
              );
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`,
                []
              );
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.CITY}`,
                []
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.COUNTRY}`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
        <div className="col-md-6">
          <MultiSelect
            label={CUSTOMERS_CONSTANTS.STATE_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`}
            // disabled={!addressFilled}
            isLoading={locationStatus === STATUSES.LOADING}
            options={states.map((state) => ({
              value: state.id,
              label: state.state,
            }))}
            onFocusHandler={() => {
              states.length === 0 &&
                dispatch(
                  getState(
                    props.values?.address?.country?.[0]?.value
                      ? props.values.address.country[0].value
                      : FORM_CONSTANTS.DEFAULT_COUNTRY[0].value
                  )
                );
            }}
            onChangeHandler={(e) => {
              e ? dispatch(getCity(e.value)) : dispatch(fetchCity([]));
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`,
                [e]
              );
              props.setFieldValue(
                `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.CITY}`,
                []
              );
            }}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <MultiSelect
            label={CUSTOMERS_CONSTANTS.CITY_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.CITY}`}
            // disabled={!addressFilled}
            isLoading={locationStatus === STATUSES.LOADING}
            onFocusHandler={() => {
              cities.length === 0 &&
                dispatch(
                  getCity(
                    props.values?.address?.state?.[0]?.value
                      ? props.values.address.state[0].value
                      : FORM_CONSTANTS.DEFAULT_STATE[0].value
                  )
                );
            }}
            options={cities.map((city) => ({
              value: city.id,
              label: city.city,
            }))}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.CITY}`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
        <div className="col-md-6">
          <CustomInput
            label={CUSTOMERS_CONSTANTS.PINCODE_LABEL}
            // isRequired={GENERAL_CONSTANTS.TRUE}
            // isDisabled={!addressFilled}
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.PINCODE}`}
            type={FORM_CONSTANTS.NUMBER}
            placeholder={CUSTOMERS_CONSTANTS.PINCODE_PLACEHOLDER}
          />
          <ErrorMessage
            name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.PINCODE}`}
            component={FORM_CONSTANTS.ERROR_PARENT}
            className={FORM_CONSTANTS.ERROR}
          />
        </div>
      </div>
    </div>
  );
};

const CustomerOrderHistory = () => {
  return <div className="row"></div>;
};

const CustomerProductReviews = () => {
  return <div className="row"></div>;
};

const CustomerCreditNotes = () => {
  return <div className="row"></div>;
};

const CustomerRewardPoints = () => {
  return <div className="row"></div>;
};

const CustomersForm = ({ addNew = false, setModal }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { logged_in_user },
    customer: { customerToEdit, status: customerStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const PropsRef = useRef(null);

  const avaryaEditAccess = ACCESS_CONSTANTS.AVARYA_FULL_ACCESS.includes(
    logged_in_user?.email
  );

  const [customerDetailsTab, setCustomerDetailsTab] = useState<string>(
    "General Details"
  );

  const [customerHistoryTab, setCustomerHistoryTab] = useState<string>(
    "Order History"
  );

  const CustomerDetailsTabList = ["General Details", "Address Details"];

  const CustomerHistoryTabList = [
    "Order History",
    "Product Reviews",
    "Credit Notes",
    "Reward Points",
  ];

  const initialValues = {
    name: "",
    company_name: "",
    email: "",
    contact_number: "",
    remarks: "",
    whatsapp_number: 0,
    dob: "",
    anniversary: "",
    address: {
      address_line_1: "",
      address_line_2: "",
      city: FORM_CONSTANTS.DEFAULT_CITY,
      state: FORM_CONSTANTS.DEFAULT_STATE,
      country: FORM_CONSTANTS.DEFAULT_COUNTRY,
      pincode: FORM_CONSTANTS.DEFAULT_PINCODE,
    },
    is_active: [{ value: 1, label: "Active" }],
  };

  const handleSubmit = (values, actions) => {
    if (values.anniversary === "") {
      delete values.anniversary;
    }
    if (addNew) {
      setModal(false);
    }
    const dataToSend = {
      ...values,
      email: values.email ? values.email : null,
      designation: values.designation?.[0]?.value
        ? values.designation[0].value
        : "",
      whatsapp_number: values.whatsapp_number ? values.whatsapp_number : null,
      dob: values.dob ? values.dob : null,
      address: {
        ...values.address,
        city: values.address?.city?.[0]?.value
          ? values.address?.city?.[0]?.value
          : FORM_CONSTANTS.DEFAULT_CITY[0].value,
        state: values.address?.state?.[0]?.value
          ? values.address?.state?.[0]?.value
          : FORM_CONSTANTS.DEFAULT_STATE[0].value,
        country: values.address?.country?.[0]?.value
          ? values.address?.country?.[0]?.value
          : FORM_CONSTANTS.DEFAULT_COUNTRY[0].value,
        is_active: 1,
      },
      is_active: values.is_active[0].value * 1,
    };

    if (id) {
      dispatch(
        updateExistingCustomer(Number(id), dataToSend, actions, navigate)
      );
    } else {
      dispatch(addNewCustomer(dataToSend, actions, navigate));
    }
  };

  const renderCustomerDetails = () => {
    switch (customerDetailsTab) {
      case "General Details":
        return <CustomerGeneralDetailsForm id={id} />;
      case "Address Details":
        return <CustomerAddressDetailsForm props={PropsRef.current} />;
    }
  };

  const renderCustomerHistory = () => {
    switch (customerHistoryTab) {
      case "Order History":
        return <CustomerOrderHistory />;
      case "Product Reviews":
        return <CustomerProductReviews />;
      case "Credit Notes":
        return <CustomerCreditNotes />;
      case "Reward Points":
        return <CustomerRewardPoints />;
    }
  };

  useEffect(() => {
    if (Number(id) === ACCESS_CONSTANTS.CASH_SALES_ID && !avaryaEditAccess) {
      navigate(CUSTOMERS_CONSTANTS.CUSTOMERS_NAVIGATE);
    }

    const getFormData = async () => {
      await Promise.all([
        id && !addNew && dispatch(getIndividualCustomer(Number(id), true)),
        dispatch(getCountry()),
      ]);
    };

    getFormData();

    return () => {
      dispatch(setCustomerToEdit(null));
    };
  }, []);

  return (
    <div className={`${!addNew ? "content-wrapper" : ""}`}>
      <Container>
        {!addNew ? (
          <ContentHeader
            editValue={customerToEdit?.name}
            pageHeader={
              id
                ? CUSTOMERS_CONSTANTS.EDIT_HEADER
                : CUSTOMERS_CONSTANTS.ADD_HEADER
            }
          />
        ) : null}
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={id ? customerToEdit : initialValues}
            validationSchema={CustomerSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form>
                  <ScrollToFieldError />
                  {customerStatus === STATUSES.LOADING && (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div className="row">
                    {!id ? (
                      <>
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <h3 className="card-title">
                                {CUSTOMERS_CONSTANTS.CARD_TITLE_1}
                              </h3>
                            </CardHeader>
                            <CardBody>
                              <div className="row">
                                <CustomerGeneralDetailsForm id={id} />
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <h3 className="card-title">
                                {CUSTOMERS_CONSTANTS.CARD_TITLE_2}
                              </h3>
                            </CardHeader>
                            <CardBody>
                              <div className="row">
                                <CustomerAddressDetailsForm props={props} />
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="d-flex flex-column flex-md-row">
                                <div className="align-self-md-end mb-2 mb-md-0">
                                  <Tabs
                                    tabs={CustomerDetailsTabList}
                                    activeTab={customerDetailsTab}
                                    setActiveTab={setCustomerDetailsTab}
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="d-flex flex-row">
                                <div className="col-12 px-0">
                                  {renderCustomerDetails()}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        {/* <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="d-flex flex-column flex-md-row">
                                <div className="align-self-md-end mb-2 mb-md-0">
                                  <Tabs
                                    tabs={CustomerHistoryTabList}
                                    activeTab={customerHistoryTab}
                                    setActiveTab={setCustomerHistoryTab}
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="d-flex flex-row">
                                <div className="col-12 px-0">
                                  {renderCustomerHistory()}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div> */}
                      </>
                    )}
                    {/* Submit */}
                    <div className={` ${addNew ? "col-6" : "col-md-6"}`}>
                      <Button
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary align-self-center w-25"}
                        loading={customerStatus === STATUSES.LOADING}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { CustomersForm, CustomerAddressDetailsForm };
