import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
} from "components";

const Sales = () => {
  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader="Sales" />
        <div className="col-12">
          <Card>
            <CardHeader>
              <h5 className="card-title">Sales</h5>
            </CardHeader>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export { Sales };
