import { Button, Card, CardBody, CustomInput, MultiSelect } from "components";
import { Form, ErrorMessage } from "formik";
import React from "react";

import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCT_BRAND_CONSTANTS,
} from "utils";

const BrandForm = ({ edit }) => {
  return (
    <Form>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  label={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_NAME_LABEL}
                  isRequired={true}
                  value={edit ? edit.brand_name : ""}
                  name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_NAME}
                  placeholder={
                    PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_NAME_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {edit ? (
                <div className="row">
                  <MultiSelect
                    select={false}
                    name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_STATUS}
                    label={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_STATUS_LABEL}
                    options={
                      PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_STATUS_OPTIONS
                    }
                  />
                </div>
              ) : null}
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  label={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_CODE_LABEL}
                  // isRequired={true}
                  value={edit ? edit.brand_code : ""}
                  name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_CODE}
                  placeholder={
                    PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_CODE_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_CODE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  label={
                    PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_DESCRIPTION_LABEL
                  }
                  value={edit ? edit.brand_description : ""}
                  name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_DESCRIPTION}
                  placeholder={
                    PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_DESCRIPTION_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_DESCRIPTION}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12">
          <Button
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export { BrandForm };
