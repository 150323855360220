import React from "react";

import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontIcon } from "components/core-components";
import { ERROR_CONSTANTS, ROUTES } from "utils/constants";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div
      className="content-wrapper"
      style={{
        margin: 0,
        padding: 0,
        background: "#fff",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <section className="content" style={{ height: "100%" }}>
        <div className="error-page">
          <h1 className="headline text-warning fw-bold">
            {ERROR_CONSTANTS.ERROR_404}
          </h1>
          <div className="error-content">
            <h3>
              <FontIcon
                iconClassNames={"text-warning"}
                icon={faTriangleExclamation}
              />
              {ERROR_CONSTANTS.PAGE_NOT_FOUND}
            </h3>
            <p>
              {ERROR_CONSTANTS.ERROR_MSG}
              <Link to={ROUTES.DASHBOARD}>
                {ERROR_CONSTANTS.RETURN_TO_DASHBOARD}
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export { Error404 };
