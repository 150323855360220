import React, { memo, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  PRINTER_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

import { deletePrinter, getAllPrinters } from "store/Utilities/printerSlice";

import { updatePageNo } from "store/commonSlice";
import { faBan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const PrinterList = ({ setEdit, setShow }) => {
  const dispatch = useAppDispatch();

  const {
    common: { current_page, total_items, total_pages, page_size },
    printer: { status, printer },
  } = useAppSelector((state) => state.root);

  const printPermission = {
    module_name: "Manage Printer",
    perm_view: true,
    perm_add: true,
    perm_edit: true,
    perm_delete: true,
    perm_authorize: true,
  };

  const [query, setQuery] = useState("");

  const update_page = (pageNo: number) => {
    if (query) {
      dispatch(updatePageNo(1));
      dispatch(getAllPrinters(false, 1, query));
    } else {
      dispatch(getAllPrinters(false, pageNo));
    }
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PRINTER_CONSTANTS.PRINTER_COLS;
  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={printPermission?.perm_add}
          path={PRINTER_CONSTANTS.PATH}
          label={PRINTER_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : printer.length > 0 ? (
              printer.map((printer, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{printer.printer_name}</td>
                  <td>{printer.series_name}</td>
                  <td>{printer.language}</td>
                  <td>
                    <Status is_active={printer.is_active} />
                  </td>
                  <td>
                    <Button
                      text={<FontIcon icon={faPenToSquare} />}
                      isDisabled={!printPermission?.perm_edit}
                      type={BUTTON_CONSTANTS.BUTTON}
                      btnClassNames={"btn btn-info btn-sm mr-2"}
                      onClickHandler={() => {
                        let editData = {
                          ...printer,
                          is_active: [
                            {
                              value: printer.is_active,
                              label: printer.is_active
                                ? GENERAL_CONSTANTS.ACTIVE
                                : GENERAL_CONSTANTS.INACTIVE,
                            },
                          ],
                        };
                        setEdit(editData);
                        setShow(true);
                      }}
                    />
                    {printer.is_active ? (
                      <Button
                        text={<FontIcon icon={faBan} />}
                        isDisabled={!printPermission?.perm_delete}
                        type={BUTTON_CONSTANTS.BUTTON}
                        btnClassNames={"btn btn-danger btn-sm"}
                        onClickHandler={() =>
                          dispatch(deletePrinter(printer.id))
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export default memo(PrinterList);
