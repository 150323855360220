import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  CustomCheckbox,
  EmptyFormMessage,
  Table,
} from "components";
import { Form, Formik } from "formik";
import { AddAddressOffCanvas } from "pages/ManageCustomers/AddAddressOffCanvas";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addNewEcommerceOrder,
  manageEcommerceCustomerAddresses,
} from "store/Ecommerce";
import { RootState } from "store/store";
import {
  ACCESS_CONSTANTS,
  BUTTON_CONSTANTS,
  calculateAllValues,
  displayValue,
  FORM_CONSTANTS,
  POS_CONSTANTS,
  to2Decimal,
  Toast,
  TOAST_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

const OrderPreview = () => {
  const dispatch = useAppDispatch();

  const {
    root: {
      branch: { branch: stores, selectedStore },
      product: { products: productData, status: productStatus },
      customer: { customers: customerData, customerToEdit: customer_details },
      cart: { cart },
      //   common: { current_page, total_items, total_pages, page_size },
    },
    ecommerce: {
      order: { customer_addresses },
      product: { products: CMSProductData, status: CMSProductStatus },
    },
  }: RootState = useAppSelector((state) => state);

  const navigate = useNavigate();

  const [addAddress, setAddAddress] = useState({ state: false, type: "" });

  const PropsRef = useRef(null);

  // const customer_details = {
  //   id: 4809,
  //   name: "Test User",
  //   email: "testuser@gmail.com",
  //   contact_number: 1233210981,
  //   addresses: [
  //     {
  //       id: 38,
  //       country: {
  //         id: 101,
  //         country: "India",
  //         is_active: 1,
  //       },
  //       state: {
  //         id: 4030,
  //         state: "Gujarat",
  //         is_active: 1,
  //         country: 101,
  //       },
  //       city: {
  //         id: 134310,
  //         city: "Vadodara",
  //         is_active: 1,
  //         state: 4030,
  //       },
  //       first_name: "Mayuresh",
  //       last_name: "Nivatkar",
  //       contact_number: 9987659828,
  //       pincode: "390015",
  //       flat: "D-19, Marutidham,",
  //       area: "Old Padra Road",
  //       landmark: "Old Padra Road",
  //       set_as_default: 1,
  //       created_at: "2024-08-07T11:51:17.143125",
  //       updated_at: "2024-08-07T12:20:15.881420",
  //       is_active: 1,
  //       customer: 4809,
  //     },
  //     {
  //       id: 13,
  //       country: {
  //         id: 101,
  //         country: "India",
  //         is_active: 1,
  //       },
  //       state: {
  //         id: 4008,
  //         state: "Maharashtra",
  //         is_active: 1,
  //         country: 101,
  //       },
  //       city: {
  //         id: 134138,
  //         city: "Thane",
  //         is_active: 1,
  //         state: 4008,
  //       },
  //       first_name: "Mayuresh",
  //       last_name: "Nivatkar",
  //       contact_number: 9987659828,
  //       pincode: "400601",
  //       flat: "58 - A / 32",
  //       area: "Vrindavan Society",
  //       landmark: "Opp. MSEB Office",
  //       set_as_default: 0,
  //       created_at: "2024-07-24T13:19:50.055941",
  //       updated_at: "2024-08-07T12:20:15.881219",
  //       is_active: 1,
  //       customer: 4809,
  //     },
  //   ],
  // };

  const prepareOrder = () => {
    const {
      total_tax,
      final_amount_to_pay,
      bill_total,
      discount_to_send,
    } = calculateAllValues(cart);

    const selected_address = customer_addresses?.find(
      (item) => item.id === PropsRef.current?.values?.selected_address
    );

    const dataToSend = {
      customer: customer_details?.id,
      store: ACCESS_CONSTANTS.ECOMMERCE_STORE,
      bill_amount: to2Decimal(bill_total),
      payable_amount: to2Decimal(final_amount_to_pay + cart.round_off),
      discount_value: cart.discount.discount_value
        ? cart.discount.discount_value
        : 0,
      discount_code: cart.discount.discount_code
        ? cart.discount.discount_code
        : "",
      discount_type: cart.discount.discount_type
        ? cart.discount.discount_type
        : "",
      roundoff: cart.round_off,
      total_quantity: to2Decimal(total_items),
      remarks: "",
      total_discount: to2Decimal(discount_to_send),
      total_tax: to2Decimal(total_tax),
      products: cart.cartItems.map((cartItem) => ({
        product: cartItem.id,
        batch: cartItem.batch_id,
        product_weight: cartItem.weight_variations?.find(
          (weight) => weight.weight === cartItem.weight
        )?.id,
        quantity: cartItem.quantity,
        total_amount: cartItem.total_amount,
        payable_amount: cartItem.payable_amount,
        discount_value: cartItem.discount_applied,
        discount_code: "",
        discount_type: "flat",
        cess: cartItem.cess,
        cgst: to2Decimal(cartItem.tax_applied / 2),
        igst: cartItem.igst,
        sgst: to2Decimal(cartItem.tax_applied / 2),
        unit_price: to2Decimal(cartItem.total_amount / cartItem.quantity),
        total_discount: cartItem.discount_applied,
        total_tax: cartItem.tax_applied,
      })),
      addresses: [
        {
          name: `${selected_address?.first_name} ${selected_address?.last_name}`,
          address_line_1: selected_address?.address_line_1,
          address_line_2: selected_address?.address_line_2,
          city: selected_address?.city,
          state: selected_address?.state,
          country: selected_address?.country,
          postal_code: selected_address?.pincode,
          phone_number: selected_address?.contact_number,
          address_type: "shipping",
        },
        {
          name: `${selected_address?.first_name} ${selected_address?.last_name}`,
          address_line_1: selected_address?.address_line_1,
          address_line_2: selected_address?.address_line_2,
          city: selected_address?.city,
          state: selected_address?.state,
          country: selected_address?.country,
          postal_code: selected_address?.pincode,
          phone_number: selected_address?.contact_number,
          address_type: "billing",
        },
      ],
    };

    dispatch(addNewEcommerceOrder({ data: dataToSend, navigate: navigate }));
  };

  const handleSubmit = (values, actions) => {
    console.log("values", values);
  };

  const {
    total_discount,
    total_items,
    total_tax,
    total_payable,
    bill_total,
  } = calculateAllValues(cart);

  useEffect(() => {
    if (customer_details?.id) {
      dispatch(
        manageEcommerceCustomerAddresses({ customer_id: customer_details?.id })
      );
    }
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader="Order Preview" />
        <div className="col-7">
          <Card>
            <CardHeader>
              <div className="card-title">
                <h5>Checkout Details</h5>
              </div>
            </CardHeader>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  selected_address: customer_addresses?.[0]?.id || 0,
                }}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  PropsRef.current = props;
                  return (
                    <Form>
                      <div className="row gap-2">
                        <div className="col-12 accordion">
                          <div className="accordion-item border-1 border-dark-subtle">
                            <h2
                              className={`accordion-header`}
                              id={`contact-details`}
                            >
                              <button
                                className={`accordion-button`}
                                data-bs-toggle="collapse"
                                data-bs-target={`#contact-details-content`}
                                type="button"
                              >
                                <h6>Contact Details</h6>
                              </button>
                            </h2>
                            <div
                              id={`contact-details-content`}
                              className={`accordion-collapse collapse`}
                            >
                              <div className={`accordion-body p-0 m-0 ml-2`}>
                                <Table tableClassNames="table-borderless">
                                  <tr>
                                    <th>Name:</th>
                                    <td>{`${customer_details?.name}`}</td>
                                  </tr>
                                  <tr>
                                    <th>Contact Number:</th>
                                    <td>{customer_details?.contact_number}</td>
                                  </tr>
                                  <tr>
                                    <th>Email:</th>
                                    <td>{customer_details?.email}</td>
                                  </tr>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 accordion">
                          <div className="accordion-item border-1 border-dark-subtle">
                            <h2
                              className={`accordion-header`}
                              id={`address-details`}
                            >
                              <button
                                className={`accordion-button`}
                                data-bs-toggle="collapse"
                                data-bs-target={`#address-details-content`}
                                type="button"
                              >
                                <h6>Address Details</h6>
                              </button>
                            </h2>
                            <div
                              id={`address-details-content`}
                              className={`accordion-collapse collapse`}
                            >
                              <div
                                className={`accordion-body d-flex flex-column gap-2`}
                              >
                                {customer_addresses ? (
                                  customer_addresses?.map((address, index) => (
                                    <div className="col" key={index}>
                                      <CustomCheckbox
                                        type={FORM_CONSTANTS.RADIO}
                                        checkboxInputClass="mt-1"
                                        checkboxLabelClass="custom-cursor-pointer"
                                        name={`selected_address_${address.id}`}
                                        label={
                                          <>
                                            <span className="text-extra-dark font-lg fw-600 mr-10">
                                              {`${address.first_name} ${address.last_name}`}
                                            </span>
                                            <address>{`${address.address_line_1} ${address.address_line_2} ${address.landmark}`}</address>
                                            <p className="font-xs mb-0">
                                              {`${address.city.city}, ${address.state.state}, ${address.pincode} , ${address.country.country}`}
                                            </p>
                                          </>
                                        }
                                        checked={
                                          props.values.selected_address ===
                                          address.id
                                        }
                                        onClickHandler={(e) => {
                                          props.setFieldValue(
                                            "selected_address",
                                            address.id
                                          );
                                        }}
                                      />
                                      {index ===
                                        customer_addresses.length - 1 && (
                                        <CustomCheckbox
                                          type={FORM_CONSTANTS.RADIO}
                                          checkboxInputClass="mt-1"
                                          checkboxLabelClass="active text-primary custom-cursor-pointer"
                                          name={`selected_address_${address.id}_new`}
                                          label={"Add New Address"}
                                          checked={false}
                                          onClickHandler={(e) => {
                                            setAddAddress({
                                              state: true,
                                              type: "Shipping",
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  ))
                                ) : (
                                  <EmptyFormMessage
                                    disabled={false}
                                    disabledMessage={""}
                                    emptyMessage={"Add Billing Address"}
                                    icon={faLocationDot}
                                    iconSize={"lg"}
                                    actions={() => {
                                      setAddAddress({
                                        state: true,
                                        type: "Shipping",
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </div>
        <div className="col-5">
          <Card>
            <CardHeader>
              <div className="card-title">
                <h5>Order Summary</h5>
              </div>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-column">
                {/* <hr data-content={"Products"} className="hr-text"></hr> */}
                <div
                  className="row overflow-y-scroll my-2"
                  style={{
                    maxHeight: `40vh`,
                  }}
                >
                  <div className="col">
                    {cart.cartItems &&
                      cart.cartItems.length > 0 &&
                      cart?.cartItems?.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="row" key={index}>
                            <div className="col-2"></div>
                            <div className="col-10 d-flex flex-column gap-2">
                              <div className="d-flex flex-row gap-4 text-muted">
                                <h6>Quantity: {item.quantity}</h6>
                                <h6>
                                  Weight:{" "}
                                  {`${item.weight} ${item.net_weight_uom.uom_code}`}
                                </h6>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                <h6 className="text-bold">{item.print_name}</h6>
                                <h6>{`${POS_CONSTANTS.RUPEE_SIGN} ${item.payable_amount}`}</h6>
                              </div>
                            </div>
                          </div>
                          <hr className="divider" />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                {/* <hr data-content={"Promotional Code"} className="hr-text"></hr>
                <div className="d-flex flex-row gap-2 my-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Promotional Code"
                    onBlur={() => {}}
                  />
                  <Button
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={"Apply"}
                    btnClassNames="btn btn-dark rounded-lg"
                    onClickHandler={() => {}}
                  />
                </div> */}
                <hr data-content={"Summary"} className="hr-text"></hr>
                <Table tableClassNames="table-borderless">
                  {/* <tr>
                    <th>Total Items</th>
                    <td align="right">{total_items}</td>
                  </tr> */}
                  <tr>
                    <th>Subtotal</th>
                    <td align="right">
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(bill_total)}
                    </td>
                  </tr>
                  <tr>
                    <th>Shipping charges</th>
                    <td align="right">
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(70)}
                    </td>
                  </tr>
                  {total_tax > 0 && (
                    <tr>
                      <th>Tax</th>
                      <td align="right">
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(total_tax)}
                      </td>
                    </tr>
                  )}
                  {total_discount > 0 && (
                    <tr>
                      <th>Discount</th>
                      <td align="right">
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(total_discount)}
                      </td>
                    </tr>
                  )}
                  {cart?.round_off > 0 && (
                    <tr>
                      <th>Roundoff</th>
                      <td align="right">
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(cart?.round_off)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>Total</th>
                    <td align="right">
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(total_payable + 70)}
                    </td>
                  </tr>
                </Table>
                <div className="d-flex flex-column my-4">
                  <div className="d-flex flex-row gap-2">
                    <Button
                      type={BUTTON_CONSTANTS.BUTTON}
                      text={"Edit Order"}
                      btnClassNames="btn btn-light w-100 rounded-lg"
                      onClickHandler={() => {
                        navigate("/admin/sales/pos/", {
                          state: { cart: cart },
                        });
                      }}
                    />
                    <Button
                      type={BUTTON_CONSTANTS.BUTTON}
                      text={"Place Order"}
                      btnClassNames="btn btn-dark w-100 rounded-lg"
                      onClickHandler={() => {
                        prepareOrder();
                        // Toast("Order Placed", TOAST_CONSTANTS.SUCCESS);
                        // navigate(-1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
      <AddAddressOffCanvas show={addAddress} setShow={setAddAddress} />
    </div>
  );
};

export { OrderPreview };
