import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";

type StoreDept = {
  id: number;
  department_name: string;
  department_head_name: string;
  department_head_contact_number: number;
  department_head_email: string;
};

const initialState = {
  storeDepts: [] as StoreDept[],
  status: STATUSES.IDLE as string,
  error: null,
};

const storeDeptSlice = createSlice({
  name: "storeDept",
  initialState,
  reducers: {
    getAllStoreDept: (state, action) => {
      state.storeDepts = action.payload;
    },
    addStoreDept: (state, action) => {
      state.storeDepts.push(action.payload);
    },
    removeStoreDept: (state, action) => {
      state.storeDepts = state.storeDepts.filter(
        (storeDept) => storeDept.id !== action.payload
      );
    },
    editStoreDept: (state, action) => {
      const { id } = action.payload;
      const index = state.storeDepts.findIndex((storeDept) => {
        return storeDept.id === id;
      });
      if (index !== -1) {
        state.storeDepts[index] = action.payload;
      }
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getAllStoreDept,
  addStoreDept,
  removeStoreDept,
  editStoreDept,
  setStatus,
  setError,
} = storeDeptSlice.actions;

export default storeDeptSlice.reducer;
