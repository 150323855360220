import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  ContentHeader,
  Loader,
  Table,
  TableBody,
  TableHeader,
  Tabs,
} from "components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FetchCreditNoteDetails,
  PrintCreditNote,
  resetCreditNoteState,
} from "store/POS";
import {
  ADVANCE_ORDERS_CONSTANTS,
  BUTTON_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  STATUSES,
  displayValue,
  filterCartItemsByHamperCode,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { OrderPaymentDetails } from "../Orders";
import moment from "moment";

const AdvanceOrderDetail = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    common: { status: commonStatus },
    customer: { customerToEdit: customer_details },
    creditNote: { currentCreditNote, status: creditNoteStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const [activeTab, setActiveTab] = useState<string>(
    ADVANCE_ORDERS_CONSTANTS.PRODUCT_DETAILS
  );

  const calculateDiscount = (orderItem: any) => {
    return orderItem.discount_value > 0
      ? orderItem.discount_type === "percentage"
        ? (to2Decimal(orderItem.total_amount / orderItem.quantity) *
            orderItem.discount_value) /
          100
        : to2Decimal(orderItem.total_amount / orderItem.quantity) -
          orderItem.discount_value
      : 0;
  };

  const calculateValues = (productGroup: any[]) => {
    const total_amount = productGroup.reduce((acc, currentValue) => {
      return (
        acc + to2Decimal(currentValue.total_amount / currentValue.quantity)
      );
    }, 0);

    const total_tax = productGroup.reduce((acc, currentValue) => {
      return acc + currentValue.total_tax;
    }, 0);
    const payable_amount = productGroup.reduce((acc, currentValue) => {
      return acc + currentValue.payable_amount;
    }, 0);

    const total_discount = productGroup.reduce((acc, currentValue) => {
      return acc + calculateDiscount(currentValue);
    }, 0);
    return { total_amount, total_tax, payable_amount, total_discount };
  };

  const displayOrderItems = (cartItems: any[]) => {
    if (creditNoteStatus === STATUSES.LOADING) {
      return (
        <tr>
          <td colSpan={tableCols.length} className="text-center">
            <Loader small={true} />
          </td>
        </tr>
      );
    } else if (cartItems && cartItems.length > 0) {
      const filterItems =
        currentCreditNote?.products.length > 0 &&
        filterCartItemsByHamperCode(currentCreditNote?.products).map(
          (item: any, productGroupIndex) => {
            return item[0] !== "undefined" && item[1];
          }
        );

      return (
        <React.Fragment>
          {filterCartItemsByHamperCode(currentCreditNote?.products).map(
            (item: any, productGroupIndex) => {
              const productGroupDetails = item[1][0];
              const { total_amount } = calculateValues(item[1]);
              return (
                item[0] !== "undefined" && (
                  <React.Fragment key={productGroupIndex}>
                    <tr
                      key={productGroupIndex}
                      // className="accordion-item"
                      data-bs-toggle="collapse"
                      data-bs-target={`.pg${productGroupIndex}`}
                    >
                      <td>{productGroupIndex + 1}</td>
                      <td>{productGroupDetails?.product_group?.hamper_code}</td>
                      <td>
                        {productGroupDetails?.product_group?.print_hamper_name}
                      </td>
                      <td>{productGroupDetails?.quantity}</td>
                      <td>₹{displayValue(total_amount)}</td>
                    </tr>
                    <tr>
                      <td colSpan={12} className="p-0">
                        <table
                          className={`table table-fixed pg${productGroupIndex} accordion-collapse collapse mb-0`}
                        >
                          <tbody>
                            {item[1].map((orderItem: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td width={"10%"} className="pl-4">
                                    {index + 1}
                                  </td>
                                  <td width={"25%"}>
                                    {orderItem?.product?.product_code}
                                  </td>
                                  <td width={"30%"}>
                                    {orderItem?.product?.print_name}
                                  </td>
                                  <td width={"20%"} className="pl-4">
                                    {orderItem?.quantity}
                                  </td>
                                  <td>
                                    ₹
                                    {to2Decimal(
                                      orderItem?.total_amount /
                                        orderItem?.quantity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              );
            }
          )}
          {filterCartItemsByHamperCode(currentCreditNote?.products).map(
            (item: any, cartItemIndex) => {
              if (item[0] === "undefined") {
                return item[1].map((orderItem: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + filterItems.length}</td>
                      <td>{orderItem?.product?.product_code}</td>
                      <td>{orderItem?.product?.print_name}</td>
                      <td>{orderItem?.quantity}</td>
                      <td>
                        ₹
                        {displayValue(
                          to2Decimal(
                            orderItem.total_amount / orderItem.quantity
                          )
                        )}
                      </td>
                      {/* <td>
                        <div className="col-4 text-end">
                          {orderItem.discount_value > 0
                            ? `₹${displayValue(
                                to2Decimal(
                                  orderItem.discount_type === "percentage"
                                    ? (mrp * orderItem.discount_value) / 100
                                    : mrp - orderItem?.discount_value
                                )
                              )} ${
                                orderItem.discount_type === "percentage"
                                  ? `(${orderItem.discount_value}%)`
                                  : ""
                              }`
                            : "-"}
                        </div>
                      </td>
                      <td>₹{displayValue(orderItem?.total_tax)}</td>
                      <td>
                        ₹
                        {displayValue(
                          to2Decimal(
                            orderItem?.payable_amount / orderItem?.quantity
                          )
                        )}
                      </td>
                      <td>₹{displayValue(orderItem?.payable_amount)}</td> */}
                    </tr>
                  );
                });
              }
            }
          )}
        </React.Fragment>
      );
    }
  };

  const tableCols = ["#", "Product Code", "Product Name", "Quantity", "MRP"];

  const tabs = [
    ADVANCE_ORDERS_CONSTANTS.PRODUCT_DETAILS,
    currentCreditNote?.payments?.length > 0
      ? ADVANCE_ORDERS_CONSTANTS.PAYMENT_DETAILS
      : null,
  ];

  useEffect(() => {
    if (Number(id)) {
      dispatch(FetchCreditNoteDetails(Number(id)));
    }
    // currentCreditNote &&
    //   currentCreditNote?.customer &&
    //   dispatch(getIndividualCustomer(Number(currentCreditNote?.customer?.id)));
    return () => {
      dispatch(resetCreditNoteState());
    };
  }, []);

  return (
    <div className="content-wrapper text-sm">
      <ContentHeader
        pageHeader={
          currentCreditNote?.credit_note_code
            ? `# ${currentCreditNote?.credit_note_code}`
            : ""
        }
        editValue={currentCreditNote?.credit_note_code}
      />
      <div className="d-flex flex-column flex-md-row">
        {currentCreditNote?.credit_note_code && (
          <div className="col-md-6">
            <Card cardClassNames="card-dark">
              <CardHeader>
                <h6 className="card-title">Order Details</h6>
              </CardHeader>
              <CardBody bodyClassNames="p-0">
                <Table tableType="table-borderless">
                  <TableBody>
                    <tr>
                      <th>Invoice No.:</th>
                      <td>{currentCreditNote?.credit_note_code}</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>
                        <Badge
                          badgeSize="sm"
                          badgeType={
                            currentCreditNote?.payments?.length > 1
                              ? GENERAL_CONSTANTS.SUCCESS
                              : GENERAL_CONSTANTS.WARNING
                          }
                          badgeText={
                            currentCreditNote?.payments?.length > 1
                              ? GENERAL_CONSTANTS.COMPLETED.toLocaleUpperCase()
                              : GENERAL_CONSTANTS.PENDING.toLocaleUpperCase()
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Created At:</th>
                      <td>{`${currentCreditNote?.invoice_date} ${currentCreditNote?.invoice_time}`}</td>
                    </tr>
                    <tr>
                      <th>Biller Name:</th>
                      <td>{currentCreditNote?.employee_name}</td>
                    </tr>
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </div>
        )}
        {currentCreditNote?.customer && (
          <div className="col-md-6">
            <Card cardClassNames="card-dark">
              <CardHeader>
                <h6 className="card-title">Customer Details</h6>
              </CardHeader>
              <CardBody bodyClassNames="p-0">
                <Table tableType="table-borderless">
                  <TableBody>
                    <tr>
                      <th>{CUSTOMERS_CONSTANTS.CUSTOMER_NAME_LABEL}:</th>
                      <td>
                        {currentCreditNote?.customer?.designation}{" "}
                        {currentCreditNote?.customer?.name}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER_LABEL}:
                      </th>
                      <td>
                        {currentCreditNote?.customer?.contact_number
                          ? currentCreditNote?.customer?.contact_number
                          : GENERAL_CONSTANTS.NA}
                      </td>
                    </tr>
                    <tr>
                      <th>{CUSTOMERS_CONSTANTS.CUSTOMER_EMAIL_LABEL}:</th>
                      <td>
                        {currentCreditNote?.customer?.email
                          ? currentCreditNote?.customer?.email
                          : GENERAL_CONSTANTS.NA}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {CUSTOMERS_CONSTANTS.CUSTOMER_COMPANY_NAME_LABEL}:
                      </th>
                      <td>
                        {currentCreditNote?.customer?.company_name
                          ? currentCreditNote?.customer?.company_name
                          : GENERAL_CONSTANTS.NA}
                      </td>
                    </tr>
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      {currentCreditNote?.delivery_type === POS_CART_CONSTANTS.DELIVERY && (
        <div className="d-flex flex-row">
          <div className="col-12">
            <Card cardClassNames="card-dark">
              <CardHeader>
                <h6 className="card-title">Delivery Details</h6>
              </CardHeader>
              <CardBody>
                <div className="d-flex flex-column flex-md-row">
                  <div className="col-md-6">
                    <Table tableType="table-borderless">
                      <TableBody>
                        <tr>
                          <th>{POS_CART_CONSTANTS.DELIVERY_TIME_LABEL}:</th>
                          <td>
                            {currentCreditNote?.delivery_date}
                            {" at "}
                            {currentCreditNote?.delivery_time}
                          </td>
                        </tr>
                      </TableBody>
                    </Table>
                  </div>
                  <div className="col-md-6">
                    <Table tableType="table-borderless table-fixed">
                      <TableBody>
                        {(currentCreditNote?.addresses?.[0]?.address_line_1
                          ?.length > 1 ||
                          currentCreditNote?.addresses?.[0]?.address_line_2
                            ?.length > 1) && (
                          <tr>
                            <th>
                              {POS_CART_CONSTANTS.DELIVERY_ADDRESS_TITLE}:
                            </th>
                            <td className="text-wrap">
                              {`${currentCreditNote?.addresses?.[0]?.address_line_1}, ${currentCreditNote?.addresses?.[0]?.address_line_2}, ${currentCreditNote?.addresses?.[0]?.city?.city}, ${currentCreditNote?.addresses?.[0]?.state?.state}, ${currentCreditNote?.addresses?.[0]?.country?.country}, ${currentCreditNote?.addresses?.[0]?.pincode}`}
                            </td>
                          </tr>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {currentCreditNote?.credit_note_code && (
        <div className="d-flex flex-row">
          <div className="col-12">
            <Card cardClassNames="card-dark">
              <CardHeader>
                <h6 className="card-title">Order Actions</h6>
              </CardHeader>
              <CardBody>
                <div
                  className={`col-12 d-flex flex-md-row flex-wrap gap-2 align-items-start px-0`}
                >
                  <div className="d-flex flex-row">
                    <Button
                      text={ADVANCE_ORDERS_CONSTANTS.PRINT_MESSAGE}
                      type={BUTTON_CONSTANTS.BUTTON}
                      loading={
                        commonStatus.state === STATUSES.LOADING &&
                        commonStatus.type ===
                          ADVANCE_ORDERS_CONSTANTS.LOADING_STATE
                      }
                      btnClassNames="btn btn-dark w-100"
                      onClickHandler={() => {
                        dispatch(PrintCreditNote(Number(id)));
                      }}
                    />
                  </div>
                  {moment(
                    moment(
                      currentCreditNote?.created_at,
                      "DD/MM/YYYY hh:mm A"
                    ).format()
                  ).format(GENERAL_CONSTANTS.DATE_FORMAT) ===
                    moment().format(GENERAL_CONSTANTS.DATE_FORMAT) &&
                    currentCreditNote?.payments?.length > 0 && (
                      <div className="d-flex flex-row mb-2">
                        <Button
                          text={POS_CART_CONSTANTS.EDIT_PAYMENT_DETAILS}
                          type={BUTTON_CONSTANTS.BUTTON}
                          btnClassNames="btn btn-dark w-100"
                          onClickHandler={() => {
                            navigate(
                              `/admin/sales/cashier/pending-orders/advance/${currentCreditNote?.id}/`,
                              {
                                state: { edit: true },
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      <div className="d-flex flex-row">
        <div className="col-12">
          <Card cardClassNames="card-dark">
            <CardHeader>
              <div className="d-flex flex-column flex-md-row">
                <div className="col-md-4 align-self-md-end mb-2 mb-md-0">
                  <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div
                className="d-flex flex-row overflow-y-scroll mt-2"
                style={{
                  maxHeight: `calc(100vh - ${
                    activeTab === ADVANCE_ORDERS_CONSTANTS.PRODUCT_DETAILS
                      ? "400px)"
                      : "200px)"
                  }`,
                }}
              >
                <div className="col-12 px-0">
                  {activeTab === ADVANCE_ORDERS_CONSTANTS.PRODUCT_DETAILS && (
                    <div className="d-flex flex-row px-0">
                      <Table>
                        <TableHeader
                          cols={tableCols}
                          tableHeaderClassNames="table-dark"
                        />
                        <TableBody>
                          {creditNoteStatus === STATUSES.LOADING ? (
                            <tr>
                              <td
                                colSpan={tableCols.length}
                                className="text-center"
                              >
                                <Loader />
                              </td>
                            </tr>
                          ) : currentCreditNote?.products &&
                            currentCreditNote?.products.length > 0 ? (
                            displayOrderItems(currentCreditNote?.products)
                          ) : (
                            <tr>
                              <td
                                colSpan={tableCols.length}
                                className="text-center"
                              >
                                {ADVANCE_ORDERS_CONSTANTS.NO_ITEMS_MESSAGE}
                              </td>
                            </tr>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {activeTab === ADVANCE_ORDERS_CONSTANTS.PAYMENT_DETAILS &&
                    currentCreditNote?.payments?.length > 0 && (
                      <OrderPaymentDetails orderDetails={currentCreditNote} />
                    )}
                </div>
              </div>
              {currentCreditNote?.remarks?.length > 0 && (
                <div className="d-flex flex-row align-items-center mailbox-read-message">
                  <h6 className="text-wrap">
                    <span className="text-bold">Bill remarks: </span>
                    {currentCreditNote?.remarks}
                  </h6>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { AdvanceOrderDetail };
