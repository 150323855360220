import React from "react";

import {
  Button,
  Card,
  CardBody,
  CustomCheckbox,
  CustomInput,
  MultiSelect,
  Summernote,
  TextArea,
} from "components";
import { ErrorMessage, Form } from "formik";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  WIDGET_CONSTANTS,
} from "utils";

const WidgetForm = ({ edit, ...props }) => {
  return (
    <Form>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  isRequired={true}
                  label={WIDGET_CONSTANTS.WIDGET_NAME_LABEL}
                  name={WIDGET_CONSTANTS.WIDGET_NAME}
                  value={props.values.widget_name}
                  placeholder={WIDGET_CONSTANTS.WIDGET_NAME_PLACEHOLDER}
                />
                <ErrorMessage
                  name={WIDGET_CONSTANTS.WIDGET_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {/* <div className="row my-2">
                <CustomCheckbox
                  type={FORM_CONSTANTS.CHECKBOX}
                  label={"Enter Widget Content"}
                  name={"widget_content"}
                  checked={props.values.widget_content}
                />
                {props.values.widget_content && (
                  <div className="row">
                    <TextArea
                      name={"content"}
                      label={"Widget Content"}
                      placeholder={"Enter Widget Content"}
                      rows={3}
                    />
                    <ErrorMessage
                      name={"content"}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                )}
              </div> */}
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  isRequired={true}
                  label={WIDGET_CONSTANTS.WIDGET_TYPE_LABEL}
                  name={WIDGET_CONSTANTS.WIDGET_TYPE}
                  placeholder={WIDGET_CONSTANTS.WIDGET_TYPE_PLACEHOLDER}
                  value={props.values.widget_type}
                />
                <ErrorMessage
                  name={WIDGET_CONSTANTS.WIDGET_TYPE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <TextArea
                  label={WIDGET_CONSTANTS.CONFIGURATION_OPTIONS_LABEL}
                  name={WIDGET_CONSTANTS.CONFIGURATION_OPTIONS}
                  placeholder={
                    WIDGET_CONSTANTS.CONFIGURATION_OPTIONS_PLACEHOLDER
                  }
                  rows={3}
                />
                <ErrorMessage
                  name={WIDGET_CONSTANTS.CONFIGURATION_OPTIONS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <TextArea
                  label={WIDGET_CONSTANTS.DESCRIPTION_LABEL}
                  name={WIDGET_CONSTANTS.DESCRIPTION}
                  placeholder={WIDGET_CONSTANTS.DESCRIPTION_PLACEHOLDER}
                  rows={3}
                />
                <ErrorMessage
                  name={WIDGET_CONSTANTS.DESCRIPTION}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {edit ? (
                <div className="row">
                  <MultiSelect
                    select={false}
                    name={WIDGET_CONSTANTS.IS_ACTIVE}
                    label={WIDGET_CONSTANTS.IS_ACTIVE_LABEL}
                    options={GENERAL_CONSTANTS.STATUS_OPTIONS}
                  />
                  <ErrorMessage
                    name={WIDGET_CONSTANTS.IS_ACTIVE}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </div>
        <div className="col-12">
          <Button
            loading={props.isSubmitting}
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-50"}
          />
        </div>
      </div>
    </Form>
  );
};

export { WidgetForm };
