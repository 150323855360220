import React, { ReactNode } from "react";

interface LabelProps {
  label?: string;
  children?: ReactNode;
  labelClassNames?: string;
  htmlFor?: string;
}

const Label: React.FC<LabelProps> = ({
  label,
  children,
  labelClassNames,
  htmlFor,
}) => {
  return (
    <label className={`${labelClassNames} `} htmlFor={htmlFor}>
      {label}
      {children}
    </label>
  );
};

export { Label };
