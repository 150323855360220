import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTS_CONSTANTS, STATUSES } from "utils/constants";
import {
  delProductBarcodeMapping,
  generateNewBarcode,
  getActiveBarcodeType,
  getBarcodeType,
  getSingleBarcodeType,
  setBarcodeType,
} from "apis/restApis";
import { BarcodeType, Toast } from "utils";
import { FormikProps } from "formik";
import { setCommonStatus } from "store/commonSlice";

const initialState = {
  barcodeTypes: [] as BarcodeType[],
  status: STATUSES.IDLE as string,
  error: null,
};

const barcodeSlice = createSlice({
  name: "barcode",
  initialState,
  reducers: {
    getAllBarcodeType(state, action) {
      state.barcodeTypes = action.payload;
    },
    getIndividual(state, action) {
      state.barcodeTypes = action.payload;
    },
    addBarcodeType(state, action) {
      state.barcodeTypes.push(action.payload);
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getAllBarcodeType,
  getIndividual,
  setStatus,
  setError,
} = barcodeSlice.actions;

export default barcodeSlice.reducer;

//First sets the status to loading, then makes the API call, and finally sets the status to idle and updates the state with the response.
export function getBarcodeTypes(active: boolean) {
  return async function getBarcodeTypeThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveBarcodeType()
          .then((response: any) => {
            dispatch(getAllBarcodeType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setError(error.message));
            dispatch(setStatus(STATUSES.ERROR));
          })
      : getBarcodeType()
          .then((response: any) => {
            dispatch(getAllBarcodeType(response.result.results));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setError(error.message));
            dispatch(setStatus(STATUSES.ERROR));
          });
  };
}

//Gets only the individual barcode type
export function getIndividualBarcodeType(id: number) {
  return async function getIndividualBarcodeTypeThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    getSingleBarcodeType(id)
      .then((response: any) => {
        dispatch(getIndividual(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setError(error.message));
        dispatch(setStatus(STATUSES.ERROR));
      });
  };
}

//Adds a new barcode type
export function addBarcodeType(barcodeTypeParams: BarcodeType) {
  return async function addBarcodeTypeThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    setBarcodeType(barcodeTypeParams)
      .then((response: any) => {
        dispatch(addBarcodeType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setError(error.message));
        dispatch(setStatus(STATUSES.ERROR));
      });
  };
}

// Deletes a barcode type
export function deleteProductBarcodeMapping(id: number) {
  return async function deleteProductBarcodeMappingThunk(dispatch) {
    dispatch(setStatus(STATUSES.LOADING));
    delProductBarcodeMapping(id)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function GenerateNewBarcodeForAvarya(
  product_id: number,
  push: (value) => void,
  index: number
) {
  return async function GenerateNewBarcodeForAvaryaThunk(dispatch) {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: PRODUCTS_CONSTANTS.BARCODE_LOADING_STATE,
      })
    );
    generateNewBarcode(product_id)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
        push({
          product_code: response.result,
          // barcode_type: "",
          new: true,
        });
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: PRODUCTS_CONSTANTS.BARCODE_LOADING_STATE,
          })
        );
      });
  };
}
