import { createSlice } from "@reduxjs/toolkit";
import { getProductNutrients } from "apis/restApis";
import { error } from "console";
import { STATUSES } from "utils";

interface INutrient {
  id: number;
  nutrient_name: string;
  is_active: 1 | 0;
}

const initialState = {
  nutrients: [] as INutrient[],
  status: STATUSES.IDLE as string,
  error: null,
};

const nutrientSlice = createSlice({
  name: "nutrient",
  initialState,
  reducers: {
    getAllNutrients(state, action) {
      state.nutrients = action.payload;
    },

    setNutrientStatus(state, action) {
      state.status = action.payload;
    },
    setNutrientError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getAllNutrients,
  setNutrientStatus,
  setNutrientError,
} = nutrientSlice.actions;

export default nutrientSlice.reducer;

export function FetchNutrients(active: boolean = false) {
  return async function FetchNutrientsThunk(dispatch: any) {
    dispatch(setNutrientStatus(STATUSES.LOADING));
    getProductNutrients(active)
      .then((response: any) => {
        dispatch(getAllNutrients(response.result));
      })
      .catch((error) => {
        dispatch(setNutrientError(error.message));
        dispatch(setNutrientStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setNutrientStatus(STATUSES.IDLE));
      });
  };
}
