import React from "react";
import { useLocation, useParams, Link, NavLink } from "react-router-dom";
import { ROUTES, truncateString } from "utils";

interface BreadcrumbProps {
  editValue?: any;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ editValue }) => {
  const location = useLocation();
  const pathName = location.pathname.split("/").splice(2);

  function capitalizeFirstLetter(string) {
    if (string.length < 4) {
      return string.toUpperCase();
    }
    const newValue = string
      .replace(/[^\w\s]/gi, " ")
      .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
    return newValue;
  }

  return (
    <ol className="breadcrumb float-sm-right" data-testid="breadcrumb">
      <li className="breadcrumb-item">
        <NavLink to={ROUTES.DASHBOARD}>Home</NavLink>
      </li>
      {pathName.map((path, index) => {
        const newPath = capitalizeFirstLetter(
          path.replace(/([a-zA-Z])([A-Z])([a-z])/g, "$1 $2$3")
        );
        if (newPath === "Dashboard") {
          //if path is dashboard
          return (
            <li className="breadcrumb-item" key={index}>
              {pathName.length === index + 1 ? (
                <>{newPath}</>
              ) : (
                <NavLink to={`/${path}`}>{newPath}</NavLink>
              )}
            </li>
          );
        }
        if (index !== pathName.length - 1 && path !== "edit") {
          //if last element is not edit
          return (
            <li className="breadcrumb-item" key={index}>
              {pathName.length === index + 1 ? (
                <>Manage {newPath}</>
              ) : (
                <NavLink
                  data-testid={"breadcrumb-list-link"}
                  to={`/admin/${pathName.slice(0, index + 1).join("/")}`}
                >
                  Manage {newPath}
                </NavLink>
              )}
            </li>
          );
        } else if (pathName[index - 1] === "edit") {
          //if previous element is edit
          return (
            <li className="breadcrumb-item text-wrap" key={index}>
              <strong>{truncateString(editValue, 40)}</strong>
            </li>
          );
        } else {
          return (
            //if none of the above
            <li className="breadcrumb-item" key={index}>
              {`${
                path === "groups" || path === "discounts"
                  ? `Product ${capitalizeFirstLetter(path)}`
                  : pathName[index - 1] === "orders" ||
                    pathName[index - 1] === "advance"
                  ? editValue
                  : capitalizeFirstLetter(path)
              }`}
            </li>
          );
        }
      })}
    </ol>
  );
};

export { Breadcrumb };
