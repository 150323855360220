import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { delPage, getPages, setPage, updatePage } from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  PAGES_CONSTANTS,
  STATUSES,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
  TPage,
} from "utils";

const initialState = {
  pages: [] as TPage[],
  page: null as null | TPage,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchPages = createAsyncThunk(
  "page/fetchPages",
  async (
    { id, active = false, pageNo = 1, query = "", edit }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "fetch-pages" }));
    try {
      const response = await getPages({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "fetch-pages" }));
    }
  }
);

export const addNewPage = createAsyncThunk(
  "page/addNewPage",
  async (
    {
      pageParams,
      actions,
      navigate,
    }: {
      pageParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setPage(pageParams);
      actions.resetForm();
      navigate(PAGES_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingPage = createAsyncThunk(
  "page/updateExistingPage",
  async (
    {
      id,
      pageParams,
      actions,
      navigate,
    }: {
      id: number;
      pageParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updatePage(id, pageParams);
      actions.resetForm();
      navigate(PAGES_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePage = createAsyncThunk(
  "page/deletePage",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "page-delete" }));
    try {
      const response = await delPage(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "page-delete" }));
    }
  }
);

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPageToEdit: (state, action) => {
      state.page = action.payload;
    },
    resetPagesState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPages.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.page = action.payload.result;
        } else if (action.meta.arg.active) {
          state.pages = action.payload.result;
        } else {
          state.pages = action.payload.result.results;
        }
      })
      .addCase(addNewPage.fulfilled, (state, action: any) => {
        state.pages.unshift(action.payload.result);
        state.pages = state.pages.slice(0, 10);
      })
      .addCase(updateExistingPage.fulfilled, (state, action: any) => {
        // Update the existing page in the pages array
        const index = state.pages.findIndex(
          (page) => page.id === action.payload.result.id
        );
        if (index !== -1) {
          state.pages[index] = action.payload.result;
        }
      })
      .addCase(deletePage.fulfilled, (state, action: any) => {
        // update the deleted page from the pages array
        const index = state.pages.findIndex(
          (page) => page.id === action.meta.arg
        );
        if (index !== -1) {
          state.pages[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewPage.fulfilled,
          updateExistingPage.fulfilled,
          deletePage.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchPages.pending,
          addNewPage.pending,
          updateExistingPage.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const { setPageToEdit, resetPagesState } = pagesSlice.actions;

export default pagesSlice.reducer;
