import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
} from "components";
import { ErrorMessage, FieldArray } from "formik";
import React, { memo } from "react";
import { deleteProductSpecificAlias } from "store/ManageVendors/vendorSlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  VENDORS_CONSTANTS,
  useAppDispatch,
} from "utils";

const VendorProductAlias = ({ id, props, index, product, productIndex }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h5 className="card-title">{VENDORS_CONSTANTS.CARD_TITLE_8}</h5>
        </CardHeader>
        <CardBody>
          <FieldArray
            name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ALIAS_NAME}`}
            render={({ push: pushAlias, remove: removeAlias }) => (
              <div className="row">
                <div className="col-12">
                  {product.alias.map((alias, aliasIndex) => (
                    <div className="row" key={aliasIndex}>
                      <div className="col-12">
                        <CustomInput
                          label={`${VENDORS_CONSTANTS.ALIAS_NAME_LABEL} ${
                            aliasIndex + 1
                          }`}
                          isRequired={true}
                          name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ALIAS_NAME}.${aliasIndex}.${VENDORS_CONSTANTS.PRODUCT_ALIAS_NAME}`}
                          placeholder={
                            VENDORS_CONSTANTS.PRODUCT_ALIAS_NAME_PLACEHOLDER
                          }
                          type={FORM_CONSTANTS.TEXT}
                        />
                        <ErrorMessage
                          name={`${VENDORS_CONSTANTS.STORES}.${index}.${VENDORS_CONSTANTS.PRODUCT_LIST}.${productIndex}.${VENDORS_CONSTANTS.ALIAS_NAME}.${aliasIndex}.${VENDORS_CONSTANTS.PRODUCT_ALIAS_NAME}`}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </div>
                      {/* <div className="col-1">
                        <Button
                          type={BUTTON_CONSTANTS.BUTTON}
                          text={<FontIcon icon={faTrash} />}
                          btnClassNames={"btn btn-danger mr-2"}
                          isDisabled={
                            aliasIndex === 0 && product.alias.length === 1
                          }
                          onClickHandler={() => {
                            removeAlias(aliasIndex);
                            if (
                              !props.values.stores[index].products[productIndex]
                                .alias[aliasIndex].new
                            ) {
                              dispatch(deleteProductSpecificAlias(alias.id));
                            }
                          }}
                        />
                        <Button
                          type={BUTTON_CONSTANTS.BUTTON}
                          text={<FontIcon icon={faAdd} />}
                          btnClassNames={"btn btn-primary"}
                          onClickHandler={() => {
                            pushAlias({
                              product_alias_name: "",
                              new: true,
                            });
                          }}
                        />
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VendorProductAlias);
