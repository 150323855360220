import React, { memo, useState } from "react";

import { Formik } from "formik";

import { Container, ContentHeader, OffCanvas } from "components";
import { PRINTER_CONSTANTS, PrinterSchema, useAppDispatch } from "utils";

import PrinterList from "./PrinterList";
import PrinterForm from "./PrinterForm";
import {
  addNewPrinter,
  updateExistingPrinter,
} from "store/Utilities/printerSlice";

const Printer = () => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState<false | {}>();

  const initialValues = {
    printer_name: "",
    target: "",
    series_name: "",
    language: "",
    is_active: [{ value: 1, label: "Active" }],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: values.is_active[0].value,
    };
    if (!edit) {
      dispatch(addNewPrinter(dataToSend, actions, setShow));
    } else {
      dispatch(updateExistingPrinter(values.id, dataToSend, actions, setShow));
    }
  };

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader="Printer" />
        <div className="col-12">
          <PrinterList setEdit={setEdit} setShow={setShow} />
          <OffCanvas
            title={
              edit
                ? PRINTER_CONSTANTS.EDIT_HEADER
                : PRINTER_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={edit ? edit : initialValues}
              validationSchema={PrinterSchema}
              onSubmit={handleSubmit}
            >
              {(props) => <PrinterForm edit={edit} props={props} />}
            </Formik>
          </OffCanvas>
        </div>
      </Container>
    </div>
  );
};

export { Printer };
