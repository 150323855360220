import { Button, Card, CardBody, CustomInput, MultiSelect } from "components";
import { ErrorMessage, Form } from "formik";
import React from "react";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCT_CATEGORY_CONSTANTS,
} from "utils";

const Categoryform = ({ edit, ...props }) => {
  return (
    <Form>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  isRequired={true}
                  label={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_NAME_LABEL}
                  name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_NAME}
                  value={props.values.category_name}
                  placeholder={
                    PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_NAME_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  // isRequired={true}
                  label={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_CODE_LABEL}
                  name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_CODE}
                  value={props.values.category_code}
                  placeholder={
                    PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_CODE_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_CODE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  label={
                    PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_DESCRIPTION_LABEL
                  }
                  value={props.values.description}
                  name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_DESCRIPTION}
                  placeholder={
                    PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_DESCRIPTION_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_DESCRIPTION}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {edit ? (
                <div className="row">
                  <MultiSelect
                    select={false}
                    name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_STATUS}
                    label={
                      PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_STATUS_LABEL
                    }
                    options={
                      PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_STATUS_OPTIONS
                    }
                  />
                  <ErrorMessage
                    name={PRODUCT_CATEGORY_CONSTANTS.PRODUCT_CATEGORY_STATUS}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </div>
        <div className="col-12">
          <Button
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export { Categoryform };
