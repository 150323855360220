import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  Loader,
  MultiSelect,
  SelectStatus,
  Summernote,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewTestimonial,
  fetchTestimonials,
  resetTestimonialState,
  setTestimonialToEdit,
  updateExistingTestimonial,
} from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  formatDate,
  GENERAL_CONSTANTS,
  ROLE_CONSTANTS,
  ScrollToTop,
  STATUSES,
  TESTIMONIAL_CONSTANTS,
  TestimonialSchema,
  useAppDispatch,
  useAppSelector,
} from "utils";

const TestimonialsForm = () => {
  const {
    ecommerce: {
      testimonial: { testimonial, status: testimonialStatus },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const initialValues = {
    name: id ? testimonial?.name : "",
    title: id
      ? [
          {
            value: CUSTOMERS_CONSTANTS.DESIGNATION_OPTIONS.find(
              (option: any) => option.label === testimonial?.title
            )?.value,
            label: testimonial?.title,
          },
        ]
      : [],

    date: formatDate(id ? testimonial?.date : new Date()),
    priority: id ? testimonial?.priority : "",
    review: id ? testimonial?.review : "",

    is_active: id
      ? [
          {
            value: testimonial?.is_active,
            label: testimonial?.is_active
              ? GENERAL_CONSTANTS.ACTIVE
              : GENERAL_CONSTANTS.INACTIVE,
          },
        ]
      : [
          {
            value: 1,
            label: GENERAL_CONSTANTS.ACTIVE,
          },
        ],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: values?.is_active?.[0]?.value,
      title: values?.title?.[0]?.label,
    };

    if (id) {
      dispatch(
        updateExistingTestimonial({
          id: Number(id),
          testimonialParams: { ...dataToSend, id: Number(id) },
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewTestimonial({
          testimonialParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    ScrollToTop();
    id && dispatch(fetchTestimonials({ id: Number(id) }));
    return () => {
      dispatch(setTestimonialToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${TESTIMONIAL_CONSTANTS.FORM_HEADER}`}
          editValue={testimonial?.name}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={id !== undefined}
            initialValues={initialValues}
            validationSchema={TestimonialSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Form>
                  <ScrollToFieldError />
                  {testimonialStatus === STATUSES.LOADING &&
                    !props.isSubmitting && (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    )}
                  <div className="row">
                    <div className="col-12">
                      <Card>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <h4 className="card-title">General Details</h4>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-4">
                              <MultiSelect
                                isRequired={true}
                                label={TESTIMONIAL_CONSTANTS.TITLE_LABEL}
                                name={TESTIMONIAL_CONSTANTS.TITLE}
                                options={
                                  CUSTOMERS_CONSTANTS.DESIGNATION_OPTIONS
                                }
                                value={props?.values?.title || []}
                                designation={true}
                              />
                              <ErrorMessage
                                name={TESTIMONIAL_CONSTANTS.TITLE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-4">
                              <CustomInput
                                isRequired={true}
                                type={FORM_CONSTANTS.TEXT}
                                name={TESTIMONIAL_CONSTANTS.NAME}
                                label={TESTIMONIAL_CONSTANTS.NAME_LABEL}
                                placeholder={
                                  TESTIMONIAL_CONSTANTS.NAME_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={TESTIMONIAL_CONSTANTS.NAME}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-4">
                              <SelectStatus
                                props={props}
                                name={TESTIMONIAL_CONSTANTS.STATUS}
                                label={TESTIMONIAL_CONSTANTS.STATUS_LABEL}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-12">
                      <Card>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <h4 className="card-title">
                              {TESTIMONIAL_CONSTANTS.CARD_TITLE_2}
                            </h4>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={TESTIMONIAL_CONSTANTS.DATE_LABEL}
                                name={TESTIMONIAL_CONSTANTS.DATE}
                                type={FORM_CONSTANTS.DATE}
                                max={new Date().toISOString().split("T")[0]}
                                value={props?.values?.date || ""}
                              />
                              <ErrorMessage
                                name={TESTIMONIAL_CONSTANTS.DATE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                isRequired={true}
                                label={TESTIMONIAL_CONSTANTS.PRIORITY_LABEL}
                                name={TESTIMONIAL_CONSTANTS.PRIORITY}
                                type={FORM_CONSTANTS.NUMBER}
                                min={1}
                                placeholder={
                                  TESTIMONIAL_CONSTANTS.PRIORITY_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={TESTIMONIAL_CONSTANTS.PRIORITY}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Summernote
                                isRequired={true}
                                name={TESTIMONIAL_CONSTANTS.REVIEW}
                                label={TESTIMONIAL_CONSTANTS.REVIEW_LABEL}
                                // onInit={() => {
                                //   if (id) {
                                //     props.setFieldValue(
                                //       TESTIMONIAL_CONSTANTS.REVIEW,
                                //       testimonial?.review || ""
                                //     );
                                //   }
                                // }}
                                value={props?.values?.review || ""}
                                onChange={(value) =>
                                  props.setFieldValue(
                                    TESTIMONIAL_CONSTANTS.REVIEW,
                                    value
                                  )
                                }
                                summerNoteHeight={250}
                              />
                              <ErrorMessage
                                name={TESTIMONIAL_CONSTANTS.REVIEW}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Button
                        loading={
                          testimonialStatus === STATUSES.LOADING &&
                          props.isSubmitting
                        }
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary align-self-center w-25"}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { TestimonialsForm };
