import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/helper";
import { Footer, Loader, Navbar, Sidebar } from "components";
import { ROUTES, STATUSES } from "utils";
import { setNavigation } from "store/commonSlice";

const PrivateRoutes = () => {
  const {
    auth: { isLoggedIn, status: authStatus },
  } = useAppSelector((state) => state.root);

  const dispatch = useAppDispatch();

  const navigate = useNavigation();

  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const location = useLocation();
  const pathName = location.pathname.split("/").splice(2);

  const isPOS = pathName.includes("pos") || pathName.includes("cashier");

  useEffect(() => {
    dispatch(setNavigation(navigate));
  }, []);

  return isLoggedIn ? (
    <div
      className={`${!(isPOS && sidebar) ? "sidebar-mini" : ""} ${
        !isPOS && sidebar ? "sidebar-collapse" : ""
      } extra-sidebarStyles layout-fixed ${isPOS ? "sidebar-collapse" : ""}`}
      style={{ maxHeight: "auto" }}
    >
      <div className="wrapper">
        <Navbar onClickHandler={toggleSidebar} />
        <Sidebar />
        {authStatus === STATUSES.LOADING ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <Outlet />
        )}
        {!isPOS && <Footer />}
      </div>
    </div>
  ) : (
    <Navigate replace to={ROUTES.LOGIN} />
  );
};

export { PrivateRoutes };
