import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  getActiveDeliveryPolicy,
  getDeliveryPolicy,
  setDeliveryPolicy,
} from "apis/restApis";

interface DeliveryPolicy {
  id: number;
  policy: string;
  is_active: number;
}

const initialState = {
  deliveryPolicy: [] as DeliveryPolicy[],
  status: STATUSES.IDLE as string,
  error: null,
};

const deliveryPolicySlice = createSlice({
  name: "deliveryPolicy",
  initialState,
  reducers: {
    getAllDeliveryPolicy: (state, action) => {
      state.deliveryPolicy = action.payload;
    },
    addDeliveryPolicy: (state, action) => {
      state.deliveryPolicy.push(action.payload);
    },
    removeDeliveryPolicy: (state, action) => {
      state.deliveryPolicy = state.deliveryPolicy.filter(
        (deliveryPolicy) => deliveryPolicy.id !== action.payload
      );
    },
    editDeliveryPolicy: (state, action) => {
      const { id } = action.payload;
      const index = state.deliveryPolicy.findIndex(
        (deliveryPolicy) => deliveryPolicy.id === id
      );
      if (index !== -1) {
        state.deliveryPolicy[index] = action.payload;
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllDeliveryPolicy,
  addDeliveryPolicy,
  removeDeliveryPolicy,
  editDeliveryPolicy,
  setStatus,
  setError,
} = deliveryPolicySlice.actions;

export default deliveryPolicySlice.reducer;

export function getDeliveryPolicies(active: boolean) {
  return async function getDeliveryPolicyThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveDeliveryPolicy()
          .then((response: any) => {
            dispatch(getAllDeliveryPolicy(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getDeliveryPolicy()
          .then((response: any) => {
            dispatch(getAllDeliveryPolicy(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function addNewDeliveryPolicy(deliveryPolicyParams: DeliveryPolicy) {
  return async function addNewDeliveryPolicyThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    setDeliveryPolicy(deliveryPolicyParams)
      .then((response: any) => {
        dispatch(addDeliveryPolicy(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
