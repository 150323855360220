import React from "react";

interface TableProps {
  testId?: string;
  tableClassNames?: string;
  tableType?: string;
  children: React.ReactNode;
}

const Table: React.FC<TableProps> = ({
  testId,
  tableClassNames,
  tableType,
  children,
}) => {
  return (
    <div
      className={`${tableClassNames ? tableClassNames : ""} table-responsive`}
    >
      <table
        className={`table ${tableType ? tableType : ""} text-nowrap`}
        data-testid={testId}
      >
        {children}
      </table>
    </div>
  );
};

export { Table };
