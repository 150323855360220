import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delHSNCategory,
  delHSNCode,
  getActiveHSNCode,
  getHSNCode,
  setHSNCode,
  updateHSNCode,
} from "apis/restApis";
import { HSNCode, PaginationState } from "utils/types";
import { updateValues } from "./commonSlice";
import { FormikHelpers } from "formik";
import { Toast } from "utils";

const initialState = {
  hsnCodes: [] as HSNCode[],

  status: STATUSES.IDLE as string,
  error: null,
};

const hsnCodesSlice = createSlice({
  name: "hsnCodes",
  initialState,
  reducers: {
    getAllHSNCodes(state, action) {
      state.hsnCodes = action.payload;
    },
    addHSNCode(state, action) {
      state.hsnCodes.unshift(action.payload);
    },
    removeHSNCode(state, action) {
      state.hsnCodes = state.hsnCodes.filter(
        (hsnCode) => hsnCode.id !== action.payload
      );
    },
    editHSNCode(state, action) {
      const { id } = action.payload;
      const index = state.hsnCodes.findIndex((code) => code.id === id);
      if (index !== -1) {
        state.hsnCodes[index] = action.payload;
      }
    },

    setHSNStatus: (state, action) => {
      state.status = action.payload;
    },
    setHSNError: (state, action) => {
      state.error = action.payload;
    },
    resetHSNState: (state) => {
      state.hsnCodes = [] as HSNCode[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllHSNCodes,
  addHSNCode,
  removeHSNCode,
  editHSNCode,

  setHSNStatus,
  setHSNError,
  resetHSNState,
} = hsnCodesSlice.actions;

export default hsnCodesSlice.reducer;

export function getHSN(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getHSNThunk(dispatch, getState) {
    dispatch(setHSNStatus(STATUSES.LOADING));
    active
      ? getActiveHSNCode()
          .then((response: any) => {
            dispatch(setHSNStatus(STATUSES.IDLE));
            dispatch(getAllHSNCodes(response.result));
          })
          .catch((error) => {
            dispatch(setHSNError(error.message));
            dispatch(setHSNStatus(STATUSES.ERROR));
          })
      : getHSNCode(pageNo, query)
          .then((response: any) => {
            dispatch(setHSNStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllHSNCodes(response.result.results));
          })
          .catch((error) => {
            dispatch(setHSNError(error.message));
            dispatch(setHSNStatus(STATUSES.ERROR));
          });
  };
}

export function addNewHSN(
  HSNCodeParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function addNewHSNThunk(dispatch, getState) {
    dispatch(setHSNStatus(STATUSES.LOADING));
    setHSNCode(HSNCodeParams)
      .then((response: any) => {
        // dispatch(addHSNCode(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
        dispatch(getHSN());
      })
      .catch((error) => {
        dispatch(setHSNStatus(STATUSES.ERROR));
        dispatch(setHSNError(error.message));
      })
      .finally(() => {
        dispatch(setHSNStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingHSN(
  id: number,
  HSNCodeParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function updateExistingHSNThunk(dispatch, getState) {
    dispatch(setHSNStatus(STATUSES.LOADING));
    updateHSNCode(id, HSNCodeParams)
      .then((response: any) => {
        // dispatch(editHSNCode(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
        dispatch(getHSN());
      })
      .catch((error) => {
        dispatch(setHSNStatus(STATUSES.ERROR));
        dispatch(setHSNError(error.message));
      })
      .finally(() => {
        dispatch(setHSNStatus(STATUSES.IDLE));
      });
  };
}

export function deleteHSN(id: number) {
  return async function deleteHSNThunk(dispatch, getState) {
    dispatch(setHSNStatus(STATUSES.LOADING));
    delHSNCode(id)
      .then((response: any) => {
        dispatch(getHSN());
        // dispatch(editHSNCode(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setHSNError(error.message));
        dispatch(setHSNStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setHSNStatus(STATUSES.IDLE));
      });
  };
}

export function deleteHSNCategory(delHSNParams: {
  hsn_code: number;
  product_category: number;
}) {
  return async function deleteHSNTagThunk(dispatch, getState) {
    dispatch(setHSNStatus(STATUSES.LOADING));
    delHSNCategory(delHSNParams)
      .then((response: any) => {
        dispatch(setHSNStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setHSNError(error.message));
        dispatch(setHSNStatus(STATUSES.ERROR));
      });
  };
}
