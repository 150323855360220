import { ConfirmModal, Container, ContentHeader, OffCanvas } from "components";
import React, { useState } from "react";
import {
  GENERAL_CONSTANTS,
  ROLE_CONSTANTS,
  TASTE_CONSTANTS,
  TasteSchema,
  useAppDispatch,
} from "utils";
import { TasteList } from "./TasteList";
import { Formik } from "formik";
import { TasteForm } from "./TasteForm";
import {
  addNewTaste,
  deleteTaste,
  updateExistingTaste,
} from "store/ManageProducts/tasteSlice";

const Tastes = () => {
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState<false | {}>();
  const [show, setShow] = useState(false);

  const [currentTaste, setCurrentTaste] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const initialValues = {
    taste_name: "",
    description: "",
    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: values.is_active[0]?.value,
    };
    if (!edit) {
      dispatch(addNewTaste({ tasteParams: dataToSend, actions, setShow }));
    } else {
      dispatch(
        updateExistingTaste({
          id: Number(values.id),
          tasteParams: dataToSend,
          actions,
          setShow,
        })
      );
    }
  };

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={TASTE_CONSTANTS.HEADER} />
      <Container>
        <div className="col-12">
          <TasteList
            setEdit={setEdit}
            setShow={setShow}
            setConfirmModal={setConfirmModal}
            setCurrentTaste={setCurrentTaste}
          />
          <OffCanvas
            title={`${
              edit ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
            } ${TASTE_CONSTANTS.ADD_HEADER}`}
            show={show}
            onClickHandler={() => {
              setShow(false);
              setEdit(false);
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={edit ? { ...edit } : { ...initialValues }}
              validationSchema={TasteSchema}
              onSubmit={handleSubmit}
            >
              {(props) => <TasteForm edit={edit} {...props} />}
            </Formik>
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={TASTE_CONSTANTS.DELETE_TASTE}
        message={TASTE_CONSTANTS.DELETE_TASTE_MSG}
        confirmClick={() => {
          dispatch(deleteTaste(currentTaste));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Tastes };
