import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delEmailTemplate,
  getEmailTemplates,
  setEmailTemplate,
  updateEmailTemplate,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { updateValues } from "store/commonSlice";
import {
  EMAIL_TEMPLATE_CONSTANTS,
  STATUSES,
  TEmailTemplate,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
} from "utils";

const initialState = {
  email_templates: [] as TEmailTemplate[],
  email_template: {} as TEmailTemplate,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchEmailTemplates = createAsyncThunk(
  "email_template/fetchEmailTemplates",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getEmailTemplates({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewEmailTemplate = createAsyncThunk(
  "email_template/addNewEmailTemplate",
  async (
    {
      emailTemplateParams,
      actions,
      navigate,
    }: {
      emailTemplateParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setEmailTemplate(emailTemplateParams);
      actions.resetForm();
      navigate(EMAIL_TEMPLATE_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingEmailTemplate = createAsyncThunk(
  "email_template/updateExistingEmailTemplate",
  async (
    {
      id,
      emailTemplateParams,
      actions,
      navigate,
    }: {
      id: number;
      emailTemplateParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateEmailTemplate(id, emailTemplateParams);
      actions.resetForm();
      navigate(EMAIL_TEMPLATE_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteEmailTemplate = createAsyncThunk(
  "email_template/deleteEmailTemplate",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delEmailTemplate(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState,
  reducers: {
    setEmailTemplateToEdit: (state, action) => {
      state.email_template = action.payload;
    },
    resetEmailTemplateState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmailTemplates.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.email_template = action.payload.result;
        } else if (action.meta.arg.active) {
          state.email_templates = action.payload.result;
        } else {
          state.email_templates = action.payload.result.results;
        }
      })
      .addCase(addNewEmailTemplate.fulfilled, (state, action: any) => {
        state.email_templates.unshift(action.payload.result);
        state.email_templates = state.email_templates.slice(0, 10);
      })
      .addCase(updateExistingEmailTemplate.fulfilled, (state, action: any) => {
        // Update the existing email_template in the email_templates array
        const index = state.email_templates.findIndex(
          (email_template) => email_template.id === action.payload.result.id
        );
        if (index !== -1) {
          state.email_templates[index] = action.payload.result;
        }
      })
      .addCase(deleteEmailTemplate.fulfilled, (state, action: any) => {
        // update the deleted email_template from the email_templates array
        const index = state.email_templates.findIndex(
          (email_template) => email_template.id === action.meta.arg
        );
        if (index !== -1) {
          state.email_templates[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewEmailTemplate.fulfilled,
          updateExistingEmailTemplate.fulfilled,
          deleteEmailTemplate.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchEmailTemplates.pending,
          addNewEmailTemplate.pending,
          updateExistingEmailTemplate.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setEmailTemplateToEdit,
  resetEmailTemplateState,
} = emailTemplateSlice.actions;

export default emailTemplateSlice.reducer;
