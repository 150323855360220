import * as Yup from "yup";
import { faAdd, faTrash, faBars } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FontIcon,
  IconButton,
  Modal,
  MultiSelect,
} from "components/core-components";
import { CustomInput } from "components/CustomInput";
import { FileUpload } from "components/FileUpload";
import { Summernote } from "components/Summernote";
import { ErrorMessage, FieldArray, Form, Formik, FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { uploadImage } from "store/commonSlice";
import { ScrollToFieldError } from "../ScollToFieldError";
import {
  BUTTON_CONSTANTS,
  checkIfFilesAreCorrectType,
  checkIfFilesAreTooBig,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  isJsonString,
  makeApiRequest,
  REQUEST_METHOD,
  Toast,
  TOAST_CONSTANTS,
  TWidgetOption,
  TWidgetProperty,
  useAppDispatch,
  useAppSelector,
  WidgetContentSchema,
} from "utils";

interface IProps {
  props: FormikProps<any>;
  modal: {
    state: boolean;
    widget: TWidgetOption;
    index: any;
  };
  setModal: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      widget: TWidgetOption;
      index: any;
    }>
  >;
}

const WidgetDetailModal = ({ props, modal, setModal }: IProps) => {
  const { pathname } = useLocation();

  const filterWords = ["", "add", "edit", "ecommerce", "admin", "cms"];

  const currentFolderName = pathname
    .split("/")
    .filter((item) => !filterWords.includes(item))[0];

  const {
    common: { status: commonStatus },
  } = useAppSelector((state) => state.root);

  const [loading, setLoading] = useState({ state: false, type: "" });

  const [query, setQuery] = useState("");

  const arrayHelperRef = useRef(null);

  const dispatch = useAppDispatch();

  const getColSize = (size) => {
    switch (size) {
      case "sm":
        return "col-4";

      case "md":
        return "col-6";

      case "lg":
        return "col-12";

      default:
        return "col-12";
    }
  };

  const getOptions = async ({
    props,
    index,
    property,
    query,
    type,
    optionsParent,
  }: {
    props: FormikProps<any>;
    index: number;
    property: any;
    query: string;
    type?: string;
    optionsParent?: string;
  }) => {
    const get_options_url = () => {
      if (property?.input_config && property?.input_config?.length > 0) {
        if (property.filter_config) {
          const filter_by = props.values?.properties?.find(
            (item) => item.name === property.filter_config?.parent
          )?.value?.[0]?.value;

          const index = property.input_config.findIndex((item) =>
            item.type.match(filter_by)
          );

          const url = property.input_config[index].url;

          return { index, url };
        }
      } else {
        return { index: undefined, url: property.input_config.url };
      }
    };

    const fetch_options = async () => {
      setLoading({
        state: true,
        type: property.name,
      });

      const { index: configIndex, url } = get_options_url();

      const requestURL =
        process.env.REACT_APP_BASE_URL +
        `${url}` +
        (query ? `&query=${query}` : "");

      const options = await makeApiRequest(requestURL, REQUEST_METHOD.GET, {})
        .then((response: any) => {
          return (requestURL.includes("active")
            ? response.result
            : response.result.results
          ).map((item) => {
            return {
              value:
                configIndex !== undefined
                  ? item[property.input_config[configIndex].value]
                  : item[property.input_config.value],
              label:
                configIndex !== undefined
                  ? Array.isArray(property.input_config[configIndex].label)
                    ? item[property.input_config[configIndex].label[0]][
                        property.input_config[configIndex].label[1]
                      ]
                    : item[property.input_config[configIndex].label]
                  : item[property.input_config.label],
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });

      props.setFieldValue(`${optionsParent}.options`, [
        { value: "all", label: "All" },
        ...options,
      ]);

      setLoading({
        state: false,
        type: property.name,
      });

      return [{ value: "all", label: "All" }, ...options];
    };

    if (property?.input_config) {
      return await fetch_options();
    } else {
      return property.options || [];
    }
  };

  const updateImage = (e, fieldName, value, props) => {
    props.setFieldValue(
      fieldName,
      Object.assign(e.target.files[0], {
        preview: value,
      })
    );
  };

  const renderField = (
    property: TWidgetProperty,
    index: number,
    subProps,
    parent?: string,
    parentIndex?: number,
    innerFormIndex?: number
  ) => {
    const fieldValueName = parent
      ? `${parent}.value`
      : `properties[${index}].value`;

    const fieldName = parent
      ? `${parent}.name[${index}]`
      : `properties[${index}].name`;

    switch (property.type) {
      case "fieldarray":
        return (
          <>
            <Card>
              <CardHeader>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h3 className="card-title">{property.label}</h3>
                  </div>
                  <div className="col-8 text-end">
                    <IconButton
                      icon={faAdd}
                      btnClassNames="btn btn-outline-primary"
                      isDisabled={
                        property.inner_form.length === property.max || false
                      }
                      btnText={`Add ${property?.single_value || "Fields"}`}
                      onClickHandler={() => {
                        subProps.setFieldValue(
                          `properties[${index}].inner_form`,
                          [
                            ...property.inner_form,
                            property.inner_form[0].map((item) => ({
                              ...item,
                              value: item.type === "select" ? [] : "",
                            })),
                          ]
                        );
                      }}
                      type={BUTTON_CONSTANTS.BUTTON}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  {property?.inner_form &&
                    property?.inner_form.length > 0 &&
                    property?.inner_form?.map((innerProperty, innerIndex) => {
                      return (
                        <div
                          className="row align-items-start"
                          key={`inner-form-${innerIndex}`}
                        >
                          <div className="col-11 accordion my-2">
                            <div className="accordion-item border-1 border-dark-subtle">
                              <h2
                                className={`accordion-header`}
                                id={`fieldarr-${innerIndex}`}
                              >
                                <button
                                  className={`accordion-button`}
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#fieldarray-${innerIndex}-content`}
                                  type="button"
                                >
                                  <h6>
                                    {innerIndex + 1} {property.single_value}
                                  </h6>
                                </button>
                              </h2>
                              <div
                                id={`fieldarray-${innerIndex}-content`}
                                className={`accordion-collapse collapse`}
                              >
                                <div
                                  className={`accordion-body d-flex flex-column gap-2`}
                                >
                                  <div className="row">
                                    <FieldArray
                                      name={fieldValueName}
                                      render={(arrayHelpers) => {
                                        arrayHelperRef.current = arrayHelpers;
                                        return innerProperty?.map(
                                          (innerField, innerFieldIndex) => (
                                            <div
                                              className={`${
                                                property?.size
                                                  ? getColSize(innerField.size)
                                                  : "col-12"
                                              }`}
                                              key={`inner-form-${innerIndex}-innerfield-${innerFieldIndex}`}
                                            >
                                              {renderField(
                                                innerField,
                                                innerFieldIndex,
                                                subProps,
                                                `properties[${index}].inner_form[${innerIndex}][${innerFieldIndex}]`,
                                                index,
                                                innerIndex
                                              )}
                                            </div>
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-1 mt-3">
                            <Button
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={property.inner_form.length === 1}
                              text={<FontIcon icon={faTrash} />}
                              btnClassNames={`btn btn-danger table-button`}
                              onClickHandler={() => {
                                subProps.setFieldValue(
                                  `properties[${index}].inner_form`,
                                  property.inner_form.filter(
                                    (item, i) => i !== innerIndex
                                  )
                                );
                                subProps.setFieldValue(
                                  `properties[${index}].value`,
                                  property.value.filter(
                                    (item, i) => i !== innerIndex
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </CardBody>
            </Card>
          </>
        );

      case "summernote":
        return (
          <>
            <Summernote
              key={"description"}
              name={fieldValueName}
              label={property.label}
              onInit={() => {
                subProps.setFieldValue(
                  fieldValueName,
                  parent
                    ? subProps.values?.properties?.[parentIndex]?.value?.[
                        innerFormIndex
                      ]?.value?.[index] || ""
                    : property.value || ""
                );
              }}
              value={
                parent
                  ? subProps.values?.properties?.[parentIndex]?.value?.[
                      innerFormIndex
                    ]?.value?.[index] || ""
                  : property.value || ""
              }
              onChange={(value) => {
                subProps.setFieldValue(fieldValueName, value);
                // subProps.setFieldValue(fieldName, value);
              }}
              summerNoteHeight={160}
            />
            <ErrorMessage
              name={fieldValueName}
              component={FORM_CONSTANTS.ERROR_PARENT}
              className={FORM_CONSTANTS.ERROR}
            />
          </>
        );

      case "input":
        return (
          <>
            <CustomInput
              type={property.input_type || "text"}
              key={"input"}
              name={fieldValueName}
              placeholder={property.placeholder}
              label={property.label}
              // onChangeHandler={(value) => {
              //   subProps.setFieldValue(fieldValueName, value);
              //   subProps.setFieldValue(fieldName, value);
              // }}
            />
            <ErrorMessage
              name={fieldValueName}
              component={FORM_CONSTANTS.ERROR_PARENT}
              className={FORM_CONSTANTS.ERROR}
            />
          </>
        );

      case "select":
        const checkIfDisabled = () => {
          if (property.disable_config && property.disable_config?.parent) {
            const parent_value = subProps.values?.properties?.find(
              (item) => item.name === property.disable_config?.parent
            )?.value;
            return parent_value.length === 0 || parent_value[0] === null;
          } else {
            return false;
          }
        };

        const optionsToShow = () => {
          if (property.filter_config) {
            const filter_by = subProps.values?.properties?.find(
              (item) => item.name === property.filter_config?.parent
            )?.value?.[0]?.value;

            const regex = `^${filter_by}`;

            return (
              (property.options || [])?.filter((item) =>
                item?.value?.match(regex)
              ) || []
            );
          } else {
            return property.options || [];
          }
        };

        return (
          <>
            <MultiSelect
              select={property.multiple}
              name={fieldValueName}
              label={property.label}
              isLoading={loading.state && loading.type === property.name}
              options={
                property.input_config ? property.options : optionsToShow()
              }
              placeholder={property.placeholder}
              disabled={checkIfDisabled()}
              onFocusHandler={() => {
                getOptions({
                  props: subProps,
                  index,
                  property,
                  query: "",
                  optionsParent: parent
                    ? `properties[${parentIndex}].inner_form[${innerFormIndex}][${index}]`
                    : `properties[${index}]`,
                });
              }}
              onInputChangeHandler={(inputValue) => {
                setQuery(inputValue);
              }}
              onKeyDownHandler={(e) => {
                if (e.key === "Enter") {
                  getOptions({
                    props: subProps,
                    index,
                    property,
                    query: query,
                    optionsParent: parent
                      ? `properties[${parentIndex}].inner_form[${innerFormIndex}][${index}]`
                      : `properties[${index}]`,
                  });
                }
              }}
              onChangeHandler={(value, actions) => {
                if (
                  property?.disable_config &&
                  property?.disable_config?.child &&
                  property?.disable_config?.child?.length > 0
                ) {
                  property?.disable_config?.child?.forEach((child) => {
                    const childIndex = subProps.values?.properties?.findIndex(
                      (item) => item.name === child
                    );

                    subProps.setFieldValue(
                      parent
                        ? `properties[${parentIndex}].inner_form[${innerFormIndex}][${childIndex}].value`
                        : `properties[${childIndex}].value`,
                      []
                    );
                  });
                }

                const selected_options = Array.isArray(value) ? value : [value];
                subProps.setFieldValue(fieldValueName, selected_options);

                // subProps.setFieldValue(fieldName, Array.isArray(e) ? e : [e]);
              }}
            />
            <ErrorMessage
              name={fieldValueName}
              component={FORM_CONSTANTS.ERROR_PARENT}
              className={FORM_CONSTANTS.ERROR}
            />
          </>
        );

      case "image":
        return (
          <>
            <FileUpload
              value={null}
              name={fieldValueName}
              label={property.label}
              // hideInput={
              //   (id
              //     ? subProps.values?.properties?.[index]?.value
              //     : subProps.values?.properties?.[index]?.value?.preview
              //   )?.length > 0
              // }
              preview={
                parent
                  ? subProps.values?.properties?.[parentIndex]?.inner_form[
                      innerFormIndex
                    ]?.[index]?.value?.preview
                  : subProps.values?.properties?.[index]?.value ||
                    subProps.values?.properties?.[index]?.value?.preview
                // id
                //   ? subProps.values?.properties?.[index]?.value
                //   : subProps.values?.properties?.[index]?.value?.preview
              }
              deleteImage={() => {
                subProps.setFieldValue(
                  parent ? `${parent}.value` : `properties[${index}].value`,
                  ""
                );
              }}
              multipleUpload={property.multiple}
              onChange={(e) => {
                e?.target?.files.length > 0 &&
                  dispatch(
                    uploadImage({
                      params: {
                        folder_name: `${currentFolderName}/content`,
                        file_name: e?.target?.files?.[0]?.name,
                        file: e?.target?.files?.[0],
                      },
                      updatePropsValues: (value) => {
                        updateImage(e, fieldValueName, value, subProps);
                        // updateImage(e, fieldName, value, subProps);
                      },
                    })
                  );
              }}
            />
            <ErrorMessage
              name={fieldValueName}
              component={FORM_CONSTANTS.ERROR_PARENT}
              className={FORM_CONSTANTS.ERROR}
            />
          </>
        );
    }
  };

  const generatePropertiesSchema = ({
    properties,
    isFieldArray,
  }: {
    properties: TWidgetProperty[];
    isFieldArray?: boolean;
  }) => {
    const schema = {};

    properties.forEach((property) => {
      let fieldSchema;

      switch (property.type) {
        case "image":
          fieldSchema = Yup.string().test(
            "is-image",
            "Please upload an image",
            (value) => {
              return value && value.length > 0;
            }
          );
          break;

        case "select":
          if (property.multiple) {
            fieldSchema = property.required
              ? Yup.array()
                  .min(
                    property.min || 1,
                    `Please select ${
                      property.min > 1
                        ? `atleast ${property.min} options`
                        : "an option"
                    } for ${property.label}`
                  )
                  .max(
                    property.max > 0 ? property.max : 24,
                    `Please select ${
                      property.max > 1
                        ? `atmost ${property.max} options`
                        : "an option"
                    } for ${property.label}`
                  )
              : Yup.array().nullable();
          } else {
            fieldSchema = property.required
              ? Yup.array().min(
                  1,
                  `Please select an option for ${property.label}`
                )
              : Yup.array().nullable();

            // fieldSchema = Yup.string()
            //   .min(1, `Please select an option for ${property.label}`)
            //   .max(1);
          }
          break;

        case "summernote":
          fieldSchema = property.required
            ? Yup.string().required(`${property.label} is required`)
            : Yup.string().nullable();
          break;

        case "input":
          fieldSchema = property.required
            ? Yup.string().required(`${property.label} is required`)
            : Yup.string().nullable();
          break;
        case "fieldarray":
          fieldSchema = Yup.array()
            .of(
              generatePropertiesSchema({
                properties: property.inner_form[0],
                isFieldArray: true,
              })
            )
            .min(
              property.min || 1,
              `Please add ${
                property.min > 1 ? `atleast ${property.min} items` : "an item"
              } for ${property.label}`
            )
            .max(
              property.max > 0 ? property.max : 24,
              `Please add ${
                property.max > 1 ? `atmost ${property.max} items` : "an item"
              } for ${property.label}`
            );

          break;

        default:
          fieldSchema = property.required
            ? Yup.mixed().required(`${property.label} is required`)
            : Yup.mixed().nullable();
      }

      schema[property.name] = fieldSchema;
    });

    return isFieldArray
      ? Yup.array().of(Yup.object().shape(schema))
      : Yup.object().shape(schema);
  };

  const handleSave = (values) => {
    const propertiesObject = {};

    const propertiesSchema = generatePropertiesSchema({
      properties: values.properties,
    });

    values.properties.forEach((property, index) => {
      if (property.type === "fieldarray") {
        const innerForms = [];
        for (let i in property.inner_form) {
          const innerFields = {};
          for (let j in property.inner_form[i]) {
            if (values.properties[index].inner_form[i][j].type === "image") {
              innerFields[values.properties[index].inner_form[i][j].name] =
                values.properties[index].inner_form[i][j].value.preview;
            } else {
              innerFields[values.properties[index].inner_form[i][j].name] =
                values.properties[index].inner_form[i][j].value;
            }
          }
          innerForms.push([innerFields]);
        }
        propertiesObject[property.name] = innerForms;
      } else {
        propertiesObject[property.name] = property.value;
      }
    });

    propertiesSchema
      .validate(propertiesObject)
      .then(() => {
        const dataToSet = {
          ...values,
          properties: values.properties.map((item) => {
            if (item.type === "select") {
              const dataToAdd = { ...item };

              return dataToAdd;
            } else {
              return item;
            }
          }),
        };
        props.setFieldValue(`widgets[${modal.index}]`, dataToSet);
        setModal({
          state: false,
          widget: null,
          index: null,
        });
      })
      .catch((err) => {
        for (const error of err.errors) {
          Toast(error, TOAST_CONSTANTS.WARNING);
        }
      });
  };

  return (
    <Modal
      // title={`${modal.widget.widget_display_name} Details`}
      title={"Widget Details"}
      showModal={modal.state}
      onClickHandler={() => {
        setModal({
          state: false,
          widget: null,
          index: null,
        });
      }}
      modalClassNames="w-100"
      fullScreenSize="xxl"
      modalSize={GENERAL_CONSTANTS.EXTRA_LARGE}
    >
      <Formik
        initialValues={{
          ...props.values.widgets[modal.index],
          widget: props.values.widgets[modal.index].widget,
          widget_display_name:
            props.values.widgets[modal.index].widget_display_name ||
            props.values.widgets[modal.index].widget_name ||
            "",
          is_active: props.values.widgets[modal.index].is_active,
          properties: isJsonString(props.values.widgets[modal.index].properties)
            ? JSON.parse(props.values.widgets[modal.index].properties)
            : props.values.widgets[modal.index].properties,
        }}
        onSubmit={handleSave}
        validationSchema={WidgetContentSchema}
      >
        {(subProps) => {
          return (
            <Form>
              <ScrollToFieldError />
              <div className="row">
                <div className="col-6">
                  <CustomInput
                    name={`widget_display_name`}
                    type="text"
                    isDisabled={false}
                    label={"Widget Name"}
                    placeholder="Enter Widget Name"
                    // value={
                    //   subProps.values.widget_display_name ||
                    //   props.values.widgets[modal.index].widget_name ||
                    //   ""
                    // }
                  />
                  <ErrorMessage
                    name={`widget_display_name`}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
                <div className="col-6">
                  <MultiSelect
                    select={false}
                    name={`is_active`}
                    label="Status"
                    options={GENERAL_CONSTANTS.STATUS_OPTIONS}
                    onChangeHandler={(e, actions) => {
                      if (actions.action === "clear") {
                        e = [{ value: 1, label: GENERAL_CONSTANTS.ACTIVE }];
                      }
                      subProps.setFieldValue(
                        `is_active`,
                        Array.isArray(e) ? e : [e]
                      );
                    }}
                  />
                  <ErrorMessage
                    name={`is_active`}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
              </div>
              <hr
                data-content={"Widget Content"}
                className="hr-text text-dark"
              ></hr>
              <div className="row">
                {subProps.values?.properties &&
                  subProps.values?.properties?.length > 0 &&
                  subProps.values?.properties?.map((property, index) => (
                    <div
                      className={`${
                        property?.size ? getColSize(property.size) : "col-12"
                      }`}
                      key={index}
                    >
                      {renderField(property, index, subProps)}
                      {property.draggable_list ? (
                        <ReactSortable
                          key={`sortable-list-${index}`}
                          className="row m-2"
                          list={subProps.values.properties[index].value}
                          setList={(newList) => {
                            subProps.setFieldValue(
                              `properties[${index}].value`,
                              newList
                            );
                          }}
                          animation={200}
                          easing="ease-out"
                        >
                          {subProps.values?.properties?.[index]?.value.map(
                            (listItem, index) => (
                              <Card
                                key={`${listItem.label}-${index}`}
                                cardClassNames="bg-light"
                              >
                                <CardHeader>
                                  <h4 className="card-title">
                                    <span className="mr-3">
                                      <FontIcon icon={faBars} />
                                    </span>
                                    {listItem.label}
                                  </h4>
                                </CardHeader>
                              </Card>
                            )
                          )}
                        </ReactSortable>
                      ) : null}
                    </div>
                  ))}
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <Button
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={FORM_CONSTANTS.SAVE}
                    btnClassNames={"btn btn-primary align-self-center w-25"}
                    onClickHandler={() => {
                      subProps.submitForm();
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export { WidgetDetailModal };
