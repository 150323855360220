import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delPaymentMode,
  getActivePaymentMode,
  getPaymentMode,
  setPaymentMode,
  updatePaymentMode,
} from "apis/restApis";
import { PaymentMode, Toast } from "utils";
import { updateValues } from "store/commonSlice";
import { FormikHelpers } from "formik";

const initialState = {
  paymentMode: [] as PaymentMode[],
  status: STATUSES.IDLE as string,
  error: null,
};

const paymentModeSlice = createSlice({
  name: "paymentMode",
  initialState,
  reducers: {
    getAllPaymentMode(state, action) {
      state.paymentMode = action.payload;
    },
    addPaymentMode(state, action) {
      state.paymentMode.push(action.payload);
    },
    removePaymentMode(state, action) {
      state.paymentMode = state.paymentMode.filter(
        (paymentMode) => paymentMode.id !== action.payload
      );
    },
    editPaymentMode(state, action) {
      const { id } = action.payload;
      const index = state.paymentMode.findIndex((paymentMode) => {
        return paymentMode.id === id;
      });
      if (index !== -1) {
        state.paymentMode[index] = action.payload;
      }
    },

    setPaymentModeStatus(state, action) {
      state.status = action.payload;
    },
    setPaymentModeError(state, action) {
      state.error = action.payload;
    },
    resetPaymentModeState: (state) => {
      state.paymentMode = [] as PaymentMode[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllPaymentMode,
  addPaymentMode,
  removePaymentMode,
  editPaymentMode,

  setPaymentModeStatus,
  setPaymentModeError,
  resetPaymentModeState,
} = paymentModeSlice.actions;

export default paymentModeSlice.reducer;

export function getPaymentModes(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getPaymentModesThunk(dispatch: any) {
    dispatch(setPaymentModeStatus(STATUSES.LOADING));
    active
      ? getActivePaymentMode()
          .then((response: any) => {
            dispatch(setPaymentModeStatus(STATUSES.IDLE));
            dispatch(getAllPaymentMode(response.result));
          })
          .catch((error) => {
            dispatch(setPaymentModeStatus(STATUSES.ERROR));
            dispatch(setPaymentModeError(error.message));
          })
      : getPaymentMode(pageNo, query)
          .then((response: any) => {
            dispatch(setPaymentModeStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllPaymentMode(response.result.results));
          })
          .catch((error) => {
            dispatch(setPaymentModeStatus(STATUSES.ERROR));
            dispatch(setPaymentModeError(error.message));
          });
  };
}

export function addNewPaymentMode(
  paymentModesParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function addNewPaymentModeThunk(dispatch: any) {
    dispatch(setPaymentModeStatus(STATUSES.LOADING));
    setPaymentMode(paymentModesParams)
      .then((response: any) => {
        dispatch(addPaymentMode(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setPaymentModeStatus(STATUSES.ERROR));
        dispatch(setPaymentModeError(error.message));
      })
      .finally(() => {
        dispatch(setPaymentModeStatus(STATUSES.IDLE));
      });
  };
}

export function deletePaymentMode(id: number) {
  return async function deletePaymentModeThunk(dispatch: any) {
    dispatch(setPaymentModeStatus(STATUSES.LOADING));
    delPaymentMode(id)
      .then((response: any) => {
        dispatch(editPaymentMode(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setPaymentModeStatus(STATUSES.ERROR));
        dispatch(setPaymentModeError(error.message));
      })
      .finally(() => {
        dispatch(setPaymentModeStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingPaymentMode(
  id: number,
  paymentModeParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function updateExistingPaymentModeThunk(dispatch: any) {
    dispatch(setPaymentModeStatus(STATUSES.LOADING));
    updatePaymentMode(id, paymentModeParams)
      .then((response: any) => {
        dispatch(editPaymentMode(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setPaymentModeStatus(STATUSES.ERROR));
        dispatch(setPaymentModeError(error.message));
      })
      .finally(() => {
        dispatch(setPaymentModeStatus(STATUSES.IDLE));
      });
  };
}
