import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delCMSProduct,
  delCMSProductMediaMapping,
  delCMSProductTasteMapping,
  delCMSProductWeightMapping,
  getCMSProducts,
  getProduct,
  getSpecificProduct,
  setCMSProduct,
  updateCMSProduct,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  ECOMMERCE_PRODUCT_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
  TEcommerceProduct,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
} from "utils";

const initialState = {
  products: [] as TEcommerceProduct[],
  product: {} as TEcommerceProduct,
  product_options: [] as any[],
  status: STATUSES.IDLE as string,
  error: null,
};

export const fetchCMSProducts = createAsyncThunk(
  "ecommerce_products/fetchCMSProducts",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getCMSProducts({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewCMSProduct = createAsyncThunk(
  "ecommerce_products/addNewCMSProduct",
  async (
    {
      productParams,
      actions,
      navigate,
    }: {
      productParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setCMSProduct(productParams);
      actions.resetForm();
      navigate(ECOMMERCE_PRODUCT_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingCMSProduct = createAsyncThunk(
  "ecommerce_products/updateExistingCMSProduct",
  async (
    {
      id,
      productParams,
      actions,
      navigate,
    }: {
      id: number;
      productParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateCMSProduct(id, productParams);
      actions.resetForm();
      navigate(ECOMMERCE_PRODUCT_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCMSProduct = createAsyncThunk(
  "ecommerce_products/deleteCMSProduct",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delCMSProduct(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAvaryaEcommerceProducts = createAsyncThunk(
  "ecommerce_products/fetchAvaryaEcommerceProducts",
  async ({ pageNo = 1, query = "", extra }: TGetParams, { dispatch }) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "fetchAvaryaProducts" })
    );
    try {
      const response: any = await getProduct(pageNo, query, 669, extra);
      return response;
    } catch (error) {
      return error.message;
    } finally {
      dispatch(
        setCommonStatus({ state: STATUSES.IDLE, type: "fetchAvaryaProducts" })
      );
    }
  }
);

export const fetchFormValues = createAsyncThunk(
  "ecommerce_products/fetchFormValues",
  async ({ id, edit }: { id: number; edit: any }) => {
    try {
      const response: any = await getCMSProducts({ id });

      const supporting_data: any = await getSpecificProduct(
        response?.result?.product
      );

      const dataToEdit = {
        id: response?.result?.id,
        product_name: response?.result?.product_name,
        product_category: [
          {
            value: response?.result?.category?.id,
            label: response?.result?.category?.display_name,
          },
        ],
        product_sub_category: [
          {
            value: response?.result?.sub_category?.id,
            label: response?.result?.sub_category?.display_name,
          },
        ],
        description: response?.result?.description,
        disclaimer: response?.result?.disclaimer,
        jain_friendly: [
          {
            value: response?.result?.jain_friendly,
            label: response?.result?.jain_friendly
              ? GENERAL_CONSTANTS.YES
              : GENERAL_CONSTANTS.NO,
          },
        ],
        is_active: [
          {
            value: response?.result?.is_active,
            label: response?.result?.is_active
              ? GENERAL_CONSTANTS.ACTIVE
              : GENERAL_CONSTANTS.INACTIVE,
          },
        ],
        media: response?.result?.media.sort(
          (a, b) => a?.priority - b?.priority
        ),
        weight_variations: response?.result?.weight_variations,
        // taste: response?.result?.taste.map((item) => {
        //   return {
        //     id: item?.id,
        //     product: item?.product_cms,
        //     value: item?.taste.id,
        //     label: item?.taste.taste_name,
        //   };
        // }),

        slugHelper: `/${response?.result?.category?.meta_slug}/${response?.result?.sub_category?.meta_slug}`,

        meta_title: response?.result?.meta_title,
        meta_description: response?.result?.meta_description,
        meta_keywords: response?.result?.meta_keywords,
        meta_slug: response?.result?.meta_slug.replace(
          `/${response?.result?.meta_slug?.split("/")?.[1]}/${
            response?.result?.meta_slug?.split("/")?.[2]
          }`,
          ""
        ),

        product: [
          {
            value: supporting_data.result?.id,
            label: supporting_data.result?.print_name,
            brand: supporting_data.result?.brand?.id,
          },
        ],

        expiration_days: supporting_data.result?.expiration_days,
        net_weight: supporting_data.result?.net_weight,

        category:
          supporting_data.result && supporting_data.result.category
            ? [
                {
                  value: supporting_data.result.category.id,
                  label: supporting_data.result.category.category_name,
                },
              ]
            : "",

        sub_category:
          supporting_data.result && supporting_data.result.sub_category
            ? [
                {
                  value: supporting_data.result.sub_category.id,
                  label: supporting_data.result.sub_category.sub_category_name,
                },
              ]
            : "",

        hsn_code:
          supporting_data.result && supporting_data.result.hsn_code
            ? [
                {
                  value: supporting_data.result.hsn_code.id,
                  label: supporting_data.result.hsn_code.hsn_code,
                },
              ]
            : "",

        uom:
          supporting_data.result && supporting_data.result.uom
            ? [
                {
                  code: supporting_data.result.uom.uom_code,
                  value: supporting_data.result.uom.id,
                  label: supporting_data.result.uom.uom_name,
                },
              ]
            : "",

        selling_uom:
          supporting_data.result && supporting_data.result.selling_uom
            ? [
                {
                  code: supporting_data.result.selling_uom.uom_code,
                  value: supporting_data.result.selling_uom.id,
                  label: supporting_data.result.selling_uom.uom_name,
                },
              ]
            : "",

        tax:
          supporting_data.result?.tax?.length > 0
            ? supporting_data.result.tax.map((item) => ({
                id: item.id,
                value: item.tax.id,
                label: item.tax.tax_name,
                rate: item.tax.tax_rate,
              }))
            : [],

        product_code: supporting_data.result?.product_code,
      };

      // if (edit) {
      //   edit.setInitialTastes &&
      //     edit.setInitialTastes(
      //       response?.result?.taste.map((item) => {
      //         return {
      //           id: item?.id,
      //           product: item?.product_cms,
      //           value: item?.taste.id,
      //           label: item?.taste.taste_name,
      //         };
      //       })
      //     );
      // }

      return dataToEdit;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const deleteMediaMapping = createAsyncThunk(
  "ecommerce_products/deleteMediaMapping",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_IMAGE_STATE,
      })
    );
    try {
      const response = await delCMSProductMediaMapping(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({
          state: STATUSES.IDLE,
          type: ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_IMAGE_STATE,
        })
      );
    }
  }
);

export const deleteWeightVariation = createAsyncThunk(
  "ecommerce_products/deleteWeightVariation",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_WEIGHT_STATE,
      })
    );
    try {
      const response = await delCMSProductWeightMapping(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({
          state: STATUSES.IDLE,
          type: ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_WEIGHT_STATE,
        })
      );
    }
  }
);

const ecommerceProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearProducts: (state) => {
      state.products = [];
    },
    clearProductOptions: (state) => {
      state.product_options = [];
    },
    setCMSProductToEdit: (state, action) => {
      state.product = action.payload;
    },
    resetCMSProductState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCMSProducts.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.product = action.payload.result;
        } else if (action.meta.arg.active) {
          state.products = action.payload.result;
        } else {
          state.products = action.payload.result.results;
        }
      })
      .addCase(addNewCMSProduct.fulfilled, (state, action: any) => {
        state.products.unshift(action.payload.result);
        state.products = state.products.slice(0, 10);
      })
      .addCase(updateExistingCMSProduct.fulfilled, (state, action: any) => {
        // Update the existing product in the products array
        const index = state.products.findIndex(
          (product) => product.id === action.payload.result.id
        );
        if (index !== -1) {
          state.products[index] = action.payload.result;
        }
      })
      .addCase(deleteCMSProduct.fulfilled, (state, action: any) => {
        // update the deleted product from the products array
        const index = state.products.findIndex(
          (product) => product.id === action.meta.arg
        );
        if (index !== -1) {
          state.products[index] = action.payload.result;
        }
      })
      .addCase(fetchAvaryaEcommerceProducts.fulfilled, (state, action: any) => {
        state.product_options = [];
        state.product_options = action.payload.result.results;
      })
      .addCase(fetchFormValues.fulfilled, (state, action: any) => {
        state.product = action.payload;
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewCMSProduct.fulfilled,
          updateExistingCMSProduct.fulfilled,
          deleteCMSProduct.fulfilled,
          deleteMediaMapping.fulfilled,
          deleteWeightVariation.fulfilled
          // deleteTasteMapping.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCMSProducts.pending,
          fetchFormValues.pending,
          addNewCMSProduct.pending,
          updateExistingCMSProduct.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  clearProducts,
  clearProductOptions,
  setCMSProductToEdit,
  resetCMSProductState,
} = ecommerceProductSlice.actions;

export default ecommerceProductSlice.reducer;
