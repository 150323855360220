import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  Loader,
  MultiSelect,
  SEODetailsform,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewPage,
  fetchPages,
  resetPagesState,
  setPageToEdit,
  updateExistingPage,
} from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  PAGES_CONSTANTS,
  PagesSchema,
  processWidgetData,
  ROLE_CONSTANTS,
  ScrollToTop,
  SEO_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { WidgetsContentDetails } from "../Manage Widgets";

const PagesForm = () => {
  const dispatch = useAppDispatch();

  const {
    ecommerce: {
      pages: { page, status: pageStatus },
    },
  } = useAppSelector((state) => state);

  const navigate = useNavigate();

  const { id } = useParams();

  const [sorting, setSorting] = useState(false);

  const initialValues = {
    page_name: "",
    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],

    widgets: [],

    meta_title: "",
    meta_description: "",
    meta_slug: "",
    meta_keywords: "",
  };

  const setEditvalues = (pagetoEdit) => {
    const formValue = {
      page_name: pagetoEdit?.page_name,
      is_active: [
        {
          value: pagetoEdit?.is_active,
          label: pagetoEdit?.is_active
            ? GENERAL_CONSTANTS.ACTIVE
            : GENERAL_CONSTANTS.INACTIVE,
        },
      ],

      widgets:
        pagetoEdit?.widgets && pagetoEdit?.widgets?.length > 0
          ? pagetoEdit?.widgets
              ?.map((widget) => {
                const widgetItem = {
                  ...widget,
                  properties: widget.content,
                  is_active: [
                    {
                      value: widget.is_active,
                      label: widget.is_active
                        ? GENERAL_CONSTANTS.ACTIVE
                        : GENERAL_CONSTANTS.INACTIVE,
                    },
                  ],
                };
                delete widgetItem["content"];
                return widgetItem;
              })
              .sort((a, b) => a.priority - b.priority)
          : [],
      meta_title: pagetoEdit?.meta_title,
      meta_description: pagetoEdit?.meta_description,
      meta_slug: pagetoEdit?.meta_slug,
      meta_keywords: pagetoEdit?.meta_keywords,
    };

    return JSON.parse(JSON.stringify(formValue));
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: Number(values?.is_active?.[0]?.value),
      widgets: processWidgetData(values?.widgets),
    };

    if (id) {
      dispatch(
        updateExistingPage({
          id: Number(id),
          pageParams: { id: Number(id), ...dataToSend },
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewPage({
          pageParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    ScrollToTop();
    if (id) {
      dispatch(
        fetchPages({
          id: Number(id),
        })
      );
    }

    return () => {
      dispatch(setPageToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${PAGES_CONSTANTS.ADD_HEADER}`}
          editValue={page?.page_name}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={!sorting}
            initialValues={id ? setEditvalues(page) : initialValues}
            validationSchema={PagesSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <>
                  <Form>
                    <ScrollToFieldError />
                    {pageStatus === STATUSES.LOADING && !props.isSubmitting && (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    )}
                    <div className="row">
                      <div className="col-8">
                        <div className="row">
                          <div className="col-12">
                            <Card>
                              <CardHeader>
                                <div className="d-flex justify-content-between">
                                  <h4 className="card-title">
                                    {PAGES_CONSTANTS.CARD_TITLE_1}
                                  </h4>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="row">
                                  <div className="col-md-6">
                                    <CustomInput
                                      type={FORM_CONSTANTS.TEXT}
                                      name={PAGES_CONSTANTS.NAME}
                                      label={PAGES_CONSTANTS.NAME_LABEL}
                                      placeholder={
                                        PAGES_CONSTANTS.NAME_PLACEHOLDER
                                      }
                                      onChangeHandler={(e) => {
                                        props.setFieldValue(
                                          PAGES_CONSTANTS.NAME,
                                          e.target.value
                                        );
                                        props.setFieldValue(
                                          SEO_CONSTANTS.TITLE,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={PAGES_CONSTANTS.NAME}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <MultiSelect
                                      select={false}
                                      name={PAGES_CONSTANTS.STATUS}
                                      label={PAGES_CONSTANTS.STATUS_LABEL}
                                      options={GENERAL_CONSTANTS.STATUS_OPTIONS}
                                    />
                                    <ErrorMessage
                                      name={PAGES_CONSTANTS.STATUS}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <WidgetsContentDetails
                              props={props}
                              setSorting={setSorting}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Button
                              loading={
                                pageStatus === STATUSES.LOADING &&
                                props.isSubmitting
                              }
                              type={BUTTON_CONSTANTS.BUTTON}
                              text={FORM_CONSTANTS.SUBMIT}
                              btnClassNames={
                                "btn btn-primary align-self-center w-25"
                              }
                              onClickHandler={() => {
                                props.submitForm();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <SEODetailsform compact={true} />
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { PagesForm };
