import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ConfirmModal,
  Container,
  ContentHeader,
  CustomInput,
  Loader,
  MultiSelect,
  SelectStatus,
} from "components";
import React, { useEffect, useRef, useState } from "react";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  MENU_CONSTANTS,
  MenuSchema,
  ROLE_CONSTANTS,
  ScrollToTop,
  STATUSES,
  TMenu,
  useAppDispatch,
  useAppSelector,
} from "utils";
import MenuList from "./MenuList";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { MenuItemForm } from "./MenuItemForm";
import { useNavigate, useParams } from "react-router-dom";
import { ScrollToFieldError } from "components/ScollToFieldError";
import {
  addNewMenu,
  deleteMenuItem,
  fetchMenus,
  resetMenuState,
  setMenuToEdit,
  updateExistingMenu,
} from "store/Ecommerce";

const MenuForm = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    menu: { menu: menuToEdit, status: menuStatus },
  } = useAppSelector((state) => state.ecommerce);

  const { id } = useParams();

  const [show, setShow] = useState(false);

  const [index, setIndex] = useState(0);

  const [childIndex, setChildIndex] = useState(0);

  const [parent, setParent] = useState(null);

  const [sorting, setSorting] = useState(false);

  const [menuItemToDelete, setMenuItemToDelete] = useState<{
    child: any;
    menuItemIndex: number;
    id: number;
  }>({
    child: {
      state: false,
      parent: null,
      parentIndex: null,
    },
    menuItemIndex: 0,
    id: 0,
  });

  const [confirmModal, setConfirmModal] = useState(false);

  const PropsRef = useRef<FormikProps<TMenu>>();

  const ArrayHelperRef = useRef<any>();

  const initialValues = {
    menu_title: "",
    menu_items: [],
    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],
  };

  const UpdatePropsAfterDelete = (id, menuItemIndex, child, props) => {
    dispatch(deleteMenuItem(id));
    const updatedMenuItems = [...props.values.menu_items];
    if (child.state) {
      const updatedChildren = child.parent.children.map((item, index) =>
        index === menuItemIndex
          ? {
              ...item,
              is_active: [
                {
                  value: 0,
                  label: GENERAL_CONSTANTS.INACTIVE,
                },
              ],
            }
          : item
      );
      updatedMenuItems[child.parentIndex].children = updatedChildren;
    } else {
      updatedMenuItems[menuItemIndex].is_active = [
        { value: 0, label: GENERAL_CONSTANTS.INACTIVE },
      ];
    }
    props.setFieldValue(MENU_CONSTANTS.MENU_ITEMS, updatedMenuItems);
  };

  const setEditValues = (menu) => {
    const formValue = {
      menu_title: menu?.menu_title,
      menu_items:
        menu && menu?.menu_items && menu?.menu_items.length > 0
          ? menu?.menu_items
              .map((item) => {
                return {
                  menu_type: item.is_mega_menu
                    ? [MENU_CONSTANTS.MENU_TYPE_OPTIONS[4]]
                    : [],
                  menu_item: [],
                  mega_menu: item?.mega_menu,
                  is_mega_menu: [
                    {
                      value: item?.is_mega_menu,
                      label: item?.is_mega_menu
                        ? GENERAL_CONSTANTS.YES
                        : GENERAL_CONSTANTS.NO,
                    },
                  ],
                  menu_item_title: item?.menu_item_title,
                  id: item?.id,
                  url: item?.url,
                  priority: item?.priority,
                  parent: item?.parent,
                  is_active: [
                    {
                      value: item?.is_active,
                      label: item?.is_active
                        ? GENERAL_CONSTANTS.ACTIVE
                        : GENERAL_CONSTANTS.INACTIVE,
                    },
                  ],
                  children:
                    item?.children && item?.children.length > 0
                      ? item?.children
                          .map((child) => {
                            return {
                              menu_type: [],
                              menu_item: [],
                              menu_item_title: child?.menu_item_title,
                              id: child?.id,
                              url: child?.url,
                              priority: child?.priority,
                              parent: child?.parent,
                              is_active: [
                                {
                                  value: child?.is_active,
                                  label: child?.is_active
                                    ? GENERAL_CONSTANTS.ACTIVE
                                    : GENERAL_CONSTANTS.INACTIVE,
                                },
                              ],
                            };
                          })
                          .sort((a, b) => a.priority - b.priority)
                      : [],
                };
              })
              .sort((a, b) => a.priority - b.priority)
          : [],
      is_active: [
        {
          value: menu?.is_active,
          label: menu?.is_active
            ? GENERAL_CONSTANTS.ACTIVE
            : GENERAL_CONSTANTS.INACTIVE,
        },
      ],
    };

    return JSON.parse(JSON.stringify(formValue));
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      menu_title: values.menu_title,
      is_active: values?.is_active?.[0]?.value,
      menu_items: values?.menu_items?.map((menuItem, menuItemIndex) => {
        const menuItemToAdd = {
          ...menuItem,
          menu: Number(id),
          menu_item_title: menuItem.menu_item_title,
          url: menuItem.url,
          priority: menuItemIndex + 1,
          is_active: Number(menuItem?.is_active?.[0]?.value),
          is_mega_menu: menuItem?.is_mega_menu?.[0]?.value || 0,
          mega_menu: menuItem?.is_mega_menu?.[0]?.value
            ? menuItem?.menu_item?.[0]?.value
            : null,
          children: menuItem?.children?.map((child, childIndex) => {
            const childToAdd = {
              ...child,
              is_mega_menu: 0,
              menu: Number(id),
              parent: menuItem?.id,
              menu_item_title: child.menu_item_title,
              url: child.url,
              priority: childIndex + 1,
              is_active: Number(child?.is_active?.[0]?.value),
            };
            delete childToAdd[MENU_CONSTANTS.MENU_TYPE];
            delete childToAdd[MENU_CONSTANTS.ITEM];
            delete childToAdd["children"];
            delete childToAdd["chosen"];
            delete childToAdd["selected"];

            return childToAdd;
          }),
        };
        delete menuItemToAdd[MENU_CONSTANTS.MENU_TYPE];
        delete menuItemToAdd[MENU_CONSTANTS.ITEM];
        delete menuItemToAdd["chosen"];
        delete menuItemToAdd["selected"];

        return menuItemToAdd;
      }),
    };

    if (id) {
      dispatch(
        updateExistingMenu({
          id: Number(id),
          menuParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewMenu({
          menuParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    ScrollToTop();
    id && dispatch(fetchMenus({ id: Number(id) }));
    return () => {
      dispatch(setMenuToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${MENU_CONSTANTS.ADD_HEADER}`}
          editValue={menuToEdit?.menu_title}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={!sorting}
            initialValues={id ? setEditValues(menuToEdit) : initialValues}
            validationSchema={MenuSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form>
                  <ScrollToFieldError />
                  <div className="row">
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {MENU_CONSTANTS.CARD_TITLE_1}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                type={FORM_CONSTANTS.TEXT}
                                name={MENU_CONSTANTS.TITLE}
                                label={MENU_CONSTANTS.TITLE_LABEL}
                                placeholder={MENU_CONSTANTS.TITLE_PLACEHOLDER}
                                value={props.values.menu_title}
                              />
                              <ErrorMessage
                                name={MENU_CONSTANTS.TITLE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <SelectStatus
                                props={props}
                                name={MENU_CONSTANTS.STATUS}
                                label={MENU_CONSTANTS.STATUS_LABEL}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                  <div className="row">
                    <MenuList
                      setIndex={setIndex}
                      setChildIndex={setChildIndex}
                      setParent={setParent}
                      setShow={setShow}
                      setSorting={setSorting}
                      props={props}
                      arrayHelperRef={ArrayHelperRef}
                      setMenuItemToDelete={setMenuItemToDelete}
                      setConfirmModal={setConfirmModal}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Button
                        loading={
                          menuStatus === STATUSES.LOADING && props.isSubmitting
                        }
                        btnClassNames="btn btn-primary w-100"
                        text={FORM_CONSTANTS.SUBMIT}
                        type={BUTTON_CONSTANTS.BUTTON}
                        onClickHandler={() => props.handleSubmit()}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {show && (
            <MenuItemForm
              sorting={sorting}
              index={index}
              childIndex={childIndex}
              parent={parent}
              setParent={setParent}
              show={show}
              setShow={setShow}
              props={PropsRef.current}
              arrayHelpers={ArrayHelperRef.current}
            />
          )}
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={MENU_CONSTANTS.DELETE_MENU}
        message={MENU_CONSTANTS.DELETE_MENU_MSG}
        confirmClick={() => {
          UpdatePropsAfterDelete(
            menuItemToDelete.id,
            menuItemToDelete.menuItemIndex,
            menuItemToDelete.child,
            PropsRef.current
          );
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { MenuForm };
