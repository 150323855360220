import React, { useEffect, useState } from "react";
import { Button } from "components/core-components";
import {
  ACCESS_CONSTANTS,
  EVENT_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { AddNewHoldBill, AddNewOrder } from "store/POS";
import { PayLaterModal } from "./PayLaterModal";
import { AdvanceOrderModal } from "./AdvanceOrderModal";
import { PrintOptionsModal } from "./PrintOptionsModal";

interface IProps {
  focusRef: React.MutableRefObject<any>;
  printOptionsModal: boolean;
  setPrintOptionsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExtraActions = ({
  focusRef,
  printOptionsModal,
  setPrintOptionsModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    cart: { cart, advance },
    common: { status: commonStatus, portrait },
    creditNote: { status: creditNoteStatus },
    customer: { customerToEdit: customer_details },
    holdBill: { status: holdBillStatus },
    order: { status: orderStatus },
  } = useAppSelector((state) => state.root);

  const returnPermissions = permissionData.find(
    (p) => p.module_name === "Manage Returns/Refunds"
  )?.perm_add;

  const orderInProgress =
    holdBillStatus === STATUSES.LOADING ||
    orderStatus === STATUSES.LOADING ||
    creditNoteStatus === STATUSES.LOADING ||
    commonStatus.type === POS_CART_CONSTANTS.WHATSAPP_BILL_LOADING_STATE ||
    commonStatus.type === POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE;

  const [advanceOrder, setAdvanceOrder] = useState<boolean>(
    GENERAL_CONSTANTS.FALSE
  );

  const [payLaterModal, setPayLaterModal] = useState<boolean>(
    GENERAL_CONSTANTS.FALSE
  );

  const cartItemsInCart = cart.cartItems.length > 0;

  const handlePrintBill = (whatsApp = false) => {
    // setFocus(GENERAL_CONSTANTS.TRUE)
    dispatch(
      AddNewOrder({
        focusRef,
        setPrintOptionsModal,
        whatsApp,
      })
    );
  };

  const handleHoldBill = () => {
    dispatch(AddNewHoldBill(focusRef));
  };

  // Shortcut keys
  function handleShortcut(e) {
    switch (e.key) {
      case POS_CART_CONSTANTS.HOLD_BILL_SHORTCUT:
        // If cart is not empty and hold bill status is not loading
        if (cartItemsInCart && !orderInProgress) {
          handleHoldBill();
        } else if (!cartItemsInCart && holdBillStatus === STATUSES.IDLE) {
          Toast(
            POS_CART_CONSTANTS.ADD_ITEMS_TO_CART_MESSAGE,
            TOAST_CONSTANTS.ERROR
          );
        }
        break;

      case POS_CART_CONSTANTS.PRINT_BILL_SHORTCUT:
        // If cart is not empty and order status is not loading
        if (cartItemsInCart && !orderInProgress) {
          handlePrintBill();
        } else if (!cartItemsInCart && orderStatus === STATUSES.IDLE) {
          Toast(
            POS_CART_CONSTANTS.ADD_ITEMS_TO_CART_MESSAGE,
            TOAST_CONSTANTS.ERROR
          );
        }
        break;

      case POS_CART_CONSTANTS.PAY_LATER_SHORTCUT:
        // If cart is not empty
        if (!cartItemsInCart) {
          Toast(
            POS_CART_CONSTANTS.ADD_ITEMS_TO_CART_MESSAGE,
            TOAST_CONSTANTS.ERROR
          );
        } else if (customer_details?.id === ACCESS_CONSTANTS.CASH_SALES_ID) {
          // If customer is not selected OR Not Cash Sales
          Toast(
            POS_CART_CONSTANTS.SELECT_CUSTOMER_MESSAGE,
            TOAST_CONSTANTS.ERROR
          );
        } else if (orderStatus !== STATUSES.LOADING) {
          setPayLaterModal(GENERAL_CONSTANTS.TRUE);
        }

        break;
    }
  }

  // Ignore keys
  function ignoreKeys(e) {
    switch (e.key) {
      case POS_CART_CONSTANTS.HOLD_BILL_SHORTCUT:
        if (cartItemsInCart && !orderInProgress) {
          e.preventDefault();
        }
        break;
      case POS_CART_CONSTANTS.PRINT_BILL_SHORTCUT:
        if (cartItemsInCart && !orderInProgress) {
          e.preventDefault();
        }
        break;
      case POS_CART_CONSTANTS.PAY_LATER_SHORTCUT && !orderInProgress:
        e.preventDefault();

        break;
    }
  }

  const isMobile = window.mobileAndTabletCheck();

  useEffect(() => {
    document.addEventListener(EVENT_CONSTANTS.KEYUP, handleShortcut);
    document.addEventListener(EVENT_CONSTANTS.KEYDOWN, ignoreKeys);

    return () => {
      document.removeEventListener(EVENT_CONSTANTS.KEYUP, handleShortcut);
      document.removeEventListener(EVENT_CONSTANTS.KEYDOWN, ignoreKeys);
    };
  });

  return (
    <div className="col-12 container-fluid">
      <div className="row">
        {/* Hold Bill */}
        <div
          className={`${
            isMobile && portrait ? "col-6" : "col"
          } text-sm text-center p-1`}
        >
          <Button
            isDisabled={!cartItemsInCart || advance || orderInProgress}
            loading={holdBillStatus === STATUSES.LOADING}
            btnClassNames="btn btn-dark btn-primary text-sm w-100"
            text={
              isMobile
                ? POS_CART_CONSTANTS.HOLD_BILL_MOBILE
                : POS_CART_CONSTANTS.HOLD_BILL_LABEL
            }
            onClickHandler={handleHoldBill}
          />
        </div>
        {/* Advance Order */}
        {returnPermissions ? (
          <div
            className={`${
              isMobile && portrait ? "col-6" : "col"
            } text-sm text-center p-1`}
          >
            <Button
              btnClassNames="btn btn-dark btn-primary text-sm w-100"
              text={POS_CART_CONSTANTS.ADVANCE_ORDER_LABEL}
              isDisabled={
                !cartItemsInCart ||
                customer_details?.id === ACCESS_CONSTANTS.CASH_SALES_ID ||
                advance ||
                orderInProgress
              }
              onClickHandler={() => {
                setAdvanceOrder(GENERAL_CONSTANTS.TRUE);
              }}
            />
          </div>
        ) : null}
        {advanceOrder && returnPermissions && (
          <AdvanceOrderModal
            modal={advanceOrder}
            setModal={setAdvanceOrder}
            focusRef={focusRef}
          />
        )}
        {/* Pay Later  */}
        <div
          className={`${
            isMobile && portrait ? "col-6" : "col"
          } text-sm text-center p-1`}
        >
          <Button
            btnClassNames="btn btn-dark btn-primary text-sm w-100"
            text={
              isMobile
                ? POS_CART_CONSTANTS.PAY_LATER_MOBILE
                : POS_CART_CONSTANTS.PAY_LATER_LABEL
            }
            isDisabled={
              !cartItemsInCart ||
              customer_details?.id === ACCESS_CONSTANTS.CASH_SALES_ID ||
              advance ||
              orderInProgress
            }
            onClickHandler={() => {
              setPayLaterModal(GENERAL_CONSTANTS.TRUE);
            }}
          />
        </div>
        {payLaterModal && (
          <PayLaterModal
            modal={payLaterModal}
            setModal={setPayLaterModal}
            focusRef={focusRef}
          />
        )}
        <div
          className={`${
            isMobile && portrait ? "col-6" : "col"
          } text-sm text-center p-1`}
        >
          <Button
            isDisabled={!cartItemsInCart || orderInProgress}
            loading={
              commonStatus.type ===
              POS_CART_CONSTANTS.WHATSAPP_BILL_LOADING_STATE
            }
            btnClassNames="btn btn-dark btn-primary text-sm w-100"
            text={POS_CART_CONSTANTS.PRINT_WHATSAPP_BILL_LABEL}
            onClickHandler={() => {
              handlePrintBill(true);
            }}
          />
        </div>
        {/* Save & Print */}
        {!isMobile && (
          <div
            className={`${
              isMobile && portrait ? "col-6" : "col"
            } text-sm text-center p-1`}
          >
            <Button
              isDisabled={!cartItemsInCart || orderInProgress}
              loading={
                commonStatus.type ===
                POS_CART_CONSTANTS.PRINT_BILL_LOADING_STATE
              }
              btnClassNames="btn btn-dark btn-primary text-sm w-100"
              text={
                isMobile
                  ? POS_CART_CONSTANTS.PRINT_BILL_MOBILE
                  : POS_CART_CONSTANTS.PRINT_BILL_LABEL
              }
              onClickHandler={() => {
                handlePrintBill(false);
              }}
            />
          </div>
        )}
      </div>
      {/* If balance credit note is genrated opens a modal for selecting which doc to print */}
      <PrintOptionsModal
        modal={printOptionsModal}
        setModal={setPrintOptionsModal}
      />
    </div>
  );
};

export { ExtraActions };
