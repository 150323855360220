import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, FieldArray, ErrorMessage } from "formik";

import { getModules } from "store/ManageRoles/moduleSlice";
import {
  addNewRole,
  fetchRoles,
  updateExistingRole,
} from "store/ManageRoles/roleSlice";

import {
  useAppDispatch,
  useAppSelector,
  RoleSchema,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  ROLE_CONSTANTS,
  STATUSES,
  GENERAL_CONSTANTS,
  ScrollToTop,
  flattenArray,
} from "utils";

import {
  ContentHeader,
  CustomInput,
  CustomCheckbox,
  Button,
  Card,
  Table,
  TableBody,
  CardHeader,
  CardBody,
  Container,
  MultiSelect,
  Loader,
} from "components";

import { RoleHeader } from "./RoleHeader";
import { ScrollToFieldError } from "components/ScollToFieldError";

const RolesForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    module: { modules: moduleData, status: moduleStatus },
    role: { roleToEdit, status: roleStatus },
  } = useAppSelector((state) => state.root);

  const { id: roleId } = useParams();

  const initialPermissions =
    moduleData &&
    moduleData.length > 0 &&
    moduleData.map((module) => {
      if (module.sub_modules.length > 0) {
        const subModulePermissions = module.sub_modules.map((subModule) => {
          return {
            actions: [
              subModule.action_view,
              subModule.action_add,
              subModule.action_update,
              subModule.action_delete,
              subModule.action_authorize,
            ],
            module_id: module.id,
            module: subModule.id,
            module_name: subModule.sub_module_name,
            perm_view: false,
            perm_add: false,
            perm_edit: false,
            perm_delete: false,
            perm_authorize: false,
            isSubModule: true,
            bulk_row: "",
          };
        });
        return [
          {
            actions: [
              module.action_view,
              module.action_add,
              module.action_update,
              module.action_delete,
              module.action_authorize,
            ],
            module_id: module.id,
            module: module.id,
            module_name: module.module_name,
            sub_modules: module.sub_modules,
            perm_view: false,
            perm_add: false,
            perm_edit: false,
            perm_delete: false,
            perm_authorize: false,
            isSubModule: false,
            bulk_row: "",
          },
          ...subModulePermissions,
        ];
      } else {
        return {
          actions: [
            module.action_view,
            module.action_add,
            module.action_update,
            module.action_delete,
            module.action_authorize,
          ],
          module_id: module.id,
          module: module.id,
          module_name: module.module_name,
          sub_modules: module.sub_modules,
          perm_view: false,
          perm_add: false,
          perm_edit: false,
          perm_delete: false,
          perm_authorize: false,
          isSubModule: false,
          bulk_row: "",
        };
      }
    });

  const fetchEditPermissions = (moduleData, roleToEdit) => {
    const result = [];
    for (let i = 0; i < moduleData.length; i++) {
      if (moduleData[i].sub_modules.length > 0) {
        const subModulePermissions = moduleData[i].sub_modules.map(
          (subModule) => {
            const perm = roleToEdit?.permissions?.find(
              (item) => item?.sub_module?.id === subModule.id
            );
            return {
              ...perm,
              actions: [
                subModule.action_view,
                subModule.action_add,
                subModule.action_update,
                subModule.action_delete,
                subModule.action_authorize,
              ],
              module_id: subModule.module,
              isSubModule: true,
              module: subModule.id,
              module_name: subModule.sub_module_name,
              perm_view: perm?.perm_view ? true : false,
              perm_add: perm?.perm_add ? true : false,
              perm_edit: perm?.perm_edit ? true : false,
              perm_delete: perm?.perm_delete ? true : false,
              perm_authorize: perm?.perm_authorize ? true : false,
            };
          }
        );
        const perm = roleToEdit?.permissions?.find(
          (item) => item?.module?.id === moduleData[i].id
        );
        result.push({
          ...perm,
          actions: [
            moduleData[i].action_view,
            moduleData[i].action_add,
            moduleData[i].action_update,
            moduleData[i].action_delete,
            moduleData[i].action_authorize,
          ],
          isSubModule: false,
          module: moduleData[i].id,
          module_name: moduleData[i].module_name,
          module_id: moduleData[i].id,
          perm_view: perm?.perm_view ? true : false,
          perm_add: perm?.perm_add ? true : false,
          perm_edit: perm?.perm_edit ? true : false,
          perm_delete: perm?.perm_delete ? true : false,
          perm_authorize: perm?.perm_authorize ? true : false,
          bulk_row: "",
        });
        result.push(...subModulePermissions);
      } else {
        const perm = roleToEdit?.permissions?.find(
          (item) => item?.module?.id === moduleData[i].id
        );
        result.push({
          ...perm,
          actions: [
            moduleData[i].action_view,
            moduleData[i].action_add,
            moduleData[i].action_update,
            moduleData[i].action_delete,
            moduleData[i].action_authorize,
          ],
          module_id: moduleData[i].id,
          module: moduleData[i].id,
          module_name: moduleData[i].module_name,
          perm_view: perm?.perm_view ? true : false,
          perm_add: perm?.perm_add ? true : false,
          perm_edit: perm?.perm_edit ? true : false,
          perm_delete: perm?.perm_delete ? true : false,
          perm_authorize: perm?.perm_authorize ? true : false,
        });
      }
    }
    return result;
  };

  const deletion = [];

  const checkBulkrow = (props, index, e) => {
    props.setFieldValue(
      `${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.VIEW}`,
      e.target.checked
    );
    props.setFieldValue(
      `${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.ADD}`,
      e.target.checked
    );
    props.setFieldValue(
      `${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.EDIT}`,
      e.target.checked
    );
    props.setFieldValue(
      `${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.DELETE}`,
      e.target.checked
    );
    props.setFieldValue(
      `${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.AUTHORIZE}`,
      e.target.checked
    );
  };

  const checkBulkColumn = (props, column, e) => {
    props.values.permissions.map((_, index) => {
      props.setFieldValue(
        `${ROLE_CONSTANTS.PERMISSIONS}[${index}].${column}`,
        e.target.checked
      );
    });
  };

  const setInitialValues = () => {
    return {
      role_name: "",
      permissions: flattenArray(initialPermissions),
      is_active: [
        {
          value: 1,
          label: GENERAL_CONSTANTS.ACTIVE,
        },
      ],
      bulk_view: "",
      bulk_add: "",
      bulk_edit: "",
      bulk_delete: "",
      bulk_authorize: "",
    };
  };

  const setEditValues = (roleToEdit, editPermissions) => {
    return {
      role_name: roleToEdit?.role_name,
      permissions: editPermissions.sort((a, b) => a.module_id - b.module_id),
      is_active: [
        {
          value: roleToEdit?.is_active,
          label: roleToEdit?.is_active === 1 ? "Active" : "Inactive",
        },
      ],
      bulk_view: "",
      bulk_add: "",
      bulk_edit: "",
      bulk_delete: "",
      bulk_authorize: "",
    };
  };

  const handleDelete = (values) => {
    let updatedvalues = values;
    delete updatedvalues.bulk_view;
    delete updatedvalues.bulk_add;
    delete updatedvalues.bulk_edit;
    delete updatedvalues.bulk_delete;
    delete updatedvalues.bulk_authorize;
    delete updatedvalues.bulk_row;
    values.permissions.map((permission, index) => {
      if (
        roleId &&
        permission.id &&
        permission.perm_view === false &&
        permission.perm_add === false &&
        permission.perm_edit === false &&
        permission.perm_delete === false &&
        permission.perm_authorize === false
      ) {
        deletion.push(permission.id);
      }
    });
    updatedvalues = values.permissions.filter(
      (permission) =>
        permission.perm_view === true ||
        permission.perm_add === true ||
        permission.perm_edit === true ||
        permission.perm_delete === true ||
        permission.perm_authorize === true
    );

    return updatedvalues;
  };

  const handleSubmit = async (values, actions) => {
    const modifiedPermissions = handleDelete(values);

    const dataToSend = {
      ...values,
      id: Number(roleId),
      is_active: Number(values.is_active[0]?.value),
      permissions: modifiedPermissions.map((permission) => {
        const permissionToSend = {
          // ...permission,
          module: permission.isSubModule ? null : permission.module,
          sub_module: permission.isSubModule ? permission.module : null,
          perm_view: permission.actions[0] && permission.perm_view ? 1 : 0,
          perm_add: permission.actions[1] && permission.perm_add ? 1 : 0,
          perm_edit: permission.actions[2] && permission.perm_edit ? 1 : 0,
          perm_delete: permission.actions[3] && permission.perm_delete ? 1 : 0,
          perm_authorize:
            permission.actions[4] && permission.perm_authorize ? 1 : 0,
        };
        if (roleId) {
          permissionToSend["id"] = permission.id;
          permissionToSend["role"] = Number(roleId);
        }
        return permissionToSend;
      }),
      deletion: deletion,
    };

    if (roleId) {
      dispatch(
        updateExistingRole({
          id: Number(roleId),
          roleParams: dataToSend,
          actions,
          navigate,
        })
      );
    } else {
      dispatch(
        addNewRole({
          roleParams: dataToSend,
          actions,
          navigate,
        })
      );
    }
  };

  const getFormData = async () => {
    const data = await Promise.all([
      dispatch(getModules()),
      roleId && dispatch(fetchRoles({ id: Number(roleId) })),
    ]);
  };

  useEffect(() => {
    ScrollToTop();
    getFormData();
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          editValue={roleToEdit?.role_name}
          pageHeader={
            roleId ? ROLE_CONSTANTS.EDIT_HEADER : ROLE_CONSTANTS.ADD_HEADER
          }
        />
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={
              roleId
                ? setEditValues(
                    roleToEdit,
                    fetchEditPermissions(moduleData, roleToEdit)
                  )
                : setInitialValues()
            }
            onSubmit={handleSubmit}
            validationSchema={RoleSchema}
          >
            {(props) => {
              return (
                <Form>
                  <ScrollToFieldError />
                  <div className="row">
                    {/* Role Details:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                isRequired={true}
                                label={ROLE_CONSTANTS.ROLE_NAME_LABEL}
                                type={FORM_CONSTANTS.TEXT}
                                name={ROLE_CONSTANTS.ROLE_NAME}
                                placeholder={
                                  ROLE_CONSTANTS.ROLE_NAME_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={ROLE_CONSTANTS.ROLE_NAME}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <MultiSelect
                                isRequired={true}
                                label={ROLE_CONSTANTS.ROLE_STATUS_LABEL}
                                name={ROLE_CONSTANTS.ROLE_STATUS}
                                options={ROLE_CONSTANTS.ROLE_STATUS_OPTIONS}
                              />
                              <ErrorMessage
                                name={ROLE_CONSTANTS.ROLE_STATUS}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Role Permissions:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title mt-2">
                            {ROLE_CONSTANTS.PERMISSIONS_OPTIONS_LABEL}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <Table>
                            <RoleHeader
                              checkBulkColumn={checkBulkColumn}
                              props={props}
                            />
                            <TableBody>
                              {moduleStatus === STATUSES.LOADING ? (
                                <tr>
                                  <td colSpan={7} className="text-center">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                <FieldArray
                                  name={ROLE_CONSTANTS.PERMISSIONS}
                                  render={({ push, remove }) => {
                                    return (
                                      props.values.permissions &&
                                      props.values.permissions.length > 0 &&
                                      props.values.permissions?.map(
                                        (permission, index) => (
                                          <>
                                            <tr
                                              key={index}
                                              className={`my-2 ${
                                                permission.isSubModule ? "" : ""
                                              }`}
                                            >
                                              <td>
                                                <CustomCheckbox
                                                  type={FORM_CONSTANTS.CHECKBOX}
                                                  name={`${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.BULK_ROW}`}
                                                  // checked={false}
                                                  onClickHandler={(e) => {
                                                    checkBulkrow(
                                                      props,
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />
                                              </td>
                                              <td
                                                className={`d-flex flex-row ${
                                                  permission.isSubModule
                                                    ? "ml-4"
                                                    : ""
                                                }`}
                                              >
                                                {permission.module_name}
                                              </td>
                                              <td className="align-middle">
                                                {permission?.actions?.[0] ? (
                                                  <CustomCheckbox
                                                    type={
                                                      FORM_CONSTANTS.CHECKBOX
                                                    }
                                                    name={`${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.VIEW}`}
                                                    checked={
                                                      permission.perm_view
                                                    }
                                                  />
                                                ) : (
                                                  GENERAL_CONSTANTS.NA
                                                )}
                                              </td>
                                              <td className="align-middle">
                                                {permission?.actions?.[1] ? (
                                                  <CustomCheckbox
                                                    type={
                                                      FORM_CONSTANTS.CHECKBOX
                                                    }
                                                    name={`${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.ADD}`}
                                                    checked={
                                                      permission.perm_add
                                                    }
                                                  />
                                                ) : (
                                                  GENERAL_CONSTANTS.NA
                                                )}
                                              </td>
                                              <td className="align-middle">
                                                {permission?.actions?.[2] ? (
                                                  <CustomCheckbox
                                                    type={
                                                      FORM_CONSTANTS.CHECKBOX
                                                    }
                                                    name={`${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.EDIT}`}
                                                    checked={
                                                      permission.perm_edit
                                                    }
                                                  />
                                                ) : (
                                                  GENERAL_CONSTANTS.NA
                                                )}
                                              </td>
                                              <td className="align-middle">
                                                {permission?.actions?.[3] ? (
                                                  <CustomCheckbox
                                                    type={
                                                      FORM_CONSTANTS.CHECKBOX
                                                    }
                                                    name={`${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.DELETE}`}
                                                    checked={
                                                      permission.perm_delete
                                                    }
                                                  />
                                                ) : (
                                                  GENERAL_CONSTANTS.NA
                                                )}
                                              </td>
                                              <td className="align-middle">
                                                {permission?.actions?.[4] ? (
                                                  <CustomCheckbox
                                                    type={
                                                      FORM_CONSTANTS.CHECKBOX
                                                    }
                                                    name={`${ROLE_CONSTANTS.PERMISSIONS}[${index}].${ROLE_CONSTANTS.AUTHORIZE}`}
                                                    checked={
                                                      permission.perm_authorize
                                                    }
                                                  />
                                                ) : (
                                                  GENERAL_CONSTANTS.NA
                                                )}
                                              </td>
                                            </tr>
                                            {/* {permission.sub_modules &&
                                              permission.sub_modules.length >
                                                0 &&
                                              permission.sub_modules.map(
                                                (subModule, subModuleIndex) => (
                                                  <tr key={subModuleIndex}>
                                                    <td></td>
                                                    <td>
                                                      {
                                                        subModule.sub_module_name
                                                      }
                                                    </td>
                                                  </tr>
                                                )
                                              )} */}
                                          </>
                                        )
                                      )
                                    );
                                  }}
                                />
                              )}
                            </TableBody>
                          </Table>
                          <ErrorMessage
                            name={ROLE_CONSTANTS.PERMISSIONS}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    {/* Submit */}
                    <div className="col-md-4 ">
                      <Button
                        loading={roleStatus === STATUSES.LOADING}
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary "}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { RolesForm };
