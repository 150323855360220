import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delVendorAddress,
  getCities,
  getCountries,
  getStates,
} from "apis/restApis";

interface City {
  id: number;
  city: string;
  state: number;
  is_active: boolean;
}

interface State {
  id: number;
  state: string;
  country: number;
  is_active: boolean;
}

interface Country {
  id: number;
  country: string;
  is_active: boolean;
}

const initialState = {
  cities: [] as City[],
  states: [] as State[],
  countries: [] as Country[],
  status: STATUSES.IDLE as string,
  error: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    fetchCity: (state, action) => {
      state.cities = action.payload;
    },
    fetchState: (state, action) => {
      state.states = action.payload;
    },
    fetchCountry: (state, action) => {
      state.countries = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchCity,
  fetchState,
  fetchCountry,
  setStatus,
  setError,
} = locationSlice.actions;

export default locationSlice.reducer;

export function getCountry() {
  return async function getCountryThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    getCountries()
      .then((response: any) => {
        dispatch(fetchCountry(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function getState(CountryId: number | string = "") {
  return async function getStateThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    getStates(CountryId)
      .then((response: any) => {
        // const state = response.result.filter(
        //   (state: State) => state.country * 1 === CountryId
        // );
        // dispatch(fetchState(state));
        dispatch(fetchState(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function getCity(stateId: number | string = "") {
  return async function getCityThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    getCities(stateId)
      .then((response: any) => {
        // const city = response.result.filter(
        //   (city: City) => city.state * 1 === stateId
        // );
        // dispatch(fetchCity(city));
        dispatch(fetchCity(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteVendorAddress(id: number) {
  return async function deleteVendorAddressThunk(dispatch, getState) {
    delVendorAddress(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
