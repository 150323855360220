import React from "react";
import { Button, FontIcon } from "./core-components";
import { displayValue } from "utils";

const EmptyFormMessage = ({
  icon,
  iconSize,
  emptyMessage,
  actions,
  disabled,
  disabledMessage,
}) => {
  return (
    <React.Fragment key={emptyMessage}>
      <div className="col-12 d-flex flex-row justify-content-center align-items-center py-3">
        <h2
          className={`headline fw-bold mr-2 text-${
            disabled ? "secondary" : "primary"
          }`}
        >
          <FontIcon icon={icon} iconSize={iconSize} />
        </h2>
        <Button
          text={emptyMessage}
          isDisabled={disabled}
          btnClassNames={`btn btn-link text-md text${
            disabled ? "-secondary" : "-primary"
          }`}
          type="button"
          onClickHandler={actions}
        />
      </div>
      {disabled && (
        <div className="row text-center">
          <p className="text-muted">{disabledMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export { EmptyFormMessage };
