import { createSlice } from "@reduxjs/toolkit";
import {
  delUOM,
  getActiveUOM,
  getSpecificUOM,
  getUOM,
  setUOM,
  updateUOM,
} from "apis/restApis";
import { PaginationState, Toast, UOM } from "utils";
import { STATUSES } from "utils/constants";
import { updateValues } from "./commonSlice";
import { FormikHelpers } from "formik";

const initialState = {
  uom: [] as UOM[],
  status: STATUSES.IDLE as string,
  error: null,
};

const uomSlice = createSlice({
  name: "uom",
  initialState,
  reducers: {
    getAllUOMs(state, action) {
      state.uom = action.payload;
    },
    addUOM(state, action) {
      state.uom.unshift(action.payload);
    },
    removeUOM(state, action) {
      state.uom = state.uom.filter((uom) => uom.id !== action.payload);
    },
    editUOM(state, action) {
      const { id } = action.payload;
      const index = state.uom.findIndex((uom) => uom.id === id);
      if (index !== -1) {
        state.uom[index] = action.payload;
      }
    },

    setUOMStatus(state, action) {
      state.status = action.payload;
    },
    setUOMError(state, action) {
      state.error = action.payload;
    },
    resetUOMState: (state) => {
      state.uom = [] as UOM[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllUOMs,
  addUOM,
  removeUOM,
  editUOM,

  setUOMStatus,
  setUOMError,
  resetUOMState,
} = uomSlice.actions;

export default uomSlice.reducer;

export function getUOMs(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getUOMsThunk(dispatch, getState) {
    active
      ? getActiveUOM()
          .then((response: any) => {
            dispatch(setUOMStatus(STATUSES.IDLE));
            dispatch(getAllUOMs(response.result));
          })
          .catch((error) => {
            dispatch(setUOMError(error.message));
            dispatch(setUOMStatus(STATUSES.ERROR));
          })
      : getUOM(pageNo, query)
          .then((response: any) => {
            dispatch(setUOMStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllUOMs(response.result.results));
          })
          .catch((error) => {
            dispatch(setUOMError(error.message));
            dispatch(setUOMStatus(STATUSES.ERROR));
          });
  };
}

export function getIndividualUOM(id: number) {
  return async function getIndividualUOMThunk(dispatch, getState) {
    dispatch(setUOMStatus(STATUSES.LOADING));
    getSpecificUOM(id)
      .then((response: any) => {
        dispatch(setUOMStatus(STATUSES.IDLE));
        return response.result;
      })
      .catch((error) => {
        dispatch(setUOMError(error.message));
        dispatch(setUOMStatus(STATUSES.ERROR));
      });
  };
}

export function addNewUOM(
  uomParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function addNewUOMThunk(dispatch, getState) {
    dispatch(setUOMStatus(STATUSES.LOADING));
    setUOM(uomParams)
      .then((response: any) => {
        dispatch(addUOM(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setUOMStatus(STATUSES.ERROR));
        dispatch(setUOMError(error.message));
      })
      .finally(() => {
        dispatch(setUOMStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingUOM(
  id: number,
  uomParams: Object,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function updateExistingUOMThunk(dispatch, getState) {
    dispatch(setUOMStatus(STATUSES.LOADING));
    updateUOM(id, uomParams)
      .then((response: any) => {
        dispatch(editUOM(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setUOMStatus(STATUSES.ERROR));
        dispatch(setUOMError(error.message));
      })
      .finally(() => {
        dispatch(setUOMStatus(STATUSES.IDLE));
      });
  };
}

export function deleteUOM(id: number) {
  return async function deleteUOMThunk(dispatch, getState) {
    dispatch(setUOMStatus(STATUSES.LOADING));
    delUOM(id)
      .then((response: any) => {
        dispatch(editUOM(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setUOMError(error.message));
        dispatch(setUOMStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setUOMStatus(STATUSES.IDLE));
      });
  };
}
