import React from "react";
import { TButton } from "utils";

const Button = ({
  testId,
  text,
  type,
  btnClassNames,
  isDisabled,
  loading,
  onClickHandler,
  onBlurHandler,
  onKeyDownHandler,
  ...props
}: TButton) => {
  return (
    <button
      data-testid={testId}
      type={type}
      onClick={onClickHandler}
      disabled={isDisabled}
      className={`${btnClassNames} rounded-lg ${loading ? "disabled" : ""}`}
      onBlur={onBlurHandler}
      onKeyDown={onKeyDownHandler}
      {...props}
    >
      {loading && (
        <span
          className="spinner-border spinner-border-sm mr-2"
          aria-hidden="true"
        />
      )}
      <span role="status">{loading ? `${text}...` : text}</span>
    </button>
  );
};

export { Button };
