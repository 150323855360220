import React, { memo } from "react";

import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
} from "components";
import { ErrorMessage, FieldArray } from "formik";

import { deleteVendorContact } from "store/ManageVendors/vendorSlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  VENDORS_CONSTANTS,
  useAppDispatch,
} from "utils";
import { useParams } from "react-router-dom";

const VendorAdditionalPhoneNumber = ({ props, DeletedValues }) => {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const currentId = Number(id);

  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h3 className="card-title">
            {VENDORS_CONSTANTS.PHONE_NUMBER_HEADER}
          </h3>
        </CardHeader>
        <CardBody>
          <div className="row">
            <FieldArray
              name={VENDORS_CONSTANTS.VENDOR_PHONE_NUMBER}
              render={({ push, remove }) =>
                props.values?.phone_numbers?.map((phone_number, index) => (
                  <div className="col-6" key={index}>
                    <div className="d-flex flex-row align-items-center">
                      <div className="col-10">
                        <CustomInput
                          label={`${
                            VENDORS_CONSTANTS.VENDOR_PHONE_NUMBER_LABEL
                          } ${index + 1}`}
                          placeholder={
                            VENDORS_CONSTANTS.VENDOR_PHONE_NUMBER_PLACEHOLDER
                          }
                          name={`${VENDORS_CONSTANTS.VENDOR_PHONE_NUMBER}.${index}.${VENDORS_CONSTANTS.PHONE_NUMBER}`}
                          type={FORM_CONSTANTS.NUMBER}
                        />
                        <ErrorMessage
                          name={`${VENDORS_CONSTANTS.VENDOR_PHONE_NUMBER}.${index}.${VENDORS_CONSTANTS.PHONE_NUMBER}`}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </div>
                      <div className="col-2 mt-3">
                        <Button
                          text={<FontIcon icon={faTrash} />}
                          type={BUTTON_CONSTANTS.BUTTON}
                          isDisabled={
                            index === 0 &&
                            props.values.phone_numbers &&
                            props.values.phone_numbers.length === 1
                          }
                          btnClassNames={"btn btn-danger mr-1"}
                          onClickHandler={() => {
                            remove(index);
                            if (
                              currentId &&
                              !props.values.phone_numbers[index].new
                            ) {
                              dispatch(
                                deleteVendorContact(
                                  props.values.phone_numbers[index]?.id
                                )
                              );
                              DeletedValues.push(
                                props.values.phone_numbers[index]?.id
                              );
                            }
                          }}
                        />
                        {props.values?.phone_numbers?.length - 1 === index ? (
                          <Button
                            text={<FontIcon icon={faAdd} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            btnClassNames={"btn btn-outline-primary"}
                            onClickHandler={() =>
                              push({
                                phone_number: "",
                                new: true,
                              })
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              }
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VendorAdditionalPhoneNumber);
