import { createSlice } from "@reduxjs/toolkit";
import { delHoldBill, getHoldBill, setHoldBill } from "apis/restApis";
import { updateValues } from "store/commonSlice";
import { AppDispatch, RootState } from "store/store";

import {
  IBillItem,
  ICartItem,
  STATUSES,
  Toast,
  calculateAllValues,
  to2Decimal,
} from "utils";
import { ResetWholeCart, setCartRemarks } from "./cartSlice";
import store from "../store";

const initialState = {
  holdBills: [] as IBillItem[],
  status: STATUSES.IDLE as string,
  error: null,
};

const HoldBillSlice = createSlice({
  name: "holdBill",
  initialState,
  reducers: {
    getHoldBIll: (state, action) => {
      state.holdBills = action.payload;
    },
    addHoldBill: (state, action) => {
      state.holdBills.push(action.payload);
    },
    appendHoldBill: (state, action) => {
      state.holdBills = [...state.holdBills, ...action.payload];
    },
    removeHoldBill: (state, action) => {
      state.holdBills.splice(action.payload, 1);
    },

    setHoldBillStatus: (state, action) => {
      state.status = action.payload;
    },
    setHoldBillError: (state, action) => {
      state.error = action.payload;
    },
    resetHoldBillState: (state) => {
      state.holdBills = [] as IBillItem[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getHoldBIll,
  addHoldBill,
  appendHoldBill,
  removeHoldBill,

  setHoldBillStatus,
  setHoldBillError,
  resetHoldBillState,
} = HoldBillSlice.actions;

export default HoldBillSlice.reducer;

export function FetchAllHoldBills({
  pageNo,
  query,
  store,
}: {
  pageNo: number;
  query: string;
  store: number;
}) {
  return async function FetchAllHoldBillsThunk(dispatch: AppDispatch) {
    dispatch(setHoldBillStatus(STATUSES.LOADING));
    getHoldBill(pageNo, query, store)
      .then((response: any) => {
        dispatch(updateValues(response));
        dispatch(getHoldBIll(response.result.results));
      })
      .catch((error: any) => {
        dispatch(setHoldBillError(error.message));
        dispatch(setHoldBillStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setHoldBillStatus(STATUSES.IDLE));
      });
  };
}

// export function FetchHoldBillOnScroll(
//   pageNo: number,
//   query: string = "",
//   setPage: React.Dispatch<React.SetStateAction<number>>
// ) {
//   return async function FetchHoldBillOnScrollThunk(dispatch: AppDispatch) {
//     dispatch(setHoldBillStatus(STATUSES.LOADING));

//     getHoldBill(pageNo, query)
//       .then((response: any) => {
//         dispatch(updateValues(response));
//         dispatch(appendHoldBill(response.result.results));
//       })
//       .catch((error: any) => {
//         dispatch(setHoldBillError(error.message));
//         dispatch(setHoldBillStatus(STATUSES.ERROR));
//       })
//       .finally(() => {
//         dispatch(setHoldBillStatus(STATUSES.IDLE));
//       });
//   };
// }

export function AddNewHoldBill(focusRef?: React.MutableRefObject<any>) {
  return async function HoldBillThunk(dispatch: AppDispatch, getState: any) {
    dispatch(setHoldBillStatus(STATUSES.LOADING));
    const {
      root: {
        branch: { selectedStore },
        cart: { cart },
        customer: { customerToEdit: customer_details },
      },
    }: RootState = getState();

    const { bill_total, total_tax, final_amount_to_pay } = calculateAllValues(
      cart
    );

    const products = (cartItems) => {
      const nonZeroProducts = [];
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].quantity !== 0) {
          nonZeroProducts.push({
            product_group_id:
              cartItems[i].productGroup && cartItems[i].productGroup.id > 0
                ? cartItems[i].productGroup.id
                : null,
            product_id: cartItems[i].id,
            batch_id: cartItems[i].batch_id,
            total_amount: to2Decimal(cartItems[i].total_amount),
            quantity: cartItems[i].quantity,
            discount_value: cartItems[i].discount.discount_value,
            discount_type: cartItems[i].discount.discount_type,
            discount_code: cartItems[i].discount.discount_code,
            cess: cartItems[i].cess,
            cgst: cartItems[i].cgst,
            igst: cartItems[i].igst,
            sgst: cartItems[i].sgst,
            count: cartItems[i].count,
            payable_amount: to2Decimal(cartItems[i].payable_amount),
            price: to2Decimal(cartItems[i].selling_price),
            // total_tax: to2Decimal(cartItems[i].tax_applied),
          });
        }
      }

      return nonZeroProducts;
    };

    const dataToHold = {
      store: Number(selectedStore),
      customer:
        customer_details === undefined || customer_details === null
          ? 55
          : customer_details.id,
      bill_amount: to2Decimal(bill_total),
      payable_amount: to2Decimal(final_amount_to_pay),
      products: products(cart.cartItems),
      remarks: cart.remarks,
      discount_value: cart.discount.discount_value,
      discount_type: cart.discount.discount_type,
      discount_code: cart.discount.discount_code,
      total_tax: to2Decimal(total_tax),
    };

    setHoldBill(dataToHold)
      .then((response: any) => {
        // dispatch(addHoldBill(response.result));
        Toast(`${response.message}`, "success");
        dispatch(ResetWholeCart());
        dispatch(
          FetchAllHoldBills({
            pageNo: 1,
            query: "",
            store: selectedStore,
          })
        );
      })
      .catch((error: any) => {
        dispatch(setHoldBillError(error.message));
        dispatch(setHoldBillStatus(STATUSES.ERROR));
      })
      .finally(() => {
        focusRef.current.focus();
        dispatch(setHoldBillStatus(STATUSES.IDLE));
      });
  };
}

export function DeleteHoldBill(id: number) {
  return async function DeleteHoldBillThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const billData = getState().root.holdBill.holdBills;
    const index = billData.findIndex((bill: IBillItem) => bill.id === id);

    dispatch(setHoldBillStatus(STATUSES.LOADING));
    delHoldBill(id)
      .then((response: any) => {
        dispatch(removeHoldBill(index));
        Toast(`${response.message}`, "success");
      })
      .catch((error: any) => {
        dispatch(setHoldBillError(error.message));
        dispatch(setHoldBillStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setHoldBillStatus(STATUSES.IDLE));
      });
  };
}
