import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontIcon } from "./FontIcon";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { CustomRouteProps } from "utils";

const SidebarItem = ({ ...props }: CustomRouteProps) => {
  const [openMenu, setOpenMenu] = useState("");

  const handleMenuClick = (menuName: string) => {
    setOpenMenu(menuName === openMenu ? "" : menuName);
  };

  const renderLink = (props, url) => {
    return (
      <Link
        to={url}
        className="nav-link d-flex flex-row align-items-center"
        onClick={props.onClickHandler}
      >
        <FontIcon icon={props?.icon} iconClassNames={"nav-icon"} />
        <p className="ml-1">{props.route_name}</p>
        {props.subChildExists && (
          <FontIcon iconClassNames={"nav-icon right"} icon={faAngleLeft} />
        )}
      </Link>
    );
  };

  const renderTree = (props) => {
    return (
      props?.children &&
      props?.children.map((subItem, index) => {
        if (subItem?.permission?.perm_view && subItem?.route_name) {
          if (subItem?.subChildExists) {
            return (
              <React.Fragment key={index}>
                <SidebarItem
                  key={`${props.route_name}-${subItem.route_name}-${index}`}
                  isOpen={subItem.route_name === openMenu}
                  onClickHandler={() => handleMenuClick(subItem.route_name)}
                  path={`${props.path}/${subItem.path}`}
                  icon={subItem?.icon}
                  route_name={subItem?.route_name}
                  subChildExists={subItem?.subChildExists}
                  children={subItem?.children}
                />
              </React.Fragment>
            );
          } else {
            return (
              <li
                className={`nav-item ${props.route_name ? "" : "my-1"}`}
                key={`${props.route_name}-${subItem.route_name}-${index}`}
              >
                {renderLink(subItem, props.path + `/` + subItem.path)}
              </li>
            );
          }
        }
        return null;
      })
    );
  };

  const displayChildren = (props: CustomRouteProps) => {
    const renderSubMenu = () => (
      <React.Fragment key={props.route_name}>
        {props.route_name && renderLink(props, props.path)}
        <ul className={`${props.route_name ? "nav nav-treeview" : ""}`}>
          {renderTree(props)}
        </ul>
      </React.Fragment>
    );

    return !props.subChildExists
      ? renderLink(props, props.path)
      : renderSubMenu();
  };

  return props.route_name ? (
    <li
      className={`nav-item ${props.isOpen ? "menu-is-opening menu-open" : ""} ${
        props.subChildExists && props.route_name ? "has-treeview" : ""
      } my-1`}
      key={props.route_name}
    >
      {displayChildren(props)}
    </li>
  ) : (
    renderTree(props)
  );
};

export { SidebarItem };
