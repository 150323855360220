import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getWidgets,
  setWidget,
  updateWidget,
  delWidget,
  delWidgetContent,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  STATUSES,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
  TWidget,
  GENERAL_CONSTANTS,
} from "utils";

const initialState = {
  widgets: [] as TWidget[],
  widget: {} as TWidget,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchWidgets = createAsyncThunk(
  "widget/fetchWidgets",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getWidgets({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewWidget = createAsyncThunk(
  "widget/addNewWidget",
  async (
    {
      widgetParams,
      actions,
      setShow,
    }: {
      widgetParams: any;
      actions: FormikHelpers<any>;
      setShow: React.Dispatch<React.SetStateAction<boolean>>;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setWidget(widgetParams);
      actions.resetForm();
      setShow(false);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingWidget = createAsyncThunk(
  "widget/updateExistingWidget",
  async (
    {
      id,
      widgetParams,
      actions,
      setShow,
    }: {
      id: number;
      widgetParams: any;
      actions: FormikHelpers<any>;
      setShow: React.Dispatch<React.SetStateAction<boolean>>;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateWidget(id, widgetParams);
      actions.resetForm();
      setShow(false);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteWidget = createAsyncThunk(
  "widget/deleteWidget",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "widget-delete" })
    );
    try {
      const response = await delWidget(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({ state: STATUSES.IDLE, type: "widget-delete" })
      );
    }
  }
);

export const deleteWidgetContent = createAsyncThunk(
  "widget/deleteWidgetContent",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "widget-delete" })
    );
    try {
      const response = await delWidgetContent(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({ state: STATUSES.IDLE, type: "widget-delete" })
      );
    }
  }
);

const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    resetWidgetState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWidgets.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.widget = action.payload.result;
        } else if (action.meta.arg.active) {
          state.widgets = action.payload.result;
        } else {
          state.widgets = action.payload.result.results;
        }
      })
      .addCase(addNewWidget.fulfilled, (state, action: any) => {
        state.widgets.unshift(action.payload.result);
        state.widgets = state.widgets.slice(0, 10);
      })
      .addCase(updateExistingWidget.fulfilled, (state, action: any) => {
        // Update the existing widget in the widgets array
        const index = state.widgets.findIndex(
          (widget) => widget.id === action.payload.result.id
        );
        if (index !== -1) {
          state.widgets[index] = action.payload.result;
        }
      })
      .addCase(deleteWidget.fulfilled, (state, action: any) => {
        // update the deleted widget from the widgets array
        const index = state.widgets.findIndex(
          (widget) => widget.id === action.meta.arg
        );
        if (index !== -1) {
          state.widgets[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewWidget.fulfilled,
          updateExistingWidget.fulfilled,
          deleteWidget.fulfilled,
          deleteWidgetContent.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchWidgets.pending
          // addNewWidget.pending,
          // updateExistingWidget.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const { resetWidgetState } = widgetSlice.actions;

export default widgetSlice.reducer;
