import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ConfirmModal,
  Container,
  ContentHeader,
  CustomInput,
  FontIcon,
  Loader,
  MultiSelect,
  Search,
  Table,
  TableBody,
  TableHeader,
} from "components";
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import {
  BarcodeGeneratorSchema,
  IBarcodeGeneration,
  LABEL_GENERATION_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  Toast,
  constructProductOptions,
  containsNumbers,
  debounce,
  formatDate,
  isBarcode,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";
import {
  clearProducts,
  getIndividualProduct,
  getProducts,
  getProductsForSpecificStore,
  getProductsOnScan,
} from "store/ManageProducts/productSlice";
import {
  getAllLabelTemplates,
  getAllLabels,
  getIndividualLabel,
} from "store/Utilities/labelSLice";
import {
  getBranches,
  setSelectedStore as setCurrentStore,
} from "store/ManageBranches/branchSlice";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getAllPrinters } from "store/Utilities/printerSlice";
import { updatePageNo } from "store/commonSlice";
import { generateNewBarcode } from "store/Utilities/barcodeGeneratorSlice";

const BarcodeUtility = () => {
  const dispatch = useAppDispatch();

  const {
    branch: {
      branch: branchData,
      status: branchStatus,
      selectedStore: currentStore,
    },
    barcodeGenerator: { status: barcodeStatus },
    label: {
      label: labelData,
      individualLabel: currentLabel,
      label_template: labelTemplateData,
      status: labelStatus,
    },
    common: { status: commonStatus },
    printer: { printer: printerData, status: printerStatus },
    product: { products: productData, status: productStatus },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [printeroptions, setPrinterOptions] = useState(null);

  const [selectedStore, setSelectedStore] = useState<number>(0);

  const [confirmChangeStore, setConfirmChangeStore] = useState<boolean>(false);

  const barcodeSearchRef = useRef(null);

  const PropsRef = useRef<FormikProps<any>>();

  const ArrayHelperRef = useRef<FieldArrayRenderProps>();

  function AddProduct(selectedProduct) {
    const isProduct = selectedProduct.print_name !== undefined;

    ArrayHelperRef.current.unshift({
      product_code: isProduct
        ? selectedProduct.product_code
        : selectedProduct?.hamper_code,
      product_name: isProduct
        ? selectedProduct.print_name
        : selectedProduct?.print_hamper_name,
      product_group_id: isProduct ? null : selectedProduct.id,
      product_id: isProduct ? selectedProduct.id : null,
      batch_id:
        isProduct && selectedProduct.price.length === 1
          ? selectedProduct.price.map((item) => ({
              id: item.id,
              value: item.id,
              label: item.batch_name,
            }))
          : "",
      quantity: 1,
      packing_date: formatDate(new Date()),
      mrp: to2Decimal(
        isProduct
          ? selectedProduct.price[0].store_prices.find(
              (item) => item.store === selectedStore || currentStore
            ).mrp
          : selectedProduct.store_price[0].mrp
      ),
      batches: isProduct ? selectedProduct.price : [],
      selling_price: to2Decimal(
        isProduct
          ? selectedProduct.price[0].store_prices.find(
              (item) => item.store === selectedStore || currentStore
            ).selling_price
          : selectedProduct.store_price[0].selling_price
      ),
      store_id: selectedStore || currentStore,
      isProduct: isProduct,
    });
  }

  function handleLabelChange(e) {
    dispatch(getIndividualLabel(e.value));
  }

  function handlePriceChange(
    props: FormikProps<any>,
    index: number,
    batchId: number
  ) {
    props.setFieldValue(`products[${index}].store_id`, currentStore);
    if (batchId !== null) {
      const batchWiseStore = props.values.products[index].batches.find(
        (item) => item.id === batchId
      );
      const prices = batchWiseStore.store_prices.find(
        (item) => item.store === currentStore
      );
      props.setFieldValue(`products[${index}].mrp`, prices.mrp);
      props.setFieldValue(
        `products[${index}].selling_price`,
        prices.selling_price
      );
    } else {
      props.setFieldValue(`products[${index}].mrp`, 0);
      props.setFieldValue(`products[${index}].selling_price`, 0);
    }
  }

  const getProductOptions: any[] = useMemo(() => {
    return constructProductOptions(productData);
  }, [productData?.length]);

  const getLabelOptions = useMemo(() => {
    return labelData && labelData.length > 0
      ? labelData.map((label) =>
          labelTemplateData && labelTemplateData.length > 0
            ? labelTemplateData
                .filter((template) => template.id === label.template)
                .map((res) => ({
                  value: label.id,
                  label: res.template_name,
                }))[0]
            : []
        )
      : [];
  }, [labelData, labelTemplateData]);

  const getStoreOptions = useMemo(() => {
    return branchData && branchData.length > 0
      ? branchData.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : [];
  }, [branchData]);

  const handleSubmit = (values, action) => {
    if (values?.products?.length === 0) {
      Toast("Please select a product to generate barcode", "error");
      return;
    } else {
      const hasEmptyQuantity = values?.products?.some(
        (item) => item.quantity === "" || item.quantity === 0
      );
      const hasProductGroup = values?.products?.every((item) => {
        return item.isProduct !== false;
      });
      const hasEmptyBatch = hasProductGroup
        ? values?.products?.some((item) => {
            return item.batch_id[0] === undefined || item.batch_id[0] === null;
          })
        : false;
      if (
        hasEmptyQuantity ||
        hasEmptyBatch ||
        values.label.length === 0 ||
        values?.label?.[0] === null
      ) {
        if (hasEmptyQuantity) {
          Toast("Please enter quantity for all products", "error");
          return;
        }
        if (hasEmptyBatch) {
          Toast("Please select batch for all products", "error");
          return;
        }
        if (values.label.length === 0 || values?.label?.[0] === null) {
          Toast("Please Select a label template", "error");
          return;
        }
      } else {
        const dataToSend = {
          label_id: values.label[0].value,
          products: values.products.map((item) => {
            return {
              product_group_id: item.product_group_id,
              batch_id: item.isProduct ? item.batch_id[0].value : null,
              product_id: item.product_id,
              store_id: item.store_id,
              quantity: item.quantity,
            };
          }),
        };
        dispatch(generateNewBarcode(dataToSend, action));
      }
    }

    // const dataToSend = {
    //   label_id: values.label[0].value,
    //   products: values.products.map((item) => ({
    //     product_group_id: item.product_group_id,
    //     batch_id: item.isProduct ? item.batch_id[0].value : null,
    //     product_id: item.product_id,
    //     store_id: item.store_id,
    //     quantity: item.quantity,
    //   })),
    // };
    // dispatch(generateNewBarcode(dataToSend));
    // action.resetForm();
  };

  const searchValues = (query) => {
    if (!isBarcode(query) && query.length >= 3) {
      dispatch(
        getProductsForSpecificStore({
          pageNo: 1,
          query: query,
          store: selectedStore || currentStore,
          avarya_filter: true,
        })
      );
    } else if (isBarcode(query) && query.length > 3) {
      dispatch(
        getProductsOnScan({
          code: encodeURI(query),
          store: selectedStore || currentStore,
          avarya_filter: true,
          addProduct: AddProduct,
        })
      );
      setQuery("");
    }
  };

  const optimizeSearch = useCallback(debounce(searchValues), [
    selectedStore,
    currentStore,
  ]);

  useEffect(() => {
    const getFormData = async () => {
      const data = await Promise.all([
        dispatch(
          getBranches({
            active: true,
            edit: { setCurrentStore: setSelectedStore },
          })
        ),
        dispatch(getAllLabels(true)),
        dispatch(getAllPrinters(true)),
        dispatch(getAllLabelTemplates(true)),
      ]);
    };
    getFormData();
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader="Barcode Utility" />
        <main>
          <Formik
            // enableReinitialize={true}
            initialValues={{
              store: "",
              selected_product: "",
              label: [],
              batch_id: [],
              label_id: "",
              products: [],
            }}
            onSubmit={handleSubmit}
            validationSchema={BarcodeGeneratorSchema}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form>
                  <div className="row text-sm">
                    <div className="col-12">
                      <Card>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-4">
                              <MultiSelect
                                tabIndex={-1}
                                select={false}
                                name="store"
                                label="Select Store"
                                isLoading={branchStatus === STATUSES.LOADING}
                                value={
                                  currentStore
                                    ? {
                                        value: currentStore,
                                        label: getStoreOptions.find(
                                          (item) => item.value === currentStore
                                        ).label,
                                      }
                                    : props.values.store
                                }
                                options={getStoreOptions}
                                onChangeHandler={(e) => {
                                  const selectedOptions = Array.isArray(e)
                                    ? e
                                    : [e];

                                  if (props.values.products.length > 0) {
                                    e !== null && setSelectedStore(e.value);
                                    setConfirmChangeStore(true);
                                  } else {
                                    props.setFieldValue(
                                      "store",
                                      selectedOptions
                                    );
                                    e !== null && setSelectedStore(e.value);
                                    e !== null &&
                                      dispatch(setCurrentStore(e.value));
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="store"
                                component="div"
                                className="field-error text-danger"
                              />
                            </div>
                            <div className="col-md-4">
                              <MultiSelect
                                tabIndex={-1}
                                select={false}
                                disabled={!currentStore}
                                name="label"
                                label="Select Label"
                                isLoading={labelStatus === STATUSES.LOADING}
                                options={getLabelOptions}
                                onChangeHandler={(e) => {
                                  setPrinterOptions(null);
                                  const selectedOptions = Array.isArray(e)
                                    ? e
                                    : [e];
                                  props.setFieldValue("label", selectedOptions);
                                  e && e.value && handleLabelChange(e);
                                }}
                              />
                              <ErrorMessage
                                name="label"
                                component="div"
                                className="field-error text-danger"
                              />
                            </div>
                            {props?.values?.label?.[0]?.label !== "" ? (
                              <div className="col-md-4">
                                <MultiSelect
                                  tabIndex={-1}
                                  name="printer"
                                  label="Select Printer"
                                  isLoading={printerStatus === STATUSES.LOADING}
                                  options={
                                    currentLabel &&
                                    currentLabel.printers &&
                                    currentLabel.printers.length > 0
                                      ? currentLabel.printers.map(
                                          (item) =>
                                            printerData
                                              .filter(
                                                (printer) =>
                                                  printer.id === item.printer
                                              )
                                              ?.map((res) => ({
                                                value: res.id,
                                                label: res.printer_name,
                                              }))[0]
                                        )
                                      : []
                                  }
                                  disabled={!selectedStore}
                                  select={false}
                                />
                                <ErrorMessage
                                  name="printer"
                                  component="div"
                                  className="field-error text-danger"
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                tabIndex={1}
                                mulitSelectRef={barcodeSearchRef}
                                select={false}
                                disabled={
                                  !currentStore ||
                                  (currentLabel &&
                                    Object.keys(currentLabel).length === 0)
                                }
                                isLoading={productStatus === STATUSES.LOADING}
                                name="selected_product"
                                label="Select Product"
                                options={getProductOptions}
                                inputValue={query}
                                onInputChangeHandler={(e) => {
                                  optimizeSearch(e);
                                  setQuery(e);
                                }}
                                onMenuCloseHandler={() => {
                                  dispatch(clearProducts());
                                }}
                                onChangeHandler={(e, actions) => {
                                  if (e && e.value && e.type === "product") {
                                    dispatch(
                                      getIndividualProduct({
                                        id: e.value,
                                        addProduct: AddProduct,
                                        barcode: true,
                                      })
                                    );
                                  } else if (
                                    e &&
                                    e.value &&
                                    e.type === "productGroup"
                                  ) {
                                    dispatch(
                                      getProductsForSpecificStore({
                                        store: selectedStore,
                                        avarya_filter: true,
                                        product_group: e.value,
                                        addProduct: AddProduct,
                                      })
                                    );
                                  }

                                  // e &&
                                  //   e.value &&
                                  //   dispatch(
                                  //     getIndividualProduct({
                                  //       id: e.value,
                                  //       addProduct: AddProduct,
                                  //       barcode: true,
                                  //     })
                                  //   );
                                }}
                                onKeyDownHandler={(e) => {
                                  if (e.key === "Enter") {
                                    e.stopPropagation();
                                    optimizeSearch(query);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="selected_product"
                                component="div"
                                className="field-error text-danger"
                              />
                            </div>
                            <div className="col-md-6 text-end">
                              <Button
                                type="button"
                                onClickHandler={() => {
                                  props.submitForm();
                                }}
                                btnClassNames="btn btn-primary align-items-center"
                                text="Generate"
                                loading={barcodeStatus === STATUSES.LOADING}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card>
                        <CardBody>
                          <Table>
                            <TableHeader
                              cols={LABEL_GENERATION_CONSTANTS.HEADERS}
                            />
                            <TableBody>
                              {commonStatus.state === STATUSES.LOADING ? (
                                <tr>
                                  <td
                                    colSpan={
                                      LABEL_GENERATION_CONSTANTS.HEADERS.length
                                    }
                                    className="text-center"
                                  >
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                <FieldArray
                                  name="products"
                                  render={(arrayHelpers) => {
                                    ArrayHelperRef.current = arrayHelpers;
                                    return props.values.products &&
                                      props.values.products.length > 0
                                      ? props.values.products.map(
                                          (product, index) => (
                                            <tr key={index}>
                                              <td className="align-middle">
                                                {index + 1}
                                              </td>
                                              <td>
                                                <CustomInput
                                                  type="text"
                                                  name={`products.${index}.product_code`}
                                                  isDisabled={true}
                                                  inputClassName={"text-sm"}
                                                />
                                              </td>
                                              <td>
                                                <CustomInput
                                                  type="text"
                                                  name={`products.${index}.product_name`}
                                                  isDisabled={true}
                                                  inputClassName={"text-sm"}
                                                />
                                              </td>
                                              <td
                                                className={`align-middle ${
                                                  props.values.products[index]
                                                    .isProduct
                                                    ? ""
                                                    : "text-center"
                                                }`}
                                              >
                                                {props.values.products[index]
                                                  .isProduct ? (
                                                  <MultiSelect
                                                    tabIndex={index + 1}
                                                    select={false}
                                                    name={`products.${index}.batch_id`}
                                                    options={props.values.products[
                                                      index
                                                    ].batches.map((item) => ({
                                                      id: item.id,
                                                      value: item.id,
                                                      label: item.batch_name,
                                                    }))}
                                                    onChangeHandler={(e) => {
                                                      const selectedOptions = Array.isArray(
                                                        e
                                                      )
                                                        ? e
                                                        : [e];
                                                      props.setFieldValue(
                                                        `products.${index}.batch_id`,
                                                        selectedOptions
                                                      );
                                                      if (e) {
                                                        handlePriceChange(
                                                          props,
                                                          index,
                                                          e.value
                                                        );
                                                      } else {
                                                        props.setFieldValue(
                                                          `products[${index}].mrp`,
                                                          0
                                                        );
                                                        props.setFieldValue(
                                                          `products[${index}].selling_price`,
                                                          0
                                                        );
                                                      }
                                                    }}
                                                    // onBlurHandler={() => {
                                                    //   props.values.products
                                                    //     .length -
                                                    //     1 ===
                                                    //     index &&
                                                    //     barcodeSearchRef.current.focus();
                                                    // }}
                                                  />
                                                ) : (
                                                  "N/A"
                                                )}
                                              </td>
                                              <td>
                                                <CustomInput
                                                  tabIndex={index + 2}
                                                  step={1}
                                                  type="number"
                                                  name={`products.${index}.quantity`}
                                                  inputClassName={"text-sm"}
                                                  onBlurHandler={() => {
                                                    (index === 0 ||
                                                      props.values.products
                                                        .length -
                                                        1 ===
                                                        index) &&
                                                      barcodeSearchRef.current.focus();
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <CustomInput
                                                  type="date"
                                                  name={`products.${index}.packing_date`}
                                                  isDisabled={true}
                                                  inputClassName={"text-sm"}
                                                />
                                              </td>
                                              <td>
                                                <CustomInput
                                                  type="number"
                                                  name={`products.${index}.mrp`}
                                                  isDisabled={true}
                                                  inputClassName={"text-sm"}
                                                />
                                              </td>
                                              <td>
                                                <CustomInput
                                                  type="number"
                                                  name={`products.${index}.selling_price`}
                                                  isDisabled={true}
                                                  inputClassName={"text-sm"}
                                                />
                                              </td>
                                              <td className="align-middle">
                                                <Button
                                                  type="button"
                                                  btnClassNames="btn btn-danger btn-sm"
                                                  text={
                                                    <FontIcon icon={faTrash} />
                                                  }
                                                  onClickHandler={() => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          )
                                        )
                                      : null;
                                  }}
                                />
                              )}
                            </TableBody>
                          </Table>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </main>
      </Container>
      <ConfirmModal
        modal={confirmChangeStore}
        setModal={setConfirmChangeStore}
        title=""
        message={
          "Are you sure you want reset the cart ? It will reset the cart and change the store."
        }
        rejectClick={() => {
          setConfirmChangeStore(false);
          setSelectedStore(currentStore);
        }}
        confirmClick={() => {
          PropsRef.current?.setFieldValue("products", []);
          dispatch(setCurrentStore(selectedStore));
          setConfirmChangeStore(false);
        }}
      />
    </div>
  );
};

export { BarcodeUtility };
