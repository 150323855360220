import React from "react";

interface IProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  borderColor?: string;
  subtle?: boolean;
}

const Highlight = ({
  children,
  className,
  color = "primary",
  borderColor,
  subtle = true,
}: IProps) => {
  return (
    <div
      className={`d-inline bg-${color}${
        subtle ? "-subtle" : ""
      } bg-opacity-10 border border-${
        borderColor ? borderColor : color
      } rounded-2 p-2 mb-2 ${className}`}
    >
      {children}
    </div>
  );
};

export { Highlight };
