import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, ErrorMessage, FormikProps } from "formik";

import {
  getNewValues,
  handleOptionDelete,
  multipleDeleteCheck,
  singleDeleteCheck,
  useAppDispatch,
  useAppSelector,
} from "utils/helper";
import {
  addNewEmployee,
  deleteEmployeeStoreMapping,
  getEmployees,
  getIndividualEmployee,
  setEmployeeToEdit,
  updateExistingEmployee,
} from "store/ManageEmployee/employeeSlice";

import {
  Card,
  CardHeader,
  CardBody,
  MultiSelect,
  Button,
  Container,
} from "components/core-components";
import { ConfirmModal, ContentHeader, CustomInput, Loader } from "components";
import { FetchEmployeeSchema } from "utils/schema";
import {
  BUTTON_CONSTANTS,
  EMPLOYEE_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
} from "utils/constants";
import {
  fetchCity,
  fetchState,
  getCity,
  getCountry,
  getState,
} from "store/locationSlice";
import { fetchRoles } from "store/ManageRoles/roleSlice";
import { getBranches } from "store/ManageBranches/branchSlice";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { setCommonStatus } from "store/commonSlice";

const EmployeeForm = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);

  //get all the data from the redux store

  const {
    branch: { branch: branchData, status: branchStatus },
    employee: { employeeToEdit, status: employeeStatus },
    location: { cities, states, countries, status: locationStatus },
    role: { roles: roleData, status: roleStatus },
  } = useAppSelector((state) => state.root);

  //get the employee id from the url

  const { id } = useParams();

  const [addressFilled, setAddressFilled] = useState(
    id &&
      employeeToEdit?.address?.address_line_1 !== "" &&
      employeeToEdit?.address?.address_line_1 !== undefined &&
      employeeToEdit?.address?.address_line_2 !== "" &&
      employeeToEdit?.address?.address_line_2 !== undefined
  );

  const PropsRef = useRef<FormikProps<any>>();

  const ActionsRef = useRef<any>();

  const [confirmModal, setConfirmModal] = useState(false);

  const [deleteField, setDeleteField] = useState("stores");

  //initial values for the form

  const initialValues = {
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    date_joined: null,
    email: "",
    mobile_number: 0,
    pan: "",

    address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "",
      pincode: 0,
    },

    bank_name: "",
    bank_branch: "",
    ifsc_code: "",
    account_no: 0,
    account_holder: "",
    salary: 0,
    commissions: 0,
    extra_wages: 0,

    comments: "",

    role: "",

    stores: "",

    is_active: [{ value: 1, label: "Active" }],
    // departments: employeeToEdit?.departments || "",
    // scannedDocuments: employeeToEdit?.scannedDocuments || "",
  };

  const [initialBranches, setInitialBranches] = useState([]);

  //submit the form
  const handleSubmit = (values, actions) => {
    let dataToSend = {
      ...values,
      address: addressFilled
        ? {
            ...values.address,
            city: values.address.city[0]?.value * 1,
            state: values.address.state[0]?.value * 1,
            country: values.address.country[0]?.value * 1,
          }
        : {},
      username: values.username,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role[0]?.value * 1,
      stores: values.stores.map((store) => store.value * 1),
      is_active: Number(values.is_active[0]?.value),
    };

    if (id) {
      dataToSend = {
        ...dataToSend,
        id: Number(id),
        user: employeeToEdit?.user,
        stores: getNewValues(values.stores, initialBranches),
      };
      dispatch(
        updateExistingEmployee(Number(id), dataToSend, actions, navigate)
      );
    } else {
      dispatch(addNewEmployee(dataToSend, actions, navigate));
    }
  };
  //fetch all the data

  const getBranchOptions = useMemo(() => {
    return branchData && branchData?.length > 0
      ? branchData.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : [];
  }, [branchData?.length]);

  const getRoleOptions = useMemo(() => {
    return roleData && roleData?.length > 0
      ? roleData.map((item) => ({
          value: item.id,
          label: item.role_name,
        }))
      : [];
  }, [roleData?.length]);

  useEffect(() => {
    if (id) {
      dispatch(
        getEmployees({
          id: Number(id),
          edit: { edit: true, setInitialBranches },
        })
      );
    }
    // id && dispatch(getIndividualEmployee(Number(id), true, setInitialBranches));
    return () => {
      dispatch(setEmployeeToEdit({}));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          editValue={employeeToEdit?.username}
          pageHeader={
            id ? EMPLOYEE_CONSTANTS.EDIT_HEADER : EMPLOYEE_CONSTANTS.ADD_HEADER
          }
        />
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={id ? employeeToEdit : initialValues}
            validationSchema={FetchEmployeeSchema(Number(id))}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form autoComplete="off">
                  <ScrollToFieldError />
                  <div className="row">
                    <div className="col-md-12">
                      {employeeStatus === STATUSES.LOADING && (
                        <div className="d-flex justify-content-center">
                          <Loader />
                        </div>
                      )}
                    </div>
                    {/* Employee Details:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h5 className="card-title">
                            {EMPLOYEE_CONSTANTS.CARD_TITLE_1}
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_USERNAME_LABEL
                                  }
                                  isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_USERNAME}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_USERNAME_PLACEHOLDER
                                  }
                                  value={props.values.username}
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_USERNAME}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  isPass={true}
                                  isRequired={true}
                                  setShowPass={setShowPassword}
                                  showPass={showPassword}
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_PASSWORD_LABEL
                                  }
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_PASSWORD}
                                  type={
                                    showPassword
                                      ? FORM_CONSTANTS.TEXT
                                      : FORM_CONSTANTS.PASSWORD
                                  }
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_PASSWORD_PLACEHOLDER
                                  }
                                  value={props.values.password}
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_PASSWORD}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_FIRST_NAME_LABEL
                                  }
                                  isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_FIRST_NAME}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_FIRST_NAME_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_FIRST_NAME}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_LAST_NAME_LABEL
                                  }
                                  isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_LAST_NAME}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_LAST_NAME_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_LAST_NAME}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_DATE_JOINED_LABEL
                                  }
                                  // isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_DATE_JOINED}
                                  type={FORM_CONSTANTS.DATE}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_DATE_JOINED_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_DATE_JOINED}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_EMAIL_LABEL
                                  }
                                  isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_EMAIL}
                                  type={FORM_CONSTANTS.EMAIL}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_EMAIL_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_EMAIL}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_MOBILE_NUMBER_LABEL
                                  }
                                  // isRequired={true}
                                  name={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_MOBILE_NUMBER
                                  }
                                  type={FORM_CONSTANTS.NUMBER}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_MOBILE_NUMBER_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_MOBILE_NUMBER
                                  }
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  label={EMPLOYEE_CONSTANTS.EMPLOYEE_PAN_LABEL}
                                  // isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_PAN}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_PAN_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_PAN}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <MultiSelect
                                  select={false}
                                  isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.ROLE}
                                  label={EMPLOYEE_CONSTANTS.ROLE_LABEL}
                                  isLoading={roleStatus === STATUSES.LOADING}
                                  onFocusHandler={() => {
                                    roleData.length === 0 &&
                                      dispatch(fetchRoles({ active: true }));
                                  }}
                                  options={getRoleOptions}
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.ROLE}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <MultiSelect
                                  select={true}
                                  isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.STORE}
                                  label={EMPLOYEE_CONSTANTS.STORE_LABEL}
                                  isLoading={branchStatus === STATUSES.LOADING}
                                  onFocusHandler={() => {
                                    branchData.length === 0 &&
                                      dispatch(getBranches({ active: true }));
                                  }}
                                  options={getBranchOptions}
                                  onChangeHandler={(e, actions) => {
                                    if (
                                      (id &&
                                        actions.action == "remove-value") ||
                                      actions.action == "pop-value" ||
                                      actions.action == "clear"
                                    ) {
                                      ActionsRef.current = actions;
                                      setConfirmModal(true);
                                      setDeleteField("stores");
                                    } else {
                                      const selectedOptions = Array.isArray(e)
                                        ? e
                                        : [e];

                                      props.setFieldValue(
                                        "stores",
                                        selectedOptions
                                      );
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.STORE}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_SALARY_LABEL
                                  }
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_SALARY}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_SALARY_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_SALARY}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_COMMISSION_LABEL
                                  }
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_COMMISSION}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_COMMISSION_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_COMMISSION}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_WAGES_LABEL
                                  }
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_WAGES}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EMPLOYEE_WAGES_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EMPLOYEE_WAGES}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  label={
                                    EMPLOYEE_CONSTANTS.EXTRA_COMMENTS_LABEL
                                  }
                                  name={EMPLOYEE_CONSTANTS.EXTRA_COMMENTS}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    EMPLOYEE_CONSTANTS.EXTRA_COMMENTS_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.EXTRA_COMMENTS}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <MultiSelect
                                  select={false}
                                  // isRequired={true}
                                  name={EMPLOYEE_CONSTANTS.IS_ACTIVE}
                                  label={EMPLOYEE_CONSTANTS.IS_ACTIVE_LABEL}
                                  options={[
                                    { value: 1, label: "Active" },
                                    { value: 0, label: "Inactive" },
                                  ]}
                                />
                                <ErrorMessage
                                  name={EMPLOYEE_CONSTANTS.IS_ACTIVE}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                          </>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Employee Address Details:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {EMPLOYEE_CONSTANTS.CARD_TITLE_2}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-12">
                              <CustomInput
                                label={EMPLOYEE_CONSTANTS.ADDRESS_LINE_1_LABEL}
                                // isRequired={true}
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.ADDRESS_LINE_1}`}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.ADDRESS_LINE_1_PLACEHOLDER
                                }
                                onChangeHandler={(e) => {
                                  props.setFieldValue(
                                    `${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.ADDRESS_LINE_1}`,
                                    e.target.value
                                  );
                                  setAddressFilled(
                                    props.values.address?.address_line_1 !==
                                      "" &&
                                      props.values.address?.address_line_2 !==
                                        ""
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.ADDRESS_LINE_1}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <CustomInput
                                label={EMPLOYEE_CONSTANTS.ADDRESS_LINE_2_LABEL}
                                // isRequired={true}
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.ADDRESS_LINE_2}`}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.ADDRESS_LINE_2_PLACEHOLDER
                                }
                                onChangeHandler={(e) => {
                                  props.setFieldValue(
                                    `${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.ADDRESS_LINE_2}`,
                                    e.target.value
                                  );
                                  setAddressFilled(
                                    props.values.address.address_line_1 !==
                                      "" ||
                                      props.values.address.address_line_2 !== ""
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.ADDRESS_LINE_2}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                // isRequired={true}
                                disabled={!addressFilled}
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.COUNTRY}`}
                                label={EMPLOYEE_CONSTANTS.COUNTRY_LABEL}
                                isLoading={locationStatus === STATUSES.LOADING}
                                onFocusHandler={() => {
                                  countries.length === 0 &&
                                    dispatch(getCountry());
                                }}
                                options={countries?.map((country) => ({
                                  value: country.id.toString(),
                                  label: country.country,
                                }))}
                                onChangeHandler={(e) => {
                                  e
                                    ? dispatch(getState(e.value))
                                    : dispatch(fetchState([]));
                                  props.setFieldValue(
                                    `${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.COUNTRY}`,
                                    [e]
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.COUNTRY}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                disabled={!addressFilled}
                                // isRequired={true}
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.STATE}`}
                                label={EMPLOYEE_CONSTANTS.STATE_LABEL}
                                options={states?.map((state) => ({
                                  value: state.id.toString(),
                                  label: state.state,
                                }))}
                                isLoading={locationStatus === STATUSES.LOADING}
                                onChangeHandler={(e) => {
                                  e
                                    ? dispatch(getCity(e.value))
                                    : dispatch(fetchCity([]));
                                  props.setFieldValue(
                                    `${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.STATE}`,
                                    [e]
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.STATE}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                // isRequired={true}
                                disabled={!addressFilled}
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.CITY}`}
                                label={EMPLOYEE_CONSTANTS.CITY_LABEL}
                                isLoading={locationStatus === STATUSES.LOADING}
                                options={cities?.map((city) => ({
                                  value: city.id.toString(),
                                  label: city.city,
                                }))}
                              />
                              <ErrorMessage
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.CITY}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                isDisabled={!addressFilled}
                                label={EMPLOYEE_CONSTANTS.PINCODE_LABEL}
                                // isRequired={true}
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.PINCODE}`}
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.PINCODE_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={`${EMPLOYEE_CONSTANTS.EMPLOYEE_ADDRESS}.${EMPLOYEE_CONSTANTS.PINCODE}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Employee Bank Details:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">Bank Details</h3>
                        </CardHeader>
                        <CardBody>
                          {/* Account Holder Details */}
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_HOLDER_NAME_LABEL
                                }
                                // isRequired={true}
                                name={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_HOLDER_NAME
                                }
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_HOLDER_NAME_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_HOLDER_NAME
                                }
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_NUMBER_LABEL
                                }
                                // isRequired={true}
                                name={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_NUMBER
                                }
                                type="number"
                                placeholder={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_NUMBER_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_ACCOUNT_NUMBER
                                }
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          {/* Bank Details */}
                          <div className="row">
                            <div className="col-md-6">
                              {/* <MultiSelect
                              select={false}
                              // isRequired={true}
                              name={EMPLOYEE_CONSTANTS.EMPLOYEE_BANK}
                              label={EMPLOYEE_CONSTANTS.EMPLOYEE_BANK_LABEL}
                              options={bankData?.map((bank) => ({
                                value: bank.id.toString(),
                                label: bank.bank_name,
                              }))}
                            /> */}
                              <CustomInput
                                label={EMPLOYEE_CONSTANTS.EMPLOYEE_BANK_LABEL}
                                // isRequired={true}
                                name={EMPLOYEE_CONSTANTS.EMPLOYEE_BANK}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_BANK_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={EMPLOYEE_CONSTANTS.EMPLOYEE_BANK}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                name={EMPLOYEE_CONSTANTS.EMPLOYEE_IFSC_CODE}
                                label={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_IFSC_CODE_LABEL
                                }
                                // isRequired={true}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_IFSC_CODE_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={EMPLOYEE_CONSTANTS.EMPLOYEE_IFSC_CODE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={EMPLOYEE_CONSTANTS.EMPLOYEE_BRANCH_LABEL}
                                name={EMPLOYEE_CONSTANTS.EMPLOYEE_BRANCH}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  EMPLOYEE_CONSTANTS.EMPLOYEE_BRANCH_PLACEHOLDER
                                }
                                // isRequired={true}
                              />
                              <ErrorMessage
                                name={EMPLOYEE_CONSTANTS.EMPLOYEE_BRANCH}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Submit */}
                    <div className="col-md-8">
                      <Button
                        loading={employeeStatus === STATUSES.LOADING}
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary"}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={""}
        message={"Are you sure you want to delete the option ?"}
        confirmClick={() => {
          handleOptionDelete(
            deleteField,
            PropsRef.current,
            ActionsRef.current,
            (value) => {
              dispatch(
                deleteEmployeeStoreMapping({
                  store_id: value,
                  user_id: Number(id),
                })
              );
            },
            (value) => {
              ActionsRef.current.action !== "clear"
                ? setInitialBranches([])
                : setInitialBranches((prev) => {
                    return prev.filter((item) => item.value !== value);
                  });
            },
            (value) => {
              dispatch(setCommonStatus({ state: value, type: "deleteStore" }));
            }
          );
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { EmployeeForm };
