import React from "react";

interface IProps {
  small?: boolean;
}

const Loader = ({ small = false }: IProps) => {
  return (
    <div
      data-testid="loader"
      className={`spinner-border ${
        small ? "spinner-border-sm" : ""
      } text-primary m-2`}
    ></div>
  );
};

export { Loader };
