import React from "react";
import { useField } from "formik";
import { Label } from "./Label";

interface TextAreaProps {
  label: string;
  name: string;
  id?: string;
  rows: number;
  placeholder: string;
  isRequired?: boolean;
}

const TextArea = ({
  label,
  name,
  id,
  rows,
  placeholder,
  isRequired = false,
}: TextAreaProps) => {
  const [field] = useField(name);
  return (
    <div className="form-group my-1">
      <Label htmlFor={id} label={label} labelClassNames="form-label" />
      {isRequired ? <span className="text-danger">*</span> : ""}
      <textarea
        className="form-control"
        placeholder={placeholder}
        name={name}
        id={id}
        rows={rows}
        value={field.value}
        onChange={field.onChange}
      />
    </div>
  );
};

export { TextArea };
