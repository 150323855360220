import {
  Button,
  ConfirmModal,
  Container,
  ContentHeader,
  Tabs,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { fetchMenus, fetchPages } from "store/Ecommerce";
import {
  addNewSitePaymentDetails,
  addNewSiteShippingDetails,
  addNewSiteSMSDetails,
  deleteSitePaymentDetails,
  deleteSiteShippingDetails,
  deleteSiteSMSDetails,
  fetchSiteDetails,
  fetchsiteMenuSettings,
  fetchSitePaymentDetails,
  fetchSiteShippingDetails,
  fetchSiteSMSDetails,
  resetSiteManagementState,
  setSiteMenuSettings,
  updateExistingSiteDetails,
  updateExistingSiteMenuSettings,
} from "store/Ecommerce/siteManagementSlice";
import {
  SITE_MANAGEMENT_CONSTANTS,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
  SiteManagementSchema,
  STATUSES,
  filterNullElements,
} from "utils";
import { SiteGeneralDetails } from "./SiteGeneralDetails";
import { SMSDetails } from "./SMSDetails";
import { PaymentDetails } from "./PaymentDetails";
import { ShippingDetails } from "./ShippingDetails";
import { MenuDetails } from "./MenuDetails";

const SiteManagement = () => {
  const {
    ecommerce: {
      siteManagement: {
        site_management_details,
        payment_details,
        shipping_details,
        sms_details,
        menu_settings,
        status,
      },
      pages: { pages },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const ArrayHelperRef = useRef(null);

  const [activeTab, setActiveTab] = useState<string>(
    SITE_MANAGEMENT_CONSTANTS.TABS[0]
  );

  const [currentItemToDelete, setCurrentItemToDelete] = useState<{
    id: number;
    removeIndex: () => void;
    type: string;
  }>();

  const [confirmModal, setConfirmModal] = useState(false);

  const InitialValues = useMemo(() => {
    switch (activeTab) {
      case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
        const front_page = {
          ...pages.find(
            (page: any) => page.id === site_management_details?.front_page
          ),
        };

        return {
          site_name: site_management_details?.site_name || "",
          site_url: site_management_details?.site_url || "",
          site_contact_number:
            site_management_details?.site_contact_number || 0,
          site_contact_email: site_management_details?.site_contact_email || "",

          icon: site_management_details?.icon || "",
          favicon: site_management_details?.favicon || "",

          front_page:
            Object.keys(site_management_details)?.length > 0
              ? [
                  {
                    value: front_page?.id,
                    label: front_page?.page_name,
                  },
                ]
              : "",

          publish_website: [
            {
              value: Number(site_management_details?.publish_website),
              label: Number(site_management_details?.publish_website)
                ? GENERAL_CONSTANTS.YES
                : GENERAL_CONSTANTS.NO,
            },
          ],

          google_analytics: site_management_details?.google_analytics || "",
          facebook_pixel: site_management_details?.facebook_pixel || "",

          instagram: site_management_details?.instagram || "",
          facebook: site_management_details?.facebook || "",
          twitter: site_management_details?.twitter || "",
          youtube: site_management_details?.youtube || "",

          email_details: site_management_details?.email_details || {
            email_host: "",
            email_port: "",
            email_username: "",
            email_password: "",
          },
        };
      case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
        return {
          sms_details:
            sms_details && sms_details.length > 0
              ? sms_details.map((item) => {
                  return {
                    ...item,
                    sms_name: item.sms_name,
                    sms_key: item.sms_key,
                  };
                })
              : [
                  {
                    sms_name: "",
                    sms_key: "",
                  },
                ],
        };
      case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
        return {
          payment_details:
            payment_details && payment_details.length > 0
              ? payment_details.map((item) => {
                  return {
                    ...item,
                    payment_name: item.payment_name,
                    payment_key: item.payment_key,
                  };
                })
              : [
                  {
                    payment_name: "payment",
                    payment_key: "kxmoamoismze",
                  },
                ],
        };
      case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
        return {
          shipping_details:
            shipping_details && shipping_details.length > 0
              ? shipping_details.map((item) => {
                  return {
                    ...item,
                    shipping_name: item.shipping_name,
                    shipping_key: item.shipping_key,
                  };
                })
              : [
                  {
                    shipping_name: "Shipper Name 1 ",
                    shipping_key: ",ocwemcowmeic",
                  },
                ],
        };
      case SITE_MANAGEMENT_CONSTANTS.TABS[4]:
        const menuDetails: any = menu_settings;
        return {
          menu_details: {
            header_menu:
              menuDetails?.header_menu !== null
                ? [
                    {
                      value: menuDetails?.header_menu?.id,
                      label: menuDetails?.header_menu?.menu_title,
                    },
                  ]
                : [],
            footer_menu:
              menuDetails?.footer_menu !== null
                ? [
                    {
                      value: menuDetails?.footer_menu?.id,
                      label: menuDetails?.footer_menu?.menu_title,
                    },
                  ]
                : [],
            mega_menu:
              menuDetails?.mega_menu !== null
                ? [
                    {
                      value: menuDetails?.mega_menu?.id,
                      label: menuDetails?.mega_menu?.menu_title,
                    },
                  ]
                : [],
          },
        };
    }
  }, [
    activeTab,
    Object.keys(site_management_details).length,
    Object.keys(menu_settings).length,
    sms_details.length,
    payment_details.length,
    shipping_details.length,
  ]);

  const renderForms = (props, arrayHelperRef) => {
    switch (activeTab) {
      case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
        return <SiteGeneralDetails props={props} />;
      case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
        return (
          <SMSDetails
            props={props}
            arrayHelperRef={arrayHelperRef}
            setCurrentItemToDelete={setCurrentItemToDelete}
            setConfirmModal={setConfirmModal}
          />
        );
      case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
        return (
          <PaymentDetails
            props={props}
            arrayHelperRef={arrayHelperRef}
            setCurrentItemToDelete={setCurrentItemToDelete}
            setConfirmModal={setConfirmModal}
          />
        );
      case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
        return (
          <ShippingDetails
            props={props}
            arrayHelperRef={arrayHelperRef}
            setCurrentItemToDelete={setCurrentItemToDelete}
            setConfirmModal={setConfirmModal}
          />
        );
      case SITE_MANAGEMENT_CONSTANTS.TABS[4]:
        return <MenuDetails props={props} />;
    }
  };

  const handleSubmit = (values) => {
    switch (activeTab) {
      case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
        const siteDetailstoSend = {
          ...values,
          id: site_management_details?.id,
          icon: values?.icon?.preview,
          favicon: values?.favicon?.preview,
          publish_website: values?.publish_website?.[0]?.value,
        };

        delete siteDetailstoSend["sms_details"];
        delete siteDetailstoSend["payment_details"];
        delete siteDetailstoSend["shipping_details"];
        dispatch(
          updateExistingSiteDetails({
            siteDetailsParams: siteDetailstoSend,
          })
        );
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
        const smsDetailsToSend = {
          sms_details: filterNullElements(
            values.sms_details.map((sms) => {
              if (sms.new) {
                return {
                  sms_name: sms.sms_name,
                  sms_key: sms.sms_key,
                };
              }
            })
          ),
        };
        smsDetailsToSend.sms_details.length > 0 &&
          dispatch(
            addNewSiteSMSDetails({
              smsDetailsParams: smsDetailsToSend,
            })
          );
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
        const paymentDetailsToSend = {
          payment_details: filterNullElements(
            values.payment_details.map((payment) => {
              if (payment.new) {
                return {
                  payment_name: payment.payment_name,
                  payment_key: payment.payment_key,
                };
              }
            })
          ),
        };
        paymentDetailsToSend.payment_details.length > 0 &&
          dispatch(
            addNewSitePaymentDetails({
              paymentDetailsParams: paymentDetailsToSend,
            })
          );
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
        const shippingDetailsToSend = {
          shipping_details: filterNullElements(
            values.shipping_details.map((shipping) => {
              if (shipping.new) {
                return {
                  shipping_name: shipping.shipping_name,
                  shipping_key: shipping.shipping_key,
                };
              }
            })
          ),
        };
        shippingDetailsToSend.shipping_details.length > 0 &&
          dispatch(
            addNewSiteShippingDetails({
              shippingDetailsParams: shippingDetailsToSend,
            })
          );
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[4]:
        const menuSettingsToSend = {
          header_menu:
            values.menu_details?.header_menu[0] !== null
              ? values.menu_details?.header_menu?.[0]?.value
              : "",
          footer_menu:
            values.menu_details?.footer_menu[0] !== null
              ? values.menu_details?.footer_menu?.[0]?.value
              : "",
          mega_menu:
            values.menu_details?.mega_menu[0] !== null
              ? values.menu_details?.mega_menu?.[0]?.value
              : "",
        };
        dispatch(
          updateExistingSiteMenuSettings({
            menuSettingsParams: menuSettingsToSend,
          })
        );
        return;
    }
  };

  useEffect(() => {
    switch (activeTab) {
      case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
        dispatch(fetchSiteDetails());
        dispatch(fetchPages({ active: true }));
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
        dispatch(fetchSiteSMSDetails());
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
        dispatch(fetchSitePaymentDetails());
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
        dispatch(fetchSiteShippingDetails());
        return;
      case SITE_MANAGEMENT_CONSTANTS.TABS[4]:
        dispatch(fetchsiteMenuSettings());
        dispatch(fetchMenus({ active: true }));
        return;
    }
    return () => {
      switch (activeTab) {
        case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
          dispatch(resetSiteManagementState());
          return;
        case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
          return;
        case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
          return;
        case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
          return;
        case SITE_MANAGEMENT_CONSTANTS.TABS[4]:
          dispatch(setSiteMenuSettings(null));
          return;
      }
    };
  }, [activeTab]);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader={SITE_MANAGEMENT_CONSTANTS.HEADER} />
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={InitialValues}
            validationSchema={SiteManagementSchema(activeTab)}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Form>
                  <ScrollToFieldError />
                  <div className="row">
                    <div className="col-12 mb-3">
                      <Tabs
                        tabs={SITE_MANAGEMENT_CONSTANTS.TABS}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    </div>
                    {renderForms(props, ArrayHelperRef.current)}
                    <div className="col-md-6">
                      <Button
                        loading={
                          status === STATUSES.LOADING && props.isSubmitting
                        }
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary align-self-center w-25"}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={SITE_MANAGEMENT_CONSTANTS.DELETE_API}
        message={SITE_MANAGEMENT_CONSTANTS.DELETE_API_MSG}
        confirmClick={() => {
          setConfirmModal(false);
          currentItemToDelete.removeIndex();
          switch (activeTab) {
            case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
              return;
            case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
              dispatch(deleteSiteSMSDetails(currentItemToDelete.id));
              return;
            case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
              dispatch(deleteSitePaymentDetails(currentItemToDelete.id));
              return;
            case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
              dispatch(deleteSiteShippingDetails(currentItemToDelete.id));
              return;
            default:
              return;
          }
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { SiteManagement };
