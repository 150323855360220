import { Card, CardBody, Modal } from "components/core-components";
import React from "react";
import {
  GENERAL_CONSTANTS,
  makePDFWindow,
  POS_CART_CONSTANTS,
  POS_MODAL_CONSTANTS,
  useAppSelector,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrintOptionsModal = ({ modal, setModal }: IProps) => {
  const {
    order: { printOptions },
  } = useAppSelector((state) => state.root);

  const bill = printOptions.find(
    (option) => option.type === POS_CART_CONSTANTS.BILL_CONTENT
  );

  const balanceCreditNote = printOptions.find(
    (option) => option.type === POS_CART_CONSTANTS.BALANCE_CREDIT_CONTENT
  );

  return (
    <Modal
      modalClassNames="w-100"
      modalSize={GENERAL_CONSTANTS.LARGE}
      title={POS_MODAL_CONSTANTS.PRINT}
      showModal={modal}
      onClickHandler={() => {
        setModal(GENERAL_CONSTANTS.FALSE);
      }}
    >
      <Card>
        <CardBody>
          <div className="d-flex flex-row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-sm text-center p-1">
                  <button
                    className="btn btn-dark btn-primary text-sm w-100"
                    onClick={() => {
                      makePDFWindow(bill.content);
                    }}
                  >
                    {POS_CART_CONSTANTS.BILL_LABEL}
                  </button>
                </div>
                <div className="col-6 text-sm text-center p-1">
                  <button
                    className="btn btn-dark btn-primary text-sm w-100"
                    onClick={() => {
                      makePDFWindow(balanceCreditNote.content);
                    }}
                  >
                    {POS_CART_CONSTANTS.BALANCE_CREDIT}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export { PrintOptionsModal };
