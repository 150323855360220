import { createSlice } from "@reduxjs/toolkit";
import { GENERAL_CONSTANTS, STATUSES } from "utils/constants";
import {
  delProductSpecificAlias,
  delSpecificOrderDeliveryDays,
  delSpecificStore,
  delVendor,
  delVendorContact,
  delVendorStoreSpecificProduct,
  getSpecificVendor,
  getVendor,
  setVendor,
  updateVendor,
} from "apis/restApis";
import { PaginationState, Vendor } from "utils/types";
import { updateValues } from "store/commonSlice";
import { getCity, getCountry, getState } from "../locationSlice";
import { getAccountTypes } from "store/ManageBanks/bankAccountTypeSlice";
import { getAddressTypes } from "store/addressTypeSlice";
import { getBanks } from "store/ManageBanks/bankSlice";
import { getBranches } from "store/ManageBranches/branchSlice";
import { getDeliveryPolicies } from "./deliveryPolicySlice";
import { getPaymentModes } from "store/ManagePayments/paymentModeSlice";
import { getPaymentTerms } from "store/ManagePayments/paymentTermSlice";
import { getProducts } from "store/ManageProducts/productSlice";
import { getSupplierTypes } from "./supplierTypeSlice";
import { getTaxes } from "store/ManageProducts/productTaxSlice";
import { getUOMs } from "store/uomSlice";

const initialState = {
  vendors: [] as Vendor[],
  vendorToEdit: {} as Vendor,
  status: STATUSES.IDLE as string,
  error: null,
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    getAllVendors: (state, action) => {
      state.vendors = action.payload;
    },
    addVendor: (state, action) => {
      state.vendors.push(action.payload);
    },
    removeVendor: (state, action) => {
      state.vendors = state.vendors.filter(
        (vendor) => vendor.id !== action.payload
      );
    },
    editVendor: (state, action) => {
      const { id } = action.payload;
      const index = state.vendors.findIndex((vendor) => vendor.id === id);
      if (index !== -1) {
        state.vendors[index] = action.payload;
      }
    },
    setVendorToEdit: (state, action) => {
      state.vendorToEdit = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllVendors,
  addVendor,
  removeVendor,
  editVendor,
  setVendorToEdit,

  setStatus,
  setError,
} = vendorSlice.actions;

export default vendorSlice.reducer;

export function getVendors(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getVendorsThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    getVendor(pageNo, query)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(updateValues(response.result));
        dispatch(getAllVendors(response.result.results));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function getIndividualVendor(id: number, edit?: boolean) {
  return async function getIndividualVendorThunk(
    dispatch: any,
    getReduxState: any
  ) {
    dispatch(setStatus(STATUSES.LOADING));
    getSpecificVendor(id)
      .then((response: any) => {
        dispatch(setVendorToEdit(response.result));
        if (edit) {
          dispatch(FetchFormOptionsData());
          const {
            addressType: { addressTypes: addressTypeData },
            bank: { banks: bankData },
            bankAccountType: { bankAccountTypes: bankAccountTypeData },
            branch: { branch: branchData },
            deliveryPolicy: { deliveryPolicy: deliveryPolicyData },
            location: {
              cities: cityData,
              states: stateData,
              countries: countryData,
            },
            paymentMode: { paymentMode: paymentModeData },
            paymentTerm: { paymentTerms: paymentTermData },
            product: { products: productData },
            productTax: { productTax: productTaxData },
            supplierType: { supplierType: supplierTypeData },
            uom: { uom: uomData },
          } = getReduxState().root;

          const dataToEdit = {
            name: response.result.name,
            company_name: response.result.company_name,
            email: response.result.email,
            phone_numbers:
              response.result.phone_numbers &&
              response.result.phone_numbers.length > 0 &&
              response.result.phone_numbers.map((item) => ({
                ...item,
                phone_number: item.contact_number,
              })),
            whatsapp_number: response.result.whatsapp_number,
            remarks: response.result.remarks,
            supplier_type:
              response.result.supplier_type &&
              supplierTypeData
                .filter((item) => item.id === response.result.supplier_type)
                .map((res) => ({ value: res.id, label: res.name })),
            apply_tds: [
              {
                value: response.result.apply_tds,
                label: response.result.apply_tds === 1 ? "Yes" : "No",
              },
            ],
            payment_mode:
              response.result.payment_mode &&
              paymentModeData
                .filter((item) => item.id === response.result.payment_mode)
                .map((res) => ({ value: res.id, label: res.payment_mode })),
            credit_limit: response.result?.credit_limit,
            payment_terms:
              response.result.payment_terms &&
              paymentTermData
                .filter((item) => item.id === response.result.payment_terms)
                .map((res) => ({ value: res.id, label: res.term_name })),
            bank_details: response.result &&
              response.result.bank_details && {
                ...response.result.bank_details[0],
                account_type:
                  response.result.bank_details &&
                  response.result.bank_details.length > 0 &&
                  response.result.bank_details[0] &&
                  response.result.bank_details[0].account_type &&
                  bankAccountTypeData
                    .filter(
                      (item) =>
                        item.id === response.result.bank_details[0].account_type
                    )
                    .map((res) => ({ value: res.id, label: res.type })),
                bank_name:
                  response.result.bank_details &&
                  response.result.bank_details.length > 0 &&
                  response.result.bank_details[0] &&
                  bankData
                    .filter(
                      (item) =>
                        item.bank_name ===
                        response.result.bank_details[0].bank_name
                    )
                    .map((res) => ({ value: res.id, label: res.bank_name })),
                account_number:
                  response.result.bank_details &&
                  response.result.bank_details.length > 0 &&
                  response.result.bank_details[0].account_number,
                ifsc_code:
                  response.result.bank_details &&
                  response.result.bank_details.length > 0 &&
                  response.result.bank_details[0].ifsc_code,
                branch_name:
                  response.result.bank_details &&
                  response.result.bank_details.length > 0 &&
                  response.result.bank_details[0].branch_name,
              },
            address:
              response.result.address &&
              response.result.address.map((item, index) => ({
                ...response.result.address[index],
                address_type:
                  response.result.address[index].address_type &&
                  addressTypeData
                    .filter(
                      (item) =>
                        item.id === response.result.address[index].address_type
                    )
                    .map((res) => ({ value: res.id, label: res.type })),
                address_line_1: item.address_line_1,
                address_line_2: item.address_line_2,
                country:
                  item.country &&
                  countryData
                    .filter((res) => res.id === item.country)
                    .map((res) => ({ value: res.id, label: res.country })),
                state:
                  item.country &&
                  item.state &&
                  stateData
                    .filter((res) => res.id === item.state)
                    .map((res) => ({ value: res.id, label: res.state })),
                city:
                  item.state &&
                  item.city &&
                  cityData
                    .filter((res) => res.id === item.city)
                    .map((res) => ({ value: res.id, label: res.city })),
                pincode: item.pincode,
              })),
            gstn: response.result.gstn,
            pan: response.result.pan,
            contact_person_name: response.result.contact_person_name,
            contact_person_email: response.result.contact_person_email,
            contact_person_number: response.result.contact_person_number,
            commission: response.result.commission,
            delivery_policy:
              response.result.delivery_policy &&
              deliveryPolicyData
                .filter((item) => item.id === response.result.delivery_policy)
                .map((res) => ({ value: res.id, label: res.policy })),
            stores:
              response.result.stores &&
              response.result.stores.length > 0 &&
              response.result.stores.map((item) => ({
                ...item,
                store:
                  item.store &&
                  branchData
                    .filter((res) => res.id === item.store)
                    .map((res) => ({ value: res.id, label: res.store_name })),
                products:
                  item.products &&
                  item.products.length > 0 &&
                  item.products.map((productItem) => ({
                    ...productItem,
                    product: productItem.product
                      ? [
                          {
                            value: productItem.product.id,
                            label: productItem.product.print_name,
                          },
                        ]
                      : [],
                    purchase_price: productItem?.purchase_price,
                    landing_cost: productItem?.landing_cost,
                    purchase_tax_included: item.purchase_tax_included
                      ? [
                          {
                            value: productItem.purchase_tax_included,
                            label:
                              productItem.purchase_tax_included === 1
                                ? "Yes"
                                : "No",
                          },
                        ]
                      : [],
                    gst_included: productItem.gst_included
                      ? [
                          {
                            value: productItem.gst_included,
                            label:
                              productItem.gst_included === 1 ? "Yes" : "No",
                          },
                        ]
                      : [],
                    uom:
                      productItem.uom &&
                      uomData
                        .filter((res) => res.id === productItem.uom)
                        .map((res) => ({ value: res.id, label: res.uom_name })),
                    moq: productItem.moq,
                    moq_pieces: productItem.moq_pieces,
                    preferred_ordering_days:
                      productItem.preferred_ordering_days &&
                      productItem.preferred_ordering_days.length > 0
                        ? productItem.preferred_ordering_days.map((item) => ({
                            value: item.id,
                            label: item.days,
                          }))
                        : [],
                    preferred_delivery_days:
                      productItem.preferred_delivery_days &&
                      productItem.preferred_delivery_days.length > 0
                        ? productItem.preferred_delivery_days.map((item) => ({
                            value: item.id,
                            label: item.days,
                          }))
                        : [],
                    alias:
                      productItem.alias && productItem.alias.length > 0
                        ? productItem.alias.map((alias) => ({
                            ...alias,
                            product_alias_name: alias.product_alias_name,
                          }))
                        : [],
                  })),
              })),
            is_active: response.result.is_active
              ? [
                  {
                    value: response.result.is_active,
                    label:
                      response.result.is_active === 1
                        ? GENERAL_CONSTANTS.ACTIVE
                        : GENERAL_CONSTANTS.INACTIVE,
                  },
                ]
              : [],
          };

          dispatch(setVendorToEdit(dataToEdit));
        }
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function FetchFormOptionsData() {
  return async function FetchFormOptionsDataThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    const data = await Promise.all([
      dispatch(getAccountTypes()),
      dispatch(getAddressTypes()),
      dispatch(getBanks({ active: true })),
      dispatch(getBranches({ active: true })),
      dispatch(getCountry()),
      dispatch(getState()),
      dispatch(getCity()),
      dispatch(getDeliveryPolicies(true)),
      dispatch(getPaymentModes(true)),
      dispatch(getPaymentTerms(true)),
      dispatch(getSupplierTypes(true)),
      dispatch(getTaxes(true)),
      dispatch(getUOMs(true)),
    ])
      .then(() => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function addNewVendor(vendorParams: Vendor) {
  return async function addNewVendorThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    setVendor(vendorParams)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(addVendor(response.result));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function updateExistingVendor(id: number, vendorParams: Vendor) {
  return async function updateExistingVendorThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    updateVendor(id, vendorParams)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(editVendor(response.result));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteVendor(id: number) {
  return async function deleteVendorThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delVendor(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(editVendor(response.result));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteVendorContact(id: number) {
  return async function deleteVendorContactThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delVendorContact(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteVendorStore(delParams: {
  vendor: number;
  store: number;
}) {
  return async function deleteVendorStoreThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delSpecificStore(delParams)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteStoreSpecificProduct(delParams: {
  store: number;
  product: number;
}) {
  return async function deleteStoreSpecificProductThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delVendorStoreSpecificProduct(delParams)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteProductSpecificAlias(id: number) {
  return async function deleteProductSpecificAliasThunk(dispatch: any) {
    delProductSpecificAlias(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function deleteOrderDeliveryDays(id: number) {
  return async function deleteOrderDeliveryDaysThunk(dispatch: any) {
    delSpecificOrderDeliveryDays(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
