import React from "react";

interface CardBodyProps {
  id?: string;
  children: React.ReactNode;
  bodyClassNames?: string;
}

const CardBody: React.FC<CardBodyProps> = ({
  id,
  children,
  bodyClassNames,
}) => {
  return (
    <div
      id={id}
      className={`card-body ${bodyClassNames ? bodyClassNames : ""}`}
    >
      {children}
    </div>
  );
};

export { CardBody };
