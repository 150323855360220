import { createSlice } from "@reduxjs/toolkit";
import {
  delLabel,
  delLabelPrinterMapping,
  generateBarcode,
  getActiveLabelTemplate,
  getActiveLabels,
  getActivePrintSize,
  getActivePrintType,
  getLabelTemplate,
  getLabels,
  getPrintSize,
  getPrintType,
  getSpecificLabel,
  setLabel,
  updateLabel,
} from "apis/restApis";
import { FormikHelpers, setIn } from "formik";
import {
  ILabelState,
  ILabelTemplateState,
  IPrintSizeState,
  IPrintTypeState,
  STATUSES,
  Toast,
} from "utils";

// Label Name
// Print Type (1ups, 2 ups, 3 ups, 4 ups, A4)
// Size (50x25, 102x60)
// Printer Mapping

const initialState = {
  label: [] as ILabelState[],
  individualLabel: {} as ILabelState,
  label_template: [] as ILabelTemplateState[],
  print_type: [] as IPrintTypeState[],
  print_size: [] as IPrintSizeState[],
  status: STATUSES.IDLE as string,
  error: null,
};

const labelSLice = createSlice({
  name: "label",
  initialState,
  reducers: {
    getLabel(state, action) {
      state.label = action.payload;
    },
    addLabel(state, action) {
      state.label.push(action.payload);
    },
    removeLabel(state, action) {
      state.label = state.label.filter((label) => label.id !== action.payload);
    },
    editLabel(state, action) {
      const { id } = action.payload;
      const index = state.label.findIndex((label) => label.id === id);
      if (index !== -1) {
        state.label[index] = action.payload;
      }
    },
    setIndividualLabel(state, action) {
      state.individualLabel = action.payload;
    },

    getPrintTypes(state, action) {
      state.print_type = action.payload;
    },
    addPrintType(state, action) {
      state.print_type.push(action.payload);
    },
    getPrintSizes(state, action) {
      state.print_size = action.payload;
    },
    addPrintSize(state, action) {
      state.print_size.push(action.payload);
    },
    getLabelTemplates(state, action) {
      state.label_template = action.payload;
    },
    addLabelTemplate(state, action) {
      state.label_template.push(action.payload);
    },

    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  getLabel,
  addLabel,
  removeLabel,
  editLabel,
  setIndividualLabel,

  getPrintTypes,
  addPrintType,
  getPrintSizes,
  addPrintSize,
  getLabelTemplates,
  addLabelTemplate,

  setStatus,
  setError,
} = labelSLice.actions;

export default labelSLice.reducer;

export function getAllLabels(
  active?: boolean,
  pageNo: number = 1,
  query: string = ""
) {
  return async function getAllLabelsThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveLabels()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getLabel(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getLabels(pageNo, query)
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getLabel(response.result.results));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function getIndividualLabel(
  id: number,
  handleLabelChange?: (params) => void
) {
  return async function getIndividualLabelThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    getSpecificLabel(id)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(setIndividualLabel(response.result));
        handleLabelChange(response.result);
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function addNewLabel(
  label: ILabelState,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function addNewLabelThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    setLabel(label)
      .then((response: any) => {
        dispatch(addLabel(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingLabel(
  id: number,
  label: ILabelState,
  actions: FormikHelpers<any>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function updateExistingLabelThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    updateLabel(id, label)
      .then((response: any) => {
        dispatch(editLabel(response.result));
        setShow(false);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function deleteLabel(id: number) {
  return async function deleteLabelThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delLabel(id)
      .then((response: any) => {
        dispatch(editLabel(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      })
      .finally(() => {
        dispatch(setStatus(STATUSES.IDLE));
      });
  };
}

export function deleteLabelPrinterMapping(delParams: {
  label: number;
  printer: number;
}) {
  return async function deleteLabelPrinterMappingThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    delLabelPrinterMapping(delParams)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}

export function getAllPrintType(active?: boolean) {
  return async function getAllPrintTypeThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActivePrintType()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getPrintTypes(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getPrintType()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getPrintTypes(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function getAllPrintSize(active?: boolean) {
  return async function getAllPrintSizeThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActivePrintSize()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getPrintSizes(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getPrintSize()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getPrintSizes(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function getAllLabelTemplates(active?: boolean) {
  return async function getAllLabelTemplatesThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveLabelTemplate()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getLabelTemplates(response.result));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getLabelTemplate()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getLabelTemplates(response.result.results));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function generateBarcodeForGivenLabel(barcodeParams: Object) {
  return async function generateBarcodeThunk(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    generateBarcode(barcodeParams);
  };
}
