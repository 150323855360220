import React from "react";

interface CardHeaderProps {
  children: React.ReactNode;
  headerClassNames?: string;
}

const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  headerClassNames,
}) => {
  return (
    <div className={`card-header ${headerClassNames ? headerClassNames : ""}`}>
      {children}
    </div>
  );
};

export { CardHeader };
