import React from "react";
import { Button, FontIcon, Label } from "./core-components";
import { BUTTON_CONSTANTS, useAppDispatch } from "utils";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteImage } from "store/commonSlice";

interface FileUploadProps {
  name: string;
  label?: string;
  value?: any;
  inputClassNames?: string;
  multipleUpload?: boolean;
  onChange: any;
  onBlur?: any;
  preview?: any;
  hideInput?: boolean;
  deleteImage?: () => void;
  isRequired?: boolean;
  isDisabled?: boolean;
}

const FileUpload = ({
  name,
  label,
  value,
  inputClassNames,
  multipleUpload,
  onChange,
  onBlur,
  preview,
  hideInput = false,
  isRequired = false,
  isDisabled = false,
  deleteImage: deleteImageFunction,
  ...props
}: FileUploadProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className="d-flex flex-column form-group my-2">
      <Label htmlFor={name} label={label} labelClassNames="form-label">
        {isRequired ? <span className="text-danger">*</span> : ""}
      </Label>
      {preview && (
        <div
          className="hover_img_container col-3"
          style={{
            borderRadius: 2,
            border: "1px solid #eaeaea",
          }}
        >
          <div className="hover_img_middle">
            <Button
              text={<FontIcon icon={faTrash} />}
              btnClassNames="hover_img_button btn-sm btn btn-danger"
              onClickHandler={() => {
                dispatch(deleteImage(preview));
                deleteImageFunction && deleteImageFunction();
              }}
              type={BUTTON_CONSTANTS.BUTTON}
            />
          </div>
          <img src={preview} className="hover_img img-responsive w-100 h-100" />
        </div>
      )}
      {!hideInput && (
        <input
          type="file"
          className={`form-control ${inputClassNames ? inputClassNames : ""}`}
          multiple={multipleUpload}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={isDisabled}
          {...props}
        />
      )}
    </div>
  );
};

export { FileUpload };
