import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import React, { useEffect, useState } from "react";
import { deleteBrand, getBrands } from "store/ManageProducts/productBrandSlice";
import { updatePageNo } from "store/commonSlice";
import {
  BUTTON_CONSTANTS,
  PRODUCT_BRAND_CONSTANTS,
  STATUSES,
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const BrandList = ({ setEdit, setShow, setConfirmModal, setCurrentBrand }) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    productBrand: { productBrand: brandData, status },
  } = useAppSelector((state) => state.root);

  const brandPermission = permissions.find(
    (p) => p.module_name === "Manage Product Brands"
  );

  const [query, setQuery] = useState("");

  const update_page = (pageNo: number) => {
    dispatch(getBrands(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_COLS;

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={brandPermission?.perm_add}
          path={PRODUCT_BRAND_CONSTANTS.PATH}
          label={PRODUCT_BRAND_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : brandData.length > 0 ? (
              brandData.map((brand, index) => {
                return (
                  <tr key={index}>
                    <td>{page_size * (current_page - 1) + index + 1}</td>
                    <td>{brand.brand_name}</td>
                    <td>{brand.brand_code ? brand.brand_code : "-"}</td>
                    <td>
                      <Status is_active={brand.is_active} />
                    </td>
                    <td>
                      <Button
                        text={<FontIcon icon={faPenToSquare} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        isDisabled={!brandPermission?.perm_edit}
                        btnClassNames={"btn btn-info btn-sm mr-2"}
                        onClickHandler={() => {
                          let editData = {
                            id: brand.id,
                            brand_name: brand.brand_name,
                            brand_code: brand.brand_code,
                            brand_description: brand.brand_description,
                            is_active: [
                              {
                                label:
                                  brand.is_active * 1 === 1
                                    ? "Active"
                                    : "Inactive",
                                value: brand.is_active,
                              },
                            ],
                          };
                          setShow(true);
                          setEdit(editData);
                        }}
                      />
                      {brand.is_active ? (
                        <Button
                          text={<FontIcon icon={faBan} />}
                          type={BUTTON_CONSTANTS.BUTTON}
                          isDisabled={!brandPermission?.perm_delete}
                          btnClassNames={"btn btn-danger btn-sm"}
                          onClickHandler={() => {
                            setConfirmModal(true);
                            setCurrentBrand(Number(brand.id));
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={status === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { BrandList };
