import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  MultiSelect,
  TextArea,
} from "components";
import { ErrorMessage, Field, FormikProps } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { getBarcodeTypes } from "store/ManageProducts/barcodeSlice";
import { getCategories } from "store/ManageProducts/productCategorySlice";
import { getSubCategories } from "store/ManageProducts/productSubCategorySlice";
import { getHSN } from "store/hsnCodesSlice";
import {
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  PRODUCT_GROUPS_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  props: FormikProps<any>;
}

const ProductGroupDetails = ({ props }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    barcode: { barcodeTypes: barCodeTypeData, status: barcodeTypeStatus },
    // hsnCode: { hsnCodes: hsnCodeData, status: hsnCodeStatus },
    productCategory: {
      productCategory: productCategoryData,
      status: productCategoryStatus,
    },
    productSubCategory: {
      productSubCategory: productSubCategoryData,
      status: productSubCategoryStatus,
    },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();
  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h5 className="card-title">
            {PRODUCT_GROUPS_CONSTANTS.CARD_TITLE_1}
          </h5>
        </CardHeader>
        <CardBody>
          {/* Print Hamper Name and HAmper Name */}
          <div className="row">
            <div className="col-md-6">
              <Field
                as={CustomInput}
                isRequired={true}
                label={
                  PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRINT_HAMPER_NAME_LABEL
                }
                isDisabled={id !== undefined}
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRINT_HAMPER_NAME}
                id={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRINT_HAMPER_NAME}
                placeholder={
                  PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRINT_HAMPER_NAME_PLACEHOLDER
                }
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRINT_HAMPER_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <Field
                as={CustomInput}
                isRequired={true}
                label={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_NAME_LABEL}
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_NAME}
                id={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_NAME}
                placeholder={
                  PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_NAME_PLACEHOLDER
                }
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          {/* Hamper Code and HSN code */}
          {/* <div className="row">
           
          </div> */}
          {/* Category and Sub-category */}
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                select={false}
                isRequired={true}
                label={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_CATEGORY_LABEL}
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_CATEGORY}
                options={productCategoryData?.map((item, index) => ({
                  value: item.id,
                  label: item.category_name,
                }))}
                onFocusHandler={() => {
                  productCategoryData.length === 0 &&
                    dispatch(getCategories(true));
                }}
                isLoading={productCategoryStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_CATEGORY}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={false}
                isRequired={true}
                label={
                  PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_SUB_CATEGORY_LABEL
                }
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_SUB_CATEGORY}
                options={productSubCategoryData?.map((item, index) => ({
                  value: item.id,
                  label: item.sub_category_name,
                }))}
                onFocusHandler={() => {
                  productSubCategoryData.length === 0 &&
                    dispatch(getSubCategories(true));
                }}
                isLoading={productSubCategoryStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_SUB_CATEGORY}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          {/* BarcodeType */}
          <div className="row">
            {id && (
              <div className="col-md-6">
                <Field
                  as={CustomInput}
                  isRequired={true}
                  isDisabled={true}
                  label={
                    PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_CODE_LABEL
                  }
                  name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_CODE}
                  id={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_CODE}
                  placeholder={
                    PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_CODE_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HAMPER_CODE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            )}
            {/* <div className="col-md-6">
              <MultiSelect
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HSN_CODES}
                isRequired={true}
                label={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HSN_CODES_LABEL}
                options={hsnCodeData.map((item) => ({
                  value: item.id,
                  label: item.hsn_name,
                }))}
                onFocusHandler={() => {
                  hsnCodeData.length === 0 && dispatch(getHSN(true));
                }}
                isLoading={hsnCodeStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_HSN_CODES}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div> */}
            <div className="col-md-6">
              <MultiSelect
                select={false}
                isRequired={true}
                label={
                  PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_BARCODE_TYPE_LABEL
                }
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_BARCODE_TYPE}
                options={barCodeTypeData.map((item) => ({
                  value: item.id,
                  label: item.barcode_type,
                }))}
                onFocusHandler={() => {
                  barCodeTypeData.length === 0 &&
                    dispatch(getBarcodeTypes(true));
                }}
                isLoading={barcodeTypeStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_BARCODE_TYPE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={false}
                isRequired={true}
                label={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_STATUS_LABEL}
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_STATUS}
                options={GENERAL_CONSTANTS.STATUS_OPTIONS}
              />
              <ErrorMessage
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_STATUS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          {/* Description */}
          <div className="row">
            <div className="col-md-12">
              <TextArea
                label={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_DESCRIPTION_LABEL}
                name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_DESCRIPTION}
                id={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_DESCRIPTION}
                placeholder={
                  PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_DESCRIPTION_PLACEHOLDER
                }
                rows={2}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { ProductGroupDetails };
