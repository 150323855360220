import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ConfirmModal,
  Container,
  ContentHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import React, { useEffect, useState } from "react";
import {
  BUTTON_CONSTANTS,
  MENU_CONSTANTS,
  PERMISSION_CONSTANTS,
  ROUTES_CONSTANTS,
  ScrollToTop,
  STATUSES,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";
import { deleteMenu, fetchMenus, resetMenuState } from "store/Ecommerce";
import {
  faBan,
  faCircleNotch,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    root: {
      auth: { permissions: permissionData },
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
    },
    ecommerce: {
      menu: { menus, status: menuStatus },
    },
  } = useAppSelector((state) => state);

  const [print, setPrint] = useState<number>(0);

  const [query, setQuery] = useState("");

  const [currentMenu, setCurrentMenu] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const menuPermission = permissionData.find(
    (p) => p.module_name === PERMISSION_CONSTANTS.MENU
  );

  const update_page = (pageNo: number) => {
    dispatch(
      fetchMenus({
        active: false,
        pageNo: pageNo,
        query: query,
      })
    );
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
      dispatch(resetMenuState());
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader={MENU_CONSTANTS.HEADER} />
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={menuPermission.perm_add}
                path={MENU_CONSTANTS.PATH}
                label={MENU_CONSTANTS.ADD_HEADER}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader
                  cols={MENU_CONSTANTS.COLS.map((item) => {
                    if (item.list) {
                      return item.name;
                    }
                  })}
                />
                <TableBody>
                  {menuStatus === STATUSES.LOADING ? (
                    <tr>
                      <td
                        colSpan={MENU_CONSTANTS.COLS.length}
                        className="text-center"
                      >
                        <Loader />
                      </td>
                    </tr>
                  ) : menus && menus.length > 0 ? (
                    menus.map((menu, index) => {
                      const deleteItem =
                        commonStatus.type === MENU_CONSTANTS.DELETE_STATE &&
                        commonStatus.state === STATUSES.LOADING;
                      return (
                        <tr key={index}>
                          <td>{page_size * (current_page - 1) + index + 1}</td>
                          <td>{menu.menu_title}</td>
                          <td>
                            <Status is_active={menu.is_active} />
                          </td>
                          <td>
                            <Button
                              text={<FontIcon icon={faPenToSquare} />}
                              isDisabled={!menuPermission?.perm_edit}
                              type={BUTTON_CONSTANTS.BUTTON}
                              btnClassNames={"btn btn-info btn-sm mr-2"}
                              onClickHandler={() =>
                                navigate(ROUTES_CONSTANTS.EDIT + "/" + menu.id)
                              }
                            />
                            {menu.is_active ? (
                              <Button
                                text={
                                  deleteItem && print === index ? (
                                    <FontIcon
                                      icon={faCircleNotch}
                                      iconClassNames={
                                        "fa-solid fa-circle-notch fa-spin"
                                      }
                                    />
                                  ) : (
                                    <FontIcon icon={faBan} />
                                  )
                                }
                                isDisabled={
                                  !menuPermission?.perm_delete || deleteItem
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                btnClassNames={"btn btn-danger btn-sm"}
                                onClickHandler={() => {
                                  setPrint(index);
                                  setConfirmModal(true);
                                  setCurrentMenu(Number(menu.id));
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoData len={MENU_CONSTANTS.COLS.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={menuStatus === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={MENU_CONSTANTS.DELETE_MENU}
        message={MENU_CONSTANTS.DELETE_MENU_MSG}
        confirmClick={() => {
          dispatch(deleteMenu(currentMenu));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Menu };
