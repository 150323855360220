import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delCategory,
  getActiveCategory,
  getCategory,
  getSpecificCategory,
  setCategory,
  updateCategory,
} from "apis/restApis";
import { CategoryItem, PaginationState } from "utils/types";
import { updateValues } from "store/commonSlice";
import { FormikHelpers } from "formik";
import { Toast } from "utils";

const initialState = {
  productCategory: [] as CategoryItem[],
  status: STATUSES.IDLE as string,
  error: null,
};

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {
    getAllProductCategory(state, action) {
      state.productCategory = action.payload;
    },
    addProductCategory(state, action) {
      state.productCategory.push(action.payload);
    },
    removeProductCategory(state, action) {
      state.productCategory = state.productCategory.filter(
        (category) => category.id !== action.payload
      );
    },
    editProductCategory(state, action) {
      const { id } = action.payload;
      const index = state.productCategory.findIndex(
        (category) => category.id === id
      );
      if (index !== -1) {
        state.productCategory[index] = action.payload;
      }
    },

    setCategoryStatus(state, action) {
      state.status = action.payload;
    },
    setCategoryError(state, action) {
      state.error = action.payload;
    },

    resetCategoryState: (state) => {
      state.productCategory = [] as CategoryItem[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllProductCategory,
  addProductCategory,
  removeProductCategory,
  editProductCategory,

  setCategoryStatus,
  setCategoryError,
  resetCategoryState,
} = productCategorySlice.actions;

export default productCategorySlice.reducer;

export function getCategories(
  active?: boolean,
  pageNo: number = 1,
  query: string = "",
  cms: boolean = false
) {
  return async function getCategoryThunk(dispatch: any) {
    dispatch(setCategoryStatus(STATUSES.LOADING));
    active
      ? getActiveCategory(cms)
          .then((response: any) => {
            dispatch(setCategoryStatus(STATUSES.IDLE));
            dispatch(getAllProductCategory(response.result));
          })
          .catch((error) => {
            dispatch(setCategoryStatus(STATUSES.ERROR));
            dispatch(setCategoryError(error.message));
          })
      : getCategory(pageNo, query)
          .then((response: any) => {
            dispatch(setCategoryStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllProductCategory(response.result.results));
          })
          .catch((error) => {
            dispatch(setCategoryStatus(STATUSES.ERROR));
            dispatch(setCategoryError(error.message));
          });
  };
}

export function getIndividualCategory(id: number) {
  return async function getIndividualCategoryThunk(dispatch: any) {
    dispatch(setCategoryStatus(STATUSES.LOADING));
    getSpecificCategory(id)
      .then((response: any) => {
        dispatch(setCategoryStatus(STATUSES.IDLE));
        return response.result;
      })
      .catch((error) => {
        dispatch(setCategoryStatus(STATUSES.ERROR));
        dispatch(setCategoryError(error.message));
      });
  };
}

export function addNewCategory(
  categoryParams: CategoryItem,
  action: FormikHelpers<CategoryItem>
) {
  return async function addNewCategoryThunk(dispatch: any) {
    dispatch(setCategoryStatus(STATUSES.LOADING));
    setCategory(categoryParams)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
        action.resetForm();
        // dispatch(addProductCategory(response.result));
        dispatch(getCategories());
      })
      .catch((error) => {
        dispatch(setCategoryStatus(STATUSES.ERROR));
        dispatch(setCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCategoryStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingCategory(
  id: number,
  categoryParams: CategoryItem,
  action: FormikHelpers<CategoryItem>
) {
  return async function updateExistingCategoryThunk(dispatch: any) {
    dispatch(setCategoryStatus(STATUSES.LOADING));
    updateCategory(id, categoryParams)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
        action.resetForm();
        // dispatch(editProductCategory(response.result));
        dispatch(getCategories());
      })
      .catch((error) => {
        dispatch(setCategoryStatus(STATUSES.ERROR));
        dispatch(setCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCategoryStatus(STATUSES.IDLE));
      });
  };
}

export function deleteCategory(id: number) {
  return async function deleteCategoryThunk(dispatch: any) {
    dispatch(setCategoryStatus(STATUSES.LOADING));
    delCategory(id)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
        // dispatch(editProductCategory(response.result));
        dispatch(getCategories());
      })
      .catch((error) => {
        dispatch(setCategoryStatus(STATUSES.ERROR));
        dispatch(setCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCategoryStatus(STATUSES.IDLE));
      });
  };
}

export function getMultipleCategoriesDetails(ids: number[]) {
  return async function getMultipleCategoriesDetailsThunk(dispatch: any) {
    dispatch(setCategoryStatus(STATUSES.LOADING));
    Promise.all(
      ids.map((id) => {
        return getSpecificCategory(id).then((response: any) => ({
          value: response.result.id,
          label: response.result.category_name,
        }));
      })
    )
      .then((response: any) => {
        dispatch(setCategoryStatus(STATUSES.IDLE));
        return response;
      })
      .catch((error) => {
        dispatch(setCategoryStatus(STATUSES.ERROR));
        dispatch(setCategoryError(error.message));
      });
  };
}
