import { Loader } from "components/Loader";
import { Card, CardBody, Modal } from "components/core-components";
import React from "react";
import { AddPOSProduct, AddProduct } from "store/POS";
import {
  POS_CONSTANTS,
  STATUSES,
  TPOSHeaderQuery,
  transformProduct,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setQuery?: React.Dispatch<
    React.SetStateAction<{
      query: string;
      type: TPOSHeaderQuery;
    }>
  >;
  focusRef?: any;
}

const ChooseBatchModal = ({ modal, setModal, focusRef, setQuery }: IProps) => {
  const dispatch = useAppDispatch();
  const {
    branch: { selectedStore },
    product: {
      batchOptions,
      status: productStatus,
      productToEdit: selectedProduct,
    },
  } = useAppSelector((state) => state.root);

  const currentProduct: any = selectedProduct;

  return (
    <Modal
      modalClassNames="w-100"
      title="Choose Batch"
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
        focusRef && focusRef?.current?.focus();
        setQuery && setQuery({ query: "", type: "product" });
      }}
    >
      <div className="col-12">
        {productStatus === STATUSES.LOADING ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : Object.keys(selectedProduct).length === 0 ? (
          batchOptions &&
          batchOptions.batch.length &&
          batchOptions.batch.map((price, index) => (
            <Card
              key={index}
              onclickHandler={() => {
                dispatch(AddPOSProduct(batchOptions, index));
                setModal(false);
              }}
            >
              <CardBody>
                <div className="row">
                  <div className="col-6 text-start">{price.batch}</div>
                  <div className="col-6 text-end">
                    {batchOptions?.net_weight} {batchOptions?.uom?.uom_code} |{" "}
                    {POS_CONSTANTS.RUPEE_SIGN}
                    {price.selling_price}
                  </div>
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          selectedProduct &&
          selectedProduct.price &&
          selectedProduct.price.length &&
          selectedProduct.price.map((price, index) => (
            <Card
              key={index}
              onclickHandler={() => {
                dispatch(
                  AddProduct(
                    transformProduct(
                      selectedProduct,
                      selectedStore,
                      selectedProduct.quantity,
                      {
                        id: 0,
                        product_group_code: "",
                        product_group_name: "",
                        selling_price: 0,
                        products_quantity: 0,
                      }
                    ),
                    {
                      batch_id: price.id,
                      store_id: price.store_prices[0].store,
                      batch_name: price.batch_name,
                      batch_mrp: price.store_prices[0].mrp,
                      batch_selling_price: price.store_prices[0].selling_price,
                    }
                  )
                );
                setModal(false);
                focusRef && focusRef?.current?.focus();
                setQuery &&
                  setQuery({
                    query: "",
                    type: "product",
                  });
              }}
            >
              <CardBody>
                <div className="row">
                  <div className="col-6 text-start">{price.batch_name}</div>
                  <div className="col-6 text-end">
                    {currentProduct?.net_weight} {currentProduct?.uom?.uom_code}{" "}
                    | {POS_CONSTANTS.RUPEE_SIGN}
                    {price.store_prices[0].selling_price}
                  </div>
                </div>
              </CardBody>
            </Card>
          ))
        )}
      </div>
    </Modal>
  );
};

export { ChooseBatchModal };
