import {
  faArrowRight,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  CustomMultiSelect,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Search,
  SelectStoreModal,
  Table,
  TableBody,
  TableHeader,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  clearSelectedStore,
  getBranches,
} from "store/ManageBranches/branchSlice";
import {
  FetchAllPaymentTypes,
  FilterCreditNotes,
  setcurrentCreditNote,
} from "store/POS";
import {
  FilterOrder,
  resetOrderState,
  setCurrentOrder,
} from "store/POS/orderSlice";
import {
  ADVANCE_ORDERS_CONSTANTS,
  BUTTON_CONSTANTS,
  CASHIER_CONSTANTS,
  GENERAL_CONSTANTS,
  IGetOrder,
  ORDER_CONSTANTS,
  POS_HEADER_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  financialYear,
  formatDate,
  formatDateTime,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const Cashier = () => {
  const dispatch = useAppDispatch();

  const {
    branch: { branch: stores, selectedStore },
    common: { status: commonStatus },
    creditNote: { creditNote: creditNoteData, status: creditNoteStatus },
    order: { orders: orderData, status: orderStatus },
    posPaymentType: { payment_type },
    common: { current_page, total_items, total_pages, page_size },
  } = useAppSelector((state) => state.root);

  const [storeModal, setStoreModal] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(GENERAL_CONSTANTS.EMPTY_STRING);

  const store_code = selectedStore
    ? stores.find((store) => store.id === selectedStore).store_code
    : null;

  const [currentPaymentType, setCurrentPaymentType] = useState<number>(0);

  const [cashierOption, setCashierOption] = useState<number>(0);

  const [dropdown, setDropdown] = useState(false);

  const navigate = useNavigate();

  const update_page = useCallback(
    (
      pageNo = 1,
      store = selectedStore,
      paymentType: number = currentPaymentType,
      order_or_cashier = cashierOption
    ) => {
      // default options for credit note and order
      const creditNoteOptions = {
        store: selectedStore || store,
        type: ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE,
        pageNo: pageNo,
        payment_status: 0,
      };

      const orderOptions = {
        pageNo: pageNo,
        query: GENERAL_CONSTANTS.EMPTY_STRING,
        status: GENERAL_CONSTANTS.PENDING,
        store: selectedStore || store,
        type: paymentType,
      };
      // if payment type is not all, then add it to the order options
      paymentType !== 0 && (orderOptions[CASHIER_CONSTANTS.TYPE] = paymentType);

      if (query.length > 0) {
        if (order_or_cashier !== 0) {
          // if cashier option is advance order
          creditNoteOptions[
            CASHIER_CONSTANTS.CODE
          ] = `ADC/${store_code}/${financialYear(new Date())}/${query}`;
          creditNoteOptions[CASHIER_CONSTANTS.NAVIGATE] = navigate;
          creditNoteOptions[CASHIER_CONSTANTS.CASHIER] = true;
          dispatch(FilterCreditNotes(creditNoteOptions));
          return;
        } else {
          // if cashier option is order
          orderOptions[CASHIER_CONSTANTS.CODE] = `${store_code}/${financialYear(
            new Date()
          )}/${query}`;
          orderOptions[CASHIER_CONSTANTS.NAVIGATE] = navigate;
          orderOptions[CASHIER_CONSTANTS.CASHIER] = true;
          return dispatch(FilterOrder(orderOptions));
        }
      }
      if (selectedStore || store) {
        if (order_or_cashier !== 0) {
          dispatch(FilterCreditNotes(creditNoteOptions));
        } else {
          dispatch(FilterOrder(orderOptions));
        }
      }
    },
    [
      dispatch,
      selectedStore,
      store_code,
      query,
      currentPaymentType,
      cashierOption,
    ]
  );

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query: query,
  });

  const tableCols =
    cashierOption === 0
      ? CASHIER_CONSTANTS.ORDER_HEADER
      : CASHIER_CONSTANTS.CREDIT_NOTE_HEADER;

  const getPaymentTypeOptions = useMemo(() => {
    return payment_type && payment_type.length > 0
      ? [
          { value: 0, label: "All" },
          ...payment_type.map((item) => ({
            value: item.id,
            label: item.payment_type,
          })),
        ]
      : [];
  }, [payment_type]);

  const renderCreditNotes = () => {
    return creditNoteStatus === STATUSES.LOADING ? (
      <tr>
        <td colSpan={tableCols.length} className="text-center">
          <Loader />
        </td>
      </tr>
    ) : creditNoteData.length > 0 ? (
      creditNoteData.map((credit, creditIndex) => {
        return (
          credit.refund_amount > 0 && (
            <tr key={creditIndex}>
              <td>{page_size * (current_page - 1) + creditIndex + 1}</td>
              <td>{credit?.credit_note_code}</td>
              <td>{credit?.customer_name}</td>
              <td>{formatDate(credit?.created_at)}</td>
              <td>{credit?.delivery_date}</td>
              <td>{credit?.delivery_type.toUpperCase()}</td>
              <td>₹{credit?.refund_amount}</td>
              <td>
                <Button
                  text={
                    <div className="d-flex flex-row">
                      <span className="mr-2">Proceed</span>
                      <FontIcon icon={faArrowRight} iconClassNames="mt-1" />
                    </div>
                  }
                  type={BUTTON_CONSTANTS.BUTTON}
                  btnClassNames={"btn btn-info btn-sm mr-2"}
                  onClickHandler={() => {
                    navigate("advance/" + credit.id + "/");
                    dispatch(setcurrentCreditNote(credit));
                  }}
                />
              </td>
            </tr>
          )
        );
      })
    ) : (
      <NoData len={tableCols.length} />
    );
  };

  const renderOrders = () => {
    return orderStatus === STATUSES.LOADING &&
      commonStatus.state === STATUSES.LOADING ? (
      <tr>
        <td colSpan={tableCols.length} className="text-center">
          <Loader />
        </td>
      </tr>
    ) : orderData.length > 0 ? (
      orderData.map((order: any, index) => {
        return (
          <tr key={index}>
            <td>{page_size * (current_page - 1) + index + 1}</td>
            <td>{order.invoice_code}</td>
            <td>{order?.customer_name}</td>
            <td>₹{order.payable_amount}</td>
            <td>
              {order.status === "completed" ? (
                <h6>
                  <Badge
                    badgeType={GENERAL_CONSTANTS.SUCCESS}
                    badgeText="Completed"
                  />
                </h6>
              ) : (
                <h6>
                  <Badge
                    badgeType={GENERAL_CONSTANTS.SECONDARY}
                    badgeText="Pending"
                  />
                </h6>
              )}
            </td>
            <td>{formatDateTime(order.created_at.toString())}</td>
            {order.status !== "completed" ? (
              <td>
                <Button
                  text={
                    <div className="d-flex flex-row">
                      <span className="mr-2">Proceed</span>
                      <FontIcon icon={faArrowRight} iconClassNames="mt-1" />
                    </div>
                  }
                  type={BUTTON_CONSTANTS.BUTTON}
                  btnClassNames={"btn btn-info btn-sm mr-2"}
                  onClickHandler={() => {
                    navigate("orders/" + order.id + "/");
                    dispatch(setCurrentOrder(order));
                  }}
                />
              </td>
            ) : null}
          </tr>
        );
      })
    ) : (
      <NoData len={tableCols.length} />
    );
  };

  useEffect(() => {
    dispatch(FetchAllPaymentTypes());
    dispatch(getBranches({ active: true, edit: { setStoreModal } }));
    return () => {
      dispatch(resetOrderState());
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper row m-0 p-0 text-sm">
      {!selectedStore && (
        <SelectStoreModal
          modal={storeModal}
          setModal={setStoreModal}
          update={(value) => {
            update_page(1, value, currentPaymentType, cashierOption);
          }}
        />
      )}
      <Container>
        <ContentHeader pageHeader={CASHIER_CONSTANTS.CASHIER_HEADER} />
        <Card cardClassNames="p-0">
          <CardHeader>
            <div className="row">
              <div className="col-md-3">
                <CustomMultiSelect
                  label={CASHIER_CONSTANTS.FILTER_LABEL}
                  defaultValue={CASHIER_CONSTANTS.FILTER_OPTIONS_DEFAULT_VALUE}
                  options={CASHIER_CONSTANTS.FILTER_OPTION}
                  onChangeHandler={(e) => {
                    e && e.value && update_page(1, selectedStore, 4, e.value);
                    e && e.value !== null && setCashierOption(e.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                {cashierOption === 0 ? (
                  <CustomMultiSelect
                    label={ORDER_CONSTANTS.PAYMENT_TYPE_LABEL}
                    defaultValue={CASHIER_CONSTANTS.PAYMENT_TYPE_DEFAULT_VALUE}
                    options={getPaymentTypeOptions}
                    onChangeHandler={(e) => {
                      e &&
                        update_page(1, selectedStore, e.value, cashierOption);
                      e && setCurrentPaymentType(e.value);
                    }}
                  />
                ) : null}
              </div>
              <div className="col-md-6 mt-1">
                <div className="row mt-4">
                  <div className="col-11">
                    <Search
                      placeholder="Search ..."
                      setQuery={setQuery}
                      prepend={
                        store_code
                          ? `${
                              cashierOption !== 0 ? "ADC/" : ""
                            }${store_code}/${financialYear(new Date())}/`
                          : ""
                      }
                    />
                  </div>
                  <div className="col-1 d-flex flex-row-reverse">
                    <Button
                      text={<FontIcon icon={faEllipsisVertical} />}
                      btnClassNames="btn btn-dark btn-sm"
                      type={BUTTON_CONSTANTS.BUTTON}
                      onClickHandler={() => {
                        setDropdown(!dropdown);
                      }}
                    />
                    <ul
                      className={`dropdown-menu dropdown-menu-right ${
                        dropdown ? "show" : GENERAL_CONSTANTS.EMPTY_STRING
                      }`}
                    >
                      <li className="dropdown-item">
                        <div
                          className="row text-sm"
                          onClick={() => {
                            setDropdown(false);
                            dispatch(clearSelectedStore());
                            setStoreModal(true);
                          }}
                        >
                          <div className="col-9">
                            {POS_HEADER_CONSTANTS.CHANGE_STORE}
                          </div>
                          <div className="col-3 d-flex align-self-center justify-content-center">
                            <FontIcon icon={faArrowRight} />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Table>
              <TableHeader cols={tableCols} />
              <TableBody>
                {cashierOption === 0 ? renderOrders() : renderCreditNotes()}
              </TableBody>
            </Table>
          </CardBody>
          <CardFooter>
            <Pagination
              loading={
                orderStatus === STATUSES.LOADING ||
                creditNoteStatus === STATUSES.LOADING
              }
              pageSize={page_size}
              totalItems={total_items}
              totalPages={total_pages}
              currentPage={current_page}
              prevClickHandler={prevClickHandler}
              nextClickHandler={nextClickHandler}
              pageChangeHandler={pageChangeHandler}
            />
          </CardFooter>
        </Card>
      </Container>
    </div>
  );
};

export { Cashier };
