import {
  faAdd,
  faBars,
  faIcons,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ConfirmModal,
  EmptyFormMessage,
  FontIcon,
  IconButton,
  Status,
  WidgetDetailModal,
} from "components";
import { ErrorMessage, FieldArray, FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PAGES_CONSTANTS,
  useAppDispatch,
  WIDGET_CONSTANTS,
} from "utils";
import { AddWidgetOffCanvas } from "./AddWidgetOffCanvas";
import { useParams } from "react-router-dom";
import { deleteWidgetContent } from "store/Ecommerce";

const WidgetsContentDetails = ({
  props,
  setSorting,
}: {
  props: FormikProps<any>;
  setSorting?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const [widgetModal, setWidgetModal] = useState({
    state: false,
    widget: null,
    index: null,
  });

  const ArrayHelpersRef = useRef(null);

  const [widgetOffCanvas, setWidgetOffCanvas] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);

  const [widgetContentToDelete, setWidgetContentToDelete] = useState<{
    id: number | null;
    index: number | null;
  }>({
    id: null,
    index: null,
  });

  return (
    <>
      <FieldArray
        name={WIDGET_CONSTANTS.WIDGETS}
        render={(arrayHelpers) => {
          ArrayHelpersRef.current = arrayHelpers;
          return (
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="card-title">
                    {WIDGET_CONSTANTS.CARD_TITLE_1}
                  </h4>
                  <div className="card-tools">
                    <IconButton
                      icon={faAdd}
                      type={BUTTON_CONSTANTS.BUTTON}
                      btnClassNames="btn btn-outline-primary"
                      btnText={WIDGET_CONSTANTS.CARD_HEADER_BTN}
                      onClickHandler={() => {
                        setWidgetOffCanvas(true);
                      }}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {props?.values?.widgets &&
                props?.values?.widgets?.length > 0 ? (
                  <ReactSortable
                    list={props?.values?.widgets}
                    setList={(newList) => {
                      props.setFieldValue(WIDGET_CONSTANTS.WIDGETS, newList);
                    }}
                    animation={200}
                    easing="ease-out"
                    onSort={() => {
                      setSorting && setSorting(true);
                    }}
                    onChoose={() => {
                      setSorting && setSorting(true);
                    }}
                  >
                    {props?.values?.widgets &&
                      props?.values?.widgets?.length > 0 &&
                      props?.values?.widgets?.map((item, index) => (
                        <Card key={`${item.id}`}>
                          <CardHeader headerClassNames="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="col-8">
                              <span className="mr-3">
                                <FontIcon icon={faBars} />
                              </span>
                              {item.widget_display_name ||
                                item.widget_name ||
                                ""}
                            </h5>
                            <div className="col-2">
                              <Status
                                is_active={Number(item.is_active?.[0]?.value)}
                              />
                            </div>
                            <div className="col-2 d-flex flex-row justify-content-end">
                              <Button
                                btnClassNames="btn btn-outline-info mr-2"
                                type={BUTTON_CONSTANTS.BUTTON}
                                text={<FontIcon icon={faPenToSquare} />}
                                onClickHandler={() => {
                                  setWidgetModal({
                                    state: true,
                                    widget: item,
                                    index: index,
                                  });
                                }}
                              />
                              {
                                <Button
                                  btnClassNames="btn btn-outline-danger"
                                  type={BUTTON_CONSTANTS.BUTTON}
                                  text={<FontIcon icon={faTrash} />}
                                  onClickHandler={() => {
                                    if (item?.id) {
                                      setWidgetContentToDelete({
                                        id: item.id,
                                        index: index,
                                      });
                                      setConfirmModal(true);
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                />
                              }
                            </div>
                          </CardHeader>
                        </Card>
                      ))}
                  </ReactSortable>
                ) : (
                  <EmptyFormMessage
                    disabled={false}
                    disabledMessage={""}
                    emptyMessage={"Add Widget"}
                    icon={faIcons}
                    iconSize={"lg"}
                    actions={() => {
                      setWidgetOffCanvas(true);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          );
        }}
      />
      <ErrorMessage
        name={PAGES_CONSTANTS.WIDGETS}
        component={FORM_CONSTANTS.ERROR_PARENT}
        className={FORM_CONSTANTS.ERROR}
      />
      {widgetModal.state && (
        <WidgetDetailModal
          props={props}
          modal={widgetModal}
          setModal={setWidgetModal}
        />
      )}
      <AddWidgetOffCanvas
        arrayHelpers={ArrayHelpersRef.current}
        show={widgetOffCanvas}
        setShow={setWidgetOffCanvas}
      />
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={WIDGET_CONSTANTS.DELETE_WIDGET_CONTENT}
        message={WIDGET_CONSTANTS.DELETE_WIDGET_CONTENT_MSG}
        confirmClick={() => {
          dispatch(deleteWidgetContent(widgetContentToDelete.id));
          ArrayHelpersRef.current.remove(widgetContentToDelete.index);
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </>
  );
};

export { WidgetsContentDetails };
