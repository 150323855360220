import React, { useState } from "react";

import { Formik } from "formik";

import { useAppDispatch } from "utils/helper";
import {
  addNewBrand,
  deleteBrand,
  updateExistingBrand,
} from "store/ManageProducts/productBrandSlice";

import { ConfirmModal, ContentHeader } from "components";

import { OffCanvas, Container } from "components/core-components";

import { ProductBrandsSchema } from "utils/schema";

import { PRODUCT_BRAND_CONSTANTS } from "utils/constants";
import { BrandForm } from "./BrandForm";
import { BrandList } from "./BrandList";

const ProductBrand = () => {
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState(false || {});

  const [show, setShow] = useState(false);

  const [currentBrand, setCurrentBrand] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const initialValues = {
    brand_name: "",
    brand_code: "",
    brand_description: "",
    is_active: "",
  };

  const handleSubmit = (values, action) => {
    const dataToSend = {
      ...values,
      is_active: values.is_active[0]?.value,
    };
    if (!edit) {
      dispatch(addNewBrand(dataToSend, action));
    } else {
      dispatch(updateExistingBrand(values.id, dataToSend, action));
    }
    setShow(false);
  };

  return (
    <div className="content-wrapper">
      <ContentHeader
        pageHeader={PRODUCT_BRAND_CONSTANTS.PRODUCT_BRAND_HEADER}
      />
      <Container>
        <div className="col-12">
          <BrandList
            setEdit={setEdit}
            setShow={setShow}
            setCurrentBrand={setCurrentBrand}
            setConfirmModal={setConfirmModal}
          />
          <OffCanvas
            title={
              edit
                ? PRODUCT_BRAND_CONSTANTS.EDIT_HEADER
                : PRODUCT_BRAND_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
              setEdit(false);
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={edit ? edit : initialValues}
              validationSchema={ProductBrandsSchema}
              onSubmit={handleSubmit}
            >
              {(props) => <BrandForm edit={edit} />}
            </Formik>
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={PRODUCT_BRAND_CONSTANTS.DELETE_BRANDS}
        message={PRODUCT_BRAND_CONSTANTS.DELETE_BRANDS_MSG}
        confirmClick={() => {
          dispatch(deleteBrand(currentBrand));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { ProductBrand };
