import {
  faAdd,
  faBan,
  faList,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ConfirmModal,
  EmptyFormMessage,
  FontIcon,
  Highlight,
  IconButton,
  Loader,
  Status,
} from "components";
import { ErrorMessage, FieldArray } from "formik";
import React, { forwardRef, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { deleteMenuItem } from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  MENU_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

const MenuItem = ({
  props,
  setIndex,
  setChildIndex,
  setParent,
  setShow,
  menuItem,
  menuItemIndex,
  child,
  setMenuItemToDelete,
  setConfirmModal,
}) => {
  return (
    <div
      className={`d-flex flex-row align-items-center w-100 index-${menuItemIndex} ${
        child.state ? `parent-${child.parentIndex} mb-4` : ""
      }`}
    >
      <div className={`col-1 ${child.state ? "pl-3" : "text-bold"}`}>
        {menuItemIndex + 1}
      </div>
      <div className={`col ${child.state ? "pl-3" : "text-bold"}`}>
        {menuItem.menu_item_title}
      </div>
      <div className={`col ${child.state ? "" : "px-0"}`}>
        <Highlight
          className={`${child.state ? "" : "text-bold"}`}
          color={"info"}
        >
          {menuItem?.url}
        </Highlight>
      </div>
      <div className={`col ${child.state ? "pl-3" : "text-bold"}`}>
        <Status is_active={Number(menuItem.is_active?.[0]?.value)} />
      </div>
      <div
        className={`col align-items-center ${child.state ? "pl-4" : "pl-0"}`}
      >
        {!child.state && menuItem?.is_mega_menu?.[0]?.value !== 1 && (
          <Button
            text={<FontIcon icon={faAdd} />}
            type={BUTTON_CONSTANTS.BUTTON}
            btnClassNames="btn btn-sm btn-primary mr-2"
            onClickHandler={() => {
              setIndex(menuItemIndex);
              setChildIndex(-1);
              setParent(menuItem);
              setShow(true);
            }}
          />
        )}
        <Button
          text={<FontIcon icon={faPenToSquare} />}
          type={BUTTON_CONSTANTS.BUTTON}
          btnClassNames={`btn btn-sm btn-info mr-2 ${
            child.state ? "ml-4" : ""
          }`}
          onClickHandler={() => {
            setShow(true);
            if (child.state) {
              setParent(child.parent);
              setIndex(child.parentIndex);
              setChildIndex(menuItemIndex);
            } else {
              setIndex(menuItemIndex);
            }
          }}
        />
        {menuItem.is_active?.[0]?.value ? (
          <Button
            text={<FontIcon icon={faBan} />}
            type={BUTTON_CONSTANTS.BUTTON}
            btnClassNames="btn btn-sm btn-danger"
            onClickHandler={() => {
              if (menuItem.id) {
                setConfirmModal(true);
                setMenuItemToDelete({
                  child,
                  menuItemIndex,
                  id: menuItem.id,
                });
                return;
              }
              if (child.state) {
                const updatedChildren = child.parent.children.filter(
                  (_, index) => index !== menuItemIndex
                );
                props.setFieldValue(
                  `${MENU_CONSTANTS.MENU_ITEMS}[${child.parentIndex}].children`,
                  updatedChildren
                );
              } else {
                child.arrayHelpers?.remove(menuItemIndex);
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const MenuList = ({
  setIndex,
  setChildIndex,
  setParent,
  setShow,
  setSorting,
  props,
  arrayHelperRef,
  setMenuItemToDelete,
  setConfirmModal,
}) => {
  const {
    ecommerce: {
      menu: { status: menuStatus },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="col-12">
      <Card>
        <CardHeader>
          <div className="row align-items-center">
            <div className="col-4">
              <h3 className="card-title">{MENU_CONSTANTS.CARD_TITLE_2}</h3>
            </div>
            <div className="col-8 text-end">
              <IconButton
                icon={faAdd}
                btnClassNames="btn btn-outline-primary"
                btnText={MENU_CONSTANTS.CARD_HEADER_BTN}
                onClickHandler={() => {
                  setIndex(-1);
                  setShow(true);
                }}
                type={BUTTON_CONSTANTS.BUTTON}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="row">
            {props.values.menu_items.length > 0 && (
              <div className="col-12 my-2">
                <div className="row align-items-center px-4">
                  {MENU_CONSTANTS.COLS.map((col, index) => (
                    <h5
                      key={index}
                      className={`${col.className} text-bold mb-0 py-2`}
                    >
                      {col.name}
                    </h5>
                  ))}
                </div>
              </div>
            )}
            {menuStatus === STATUSES.LOADING && !props.isSubmitting ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <>
                <FieldArray
                  name={MENU_CONSTANTS.MENU_ITEMS}
                  render={(arrayHelpers) => {
                    arrayHelperRef.current = arrayHelpers;
                    const { values } = props;

                    return values.menu_items && values.menu_items.length > 0 ? (
                      <ReactSortable
                        key={MENU_CONSTANTS.MENU_ITEMS}
                        animation={100}
                        easing="ease-in-out"
                        className="col-12 d-flex flex-column p-0"
                        list={values.menu_items}
                        setList={(newList) => {
                          props.setFieldValue(
                            MENU_CONSTANTS.MENU_ITEMS,
                            newList
                          );
                        }}
                        onSort={() => {
                          setSorting(true);
                        }}
                        onChoose={() => {
                          setSorting(true);
                        }}
                      >
                        {values.menu_items.map((menuItem, menuItemIndex) => {
                          return (
                            <Card
                              cardClassNames="card my-1 user-select-none"
                              key={`${menuItem?.menu_item_title}-${menuItemIndex}`}
                            >
                              <CardHeader headerClassNames="mt-2">
                                <MenuItem
                                  key={`${menuItem?.menu_item_title}-${menuItemIndex}`}
                                  props={props}
                                  setIndex={setIndex}
                                  setChildIndex={setChildIndex}
                                  setParent={setParent}
                                  setShow={setShow}
                                  menuItem={menuItem}
                                  menuItemIndex={menuItemIndex}
                                  setMenuItemToDelete={setMenuItemToDelete}
                                  setConfirmModal={setConfirmModal}
                                  child={{
                                    state: false,
                                    parent: null,
                                    parentIndex: null,
                                    arrayHelpers: arrayHelpers,
                                  }}
                                />
                              </CardHeader>
                              {menuItem.children.length > 0 ? (
                                <ReactSortable
                                  key="menu-items-children"
                                  animation={100}
                                  easing="ease-in-out"
                                  className="card-body"
                                  list={menuItem.children}
                                  setList={(newChildren) => {
                                    props.setFieldValue(
                                      MENU_CONSTANTS.MENU_ITEMS,
                                      props.values.menu_items.map((item) =>
                                        item.menu_item_title ===
                                        menuItem.menu_item_title
                                          ? {
                                              ...menuItem,
                                              children: newChildren,
                                            }
                                          : item
                                      )
                                    );
                                  }}
                                  onSort={() => {
                                    setSorting(true);
                                  }}
                                  onChoose={() => {
                                    setSorting(true);
                                  }}
                                >
                                  {menuItem.children.map(
                                    (menuItemChild, menuItemChildIndex) =>
                                      menuItemChild &&
                                      menuItemChild.menu_item_title && (
                                        <MenuItem
                                          key={`${menuItem?.menu_item_title}-${menuItemIndex}-${menuItemChild.menu_item_title}-${menuItemChildIndex}`}
                                          props={props}
                                          setIndex={setIndex}
                                          setChildIndex={setChildIndex}
                                          setParent={setParent}
                                          setShow={setShow}
                                          menuItem={menuItemChild}
                                          menuItemIndex={menuItemChildIndex}
                                          setMenuItemToDelete={
                                            setMenuItemToDelete
                                          }
                                          setConfirmModal={setConfirmModal}
                                          child={{
                                            state: true,
                                            parent: menuItem,
                                            parentIndex: menuItemIndex,
                                          }}
                                        />
                                      )
                                  )}
                                </ReactSortable>
                              ) : null}
                            </Card>
                          );
                        })}
                      </ReactSortable>
                    ) : (
                      <EmptyFormMessage
                        disabled={false}
                        disabledMessage=""
                        emptyMessage={MENU_CONSTANTS.CARD_HEADER_BTN}
                        icon={faList}
                        iconSize="xl"
                        actions={() => {
                          setIndex(-1);
                          setShow(true);
                        }}
                      />
                    );
                  }}
                />
                <ErrorMessage
                  name={MENU_CONSTANTS.MENU_ITEMS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MenuList;
