import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, ErrorMessage, FormikProps } from "formik";

import {
  ContentHeader,
  CustomInput,
  Loader,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  MultiSelect,
  ConfirmModal,
} from "components";

import {
  useAppDispatch,
  useAppSelector,
  DiscountSchema,
  BUTTON_CONSTANTS,
  DISCOUNTS_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
  getNewValues,
  debounce,
  singleDeleteCheck,
  multipleDeleteCheck,
  GENERAL_CONSTANTS,
  handleOptionDelete,
} from "utils";

import {
  FetchDiscountFormOptionData,
  addDiscounts,
  deleteDiscountBrandMapping,
  deleteDiscountCategoryMapping,
  deleteDiscountProductMapping,
  deleteDiscountStoreMapping,
  deleteDiscountSubCategoryMapping,
  deleteMultipleDiscountStoreMapping,
  getIndividualDiscount,
  setDiscountToEdit,
  updateDiscounts,
} from "store/ManageProducts//ManageDiscounts/discountSlice";
import {
  clearProducts,
  getAllProducts,
  getProducts,
  getProductsForSpecificStore,
} from "store/ManageProducts/productSlice";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { setCommonStatus, updatePageNo } from "store/commonSlice";

const DiscountForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    branch: { branch: branchData },
    common: { status: commonStatus },
    discount: { discountToEdit, status: discountStatus },
    discountApplicableType: {
      discountApplicableType: discountApplicableTypeData,
    },
    discountCouponUsage: { couponUsage: couponUsageData },
    discountCustomerEligibility: {
      customerEligibility: customerEligibilityData,
    },
    discountType: { discountType: discountTypeData },
    product: { products: productData, status: productStatus },
    productBrand: { productBrand: brandData },
    productCategory: { productCategory: categoryData },
    productSubCategory: { productSubCategory: subCategoryData },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const [query, setQuery] = useState("");

  const PropsRef = useRef<FormikProps<any>>();

  const ActionsRef = useRef<any>();

  const [confirmModal, setConfirmModal] = useState(false);

  const [deleteField, setDeleteField] = useState<
    "categories" | "sub_categories" | "brands" | "products" | "stores"
  >("categories");

  const [initialData, setInitialData] = useState({
    categories: [],
    sub_categories: [],
    brands: [],
    products: [],
    stores: [],
  });

  const initialValues = {
    discount_code: "",
    discount_applicable: "",
    discount_auto_apply: "",
    exclude_products_with_discount: "",
    discount_type: "",
    discount_value: "",
    categories: "",
    sub_categories: "",
    brands: "",
    products: "",
    stores: "",
    minimum_requirement_type: "",
    minimum_range: "",
    maximum_range: "",
    customer_eligibility: "",
    coupon_usage_time: 0,
    coupon_usage: "",
    coupon_start_date: "",
    coupon_end_date: "",
    is_active: [{ value: 1, label: "Active" }],
  };

  const filterProducts = (value) => {
    dispatch(
      getProductsForSpecificStore({
        pageNo: 1,
        query: value,
        pos: true,
      })
    );
  };

  const optimizedFilter = useCallback(debounce(filterProducts), []);

  function handleSubmit(values, actions) {
    let dataToSend = {
      ...values,
      discount_applicable: values.discount_applicable[0]?.value,
      discount_auto_apply:
        values.discount_auto_apply[0]?.label === "Yes" ? 1 : 0,
      discount_type: values.discount_type[0]?.value,
      exclude_products_with_discount:
        values.exclude_products_with_discount[0]?.label === "Yes" ? 1 : 0,
      categories: values.categories.map((item) => item && item.value),
      sub_categories: values.sub_categories.map((item) => item && item.value),
      brands: values.brands.map((item) => item && item.value),
      products: values.products.map((item) => item && item.value),
      stores: values.stores.map((item) => item && item.value),
      minimum_requirement_type:
        values.minimum_requirement_type[0]?.label === "Purchase Amount Range"
          ? "amount"
          : "Quantity Range"
          ? "quantity"
          : "",
      minimum_range: values.minimum_range * 1,
      maximum_range: values.maximum_range * 1,
      coupon_usage: values.coupon_usage[0]?.value,
      customer_eligibility: values.customer_eligibility[0]?.value,
      is_active: 1,
    };

    if (id) {
      dataToSend = {
        ...dataToSend,
        categories: getNewValues(values.categories, initialData.categories),
        sub_categories: getNewValues(
          values.sub_categories,
          initialData.sub_categories
        ),
        brands: getNewValues(values.brands, initialData.brands),
        products: getNewValues(values.products, initialData.products),
        stores: getNewValues(values.stores, initialData.stores),
        is_active: values.is_active[0].value,
      };
      dispatch(updateDiscounts(Number(id), dataToSend, actions, navigate));
    } else {
      dispatch(addDiscounts(dataToSend, actions, navigate));
    }
  }

  useEffect(() => {
    dispatch(FetchDiscountFormOptionData(Number(id), setInitialData));

    return () => {
      dispatch(setDiscountToEdit(null));
    };
  }, []);
  // console.log(discountToEdit);
  return (
    <div className="content-wrapper">
      <ContentHeader
        editValue={discountToEdit?.discount_code}
        pageHeader={
          id ? DISCOUNTS_CONSTANTS.EDIT_HEADER : DISCOUNTS_CONSTANTS.ADD_HEADER
        }
      />
      <Container>
        {/* {commonStatus.state === STATUSES.LOADING &&
          commonStatus.type === "individualDiscount" && (
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            </div>
          )} */}
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={id ? discountToEdit : initialValues}
            validationSchema={DiscountSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form>
                  <ScrollToFieldError />
                  {discountStatus === STATUSES.LOADING && (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div className="row">
                    {/* Discount Details:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {DISCOUNTS_CONSTANTS.CARD_TITLE_1}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={DISCOUNTS_CONSTANTS.DISCOUNT_CODE_LABEL}
                                isRequired={true}
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_CODE}
                                placeholder={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_CODE_PLACEHOLDER
                                }
                                type={FORM_CONSTANTS.TEXT}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_CODE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_APPLICABLE}
                                label={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_APPLICABLE_LABEL
                                }
                                isRequired={true}
                                options={discountApplicableTypeData.map(
                                  (item) => ({
                                    value: item.id,
                                    label: item.type,
                                  })
                                )}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_APPLICABLE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_AUTO_APPLY}
                                label={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_AUTO_APPLY_LABEL
                                }
                                isRequired={true}
                                options={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_AUTO_APPLY_OPTIONS
                                }
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_AUTO_APPLY}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                name={DISCOUNTS_CONSTANTS.EXCLUDE_PRODUCTS}
                                label={
                                  DISCOUNTS_CONSTANTS.EXCLUDE_PRODUCTS_LABEL
                                }
                                isRequired={true}
                                options={
                                  DISCOUNTS_CONSTANTS.EXCLUDE_PRODUCTS_OPTIONS
                                }
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.EXCLUDE_PRODUCTS}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                label={DISCOUNTS_CONSTANTS.DISCOUNT_TYPE_LABEL}
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_TYPE}
                                isRequired={true}
                                options={discountTypeData.map((item) => ({
                                  value: item.id,
                                  label: item.type,
                                }))}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_TYPE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={DISCOUNTS_CONSTANTS.DISCOUNT_VALUE_LABEL}
                                isRequired={true}
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_VALUE}
                                placeholder={
                                  props.values?.discount_type?.[0]?.label ===
                                  DISCOUNTS_CONSTANTS.PERCENTAGE
                                    ? DISCOUNTS_CONSTANTS.DISCOUNT_VALUE_PERCENTAGE
                                    : DISCOUNTS_CONSTANTS.DISCOUNT_VALUE_FIXED_AMOUNT
                                }
                                type={FORM_CONSTANTS.NUMBER}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.DISCOUNT_VALUE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          {id && (
                            <div className="row">
                              <div className="col-6">
                                <MultiSelect
                                  select={false}
                                  label={
                                    DISCOUNTS_CONSTANTS.DISCOUNT_STATUS_LABEL
                                  }
                                  name={DISCOUNTS_CONSTANTS.DISCOUNT_STATUS}
                                  isRequired={true}
                                  options={GENERAL_CONSTANTS.STATUS_OPTIONS}
                                />
                                <ErrorMessage
                                  name={DISCOUNTS_CONSTANTS.DISCOUNT_STATUS}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                    {/* Discount Applies To:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {DISCOUNTS_CONSTANTS.CARD_TITLE_2}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          {/* Stores */}
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={true}
                                label={DISCOUNTS_CONSTANTS.BRANCHES_LABEL}
                                isRequired={true}
                                name={DISCOUNTS_CONSTANTS.BRANCHES}
                                options={branchData.map((branch) => ({
                                  value: branch.id,
                                  label: branch.store_name,
                                }))}
                                onChangeHandler={(e, actions) => {
                                  if (
                                    (id && actions.action == "remove-value") ||
                                    actions.action == "pop-value" ||
                                    actions.action == "clear"
                                  ) {
                                    ActionsRef.current = actions;
                                    setConfirmModal(true);
                                    setDeleteField("stores");
                                  } else {
                                    const selectedOptions = Array.isArray(e)
                                      ? e
                                      : [e];
                                    props.setFieldValue(
                                      "stores",
                                      selectedOptions
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.BRANCHES}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            {/* Categories */}
                            <div className="col-md-6">
                              <MultiSelect
                                select={true}
                                label={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_CATEGORY_LABEL
                                }
                                isRequired={true}
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_CATEGORY}`}
                                options={categoryData.map((category) => ({
                                  value: category.id,
                                  label: category.category_name,
                                }))}
                                onChangeHandler={(e, actions) => {
                                  if (
                                    (id && actions.action == "remove-value") ||
                                    actions.action == "pop-value" ||
                                    actions.action == "clear"
                                  ) {
                                    ActionsRef.current = actions;
                                    setConfirmModal(true);
                                    setDeleteField("categories");
                                  } else {
                                    const selectedOptions = Array.isArray(e)
                                      ? e
                                      : [e];
                                    props.setFieldValue(
                                      "categories",
                                      selectedOptions
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_CATEGORY}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            {/* sub-Categories */}
                            <div className="col-md-6">
                              <MultiSelect
                                select={true}
                                label={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_SUB_CATEGORY_LABEL
                                }
                                isRequired={true}
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_SUB_CATEGORY}`}
                                options={subCategoryData.map((subCategory) => ({
                                  value: subCategory.id,
                                  label: subCategory.sub_category_name,
                                }))}
                                onChangeHandler={(e, actions) => {
                                  if (
                                    (id && actions.action == "remove-value") ||
                                    actions.action == "pop-value" ||
                                    actions.action == "clear"
                                  ) {
                                    ActionsRef.current = actions;
                                    setConfirmModal(true);
                                    setDeleteField("sub_categories");
                                  } else {
                                    const selectedOptions = Array.isArray(e)
                                      ? e
                                      : [e];
                                    props.setFieldValue(
                                      "sub_categories",
                                      selectedOptions
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_SUB_CATEGORY}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            {/* Brands */}
                            <div className="col-md-6">
                              <MultiSelect
                                select={true}
                                label={DISCOUNTS_CONSTANTS.DISCOUNT_BRAND_LABEL}
                                isRequired={true}
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_BRAND}`}
                                options={brandData.map((brand) => ({
                                  value: brand.id,
                                  label: brand.brand_name,
                                }))}
                                onChangeHandler={(e, actions) => {
                                  if (
                                    (id && actions.action == "remove-value") ||
                                    actions.action == "pop-value" ||
                                    actions.action == "clear"
                                  ) {
                                    ActionsRef.current = actions;
                                    setConfirmModal(true);
                                    setDeleteField("brands");
                                  } else {
                                    const selectedOptions = Array.isArray(e)
                                      ? e
                                      : [e];
                                    props.setFieldValue(
                                      "brands",
                                      selectedOptions
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_BRAND}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            {/* Products */}
                            <div className="col-md-6">
                              <MultiSelect
                                select={true}
                                label={
                                  DISCOUNTS_CONSTANTS.DISCOUNT_PRODUCT_LABEL
                                }
                                placeholder="Search Product..."
                                isRequired={true}
                                // value={query}
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_PRODUCT}`}
                                options={productData.map((product) => ({
                                  value: product.id,
                                  label: product.print_name,
                                }))}
                                onChangeHandler={(e, actions) => {
                                  if (
                                    (id && actions.action == "remove-value") ||
                                    actions.action == "pop-value" ||
                                    actions.action == "clear"
                                  ) {
                                    ActionsRef.current = actions;
                                    setConfirmModal(true);
                                    setDeleteField("products");
                                  } else {
                                    const selectedOptions = Array.isArray(e)
                                      ? e
                                      : [e];
                                    props.setFieldValue(
                                      "products",
                                      selectedOptions
                                    );
                                  }
                                }}
                                onInputChangeHandler={(e) => {
                                  if (e.length === 0) {
                                    dispatch(updatePageNo(1));
                                    dispatch(clearProducts());
                                  }
                                  setQuery(e);
                                }}
                                onKeyDownHandler={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    optimizedFilter(query);
                                  }
                                }}
                                onMenuCloseHandler={() => [
                                  dispatch(getAllProducts([])),
                                ]}
                                isLoading={productStatus === STATUSES.LOADING}
                              />
                              <ErrorMessage
                                name={`${DISCOUNTS_CONSTANTS.DISCOUNT_PRODUCT}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Other Details:  */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {DISCOUNTS_CONSTANTS.CARD_TITLE_3}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                name={DISCOUNTS_CONSTANTS.MINIMUM_REQUIREMENT}
                                label={
                                  DISCOUNTS_CONSTANTS.MINIMUM_REQUIREMENT_LABEL
                                }
                                isRequired={true}
                                options={
                                  DISCOUNTS_CONSTANTS.MINIMUM_REQUIREMENT_OPTIONS
                                }
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.MINIMUM_REQUIREMENT}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            {props.values?.minimum_requirement_type?.[0]
                              ?.label ===
                            DISCOUNTS_CONSTANTS.MINIMUM_REQUIREMENT_AMOUNT ? (
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <CustomInput
                                      label={
                                        DISCOUNTS_CONSTANTS.MINIMUM_AMOUNT_LABEL
                                      }
                                      name={DISCOUNTS_CONSTANTS.MINIMUM}
                                      type={FORM_CONSTANTS.NUMBER}
                                      placeholder={
                                        DISCOUNTS_CONSTANTS.MINIMUM_AMOUNT_PLACEHOLDER
                                      }
                                    />
                                    <ErrorMessage
                                      name={DISCOUNTS_CONSTANTS.MINIMUM}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <CustomInput
                                      label={
                                        DISCOUNTS_CONSTANTS.MAXIMUM_AMOUNT_LABEL
                                      }
                                      name={DISCOUNTS_CONSTANTS.MAXIMUM}
                                      type={FORM_CONSTANTS.NUMBER}
                                      placeholder={
                                        DISCOUNTS_CONSTANTS.MAXIMUM_AMOUNT_PLACEHOLDER
                                      }
                                    />
                                    <ErrorMessage
                                      name={DISCOUNTS_CONSTANTS.MAXIMUM}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : props.values?.minimum_requirement_type?.[0]
                                ?.label ===
                              DISCOUNTS_CONSTANTS.MINIMUM_REQUIREMENT_QUANTITY ? (
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <CustomInput
                                      label={
                                        DISCOUNTS_CONSTANTS.MINIMUM_QUANTITY_LABEL
                                      }
                                      name={DISCOUNTS_CONSTANTS.MINIMUM}
                                      value={props.values.minimum_range}
                                      type={FORM_CONSTANTS.NUMBER}
                                      placeholder={
                                        DISCOUNTS_CONSTANTS.MINIMUM_QUANTITY_PLACEHOLDER
                                      }
                                    />
                                    <ErrorMessage
                                      name={DISCOUNTS_CONSTANTS.MINIMUM}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <CustomInput
                                      label={
                                        DISCOUNTS_CONSTANTS.MAXIMUM_QUANTITY_LABEL
                                      }
                                      value={props.values.maximum_range}
                                      name={DISCOUNTS_CONSTANTS.MAXIMUM}
                                      type={FORM_CONSTANTS.NUMBER}
                                      placeholder={
                                        DISCOUNTS_CONSTANTS.MAXIMUM_QUANTITY_PLACEHOLDER
                                      }
                                    />
                                    <ErrorMessage
                                      name={DISCOUNTS_CONSTANTS.MAXIMUM}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                name={DISCOUNTS_CONSTANTS.CUSTOMER_ELIGIBILITY}
                                label={
                                  DISCOUNTS_CONSTANTS.CUSTOMER_ELIGIBILITY_LABEL
                                }
                                isRequired={true}
                                options={customerEligibilityData.map(
                                  (item) => ({
                                    value: item.id,
                                    label: item.eligibility,
                                  })
                                )}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.CUSTOMER_ELIGIBILITY}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                name={DISCOUNTS_CONSTANTS.COUPON_USAGE}
                                label={DISCOUNTS_CONSTANTS.COUPON_USAGE_LABEL}
                                isRequired={true}
                                options={couponUsageData.map((item) => ({
                                  value: item.id,
                                  label: item.usage,
                                }))}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.COUPON_USAGE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            {props.values?.coupon_usage?.[0]?.label ===
                            DISCOUNTS_CONSTANTS.COUPON_USAGGE_CHECK ? (
                              <>
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <CustomInput
                                        label={
                                          DISCOUNTS_CONSTANTS.COUPON_USAGE_NUMBER_OF_TIMES_LABEL
                                        }
                                        name={
                                          DISCOUNTS_CONSTANTS.COUPON_USAGE_NUMBER_OF_TIMES
                                        }
                                        placeholder={
                                          DISCOUNTS_CONSTANTS.COUPON_USAGE_NUMBER_OF_TIMES_PLACEHOLDER
                                        }
                                        type={FORM_CONSTANTS.NUMBER}
                                      />
                                      <ErrorMessage
                                        name={
                                          DISCOUNTS_CONSTANTS.COUPON_USAGE_NUMBER_OF_TIMES
                                        }
                                        component={FORM_CONSTANTS.ERROR_PARENT}
                                        className={FORM_CONSTANTS.ERROR}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={
                                  DISCOUNTS_CONSTANTS.COUPON_START_DATE_LABEL
                                }
                                isRequired={true}
                                name={DISCOUNTS_CONSTANTS.COUPON_START_DATE}
                                type={FORM_CONSTANTS.DATE}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.COUPON_START_DATE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={
                                  DISCOUNTS_CONSTANTS.COUPON_END_DATE_LABEL
                                }
                                isRequired={true}
                                name={DISCOUNTS_CONSTANTS.COUPON_END_DATE}
                                type={FORM_CONSTANTS.DATE}
                              />
                              <ErrorMessage
                                name={DISCOUNTS_CONSTANTS.COUPON_END_DATE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Submit */}
                    <div className="col-md-6">
                      <Button
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary align-self-center w-25"}
                        loading={discountStatus === STATUSES.LOADING}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={""}
        message={"Are you sure you want to delete the option ?"}
        confirmClick={() => {
          handleOptionDelete(
            deleteField,
            PropsRef.current,
            ActionsRef.current,
            (value) => {
              switch (deleteField) {
                case "stores":
                  dispatch(
                    deleteDiscountStoreMapping({
                      discount: Number(id),
                      store: value,
                    })
                  );
                  break;

                case "categories":
                  dispatch(
                    deleteDiscountCategoryMapping({
                      discount: Number(id),
                      category: value,
                    })
                  );
                  break;

                case "sub_categories":
                  dispatch(
                    deleteDiscountSubCategoryMapping({
                      discount: Number(id),
                      sub_category: value,
                    })
                  );
                  break;

                case "brands":
                  dispatch(
                    deleteDiscountBrandMapping({
                      discount: Number(id),
                      brand: value,
                    })
                  );
                  break;

                case "products":
                  dispatch(
                    deleteDiscountProductMapping({
                      discount: Number(id),
                      product: value,
                    })
                  );
                  break;
              }
            },
            (value) => {
              setInitialData((prev) => {
                return {
                  ...prev,
                  [deleteField]:
                    ActionsRef.current.action !== "clear"
                      ? prev[deleteField].filter((item) => item.value !== value)
                      : [],
                };
              });
            },
            (value) => {
              dispatch(
                setCommonStatus({
                  state: value,
                  type: "deleteDiscountMapping",
                })
              );
            }
          );
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { DiscountForm };
