import React from "react";

interface ContainerProps {
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">{children}</div>
      </div>
    </div>
  );
};

export { Container };
