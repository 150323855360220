import { createSlice } from "@reduxjs/toolkit";
import { getPOSPaymentType } from "apis/restApis";
import { AppDispatch } from "store/store";
import { POSPaymentType, STATUSES } from "utils";

const initialState = {
  payment_type: [] as POSPaymentType[],
  status: STATUSES.IDLE as string,
  error: null,
};

const posPaymentTypeSlice = createSlice({
  name: "POSPaymentType",
  initialState,
  reducers: {
    getAllPaymentType: (state, action) => {
      state.payment_type = action.payload;
    },
    setPOSPaymentTypeStatus: (state, action) => {
      state.status = action.payload;
    },
    setPOSPaymentTypeError: (state, action) => {
      state.error = action.payload;
    },
    resetPOSPaymentTypeState: (state) => {
      state.payment_type = [] as POSPaymentType[];
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllPaymentType,
  setPOSPaymentTypeError,
  setPOSPaymentTypeStatus,
  resetPOSPaymentTypeState,
} = posPaymentTypeSlice.actions;

export default posPaymentTypeSlice.reducer;

export function FetchAllPaymentTypes() {
  return async function FetchAllPaymentTypesThunk(dispatch: AppDispatch) {
    dispatch(setPOSPaymentTypeStatus(STATUSES.LOADING));
    getPOSPaymentType()
      .then((response: any) => {
        dispatch(getAllPaymentType(response.result));
      })
      .catch((error: any) => {
        dispatch(setPOSPaymentTypeError(error.message));
        dispatch(setPOSPaymentTypeStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setPOSPaymentTypeStatus(STATUSES.IDLE));
      });
  };
}
