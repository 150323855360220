import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  getActiveSupplierType,
  getSupplierType,
  setSupplierType,
} from "apis/restApis";
import { SupplierType } from "utils";

const initialState = {
  supplierType: [] as SupplierType[],
  status: STATUSES.IDLE as string,
  error: null,
};

const supplierTypeSlice = createSlice({
  name: "supplierType",
  initialState,
  reducers: {
    getAllSupplierType: (state, action) => {
      state.supplierType = action.payload;
    },
    addSupplierType: (state, action) => {
      state.supplierType.push(action.payload);
    },
    removeSupplierType: (state, action) => {
      state.supplierType = state.supplierType.filter(
        (supplierType) => supplierType.id !== action.payload
      );
    },
    editSupplierType: (state, action) => {
      const { id } = action.payload;
      const index = state.supplierType.findIndex(
        (supplierType) => supplierType.id === id
      );
      if (index !== -1) {
        state.supplierType[index] = action.payload;
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllSupplierType,
  addSupplierType,
  removeSupplierType,
  editSupplierType,
  setStatus,
  setError,
} = supplierTypeSlice.actions;

export default supplierTypeSlice.reducer;

export function getSupplierTypes(active: boolean) {
  return async (dispatch: any) => {
    active
      ? getActiveSupplierType()
          .then((response: any) => {
            dispatch(getAllSupplierType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getSupplierType()
          .then((response: any) => {
            dispatch(getAllSupplierType(response.result));
            dispatch(setStatus(STATUSES.IDLE));
          })
          .catch((error) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}

export function addNewSupplierType(supplierTypeParams: SupplierType) {
  return async (dispatch: any) => {
    dispatch(setStatus(STATUSES.LOADING));
    setSupplierType(supplierTypeParams)
      .then((response: any) => {
        dispatch(addSupplierType(response.result));
        dispatch(setStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error.message));
      });
  };
}
