import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontIcon } from "./FontIcon";
import {
  IconDefinition,
  faAdd,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";
import { BUTTON_CONSTANTS } from "utils";

type DropdownItem = {
  path?: string;
  label: React.ReactNode | string;
  dropdownClickHandler?: any;
  icon: IconDefinition;
  reverse?: boolean;
};

const Dropdown = ({
  items,
  dropdownIcon,
  dropdownBtnClassNames,
  dropdownParentclassNames,
  parentClassName,
}: {
  items?: DropdownItem[];
  parentClassName?: string;
  dropdownIcon?: React.ReactNode;
  dropdownBtnClassNames?: string;
  dropdownParentclassNames?: string;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  return (
    <div
      className={`${parentClassName} btn-group ${dropdown ? "show" : ""} mx-2`}
      ref={dropdownRef}
    >
      <Button
        testId={"dropdown-button"}
        text={
          dropdownIcon ? dropdownIcon : <FontIcon icon={faEllipsisVertical} />
        }
        btnClassNames={`btn btn-sm ${
          dropdownBtnClassNames ? dropdownBtnClassNames : "btn-dark"
        }`}
        type={BUTTON_CONSTANTS.BUTTON}
        onClickHandler={() => {
          setDropdown(!dropdown);
        }}
      />
      <ul
        className={`${dropdownParentclassNames} dropdown-menu dropdown-menu-right ${
          dropdown ? "show" : ""
        } my-2`}
      >
        {items.map((item: DropdownItem, index: number) => (
          <li key={index}>
            <Link
              to={item.path}
              className="dropdown-item"
              data-testid={`${item.icon === faAdd ? "add-btn" : ""}`}
            >
              <div
                className={`d-flex flex-row${item.reverse ? "-reverse" : ""}`}
              >
                {item.icon && (
                  <div className="col-2 d-flex align-self-center justify-content-center">
                    <FontIcon icon={item.icon} />
                  </div>
                )}
                <div
                  className={`col-${item.icon ? "10" : "12"}`}
                  onClick={item.dropdownClickHandler || undefined}
                >
                  {item.label}
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { Dropdown };
