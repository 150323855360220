import React from "react";
import { Button } from "./Button";
import { TModalHeight, TModalSize } from "utils";

interface ModalProps {
  testId?: string;
  modalClassNames?: string;
  title?: any;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  backdrop?: boolean;
  onClickHandler: () => void;
  showModal: boolean;
  modalSize?: TModalSize;
  centered?: boolean;
  modalHeight?: TModalHeight;
  fullScreenSize?: TModalSize;
}

const Modal = ({
  testId,
  onClickHandler,
  modalClassNames,
  modalSize,
  modalHeight,
  backdrop = true,
  showModal,
  title,
  children,
  centered = true,
  footer,
  fullScreenSize,
}: ModalProps) => {
  return (
    <>
      <div
        className={`modal fade bs content ${
          showModal ? "show" : ""
        } ${modalClassNames} `}
        {...(showModal && {
          style: { display: "block" },
        })}
      >
        <div
          className={`modal-dialog ${modalHeight ? `h-${modalHeight}` : ""} ${
            centered ? "modal-dialog-centered" : ""
          } modal-dialog-scrollable ${modalSize ? `modal-${modalSize}` : ""} ${
            fullScreenSize ? `modal-fullscreen-${fullScreenSize}-down` : ""
          }`}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title ml-2">{title}</h4>
              <Button
                testId={`${testId}-close-modal`}
                type="button"
                btnClassNames="btn-close"
                onClickHandler={onClickHandler}
              />
            </div>
            <div className={`modal-body`}>{children}</div>
            <div className="modal-footer justify-content-between">{footer}</div>
          </div>
        </div>
      </div>
      {showModal && backdrop && (
        <div className="modal-backdrop fade show "></div>
      )}
    </>
  );
};

export { Modal };
