import { createSlice } from "@reduxjs/toolkit";
import {
  getActiveCustomerEligibility,
  getCustomerEligibility,
} from "apis/restApis";
import { STATUSES } from "utils";

interface CustomerEligibility {
  id: number;
  eligibility: string;
}

const initialState = {
  customerEligibility: [] as CustomerEligibility[],
  status: STATUSES.IDLE as string,
  error: null,
};

const discountCustomerEligibilitySlice = createSlice({
  name: "discountCustomerEligibility",
  initialState,
  reducers: {
    getAllCustomerEligibility: (state, action) => {
      state.customerEligibility = action.payload;
    },
    addCustomerEligibility: (state, action) => {
      state.customerEligibility.push(action.payload);
    },
    removeCustomerEligibility: (state, action) => {
      state.customerEligibility = state.customerEligibility.filter(
        (customerEligibility) => customerEligibility.id !== action.payload
      );
    },
    editCustomerEligibility: (state, action) => {
      const { id } = action.payload;
      const index = state.customerEligibility.findIndex(
        (customerEligibility) => customerEligibility.id === id
      );
      if (index !== -1) {
        state.customerEligibility[index] = action.payload;
      }
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllCustomerEligibility,
  addCustomerEligibility,
  removeCustomerEligibility,
  editCustomerEligibility,

  setStatus,
  setError,
} = discountCustomerEligibilitySlice.actions;

export default discountCustomerEligibilitySlice.reducer;

export function fetchCustomerEligibility(active?: boolean) {
  return async function fetchCustomerEligibilityThuink(dispatch: any) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveCustomerEligibility()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getAllCustomerEligibility(response.result));
          })
          .catch((error: any) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          })
      : getCustomerEligibility()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getAllCustomerEligibility(response.result));
          })
          .catch((error: any) => {
            dispatch(setStatus(STATUSES.ERROR));
            dispatch(setError(error.message));
          });
  };
}
