import { Button, Card, CardBody, CustomInput, MultiSelect } from "components";
import { Form, ErrorMessage } from "formik";
import React from "react";
import { deleteHSNTax } from "store/ManageProducts/productTaxSlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCT_TAX_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

const TaxForm = ({ edit, editId, ...props }) => {
  return (
    <Form>
      <div className="col-12">
        <Card>
          <CardBody>
            <div className="row">
              <CustomInput
                type={FORM_CONSTANTS.TEXT}
                isRequired={true}
                label={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_NAME_LABEL}
                value={edit ? edit.tax_name : ""}
                name={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_NAME}
                placeholder={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_NAME_PLACEHOLDER}
              />
              <ErrorMessage
                name={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>

            <div className="row">
              <CustomInput
                type={FORM_CONSTANTS.NUMBER}
                isRequired={true}
                value={edit ? edit.tax_rate : ""}
                label={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_RATE_LABEL}
                name={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_RATE}
                placeholder={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_RATE_PLACEHOLDER}
              />
              <ErrorMessage
                name={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_RATE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            {edit ? (
              <div className="row">
                <MultiSelect
                  select={false}
                  name={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_STATUS}
                  label={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_STATUS_LABEL}
                  options={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_STATUS_OPTIONS}
                />
                <ErrorMessage
                  name={PRODUCT_TAX_CONSTANTS.PRODUCT_TAX_STATUS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            ) : null}
          </CardBody>
        </Card>
      </div>
      <div className="col-12">
        <Button
          type={BUTTON_CONSTANTS.SUBMIT}
          text={FORM_CONSTANTS.SUBMIT}
          btnClassNames={"btn btn-primary align-self-center w-25"}
        />
      </div>
    </Form>
  );
};

export { TaxForm };
