import { createSlice } from "@reduxjs/toolkit";
import { generateBarcode } from "apis/restApis";
import { FormikHelpers } from "formik";
import {
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  base64ToArrayBuffer,
  promisedPopupBlockerTest,
  testPopUp,
} from "utils";

const initialState = {
  status: STATUSES.IDLE,
  error: null,
};

const barcodeGeneratorSlice = createSlice({
  name: "barcodeGenerator",
  initialState,
  reducers: {
    setBarcodeGeneratorStatus: (state, action) => {
      state.status = action.payload;
    },
    setBarcodeGeneratorError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setBarcodeGeneratorStatus,
  setBarcodeGeneratorError,
} = barcodeGeneratorSlice.actions;

export default barcodeGeneratorSlice.reducer;

export function generateNewBarcode(
  barcodeParams: any,
  action: FormikHelpers<any>
) {
  return async function generateNewBarcodeThunk(dispatch) {
    dispatch(setBarcodeGeneratorStatus(STATUSES.LOADING));
    generateBarcode(barcodeParams)
      .then((response: any) => {
        const content = base64ToArrayBuffer(response.result);
        const blob = new Blob([content], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        // promisedPopupBlockerTest()
        //   .then((result) =>
        //     console.log(`resolved with ${JSON.stringify(result)}`)
        //   )
        //   .catch((reason) =>
        //     console.log(`rejected with ${JSON.stringify(reason)}`)
        //   );
        // testPopUp(url);
        try {
          window.open(url, "_blank");
          // const generatedLabel = window.open(
          //   url,
          //   "_blank",
          //   "toolbar=no,location=no,menubar=no"
          // );
          // if (!generatedLabel || generatedLabel.outerHeight === 0) {
          //   Toast(
          //     "Popup Blocker is enabled! Please add this site to your exception list.",
          //     TOAST_CONSTANTS.WARNING
          //   );
          //   return;
          // }
          action.resetForm();
        } catch (error) {
          Toast(
            "Please enable pop-ups to print the barcode",
            TOAST_CONSTANTS.ERROR
          );
        }

        // const iframe = document.createElement("iframe");
        // iframe.style.display = "none";
        // iframe.src = url;
        // document.body.appendChild(iframe);
        // iframe.contentWindow.print();
      })
      .catch((error: any) => {
        dispatch(setBarcodeGeneratorError(error));
        dispatch(setBarcodeGeneratorStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setBarcodeGeneratorStatus(STATUSES.IDLE));
      });
  };
}
