import React, { useEffect, useRef, useState } from "react";

import { Formik, FormikProps } from "formik";

import { ConfirmModal, Container, ContentHeader, OffCanvas } from "components";

import {
  LABEL_CONSTANTS,
  LabelSchema,
  getNewValues,
  handleOptionDelete,
  useAppDispatch,
  useAppSelector,
} from "utils";

import {
  addNewLabel,
  deleteLabel,
  deleteLabelPrinterMapping,
  getAllPrintSize,
  getAllPrintType,
  updateExistingLabel,
} from "store/Utilities/labelSLice";

import { LabelList } from "./LabelList";
import LabelForm from "./LabelForm";
import { setCommonStatus } from "store/commonSlice";

const Label = () => {
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState<false | {}>();
  const [editId, setEditId] = useState(0);
  const [show, setShow] = useState(false);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const PropsRef = useRef<FormikProps<any>>();

  const ActionsRef = useRef<any>();

  const [currentLabel, setCurrentLabel] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const [initialPrinters, setInitialPrinters] = useState([]);

  const initialValues = {
    template: "",
    print_type: "",
    printers: [],
    size: "",
    is_active: [{ value: 1, label: "Active" }],
  };

  const handleSubmit = (values, actions) => {
    let dataToSend = {
      ...values,
      template: values.template ? values.template[0].value : null,
      print_type: values.print_type ? values.print_type[0].value : null,
      printers: values.printers.map((item) => item.value),
      size: values.size ? values.size[0].value : null,
      is_active: values?.is_active?.[0]?.value,
    };
    if (edit) {
      dataToSend = {
        ...dataToSend,
        printers: getNewValues(values.printers, initialPrinters),
      };
      dispatch(updateExistingLabel(values.id, dataToSend, actions, setShow));
    } else {
      dispatch(addNewLabel(dataToSend, actions, setShow));
    }
  };

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader pageHeader="Label" />
        <div className="col-12">
          <LabelList
            setEdit={setEdit}
            setShow={setShow}
            setInitialPrinters={setInitialPrinters}
            setCurrentLabel={setCurrentLabel}
            setConfirmModal={setConfirmDeleteModal}
          />
          <OffCanvas
            title={
              edit ? LABEL_CONSTANTS.EDIT_HEADER : LABEL_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={edit ? edit : initialValues}
              validationSchema={LabelSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                PropsRef.current = props;
                return (
                  <LabelForm
                    props={PropsRef.current}
                    actionsRef={ActionsRef}
                    edit={edit}
                    editId={editId}
                    setConfirmModal={setConfirmModal}
                  />
                );
              }}
            </Formik>
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={""}
        message={"Are you sure you want to delete the option ?"}
        confirmClick={() => {
          handleOptionDelete(
            "printers",
            PropsRef.current,
            ActionsRef.current,
            (value) => {
              dispatch(
                deleteLabelPrinterMapping({
                  label: Number(editId),
                  printer: value,
                })
              );
            },
            (value) => {
              setInitialPrinters((prev) => {
                return ActionsRef.current.action !== "clear"
                  ? prev.filter((item) => item.value !== value)
                  : [];
              });
            },
            (value) => {
              dispatch(
                setCommonStatus({ state: value, type: "deletePrinter" })
              );
            }
          );
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
      <ConfirmModal
        modal={confirmDeleteModal}
        setModal={setConfirmDeleteModal}
        title={LABEL_CONSTANTS.DELETE_LABEL}
        message={LABEL_CONSTANTS.DELETE_LABEL_MSG}
        confirmClick={() => {
          dispatch(deleteLabel(currentLabel));
          setConfirmDeleteModal(false);
        }}
        rejectClick={() => {
          setConfirmDeleteModal(false);
        }}
      />
    </div>
  );
};

export { Label };
