import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, ErrorMessage } from "formik";

import {
  ContentHeader,
  CustomInput,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  MultiSelect,
  Loader,
} from "components";

import {
  useAppDispatch,
  useAppSelector,
  BankSchema,
  BANK_CONSTANTS,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
  GENERAL_CONSTANTS,
} from "utils";

import {
  fetchCity,
  fetchState,
  getCity,
  getCountry,
  getState,
} from "store/locationSlice";

import {
  addNewBank,
  getIndividualBank,
  setBankToEdit,
  updateExistingBank,
} from "store/ManageBanks/bankSlice";
import { ScrollToFieldError } from "components/ScollToFieldError";

const BanksForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    bank: { bankToEdit, status: bankStatus },
    location: { countries, cities, states, status: locationStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const [addressFilled, setAddressFilled] = useState(
    id &&
      bankToEdit?.bank_address_line_1 !== "" &&
      bankToEdit?.bank_address_line_2 !== ""
  );

  const initialValues = {
    bank_name: "",
    ifsc_code: "",
    branch_name: "",

    account_holder_name: "",
    account_number: "",

    opening_credit_balance: 0,
    opening_debit_balance: 0,
    is_upi_available: [],

    bank_address_line_1: "",
    bank_address_line_2: "",
    bank_city: "",
    bank_state: "",
    bank_country: "",
    bank_pincode: "",

    is_active: [{ value: 1, label: "Active" }],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: values.is_active?.[0].value * 1,
      is_upi_available: values.is_upi_available?.[0]?.value || 0,
      bank_address_line_1: addressFilled ? values.bank_address_line_1 : "",
      bank_address_line_2: addressFilled ? values.bank_address_line_2 : "",
      bank_city: addressFilled ? values.bank_city?.[0]?.value : "",
      bank_state: addressFilled ? values.bank_state?.[0]?.value : "",
      bank_country: addressFilled ? values.bank_country?.[0]?.value : "",
    };

    // console.log("=====================================");
    // console.log("values", values);
    // console.log("=====================================");
    // console.log("dataToSend", dataToSend);
    // console.log("=====================================");
    if (id) {
      dispatch(updateExistingBank(Number(id), dataToSend, actions, navigate));
    } else {
      dispatch(addNewBank(dataToSend, actions, navigate));
    }
  };

  useEffect(() => {
    id && dispatch(getIndividualBank(Number(id), true));
    return () => {
      dispatch(setBankToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          editValue={bankToEdit?.bank_name}
          pageHeader={
            id ? BANK_CONSTANTS.EDIT_HEADER : BANK_CONSTANTS.ADD_HEADER
          }
        />
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={id ? bankToEdit : initialValues}
            validationSchema={BankSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form data-testid={"bank-form"}>
                <ScrollToFieldError />
                {bankStatus === STATUSES.LOADING && (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                )}
                <div className="row">
                  {/* Bank Details:  */}
                  <div className="col-md-12">
                    <Card>
                      <CardHeader>
                        <h3 className="card-title">
                          {BANK_CONSTANTS.CARD_TITLE_1}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <div className="row ">
                          <div className="col-md-6">
                            <CustomInput
                              isRequired={true}
                              label={BANK_CONSTANTS.BANK_NAME_LABEL}
                              name={BANK_CONSTANTS.BANK_NAME}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={BANK_CONSTANTS.BANK_NAME_PLACEHOLDER}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.BANK_NAME}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <MultiSelect
                              // isRequired={true}
                              label={BANK_CONSTANTS.BANK_STATUS_LABEL}
                              name={BANK_CONSTANTS.BANK_STATUS}
                              select={false}
                              options={BANK_CONSTANTS.BANK_STATUS_OPTIONS}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.BANK_STATUS}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <CustomInput
                              isRequired={true}
                              label={BANK_CONSTANTS.BANK_IFSC_CODE_LABEL}
                              name={BANK_CONSTANTS.BANK_IFSC_CODE}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={
                                BANK_CONSTANTS.BANK_IFSC_CODE_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.BANK_IFSC_CODE}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <CustomInput
                              label={BANK_CONSTANTS.BANK_BRANCH_NAME_LABEL}
                              // isRequired={true}
                              name={BANK_CONSTANTS.BANK_BRANCH_NAME}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={
                                BANK_CONSTANTS.BANK_BRANCH_NAME_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.BANK_BRANCH_NAME}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {/* Bank Address:  */}
                  <div className="col-md-12">
                    <Card>
                      <CardHeader>
                        <h3 className="card-title">
                          {BANK_CONSTANTS.CARD_TITLE_2}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col-12">
                            <CustomInput
                              label={BANK_CONSTANTS.ADDRESS_LINE_1_LABEL}
                              // isRequired={true}
                              name={BANK_CONSTANTS.ADDRESS_LINE_1}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={
                                BANK_CONSTANTS.ADDRESS_LINE_1_PLACEHOLDER
                              }
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  BANK_CONSTANTS.ADDRESS_LINE_1,
                                  e.target.value
                                );
                                setAddressFilled(
                                  props.values.bank_address_line_1 !== "" &&
                                    props.values.bank_address_line_2 !== ""
                                );
                              }}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.ADDRESS_LINE_1}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-12">
                            <CustomInput
                              label={BANK_CONSTANTS.ADDRESS_LINE_2_LABEL}
                              // isRequired={true}
                              name={BANK_CONSTANTS.ADDRESS_LINE_2}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={
                                BANK_CONSTANTS.ADDRESS_LINE_2_PLACEHOLDER
                              }
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  BANK_CONSTANTS.ADDRESS_LINE_2,
                                  e.target.value
                                );
                                setAddressFilled(
                                  props.values.bank_address_line_1 !== "" ||
                                    props.values.bank_address_line_2 !== ""
                                );
                              }}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.ADDRESS_LINE_2}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <MultiSelect
                              select={false}
                              // isRequired={true}
                              disabled={!addressFilled}
                              label={BANK_CONSTANTS.COUNTRY_LABEL}
                              name={BANK_CONSTANTS.COUNTRY}
                              isLoading={locationStatus === STATUSES.LOADING}
                              options={countries?.map((country, index) => ({
                                value: country.id,
                                label: country.country,
                              }))}
                              onFocusHandler={() => {
                                countries.length === 0 &&
                                  dispatch(getCountry());
                              }}
                              onChangeHandler={(e) => {
                                e
                                  ? dispatch(getState(e.value))
                                  : dispatch(fetchState([]));
                                props.setFieldValue(
                                  `${BANK_CONSTANTS.COUNTRY}`,
                                  [e]
                                );
                              }}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.COUNTRY}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <MultiSelect
                              select={false}
                              // isRequired={true}
                              disabled={!addressFilled}
                              label={BANK_CONSTANTS.STATE_LABEL}
                              name={BANK_CONSTANTS.STATE}
                              isLoading={locationStatus === STATUSES.LOADING}
                              options={states.map((state, index) => ({
                                value: state.id,
                                label: state.state,
                              }))}
                              onFocusHandler={() => {
                                states.length === 0 &&
                                  props.values?.bank_country?.[0]?.value &&
                                  dispatch(
                                    getState(
                                      props.values?.bank_country?.[0]?.value
                                    )
                                  );
                              }}
                              onChangeHandler={(e) => {
                                e
                                  ? dispatch(getCity(e.value))
                                  : dispatch(fetchCity([]));
                                props.setFieldValue(`${BANK_CONSTANTS.STATE}`, [
                                  e,
                                ]);
                              }}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.STATE}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <MultiSelect
                              select={false}
                              // isRequired={true}
                              disabled={!addressFilled}
                              label={BANK_CONSTANTS.CITY_LABEL}
                              name={BANK_CONSTANTS.CITY}
                              isLoading={locationStatus === STATUSES.LOADING}
                              onFocusHandler={() => {
                                cities.length === 0 &&
                                  props.values?.bank_state?.[0]?.value &&
                                  dispatch(
                                    getCity(
                                      props.values?.bank_state?.[0]?.value
                                    )
                                  );
                              }}
                              options={cities.map((city, index) => ({
                                value: city.id,
                                label: city.city,
                              }))}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.CITY}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <CustomInput
                              label={BANK_CONSTANTS.PINCODE_LABEL}
                              // isRequired={true}
                              isDisabled={!addressFilled}
                              name={BANK_CONSTANTS.PINCODE}
                              type={FORM_CONSTANTS.NUMBER}
                              placeholder={BANK_CONSTANTS.PINCODE_PLACEHOLDER}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.PINCODE}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {/* Bank Account Details:  */}
                  <div className="col-md-12">
                    <Card>
                      <CardHeader>
                        <h3 className="card-title">
                          {BANK_CONSTANTS.CARD_TITLE_3}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col-md-6">
                            <CustomInput
                              label={BANK_CONSTANTS.ACCOUNT_HOLDER_NAME_LABEL}
                              // isRequired={true}
                              name={BANK_CONSTANTS.ACCOUNT_HOLDER_NAME}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={
                                BANK_CONSTANTS.ACCOUNT_HOLDER_NAME_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.ACCOUNT_HOLDER_NAME}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <CustomInput
                              label={BANK_CONSTANTS.ACCOUNT_NUMBER_LABEL}
                              // isRequired={true}
                              name={BANK_CONSTANTS.ACCOUNT_NUMBER}
                              type={FORM_CONSTANTS.TEXT}
                              placeholder={
                                BANK_CONSTANTS.ACCOUNT_NUMBER_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.ACCOUNT_NUMBER}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <CustomInput
                              label={
                                BANK_CONSTANTS.OPENING_CREDIT_BALANCE_LABEL
                              }
                              // isRequired={true}
                              name={BANK_CONSTANTS.OPENING_CREDIT_BALANCE}
                              type={FORM_CONSTANTS.NUMBER}
                              placeholder={
                                BANK_CONSTANTS.OPENING_CREDIT_BALANCE_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.OPENING_CREDIT_BALANCE}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-4">
                            <CustomInput
                              label={BANK_CONSTANTS.OPENING_DEBIT_BALANCE_LABEL}
                              // isRequired={true}
                              name={BANK_CONSTANTS.OPENING_DEBIT_BALANCE}
                              type={FORM_CONSTANTS.NUMBER}
                              placeholder={
                                BANK_CONSTANTS.OPENING_DEBIT_BALANCE_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.OPENING_DEBIT_BALANCE}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-4">
                            <MultiSelect
                              label={BANK_CONSTANTS.UPI_AVAILABLE_LABEL}
                              name={BANK_CONSTANTS.UPI_AVAILABLE}
                              select={false}
                              // isRequired={true}
                              options={BANK_CONSTANTS.UPI_AVAILABLE_OPTIONS}
                            />
                            <ErrorMessage
                              name={BANK_CONSTANTS.UPI_AVAILABLE}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {/* Submit */}
                  <div className="col-md-6">
                    <Button
                      testId={"submit-button"}
                      loading={bankStatus === STATUSES.LOADING}
                      type={BUTTON_CONSTANTS.SUBMIT}
                      text={FORM_CONSTANTS.SUBMIT}
                      btnClassNames={"btn btn-primary align-self-center w-25"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { BanksForm };
