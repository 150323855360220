import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorMessage, Form, Formik } from "formik";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import { login } from "store/authSlice";

import {
  CustomCheckbox,
  CustomInput,
  Card,
  Button,
  CardHeader,
  CardBody,
  Loader,
} from "components";

import {
  useAppDispatch,
  useAppSelector,
  LOGIN_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
  BUTTON_CONSTANTS,
  LoginSchema,
  ROUTES,
  TLogin,
} from "utils";

import logo from "assets/images/avaryaLogo.png";

/**
 * Login component for user authentication.
 * Renders a login form with email and password fields.
 * Handles form submission and redirects to the dashboard if the user is logged in.
 */
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const {
    auth: { isLoggedIn, status, error },
  } = useAppSelector((state) => state.root);

  const initialValues: TLogin = {
    login_password: "",
    login_email: "",
  };

  const submitHandler = async (values) => {
    await dispatch(
      login({ email: values.login_email, password: values.login_password })
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [status, isLoggedIn]);

  return (
    <div className="login-page ">
      <div className="login-box">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-center">
              <img
                src={logo}
                alt="logo"
                className="img-responsive w-100 h-100"
              />
            </div>
          </CardHeader>
          <CardBody>
            <p className="login-box-msg mt-2">{LOGIN_CONSTANTS.LOGIN_MSG}</p>
            <Formik
              initialValues={initialValues}
              onSubmit={submitHandler}
              validationSchema={LoginSchema}
            >
              {(props) => (
                <Form>
                  <div className="row">
                    <CustomInput
                      label={LOGIN_CONSTANTS.EMAIL_LABEL}
                      type={FORM_CONSTANTS.EMAIL}
                      value={props.values.login_email}
                      name={LOGIN_CONSTANTS.EMAIL}
                      placeholder={LOGIN_CONSTANTS.EMAIL_PLACEHOLDER}
                      iconType={faEnvelope}
                    />
                    <ErrorMessage
                      name={LOGIN_CONSTANTS.EMAIL}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                  <div className="row">
                    <CustomInput
                      type={
                        showPassword
                          ? FORM_CONSTANTS.TEXT
                          : FORM_CONSTANTS.PASSWORD
                      }
                      value={props.values.login_password}
                      label={LOGIN_CONSTANTS.PASSWORD_LABEL}
                      name={LOGIN_CONSTANTS.PASSWORD}
                      placeholder={LOGIN_CONSTANTS.PASSWORD_PLACEHOLDER}
                      iconType={faLock}
                      isPass={true}
                      showPass={showPassword}
                      setShowPass={setShowPassword}
                    />
                    <ErrorMessage
                      name={LOGIN_CONSTANTS.PASSWORD}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                  <div className="row justify-content-center mt-2">
                    {status === STATUSES.ERROR && (
                      <div className="row  mt-2">
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <Button
                        loading={status === STATUSES.LOADING}
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={LOGIN_CONSTANTS.LOGIN}
                        btnClassNames={"btn btn-primary m-0"}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export { Login };
