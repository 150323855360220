import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import {
  deleteEmployee,
  getEmployees,
} from "store/ManageEmployee/employeeSlice";

import {
  ConfirmModal,
  ContentHeader,
  Loader,
  NoData,
  Status,
  TableTools,
} from "components";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FontIcon,
  Table,
  TableBody,
  TableHeader,
  CardFooter,
  Pagination,
  Badge,
} from "components/core-components";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BUTTON_CONSTANTS,
  EMPLOYEE_CONSTANTS,
  GENERAL_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { updatePageNo } from "store/commonSlice";

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    employee: { employees: employeeData, status },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [currentEmployee, setCurrentEmployee] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const employeePermission = permissions.find(
    (p) => p.module_name === "Manage Employee"
  );

  const tableCols = EMPLOYEE_CONSTANTS.EMPLOYEE_COLS;

  const update_page = (pageNo: number) => {
    dispatch(getEmployees({ pageNo, query }));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={EMPLOYEE_CONSTANTS.EMPLOYEE_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={employeePermission?.perm_add}
                path={EMPLOYEE_CONSTANTS.PATH}
                label={EMPLOYEE_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : employeeData.length > 0 ? (
                    employeeData.map((employee, index) => {
                      return (
                        <tr key={index}>
                          <td>{page_size * (current_page - 1) + index + 1}</td>
                          <td>{employee.username}</td>
                          <td>{employee.email}</td>
                          <td>
                            {employee.stores.map((store, index) => {
                              return (
                                <h5 key={index}>
                                  <Badge
                                    badgeType={GENERAL_CONSTANTS.INFO}
                                    badgeText={store.store.store_name}
                                  />
                                </h5>
                              );
                            })}
                          </td>
                          <td>
                            <Status is_active={employee.is_active} />
                          </td>
                          <td>
                            <Button
                              text={<FontIcon icon={faPenToSquare} />}
                              isDisabled={!employeePermission?.perm_edit}
                              type={BUTTON_CONSTANTS.BUTTON}
                              btnClassNames={"btn btn-info btn-sm mr-2"}
                              onClickHandler={() =>
                                navigate(
                                  ROUTES_CONSTANTS.EDIT + "/" + employee.id
                                )
                              }
                            />
                            {employee.is_active ? (
                              <Button
                                text={<FontIcon icon={faBan} />}
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={!employeePermission?.perm_delete}
                                btnClassNames={"btn btn-danger btn-sm"}
                                onClickHandler={() => {
                                  setConfirmModal(true);
                                  setCurrentEmployee(Number(employee.id));
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={EMPLOYEE_CONSTANTS.DELETE_EMPLOYEE}
        message={EMPLOYEE_CONSTANTS.DELETE_EMPLOYEE_MSG}
        confirmClick={() => {
          dispatch(deleteEmployee(currentEmployee));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Employees };
