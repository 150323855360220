import { Table, TableBody, TableHeader } from "components";
import React, { useEffect } from "react";
import { FetchAllActivePOSPaymentModes } from "store/POS";
import {
  IGetOrder,
  ORDER_CONSTANTS,
  POSAdvance,
  formatDate,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  orderDetails: IGetOrder | POSAdvance;
}

const OrderPaymentDetails = ({ orderDetails }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    posPaymentMode: { payment_mode },
  } = useAppSelector((state) => state.root);

  const findPaymentMode = (id: number) => {
    return payment_mode.find((mode) => mode?.id === id)?.payment_mode;
  };

  useEffect(() => {
    payment_mode?.length === 0 && dispatch(FetchAllActivePOSPaymentModes());
  }, []);

  return (
    <div className="d-flex flex-row px-0">
      <Table>
        <TableHeader
          cols={ORDER_CONSTANTS.PAYMENT_TABLE_HEADER}
          tableHeaderClassNames="table-dark"
        />
        <TableBody>
          {orderDetails?.payments?.map((payment, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {Object.keys(payment?.payment_mode)?.length > 0
                    ? payment?.payment_mode?.payment_mode
                    : findPaymentMode(payment?.payment_mode)}
                </td>
                <td>{formatDate(payment?.created_at)}</td>
                <td>₹{payment?.amount}</td>
              </tr>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export { OrderPaymentDetails };
