import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Loader,
  MultiSelect,
} from "components";
import { Form, ErrorMessage } from "formik";
import React, { useEffect } from "react";
import { getCategories } from "store/ManageProducts/productCategorySlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCT_SUB_CATEGORY_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

const SubCategoryForm = ({
  props,
  actionsRef,
  edit,
  editId,
  setConfirmModal,
  setDeleteField,
}) => {
  const dispatch = useAppDispatch();

  const {
    common: { status: commonStatus },
    productCategory: { productCategory: categoryData },
    productSubCategory: { status: subCategoryStatus },
  } = useAppSelector((state) => state.root);

  useEffect(() => {
    dispatch(getCategories(true));
  }, []);

  return (
    <Form>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row">
                <MultiSelect
                  select={true}
                  label={PRODUCT_SUB_CATEGORY_CONSTANTS.PARENT_CATEGORY_LABEL}
                  name={PRODUCT_SUB_CATEGORY_CONSTANTS.PARENT_CATEGORY}
                  isRequired={true}
                  options={categoryData?.map((item) => ({
                    value: item.id,
                    label: item.category_name,
                  }))}
                  onChangeHandler={(e, actions) => {
                    if (
                      (editId && actions.action == "remove-value") ||
                      actions.action == "pop-value" ||
                      actions.action == "clear"
                    ) {
                      actionsRef.current = actions;
                      setConfirmModal(true);
                      setDeleteField("categories");
                    } else {
                      const selectedOptions = Array.isArray(e) ? e : [e];

                      props.setFieldValue("categories", selectedOptions);
                    }
                  }}
                />
                <ErrorMessage
                  name={PRODUCT_SUB_CATEGORY_CONSTANTS.PARENT_CATEGORY}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  isRequired={true}
                  label={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_NAME_LABEL
                  }
                  value={props.values.sub_category_name}
                  name={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_NAME
                  }
                  placeholder={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_NAME_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_NAME
                  }
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {edit ? (
                <div className="row">
                  <MultiSelect
                    select={false}
                    name={
                      PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_STATUS
                    }
                    label={
                      PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_STATUS_LABEL
                    }
                    options={
                      PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_STATUS_OPTIONS
                    }
                  />
                </div>
              ) : null}
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  isRequired={true}
                  label={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_CODE_LABEL
                  }
                  value={props.values.sub_category_code}
                  name={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_CODE
                  }
                  placeholder={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_CODE_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_CODE
                  }
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="row">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  label={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_DESCRIPTION_LABEL
                  }
                  value={props.values.description}
                  name={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_DESCRIPTION
                  }
                  placeholder={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_DESCRIPTION_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={
                    PRODUCT_SUB_CATEGORY_CONSTANTS.PRODUCT_SUB_CATEGORY_DESCRIPTION
                  }
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12">
          <Button
            loading={subCategoryStatus === STATUSES.LOADING}
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export { SubCategoryForm };
