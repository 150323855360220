import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delCMSProductTasteMapping,
  delTaste,
  getTastes,
  setTaste,
  updateTaste,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  ECOMMERCE_PRODUCT_CONSTANTS,
  STATUSES,
  TASTE_CONSTANTS,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
  TTaste,
} from "utils";

const initialState = {
  tastes: [] as TTaste[],
  taste: {} as TTaste,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchTastes = createAsyncThunk(
  "taste/fetchTastes",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getTastes({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewTaste = createAsyncThunk(
  "taste/addNewTaste",
  async (
    {
      tasteParams,
      actions,
      setShow,
    }: {
      tasteParams: any;
      actions: FormikHelpers<any>;
      setShow: React.Dispatch<React.SetStateAction<boolean>>;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setTaste(tasteParams);
      actions.resetForm();
      setShow(false);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingTaste = createAsyncThunk(
  "taste/updateExistingTaste",
  async (
    {
      id,
      tasteParams,
      actions,
      setShow,
    }: {
      id: number;
      tasteParams: any;
      actions: FormikHelpers<any>;
      setShow: React.Dispatch<React.SetStateAction<boolean>>;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateTaste(id, tasteParams);
      actions.resetForm();
      setShow(false);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTaste = createAsyncThunk(
  "taste/deleteTaste",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "taste-delete" })
    );
    try {
      const response = await delTaste(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "taste-delete" }));
    }
  }
);

export const deleteTasteMapping = createAsyncThunk(
  "ecommerce_products/deleteTasteMapping",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_TASTE_STATE,
      })
    );
    try {
      const response = await delCMSProductTasteMapping(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({
          state: STATUSES.IDLE,
          type: ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_TASTE_STATE,
        })
      );
    }
  }
);

const tasteSlice = createSlice({
  name: "taste",
  initialState,
  reducers: {
    getAllProductTaste(state, action) {
      state.tastes = action.payload;
    },
    resetTasteState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTastes.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.taste = action.payload.result;
        } else if (action.meta.arg.active) {
          state.tastes = action.payload.result;
        } else {
          state.tastes = action.payload.result.results;
        }
      })
      .addCase(addNewTaste.fulfilled, (state, action: any) => {
        state.tastes.unshift(action.payload.result);
        state.tastes = state.tastes.slice(0, 10);
      })
      .addCase(updateExistingTaste.fulfilled, (state, action: any) => {
        // Update the existing taste in the tastes array
        const index = state.tastes.findIndex(
          (taste) => taste.id === action.payload.result.id
        );
        if (index !== -1) {
          state.tastes[index] = action.payload.result;
        }
      })
      .addCase(deleteTaste.fulfilled, (state, action: any) => {
        // update the deleted taste from the tastes array
        const index = state.tastes.findIndex(
          (taste) => taste.id === action.meta.arg
        );
        if (index !== -1) {
          state.tastes[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewTaste.fulfilled,
          updateExistingTaste.fulfilled,
          deleteTaste.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchTastes.pending
          // addNewTaste.pending,
          // updateExistingTaste.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const { getAllProductTaste, resetTasteState } = tasteSlice.actions;

export default tasteSlice.reducer;
