import React, { memo, useState } from "react";

import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  MultiSelect,
} from "components";

import { ErrorMessage, FieldArray } from "formik";

import {
  deleteVendorAddress,
  getCity,
  getCountry,
  getState,
} from "store/locationSlice";

import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
  VENDORS_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { getAddressTypes } from "store/addressTypeSlice";
import { useParams } from "react-router-dom";

const VendorAddressDetailsForm = ({ props, DeletedValues }) => {
  const dispatch = useAppDispatch();

  const {
    addressType: { addressTypes: addressTypeData, status: addressTypeStatus },
    location: {
      cities: cityData,
      states: stateData,
      countries: countryData,
      status: locationStatus,
    },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();
  const currentId = Number(id);

  const [addressFilled, setAddressFilled] = useState(false);

  return (
    <div className="col-md-12">
      <Card>
        <CardHeader>
          <h3 className="card-title">{VENDORS_CONSTANTS.CARD_TITLE_4}</h3>
        </CardHeader>
        <CardBody>
          <FieldArray
            name={VENDORS_CONSTANTS.ADDRESS_DETAILS}
            render={({ push, remove }) =>
              props.values?.address?.map((address, index) => (
                <div key={index} className="row">
                  <div className="col-11">
                    <Card>
                      <CardHeader>
                        <h3 className="card-title">Address {index + 1}</h3>
                      </CardHeader>
                      <CardBody key={index}>
                        <div className="row">
                          <div className="col-md-12">
                            <MultiSelect
                              select={false}
                              label={
                                VENDORS_CONSTANTS.VENDOR_ADDRESS_TYPE_LABEL
                              }
                              // isRequired={true}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_TYPE}`}
                              onFocusHandler={() => {
                                addressTypeData.length === 0 &&
                                  dispatch(getAddressTypes());
                              }}
                              options={addressTypeData.map((item) => ({
                                value: item.id,
                                label: item.type,
                              }))}
                              isLoading={addressTypeStatus === STATUSES.LOADING}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_TYPE}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <CustomInput
                              label={
                                VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_1_LABEL
                              }
                              // isRequired={true}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_1}`}
                              placeholder={
                                VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_1_PLACEHOLDER
                              }
                              type={FORM_CONSTANTS.TEXT}
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  `${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_1}`,
                                  e.target.value
                                );
                                setAddressFilled(
                                  props.values.address?.[index]
                                    ?.address_line_1 !== "" &&
                                    props.values.address?.[index]
                                      ?.address_line_2 !== ""
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_1}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <CustomInput
                              label={
                                VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_2_LABEL
                              }
                              // isRequired={true}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_2}`}
                              placeholder={
                                VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_2_PLACEHOLDER
                              }
                              type={FORM_CONSTANTS.TEXT}
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  `${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_2}`,
                                  e.target.value
                                );
                                setAddressFilled(
                                  props.values.address?.[index]
                                    ?.address_line_1 !== "" ||
                                    props.values.address?.[index]
                                      ?.address_line_2 !== ""
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_ADDRESS_LINE_2}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <MultiSelect
                              select={false}
                              disabled={!addressFilled}
                              label={VENDORS_CONSTANTS.VENDOR_COUNTRY_LABEL}
                              // isRequired={true}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_COUNTRY}`}
                              onFocusHandler={() => {
                                countryData.length === 0 &&
                                  dispatch(getCountry());
                              }}
                              options={countryData.map((item) => ({
                                value: item.id,
                                label: item.country,
                              }))}
                              isLoading={locationStatus === STATUSES.LOADING}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_COUNTRY}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <MultiSelect
                              select={false}
                              disabled={!addressFilled}
                              // isRequired={true}
                              label={VENDORS_CONSTANTS.VENDOR_STATE_LABEL}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_STATE}`}
                              onFocusHandler={() => {
                                dispatch(
                                  getState(
                                    props.values?.address?.[index]?.country?.[0]
                                      ?.value
                                  )
                                );
                              }}
                              options={stateData.map((item) => ({
                                value: item.id,
                                label: item.state,
                              }))}
                              isLoading={locationStatus === STATUSES.LOADING}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_STATE}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <MultiSelect
                              select={false}
                              disabled={!addressFilled}
                              // isRequired={true}
                              isLoading={locationStatus === STATUSES.LOADING}
                              label={VENDORS_CONSTANTS.VENDOR_CITY_LABEL}
                              onFocusHandler={() => {
                                dispatch(
                                  getCity(
                                    props.values?.address?.[index]?.state?.[0]
                                      ?.value
                                  )
                                );
                              }}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_CITY}`}
                              options={cityData.map((item) => ({
                                value: item.id,
                                label: item.city,
                              }))}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_CITY}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-md-6">
                            <CustomInput
                              isDisabled={!addressFilled}
                              label={VENDORS_CONSTANTS.VENDOR_PINCODE_LABEL}
                              // isRequired={true}
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_PINCODE}`}
                              placeholder={
                                VENDORS_CONSTANTS.VENDOR_PINCODE_PLACEHOLDER
                              }
                              type={FORM_CONSTANTS.NUMBER}
                            />
                            <ErrorMessage
                              name={`${VENDORS_CONSTANTS.ADDRESS_DETAILS}.${index}.${VENDORS_CONSTANTS.VENDOR_PINCODE}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-md-1">
                    <Button
                      text={<FontIcon icon={faTrash} />}
                      type={BUTTON_CONSTANTS.BUTTON}
                      isDisabled={
                        index === 0 &&
                        props.values.address &&
                        props.values.address.length === 1
                      }
                      btnClassNames={"btn btn-danger mr-2"}
                      onClickHandler={() => {
                        remove(index);
                        if (currentId && !props.values.address[index].new) {
                          dispatch(
                            deleteVendorAddress(props.values.address[index].id)
                          );
                          DeletedValues.push(props.values.address[index].id);
                        }
                      }}
                    />
                    {props.values.address &&
                    props.values.address.length - 1 === index ? (
                      <Button
                        text={<FontIcon icon={faAdd} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        btnClassNames={"btn btn-primary"}
                        onClickHandler={() =>
                          push({
                            address: "",
                            city: "",
                            state: "",
                            pincode: 0,
                            country: "",
                            new: true,
                          })
                        }
                      />
                    ) : null}
                  </div>
                </div>
              ))
            }
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VendorAddressDetailsForm);
