import { createSlice } from "@reduxjs/toolkit";
import { getActiveCouponUsage, getCouponUsage } from "apis/restApis";
import { STATUSES } from "utils";

interface DiscountCouponUsage {
  id?: number;
  usage: string;
  is_active?: number;
}

const initialState = {
  couponUsage: [] as DiscountCouponUsage[],
  status: STATUSES.IDLE as string,
  error: null,
};

const discountCouponUsageSlice = createSlice({
  name: "discountCouponUsage",
  initialState,
  reducers: {
    getAllCouponUsage: (state, action) => {
      state.couponUsage = action.payload;
    },
    addCouponUsage: (state, action) => {
      state.couponUsage.push(action.payload);
    },
    removeCouponUsage: (state, action) => {
      state.couponUsage = state.couponUsage.filter(
        (item) => item.id !== action.payload
      );
    },
    editCouponUsage: (state, action) => {
      const { id } = action.payload;
      const index = state.couponUsage.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.couponUsage[index] = action.payload;
      }
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAllCouponUsage,
  addCouponUsage,
  removeCouponUsage,
  editCouponUsage,

  setStatus,
  setError,
} = discountCouponUsageSlice.actions;

export default discountCouponUsageSlice.reducer;

export function fetchCouponUsage(active?: boolean) {
  return async function fetchCouponUsageThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    active
      ? getActiveCouponUsage()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getAllCouponUsage(response.result));
          })
          .catch((error: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(setError(error.message));
          })
      : getCouponUsage()
          .then((response: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(getAllCouponUsage(response.result));
          })
          .catch((error: any) => {
            dispatch(setStatus(STATUSES.IDLE));
            dispatch(setError(error.message));
          });
  };
}
