import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import {
  deleteProduct,
  getProducts,
  resetProductState,
} from "store/ManageProducts/productSlice";

import {
  ContentHeader,
  NoData,
  TableTools,
  Loader,
  Status,
  ConfirmModal,
} from "components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import { faBan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  ACCESS_CONSTANTS,
  BUTTON_CONSTANTS,
  POS_CONSTANTS,
  PRODUCTS_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { IGetProduct } from "utils";
import { getUOMs } from "store/uomSlice";

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions, logged_in_user },
    common: { current_page, total_items, total_pages, page_size },
    product: { products: productData, status },
    uom: { uom: uomData },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [currentProduct, setCurrentProduct] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const productPermission = permissions.find(
    (p) => p.module_name === "Manage Products"
  );

  const update_page = (pageNo: number) => {
    dispatch(getProducts(false, pageNo, query));
  };

  const avaryaBrandEditAccess = permissions.find(
    (item) => item.module_name === "Avarya Product Filter"
  );

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    dispatch(getUOMs(true));
    return () => {
      resetState();
      dispatch(resetProductState());
    };
  }, [dispatch]);

  const tableCols = PRODUCTS_CONSTANTS.PRODUCTS_COLS;

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={PRODUCTS_CONSTANTS.PRODUCTS_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={productPermission?.perm_add}
                path={PRODUCTS_CONSTANTS.PATH}
                label={PRODUCTS_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : productData && productData?.length > 0 ? (
                    productData.map((product: IGetProduct, index) => {
                      const avaryaBrandProduct =
                        product?.brand?.id === ACCESS_CONSTANTS.AVARYA_BRAND_ID;
                      return (
                        <tr key={product.id}>
                          <td>{page_size * (current_page - 1) + index + 1}</td>
                          <td className="text-start text-wrap">
                            {product.print_name}
                          </td>
                          <td className="text-start">{product.product_code}</td>
                          <td className="text-start">
                            {product?.price[0]?.store_prices[0]?.mrp > 0
                              ? `${POS_CONSTANTS.RUPEE_SIGN}${product?.price[0]?.store_prices[0]?.mrp}`
                              : "-"}
                          </td>
                          <td className="text-start">
                            {product?.price[0]?.store_prices[0]?.selling_price >
                            0
                              ? `${POS_CONSTANTS.RUPEE_SIGN}${product?.price[0]?.store_prices[0]?.selling_price}`
                              : "-"}
                          </td>
                          <td className="text-start">
                            {product.selling_uom
                              ? product.selling_uom?.uom_code
                              : "-"}
                          </td>
                          <td>
                            <Status is_active={product.is_active} />
                          </td>
                          <td>
                            <Button
                              text={<FontIcon icon={faPenToSquare} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={
                                !productPermission?.perm_edit ||
                                !(avaryaBrandProduct
                                  ? avaryaBrandEditAccess?.perm_edit
                                    ? true
                                    : false
                                  : true)
                              }
                              btnClassNames={"btn btn-info btn-sm mr-2"}
                              onClickHandler={() =>
                                navigate(
                                  ROUTES_CONSTANTS.EDIT + "/" + product.id
                                )
                              }
                            />
                            {product.is_active ? (
                              <Button
                                text={<FontIcon icon={faBan} />}
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={
                                  !productPermission?.perm_delete ||
                                  !(avaryaBrandProduct
                                    ? avaryaBrandEditAccess?.perm_delete
                                      ? true
                                      : false
                                    : true)
                                }
                                btnClassNames={"btn btn-danger btn-sm"}
                                onClickHandler={() => {
                                  setCurrentProduct(Number(product.id));
                                  setConfirmModal(true);
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={PRODUCTS_CONSTANTS.DELETE_PRODUCT}
        message={PRODUCTS_CONSTANTS.DELETE_PRODUCT_MSG}
        confirmClick={() => {
          dispatch(deleteProduct(currentProduct));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Products };
