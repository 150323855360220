import { AddressAccordion } from "components/AddressAccordion";
import { CustomInput } from "components/CustomInput";
import { Button, CustomMultiSelect, Modal } from "components/core-components";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  clearCustomers,
  getCustomers,
  getIndividualCustomer,
  quickAddNewCustomer,
} from "store/ManageCustomers/customerSlice";
import { AddOrderPaymentMode } from "store/POS";
import { getCountry } from "store/locationSlice";
import {
  BUTTON_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_HEADER_CONSTANTS,
  QuickCustomerSchema,
  ROUTES,
  TOAST_CONSTANTS,
  Toast,
  onlyNumbers,
  debounce,
  useAppDispatch,
  useAppSelector,
  STATUSES,
} from "utils";

interface IProps {
  focusRef?: any;
  modal: any;
  setModal: React.Dispatch<React.SetStateAction<any>>;
  cashierData?: any;
}

const AddCustomerModal = ({
  focusRef,
  modal,
  setModal,
  cashierData,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    customer: { customers: customerData, status: customerStatus },
    common: { status: commonStatus },
  } = useAppSelector((state) => state.root);

  const navigate = useNavigate();

  const [addNew, setAddNew] = useState(false);

  const [query, setQuery] = useState(GENERAL_CONSTANTS.EMPTY_STRING);

  const [addressFilled, setAddressFilled] = useState(false);

  const [initialData, setInitialData] = useState(modal?.customer_data);

  const [enterPressed, setEnterPressed] = useState<boolean>(false);

  // Formik Props Ref
  const PropsRef = useRef<FormikProps<any>>();

  // Get Customer Options
  const getCustomerOptions = useMemo(() => {
    return customerData && customerData.length > 0
      ? customerData.map((item, index) => {
          return {
            value: item.contact_number,
            label: `${item.name} ${
              item?.contact_number?.toString()?.length > 0
                ? `| ${item.contact_number}`
                : ""
            }`,
            id: item.id,
          };
        })
      : [];
  }, [customerData]);

  // Search Values
  const searchValues = (query) => {
    if (query.length > 0) {
      dispatch(getCustomers(false, 1, query));
    }
  };

  const optimizeSearch = useCallback(debounce(searchValues), []);

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      name: values.name,
      contact_number: values.contact_number,
      address: {
        ...values.address,
        country: values.address.country[0].value,
        state: values.address.state[0].value,
        city: values.address.city[0].value,
      },
    };
    cashierData !== undefined
      ? dispatch(
          quickAddNewCustomer({
            customerParams: dataToSend,
            setModal,
            cashierData,
            navigate,
          })
        )
      : dispatch(
          quickAddNewCustomer({
            customerParams: dataToSend,
            setModal,
          })
        );
    focusRef && focusRef.current.focus();
  };

  useEffect(() => {
    !cashierData && setAddNew(true);
    dispatch(getCountry());
  }, []);

  return (
    <Modal
      modalClassNames="w-100"
      modalSize={`${
        cashierData ? GENERAL_CONSTANTS.MEDIUM : GENERAL_CONSTANTS.LARGE
      }`}
      title={POS_HEADER_CONSTANTS.ADD_NEW_CUSTOMER_PLACEHOLDER}
      showModal={modal.state}
      onClickHandler={() => {
        setModal({ state: false, customer_data: null });
        PropsRef.current?.resetForm();
      }}
    >
      {cashierData && !addNew && (
        <>
          <CustomMultiSelect
            label="Select Customer"
            isLoading={customerStatus === STATUSES.LOADING}
            inputValue={query}
            placeholder={"Search Customer ..."}
            options={getCustomerOptions}
            onKeyDownHandler={(e) => {
              if (e.key === GENERAL_CONSTANTS.ENTER) {
                setEnterPressed(true);
                e.stopPropagation();
                optimizeSearch(query);
              }
            }}
            onInputChangeHandler={(e) => {
              if (onlyNumbers(e) && e.length > 10) {
                Toast("Invalid Contact Number", TOAST_CONSTANTS.WARNING);
                return;
              }
              optimizeSearch(e);
              setQuery(e);
            }}
            onChangeHandler={(e, actions) => {
              if (e !== null || e !== undefined) {
                const dataToSend = {
                  ...cashierData,
                  customer: e.id,
                };
                setModal({ state: false, customer_data: null });
                dispatch(
                  AddOrderPaymentMode({
                    order: dataToSend,
                    navigate: navigate,
                  })
                );
              }
              if (actions.action === GENERAL_CONSTANTS.CLEAR) {
                dispatch(getIndividualCustomer(55));
                dispatch(clearCustomers());
              }
              setEnterPressed(false);
            }}
            menuCloseHandler={() => {
              dispatch(clearCustomers());
              setEnterPressed(false);
            }}
          />
          <hr
            data-content={"Select OR Add New Customer"}
            className="hr-text"
          ></hr>
        </>
      )}
      {cashierData || addNew ? (
        <Formik
          initialValues={{
            name: !onlyNumbers(initialData)
              ? initialData
              : GENERAL_CONSTANTS.EMPTY_STRING,
            contact_number: onlyNumbers(initialData)
              ? initialData
              : GENERAL_CONSTANTS.EMPTY_STRING,
            address: {
              address_line_1: GENERAL_CONSTANTS.EMPTY_STRING,
              address_line_2: GENERAL_CONSTANTS.EMPTY_STRING,
              city: FORM_CONSTANTS.DEFAULT_CITY,
              state: FORM_CONSTANTS.DEFAULT_STATE,
              country: FORM_CONSTANTS.DEFAULT_COUNTRY,
              pincode: 0,
            },
          }}
          validationSchema={QuickCustomerSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            PropsRef.current = props;
            return (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <CustomInput
                          label={CUSTOMERS_CONSTANTS.CUSTOMER_NAME_LABEL}
                          isRequired={GENERAL_CONSTANTS.TRUE}
                          name={CUSTOMERS_CONSTANTS.CUSTOMER_NAME}
                          type={FORM_CONSTANTS.TEXT}
                          placeholder={
                            CUSTOMERS_CONSTANTS.CUSTOMER_NAME_PLACEHOLDER
                          }
                        />
                        <ErrorMessage
                          name={CUSTOMERS_CONSTANTS.CUSTOMER_NAME}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </div>
                      <div className="col-6">
                        <CustomInput
                          label={
                            CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER_LABEL
                          }
                          isRequired={GENERAL_CONSTANTS.TRUE}
                          name={CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER}
                          type={FORM_CONSTANTS.NUMBER}
                          placeholder={
                            CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER_PLACEHOLDER
                          }
                        />
                        <ErrorMessage
                          name={CUSTOMERS_CONSTANTS.CUSTOMER_MOBILE_NUMBER}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </div>
                    </div>
                    <AddressAccordion
                      props={props}
                      title={CUSTOMERS_CONSTANTS.CARD_TITLE_2}
                      addressFilled={addressFilled}
                      setAddressFilled={setAddressFilled}
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <Button
                      text={FORM_CONSTANTS.SUBMIT}
                      loading={
                        commonStatus.state === STATUSES.LOADING &&
                        commonStatus.type === "quickAddCustomer"
                      }
                      type={BUTTON_CONSTANTS.BUTTON}
                      onClickHandler={() => props.submitForm()}
                      btnClassNames={"btn btn-primary align-self-center w-25"}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </Modal>
  );
};

export { AddCustomerModal };
