import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { delFAQ, getFAQs, setFAQ, updateFAQ } from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  FAQ_CONSTANTS,
  STATUSES,
  TFAQ,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
} from "utils";

const initialState = {
  faqs: [] as TFAQ[],
  faq: {} as TFAQ,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchFAQs = createAsyncThunk(
  "faq/fetchFAQs",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getFAQs({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewFAQ = createAsyncThunk(
  "faq/addNewFAQ",
  async (
    {
      faqParams,
      actions,
      navigate,
    }: {
      faqParams: any;
      actions: FormikHelpers<TFAQ>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setFAQ(faqParams);
      navigate(FAQ_CONSTANTS.NAVIGATE_TO);
      actions.resetForm();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingFAQ = createAsyncThunk(
  "faq/updateExistingFAQ",
  async (
    {
      id,
      faqParams,
      actions,
      navigate,
    }: {
      id: number;
      faqParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateFAQ(id, faqParams);
      navigate(FAQ_CONSTANTS.NAVIGATE_TO);
      actions.resetForm();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFAQ = createAsyncThunk(
  "faq/deleteFAQ",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "faq-delete" }));
    try {
      const response = await delFAQ(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "faq-delete" }));
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setFAQToEdit: (state, action) => {
      state.faq = action.payload;
    },
    resetFAQState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFAQs.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.faq = action.payload.result;
        } else if (action.meta.arg.active) {
          state.faqs = action.payload.result;
        } else {
          state.faqs = action.payload.result.results;
        }
      })
      .addCase(addNewFAQ.fulfilled, (state, action: any) => {
        state.faqs.unshift(action.payload.result);
        state.faqs = state.faqs.slice(0, 10);
      })
      .addCase(updateExistingFAQ.fulfilled, (state, action: any) => {
        // Update the existing faq in the faqs array
        const index = state.faqs.findIndex(
          (faq) => faq.id === action.payload.result.id
        );
        if (index !== -1) {
          state.faqs[index] = action.payload.result;
        }
      })
      .addCase(deleteFAQ.fulfilled, (state, action: any) => {
        // update the deleted faq from the faqs array
        const index = state.faqs.findIndex((faq) => faq.id === action.meta.arg);
        if (index !== -1) {
          state.faqs[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewFAQ.fulfilled,
          updateExistingFAQ.fulfilled,
          deleteFAQ.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchFAQs.pending,
          addNewFAQ.pending,
          updateExistingFAQ.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const { setFAQToEdit, resetFAQState } = faqSlice.actions;

export default faqSlice.reducer;
