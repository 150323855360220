import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "utils/constants";
import {
  delCatSubCategory,
  delSubCategory,
  getActiveSubCategory,
  getSpecificSubCategory,
  getSubCategory,
  setSubCategory,
  updateSubCategory,
} from "apis/restApis";
import { PaginationState, ProductSubCategoryType } from "utils/types";
import { setCommonStatus, updateValues } from "store/commonSlice";
import { Toast } from "utils";

const initialState = {
  productSubCategory: [] as ProductSubCategoryType[],
  subCategoryToEdit: {} as ProductSubCategoryType,
  status: STATUSES.IDLE as string,
  error: null,
};

const productSubCategorySlice = createSlice({
  name: "productSubCategory",
  initialState,
  reducers: {
    getAllProductSubCategory(state, action) {
      state.productSubCategory = action.payload;
    },
    addProductSubCategory(state, action) {
      state.productSubCategory.unshift(action.payload);
    },
    removeProductSubCategory(state, action) {
      state.productSubCategory = state.productSubCategory.filter(
        (subCategory) => subCategory.id !== action.payload
      );
    },
    editProductSubCategory(state, action) {
      const { id } = action.payload;
      const index = state.productSubCategory.findIndex(
        (subCategory) => subCategory.id === id
      );
      if (index !== -1) {
        state.productSubCategory[index] = action.payload;
      }
    },

    setSubCategoryToEdit(state, action) {
      state.subCategoryToEdit = action.payload;
    },

    setSubCategoryStatus(state, action) {
      state.status = action.payload;
    },
    setSubCategoryError(state, action) {
      state.error = action.payload;
    },
    resetSubCategoryState: (state) => {
      state.productSubCategory = [] as ProductSubCategoryType[];
      state.subCategoryToEdit = {} as ProductSubCategoryType;
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllProductSubCategory,
  addProductSubCategory,
  removeProductSubCategory,
  editProductSubCategory,
  setSubCategoryToEdit,
  setSubCategoryStatus,
  setSubCategoryError,
  resetSubCategoryState,
} = productSubCategorySlice.actions;

export default productSubCategorySlice.reducer;

export function getSubCategories(
  active?: boolean,
  pageNo: number = 1,
  query: string = "",
  category?: number,
  cms: boolean = false
) {
  return async function getSubCategoryThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    dispatch(getAllProductSubCategory([]));
    active
      ? getActiveSubCategory()
          .then((response: any) => {
            dispatch(setSubCategoryStatus(STATUSES.IDLE));
            dispatch(getAllProductSubCategory(response.result));
          })
          .catch((error) => {
            dispatch(setSubCategoryError(error.message));
            dispatch(setSubCategoryStatus(STATUSES.ERROR));
          })
      : getSubCategory(pageNo, query, category, cms)
          .then((response: any) => {
            dispatch(setSubCategoryStatus(STATUSES.IDLE));
            dispatch(updateValues(response));
            dispatch(getAllProductSubCategory(response.result.results));
          })
          .catch((error) => {
            dispatch(setSubCategoryError(error.message));
            dispatch(setSubCategoryStatus(STATUSES.ERROR));
          });
  };
}

export function getIndividualSubCategory(id: number) {
  return async function getIndividualSubCategoryThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    getSpecificSubCategory(id)
      .then((response: any) => {
        dispatch(setSubCategoryStatus(STATUSES.IDLE));
        dispatch(setSubCategoryToEdit(response.result));
        return response.result;
      })
      .catch((error) => {
        dispatch(setSubCategoryError(error.message));
        dispatch(setSubCategoryStatus(STATUSES.ERROR));
      });
  };
}

export function addNewSubCategory(subCategoryParams: ProductSubCategoryType) {
  return async function addNewSubCategoryThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    setSubCategory(subCategoryParams)
      .then((response: any) => {
        dispatch(addProductSubCategory(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setSubCategoryStatus(STATUSES.ERROR));
        dispatch(setSubCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setSubCategoryStatus(STATUSES.IDLE));
      });
  };
}

export const updateExistingSubCategory = (
  id: number,
  subCategoryParams: ProductSubCategoryType
) => {
  return async function updateExistingSubCategoryThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    updateSubCategory(id, subCategoryParams)
      .then((response: any) => {
        dispatch(editProductSubCategory(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setSubCategoryStatus(STATUSES.ERROR));
        dispatch(setSubCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setSubCategoryStatus(STATUSES.IDLE));
      });
  };
};

export function deleteSubCategory(id: number) {
  return async function deleteSubCategoryThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    delSubCategory(id)
      .then((response: any) => {
        dispatch(editProductSubCategory(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setSubCategoryError(error.message));
        dispatch(setSubCategoryStatus(STATUSES.ERROR));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setSubCategoryStatus(STATUSES.IDLE));
      });
  };
}

export function deleteCatSubCategory(delObject: Object) {
  return async function delCatSubCategoryThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    // dispatch(
    //   setCommonStatus({ state: STATUSES.LOADING, type: "deleteCategory" })
    // );
    delCatSubCategory(delObject)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setSubCategoryStatus(STATUSES.ERROR));
        dispatch(setSubCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        // dispatch(
        //   setCommonStatus({ state: STATUSES.IDLE, type: "deleteCategory" })
        // );
        dispatch(setSubCategoryStatus(STATUSES.IDLE));
      });
  };
}

export function deleteMultipleCategoryMapping(
  ids: number[],
  subCategoryID: number
) {
  return async function deleteMultipleCategoryMappingThunk(dispatch: any) {
    dispatch(setSubCategoryStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "deleteCategory" })
    );
    Promise.all(
      ids.map((id) => {
        dispatch(
          deleteCatSubCategory({
            category: id,
            sub_category: subCategoryID,
          })
        );
      })
    )
      .then((response: any) => {
        // Toast("Deleted all options successfully", "success");
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setSubCategoryStatus(STATUSES.ERROR));
        dispatch(setSubCategoryError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "deleteCategory" })
        );
        dispatch(setSubCategoryStatus(STATUSES.IDLE));
      });
  };
}
