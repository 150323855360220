import React, { useEffect, useState } from "react";

import { faBan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";

import {
  BUTTON_CONSTANTS,
  PAYMENT_TERMS_CONSTANTS,
  STATUSES,
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

import { getPaymentTerms } from "store/ManagePayments/paymentTermSlice";

const PaymentTermList = ({
  setEdit,
  setShow,
  setCurrentTerm,
  setConfirmModal,
}) => {
  const dispatch = useAppDispatch();
  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    paymentTerm: { paymentTerms: termData, status },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const paymentTermPermission = permissions.find(
    (p) => p.module_name === "Manage Payment Term"
  );

  const update_page = (pageNo: number) => {
    dispatch(getPaymentTerms(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });
  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PAYMENT_TERMS_CONSTANTS.PAYMENT_TERMS_COLS;
  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={paymentTermPermission?.perm_add}
          path={PAYMENT_TERMS_CONSTANTS.PATH}
          label={PAYMENT_TERMS_CONSTANTS.LABEL}
          setEdit={setEdit?.bind(null, false)}
          setShow={setShow?.bind(null, true)}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : termData.length > 0 ? (
              termData.map((paymentTerm, index) => {
                return (
                  <tr key={index}>
                    <td>{page_size * (current_page - 1) + index + 1}</td>
                    <td>{paymentTerm.term_name}</td>
                    <td>
                      {paymentTerm.term_type === "credit"
                        ? "Credit"
                        : "Onspot Payment"}
                    </td>
                    <td>{paymentTerm.term_days_after_delivery}</td>
                    <td>
                      <Status is_active={paymentTerm.is_active} />
                    </td>
                    <td>
                      <Button
                        text={<FontIcon icon={faPenToSquare} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        isDisabled={!paymentTermPermission?.perm_edit}
                        btnClassNames={"btn btn-info btn-sm mr-2"}
                        onClickHandler={() => {
                          let editData = {
                            id: paymentTerm.id,
                            term_type:
                              paymentTerm.term_type === "credit"
                                ? [{ label: "Credit", value: "credit" }]
                                : [
                                    {
                                      label: "Onspot Payment",
                                      value: "onspot_payment",
                                    },
                                  ],
                            term_name: paymentTerm.term_name,
                            term_days_after_delivery:
                              paymentTerm.term_days_after_delivery,
                            is_active: [
                              {
                                label:
                                  paymentTerm.is_active === 1
                                    ? "Active"
                                    : "Inactive",
                                value: paymentTerm.is_active,
                              },
                            ],
                          };

                          setShow(true);
                          setEdit(editData);
                        }}
                      />
                      {paymentTerm.is_active ? (
                        <Button
                          text={<FontIcon icon={faBan} />}
                          type={BUTTON_CONSTANTS.BUTTON}
                          isDisabled={!paymentTermPermission?.perm_delete}
                          btnClassNames={"btn btn-danger btn-sm"}
                          onClickHandler={() => {
                            setConfirmModal(true);
                            setCurrentTerm(Number(paymentTerm.id));
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={status === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { PaymentTermList };
