import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getFieldErrorNames } from "utils";

export const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    if (!element.parentNode) return;

    if (!(element.parentNode instanceof HTMLElement)) return;

    if (!element.parentNode.scrollIntoView) return;

    // Scroll to first known error into view
    (element.parentNode as HTMLElement).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [submitCount]);

  return null;
};
