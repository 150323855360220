import { Accordion, Table, TableBody } from "components/core-components";
import React, { useEffect, useMemo, useState } from "react";
import {
  ACCESS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  ICartItem,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  displayValue,
  filterCartItems,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { POSCarItems } from "./POSCarItems";
import { Loader } from "components/Loader";
import { UpdateProductQuantity } from "store/POS";
import { setScreenOrientation } from "store/commonSlice";

/**
 * Renders the POS Cart component.
 *
 * @param {React.RefObject<any>} focusRef - The reference to the focus element.
 * @returns {JSX.Element} The rendered POS Cart component.
 */

const POSCart = ({ focusRef }) => {
  const dispatch = useAppDispatch();

  const {
    branch: { selectedStore },
    common: { portrait },
    cart: { cart, status: cartStatus },
  } = useAppSelector((state) => state.root);

  const [activeBatchItem] = useState(null);

  const isMobile = window.mobileAndTabletCheck();

  //update quantity of product group
  const updateQuantity = (items: ICartItem[], quantity: number) => {
    for (let i = 0; i < items.length; i++) {
      dispatch(UpdateProductQuantity(items[i], quantity, items[i].meddler));
    }
  };

  const updateProductGroupPrice = (items: ICartItem[]) => {
    return items.reduce((acc, item) => acc + item.mrp, 0);
  };

  const updateProductGroupTax = (items: ICartItem[]) => {
    return items.reduce((acc, item) => acc + item.tax_applied, 0);
  };

  const updateProductGroupUnitCost = (items: ICartItem[]) => {
    return items.reduce(
      (acc, item) => acc + to2Decimal(item.payable_amount / item.quantity),
      0
    );
  };

  const updateProductGroupNetAmount = (items: ICartItem[]) => {
    return items.reduce((acc, item) => acc + item.payable_amount, 0);
  };

  const hasProductGroups = useMemo(() => {
    return filterCartItems(cart.cartItems).some(
      (item) => item[0] !== "undefined"
    );
  }, [cart.cartItems]);

  const displayItems = (cartItems: ICartItem[]) => {
    if (cartItems && cartItems.length > 0) {
      const filterItems = filterCartItems(cartItems).map(
        (item: any, productGroupIndex) => {
          const productGroupDetails = item[1][0].productGroup;

          return item[0] !== "undefined"
            ? {
                id: productGroupIndex,
                // To display product group details
                title: (
                  <div className="col-12 py-3 text-sm d-flex flex-row align-items-center">
                    <div
                      className={`${
                        isMobile
                          ? `${portrait ? "col-5" : "col-2"} gap-2`
                          : "col-3"
                      } d-flex flex-row align-items-center p-0`}
                    >
                      <div className="col-1 text-start">
                        <span>{productGroupIndex + 1}</span>
                      </div>
                      <div
                        className={`d-flex flex-${
                          isMobile
                            ? `column ${
                                portrait ? "col-4 p-0" : "col-12"
                              } gap-1`
                            : "row col-11"
                        } text-wrap`}
                      >
                        <div
                          className={`${
                            isMobile
                              ? `text-bold ${portrait ? "col-12" : "col-6"}`
                              : "col-6"
                          } text-start text-wrap p-0`}
                        >
                          {productGroupDetails.product_group_code}
                        </div>
                        <div
                          className={`${
                            isMobile
                              ? `${portrait ? "col-12" : "col-6"}`
                              : "col-6"
                          } text-start text-wrap p-0`}
                        >
                          {productGroupDetails.product_group_name}
                        </div>
                      </div>
                      <div className="d-md-none col-3 px-0 d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row input-group-sm">
                          <input
                            disabled={true}
                            className="form-control text-center mx-2"
                            type={FORM_CONSTANTS.NUMBER}
                            min={1}
                            step={0.01}
                            value={item[1][0].count}
                          />
                        </div>
                      </div>
                      <div className="d-md-none col-3">
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(
                          to2Decimal(updateProductGroupPrice(item[1]))
                        )}
                      </div>
                    </div>
                    <div
                      className={`${
                        isMobile && portrait ? `col-1` : "col-5"
                      } d-flex flex-row text-center align-items-center px-0`}
                    >
                      {!portrait && (
                        <>
                          <div className="col-3 px-3 col-md-7 d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-row input-group-sm">
                              <input
                                disabled={true}
                                className="form-control text-center mx-2"
                                type={FORM_CONSTANTS.NUMBER}
                                min={1}
                                step={0.01}
                                value={item[1][0].count}
                              />
                            </div>
                            {!portrait && (
                              <div className="input-group-append">
                                <div className="input-group-text px-2 mx-1">
                                  Grp
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-2 text-start p-0">
                            {POS_CONSTANTS.RUPEE_SIGN}
                            {displayValue(
                              to2Decimal(updateProductGroupPrice(item[1]))
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-12 col-md-2 offset-1 text-end text-md-center">
                        {GENERAL_CONSTANTS.NA}
                      </div>
                    </div>
                    <div
                      className={`${
                        isMobile ? "col-6" : "col-4"
                      } d-flex flex-row text-center align-items-center px-0`}
                    >
                      <div className="col-4 text-end px-3">
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(
                          to2Decimal(updateProductGroupTax(item[1]))
                        )}
                      </div>
                      <div className="col-3 text-start pl-4">
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(
                          to2Decimal(updateProductGroupUnitCost(item[1]))
                        )}
                      </div>
                      <div
                        className={`col-3 text-start ${
                          isMobile && !portrait ? "px-0" : "px-4"
                        }`}
                      >
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(
                          to2Decimal(updateProductGroupNetAmount(item[1]))
                        )}
                      </div>
                      <div className="col-2">
                        {/* <Button
                          text={<FontIcon icon={faTrash} iconSize="xs" />}
                          btnClassNames="btn btn-danger btn-sm text-center ml-2"
                          type={BUTTON_CONSTANTS.BUTTON}
                        /> */}
                      </div>
                    </div>
                  </div>
                ),
                // To display product group items (products)
                body: item[1].map((item: ICartItem, index: number) => (
                  <POSCarItems
                    focusRef={focusRef}
                    cartItem={item}
                    index={index}
                    key={index}
                    productGroupLength={0}
                  />
                )),
              }
            : null;
        }
      );

      return cartStatus === STATUSES.LOADING ? (
        <tr>
          <td colSpan={12}>
            <Loader />
          </td>
        </tr>
      ) : (
        <>
          {/* Product groups */}
          {hasProductGroups && (
            <tr className="text-sm">
              <td colSpan={12} className="p-0">
                <Accordion
                  accordionItems={filterItems}
                  activeItem={activeBatchItem}
                  id={"item"}
                  compact={true}
                />
              </td>
            </tr>
          )}
          {/* Products  */}
          {filterCartItems(cartItems).map((item: any) => {
            if (item[0] === "undefined") {
              return item[1].map((item: ICartItem, index: number) => (
                <POSCarItems
                  focusRef={focusRef}
                  cartItem={item}
                  index={index}
                  key={index}
                  productGroupLength={filterItems.length}
                />
              ));
            }
          })}
        </>
      );
    } else {
      return (
        <tr>
          <td colSpan={13} className="text-center">
            {POS_CONSTANTS.NOT_ITEMS_MESSAGE}
          </td>
        </tr>
      );
    }
  };

  const RESPONSIVE_HEADERS = useMemo(() => {
    if (isMobile) {
      return [
        ...POS_CART_CONSTANTS.POS_CART_HEADERS.slice(0, 1),
        {
          title: "Product Details",
          colSpan: 2,
          className: "text-sm align-middle",
        },
        {
          title: "Quantity",
          colSpan: 2,
          className: "text-sm align-middle text-center",
        },
        ...POS_CART_CONSTANTS.POS_CART_HEADERS.slice(4),
      ];
    }
    return POS_CART_CONSTANTS.POS_CART_HEADERS;
  }, [isMobile, selectedStore]);

  return (
    <Table>
      <thead className="table-dark">
        <tr>
          {RESPONSIVE_HEADERS?.map((header, index) => (
            <th
              key={index}
              colSpan={header.colSpan}
              className={header.className}
            >
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <TableBody>{displayItems(cart.cartItems)}</TableBody>
    </Table>
  );
};

export { POSCart };
