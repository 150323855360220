import React from "react";
import { Badge } from "./core-components";
import { GENERAL_CONSTANTS } from "utils";

const Status = ({ is_active, xl = false }) => {
  const active = () => {
    return (
      <Badge
        badgeType={GENERAL_CONSTANTS.SUCCESS}
        badgeText={GENERAL_CONSTANTS.ACTIVE}
      />
    );
  };
  const inactive = () => {
    return (
      <Badge
        badgeType={GENERAL_CONSTANTS.SECONDARY}
        badgeText={GENERAL_CONSTANTS.INACTIVE}
      />
    );
  };

  return xl ? (
    <h5 data-testid="active">{is_active === 1 ? active() : inactive()}</h5>
  ) : (
    <h6 data-testid="active">{is_active === 1 ? active() : inactive()}</h6>
  );
};

export { Status };
