import {
  faBan,
  faCircleNotch,
  faEye,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Loader } from "components/Loader";
import { NoData } from "components/NoData";
import { Search } from "components/Search";
import { Status } from "components/Status";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import moment from "moment";
import { CreditNoteList } from "pages";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchAllCreditNote, PrintCreditNote } from "store/POS";
import { updatePageNo } from "store/commonSlice";
import {
  BUTTON_CONSTANTS,
  displayValue,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreditNoteModal = ({ modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      modalSize="xl"
      centered={false}
      title="Credit Notes"
      modalClassNames="w-100"
      showModal={modal}
      onClickHandler={() => {
        dispatch(updatePageNo(1));
        setModal(false);
      }}
    >
      <CreditNoteList modal={modal} />
    </Modal>
  );
};

export { CreditNoteModal };
