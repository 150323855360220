import {
  faBan,
  faCircleNotch,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import React, { useEffect, useState } from "react";
import { fetchWidgets, resetWidgetState } from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  PERMISSION_CONSTANTS,
  ScrollToTop,
  STATUSES,
  TWidget,
  useAppDispatch,
  useAppSelector,
  usePagination,
  WIDGET_CONSTANTS,
} from "utils";

const WidgetList = ({
  setEdit,
  setShow,
  setConfirmModal,
  setCurrentWidget,
}) => {
  const dispatch = useAppDispatch();

  const {
    root: {
      auth: { permissions: permissionData },
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
    },
    ecommerce: {
      widget: { widgets, status: widgetStatus },
    },
  } = useAppSelector((state) => state);

  const [print, setPrint] = useState<number>(0);

  const [query, setQuery] = useState("");

  const widgetPermission = permissionData.find(
    (p) => p.module_name === PERMISSION_CONSTANTS.WIDGETS
  );

  const update_page = (pageNo: number) => {
    dispatch(fetchWidgets({ active: true }));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
      dispatch(resetWidgetState());
    };
  }, [dispatch]);

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={widgetPermission?.perm_add}
          path={WIDGET_CONSTANTS.PATH}
          label={WIDGET_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={WIDGET_CONSTANTS.COLS} />
          <TableBody>
            {widgetStatus === STATUSES.LOADING ? (
              <tr>
                <td
                  colSpan={WIDGET_CONSTANTS.COLS.length}
                  className="text-center"
                >
                  <Loader />
                </td>
              </tr>
            ) : widgets && widgets?.length > 0 ? (
              widgets.map((widget: TWidget, index) => {
                const deleteItem =
                  commonStatus.type === WIDGET_CONSTANTS.DELETE_STATE &&
                  commonStatus.state === STATUSES.LOADING;
                return (
                  <tr key={index}>
                    <td>{page_size * (current_page - 1) + index + 1}</td>
                    <td>{widget.widget_name}</td>
                    <td>
                      <Status is_active={widget.is_active} />
                    </td>
                    <td>
                      <Button
                        text={<FontIcon icon={faPenToSquare} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        isDisabled={!widgetPermission?.perm_edit}
                        btnClassNames={"btn btn-info btn-sm mr-2"}
                        onClickHandler={() => {
                          let editData = {
                            ...widget,
                            is_active: [
                              {
                                label: widget.is_active
                                  ? GENERAL_CONSTANTS.ACTIVE
                                  : GENERAL_CONSTANTS.INACTIVE,
                                value: widget.is_active,
                              },
                            ],
                          };
                          setShow(true);
                          setEdit(editData);
                        }}
                      />
                      {widget.is_active ? (
                        <Button
                          text={
                            deleteItem && print === index ? (
                              <FontIcon
                                icon={faCircleNotch}
                                iconClassNames={
                                  "fa-solid fa-circle-notch fa-spin"
                                }
                              />
                            ) : (
                              <FontIcon icon={faBan} />
                            )
                          }
                          isDisabled={
                            !widgetPermission?.perm_delete || deleteItem
                          }
                          type={BUTTON_CONSTANTS.BUTTON}
                          btnClassNames={"btn btn-danger btn-sm"}
                          onClickHandler={() => {
                            setPrint(index);
                            setConfirmModal(true);
                            setCurrentWidget(Number(widget.id));
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoData len={WIDGET_CONSTANTS.COLS.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={widgetStatus === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { WidgetList };
