import { Loader } from "components";
import React from "react";

const PageLoader = () => {
  return (
    <div
      className="content-wrapper text-center"
      style={{
        margin: 0,
        padding: 0,
        background: "#fff",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <section className="content" style={{ height: "100%" }}>
        <Loader />
      </section>
    </div>
  );
};

export { PageLoader };
