// import React from "react";
// import { useField } from "formik";
// import { Label } from "./core-components";

// interface CustomCheckboxProps {
//   label?: string;
//   checkboxInputClass?: string;
//   checkboxErrorClass?: string;
//   name: string;
//   [x: string]: any;
// }

// const CustomCheckbox = ({
//   label,
//   checkboxInputClass,
//   checkboxErrorClass,
//   name,
//   ...props
// }: CustomCheckboxProps) => {
//   const [field, meta] = useField(name);
//   return (
//     <>
//       <Label labelClassNames={"checkbox w-100"}>
//         {label}
//         <input
//           {...field}
//           {...props}
//           className={`${checkboxInputClass} ${
//             meta.touched && meta.error ? "input-error" : ""
//           }`}
//           checked={field.value}
//         />
//       </Label>
//       {meta.touched && meta.error && (
//         <div className={`${checkboxErrorClass}`}>{meta.error}</div>
//       )}
//     </>
//   );
// };
// export { CustomCheckbox };

import React from "react";
import { useField } from "formik";
import { Label } from "./core-components";

interface CustomCheckboxProps {
  label?: any;
  checkboxLabelClass?: string;
  checkboxInputClass?: string;
  checkboxErrorClass?: string;
  name: string;
  onClickHandler?: any;
  [x: string]: any;
}

const CustomCheckbox = ({
  label,
  checkboxLabelClass,
  checkboxInputClass,
  checkboxErrorClass,
  onClickHandler,
  name,
  ...props
}: CustomCheckboxProps) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Label
        labelClassNames={`checkbox w-100 d-flex flex-row align-items-start gap-2 ${
          checkboxLabelClass ? checkboxLabelClass : ""
        }`}
      >
        <input
          {...field}
          {...props}
          className={`${checkboxInputClass} ${
            meta.touched && meta.error ? "input-error" : ""
          }`}
          checked={props.checked !== undefined ? props.checked : field.value}
          onClick={onClickHandler}
        />
        <span className="text-wrap">
          {"  "}
          {label}
        </span>
      </Label>
      {meta.touched && meta.error && (
        <div className={`${checkboxErrorClass}`}>{meta.error}</div>
      )}
    </>
  );
};
export { CustomCheckbox };
