import {
  faBan,
  faCircleNotch,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import React, { useEffect, useState } from "react";
import { fetchTastes, resetTasteState } from "store/ManageProducts/tasteSlice";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  PERMISSION_CONSTANTS,
  ScrollToTop,
  STATUSES,
  TASTE_CONSTANTS,
  TTaste,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const TasteList = ({ setEdit, setShow, setConfirmModal, setCurrentTaste }) => {
  const dispatch = useAppDispatch();

  const {
    root: {
      auth: { permissions: permissionData },
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
      taste: { tastes, status: tasteStatus },
    },
  } = useAppSelector((state) => state);

  const [print, setPrint] = useState<number>(0);

  const [query, setQuery] = useState("");

  const tastePermission = permissionData.find(
    (p) => p.module_name === PERMISSION_CONSTANTS.TASTE
  );

  const update_page = (pageNo: number) => {
    dispatch(fetchTastes({ active: false, pageNo, query }));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
      dispatch(resetTasteState());
    };
  }, [dispatch]);

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={tastePermission?.perm_add}
          path={TASTE_CONSTANTS.PATH}
          label={TASTE_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={TASTE_CONSTANTS.COLS} />
          <TableBody>
            {tasteStatus === STATUSES.LOADING ? (
              <tr>
                <td
                  colSpan={TASTE_CONSTANTS.COLS.length}
                  className="text-center"
                >
                  <Loader />
                </td>
              </tr>
            ) : tastes && tastes?.length > 0 ? (
              tastes.map((taste: TTaste, index) => {
                const deleteItem =
                  commonStatus.type === TASTE_CONSTANTS.DELETE_STATE &&
                  commonStatus.state === STATUSES.LOADING;
                return (
                  <tr key={index}>
                    <td>{page_size * (current_page - 1) + index + 1}</td>
                    <td>{taste.taste_name}</td>
                    <td>
                      <Status is_active={taste.is_active} />
                    </td>
                    <td>
                      <Button
                        text={<FontIcon icon={faPenToSquare} />}
                        type={BUTTON_CONSTANTS.BUTTON}
                        isDisabled={!tastePermission?.perm_edit}
                        btnClassNames={"btn btn-info btn-sm mr-2"}
                        onClickHandler={() => {
                          let editData = {
                            ...taste,
                            is_active: [
                              {
                                label: taste.is_active
                                  ? GENERAL_CONSTANTS.ACTIVE
                                  : GENERAL_CONSTANTS.INACTIVE,
                                value: taste.is_active,
                              },
                            ],
                          };
                          setShow(true);
                          setEdit(editData);
                        }}
                      />
                      {taste.is_active ? (
                        <Button
                          text={
                            deleteItem && print === index ? (
                              <FontIcon
                                icon={faCircleNotch}
                                iconClassNames={
                                  "fa-solid fa-circle-notch fa-spin"
                                }
                              />
                            ) : (
                              <FontIcon icon={faBan} />
                            )
                          }
                          isDisabled={
                            !tastePermission?.perm_delete || deleteItem
                          }
                          type={BUTTON_CONSTANTS.BUTTON}
                          btnClassNames={"btn btn-danger btn-sm"}
                          onClickHandler={() => {
                            setPrint(index);
                            setConfirmModal(true);
                            setCurrentTaste(Number(taste.id));
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoData len={TASTE_CONSTANTS.COLS.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          loading={tasteStatus === STATUSES.LOADING}
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { TasteList };
