import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Status,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";

import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  LABEL_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

import { faBan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  deleteLabel,
  getAllLabels,
  removeLabel,
} from "store/Utilities/labelSLice";
import { updatePageNo } from "store/commonSlice";
import { getAllPrinters } from "store/Utilities/printerSlice";

const LabelList = ({
  setEdit,
  setShow,
  setInitialPrinters,
  setConfirmModal,
  setCurrentLabel,
}) => {
  const dispatch = useAppDispatch();

  const {
    common: { current_page, total_items, total_pages, page_size },
    label: {
      status,
      label: labelData,
      label_template: labelTemplateData,
      print_type: printTypedata,
      print_size: printSizedata,
    },
    printer: { printer: printerData },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const labelPermission = {
    module_name: "Manage Labels",
    perm_view: true,
    perm_add: true,
    perm_edit: true,
    perm_delete: true,
    perm_authorize: true,
  };

  const update_page = (pageNo: number) => {
    dispatch(getAllLabels(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    dispatch(getAllPrinters(true));

    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = LABEL_CONSTANTS.LABEL_COLS;

  return (
    <Card>
      <CardHeader>
        <TableTools
          setQuery={setQuery}
          permission={labelPermission?.perm_add}
          path={LABEL_CONSTANTS.PATH}
          label={LABEL_CONSTANTS.LABEL}
          setEdit={setEdit}
          setShow={setShow}
        />
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={tableCols} />
          <TableBody>
            {status === STATUSES.LOADING ? (
              <tr>
                <td colSpan={tableCols.length} className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : labelData && labelData.length > 0 ? (
              labelData.map((label, index) => (
                <tr key={index}>
                  <td>{page_size * (current_page - 1) + index + 1}</td>
                  <td>
                    {
                      labelTemplateData
                        .filter((item) => item.id === label.template)
                        .map((res) => res.template_name)[0]
                    }
                  </td>
                  <td>
                    {
                      printTypedata
                        .filter((item) => item.id === label.print_type)
                        .map((res) => res.type)[0]
                    }
                  </td>
                  <td>
                    {
                      printSizedata
                        .filter((item) => item.id === label.size)
                        .map((res) => res.size)[0]
                    }
                  </td>
                  <td>
                    <Status is_active={label.is_active} />
                  </td>
                  <td>
                    <Button
                      text={<FontIcon icon={faPenToSquare} />}
                      isDisabled={!labelPermission?.perm_edit}
                      type={BUTTON_CONSTANTS.BUTTON}
                      btnClassNames={"btn btn-info btn-sm mr-2"}
                      onClickHandler={() => {
                        let editData = {
                          ...label,
                          template: labelTemplateData
                            .filter((item) => item.id === label.template)
                            .map((res) => ({
                              value: res.id,
                              label: res.template_name,
                            })),

                          is_active: [
                            {
                              value: label.is_active,
                              label: label.is_active
                                ? GENERAL_CONSTANTS.ACTIVE
                                : GENERAL_CONSTANTS.INACTIVE,
                            },
                          ],
                          print_type: [
                            {
                              value: label.print_type,
                              label: printTypedata.find(
                                (item) => item.id === label.print_type
                              ).type,
                            },
                          ],
                          size: [
                            {
                              value: label.size,
                              label: printSizedata.find(
                                (item) => item.id === label.size
                              ).size,
                            },
                          ],
                          printers: label.printers
                            .map((item) =>
                              printerData.filter(
                                (printer) => printer.id === item.printer
                              )
                            )
                            .map((res) => ({
                              value: res[0].id,
                              label: res[0].printer_name,
                            })),
                        };

                        editData.printers.forEach((printer) => {
                          setInitialPrinters((prev) => [...prev, printer]);
                        });

                        setEdit(editData);
                        setShow(true);
                      }}
                    />
                    {label.is_active ? (
                      <Button
                        text={<FontIcon icon={faBan} />}
                        isDisabled={!labelPermission?.perm_delete}
                        type={BUTTON_CONSTANTS.BUTTON}
                        btnClassNames={"btn btn-danger btn-sm"}
                        onClickHandler={() => {
                          setConfirmModal(true);
                          setCurrentLabel(Number(label.id));
                        }}
                      />
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <NoData len={tableCols.length} />
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          pageSize={page_size}
          totalItems={total_items}
          totalPages={total_pages}
          currentPage={current_page}
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
          pageChangeHandler={pageChangeHandler}
        />
      </CardFooter>
    </Card>
  );
};

export { LabelList };
