import React from "react";

interface NoDataProps {
  len: number;
}

const NoData = ({ len }: NoDataProps) => {
  return (
    <tr data-testid="no-data">
      <td colSpan={len}>No data available in table</td>
    </tr>
  );
};

export { NoData };
