import React, { useState } from "react";

import { Formik } from "formik";

import {
  useAppDispatch,
  PaymentModeSchema,
  PAYMENT_MODES_CONSTANTS,
} from "utils";

import {
  updateExistingPaymentMode,
  addNewPaymentMode,
  deletePaymentMode,
} from "store/ManagePayments/paymentModeSlice";

import { ContentHeader, OffCanvas, Container, ConfirmModal } from "components";

import { PaymentModeForm } from "./PaymentModeForm";
import { PaymentModeList } from "./PaymentModeList";

const PaymentModes = () => {
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState<false | {}>();

  const [show, setShow] = useState(false);

  const [currentMode, setCurrentMode] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const initialValues = {
    payment_mode: "",
    is_active: [{ value: 1, label: "Active" }],
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      is_active: values.is_active[0]?.value,
    };
    if (!edit) {
      dispatch(addNewPaymentMode(dataToSend, actions, setShow));
    } else {
      dispatch(
        updateExistingPaymentMode(values.id, dataToSend, actions, setShow)
      );
    }
  };

  return (
    <div className="content-wrapper">
      <ContentHeader
        pageHeader={PAYMENT_MODES_CONSTANTS.PAYMENT_MODES_HEADER}
      />
      <Container>
        <div className="col-12">
          <PaymentModeList
            setEdit={setEdit}
            setShow={setShow}
            setConfirmModal={setConfirmModal}
            setCurrentMode={setCurrentMode}
          />
          <OffCanvas
            title={
              edit
                ? PAYMENT_MODES_CONSTANTS.EDIT_HEADER
                : PAYMENT_MODES_CONSTANTS.ADD_HEADER
            }
            show={show}
            onClickHandler={() => {
              setShow(false);
            }}
          >
            <Container>
              <Formik
                enableReinitialize={true}
                initialValues={edit ? edit : initialValues}
                validationSchema={PaymentModeSchema}
                onSubmit={handleSubmit}
              >
                {(props) => <PaymentModeForm props={props} />}
              </Formik>
            </Container>
          </OffCanvas>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={PAYMENT_MODES_CONSTANTS.DELETE_MODE}
        message={PAYMENT_MODES_CONSTANTS.DELETE_MODE_MSG}
        confirmClick={() => {
          dispatch(deletePaymentMode(currentMode));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { PaymentModes };
