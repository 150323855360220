import React from "react";
import { Button, Modal } from "./core-components";
import { BUTTON_CONSTANTS } from "utils";

interface IProps {
  title: string;
  message: string | React.ReactNode;
  backdrop?: boolean;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirmClick?: () => void;
  rejectClick?: () => void;
}

const ConfirmModal = ({
  title,
  message,
  backdrop = true,
  modal,
  setModal,
  confirmClick,
  rejectClick,
}: IProps) => {
  return (
    <Modal
      modalClassNames="w-100"
      title={title}
      showModal={modal}
      backdrop={backdrop}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <h5 className="row p-3 text-wrap text-justify">{message}</h5>
      <div className="row px-3">
        <Button
          type={BUTTON_CONSTANTS.BUTTON}
          testId="reject-btn"
          text="No"
          onClickHandler={() => {
            setModal(false);
            rejectClick && rejectClick();
          }}
          btnClassNames="btn offset-4 col-4"
        />
        <Button
          type={BUTTON_CONSTANTS.BUTTON}
          testId="confirm-btn"
          text="Yes"
          onClickHandler={confirmClick}
          btnClassNames="btn btn-danger col-4 "
        />
        {/* <button
          type={BUTTON_CONSTANTS.BUTTON}
          className="btn btn-primary"
          onClick={() => {
            setModal(false);
          }}
        >
          No
        </button>
        <button type={BUTTON_CONSTANTS.BUTTON} className="btn btn-danger" onClick={confirmClick}>
          Yes
        </button> */}
      </div>
    </Modal>
  );
};

export { ConfirmModal };
