import { CustomInput } from "components/CustomInput";
import { Button, Card, Container, Modal } from "components/core-components";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Bounce, toast } from "react-toastify";
import { setCurrentReturn, setReturnOrder } from "store/POS";
import {
  BUTTON_CONSTANTS,
  IOrderProduct,
  Toast,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;

  refundItems: {
    product_group: {
      id: number;
      print_hamper_name: string;
      hamper_code: string;
    };
    batch: number;
    product: number;
    price: number;
    price_with_discount: number;
    quantity: number;
    remarks: string;
    igst: number;
    cgst: number;
    sgst: number;
    cess: number;
    discount_type: string;
    discount_value: number;
    total_tax: number;
    total_amount: number;
    total_discount: number;
  }[];
  setRefundItems: React.Dispatch<
    React.SetStateAction<
      {
        product_group: {
          id: number;
          print_hamper_name: string;
          hamper_code: string;
        };
        batch: number;
        product: number;
        price: number;
        price_with_discount: number;
        quantity: number;
        remarks: string;
        igst: number;
        cgst: number;
        sgst: number;
        cess: number;
        discount_type: string;
        discount_value: number;
        total_tax: number;
        total_amount: number;
        total_discount: number;
      }[]
    >
  >;
}

const RefundModal = ({
  modal,
  setModal,
  refundItems,
  setRefundItems,
}: IProps) => {
  const {
    cart: { currentReturn },
  } = useAppSelector((state) => state.root);

  const PropsRef = useRef<FormikProps<any>>();

  const findRefundIndex = () => {
    return (
      Object.keys(currentReturn).length > 0 &&
      refundItems.findIndex((cartItem: any) => {
        if (cartItem.batch === currentReturn.batch.id) {
          if (currentReturn.product_group !== null) {
            return (
              cartItem?.product_group?.id === currentReturn?.product_group?.id
            );
          } else if (cartItem.product_group === null) {
            return true;
          }
        } else {
          return false;
        }
      })
    );
  };

  const updateValues = (quantity: number, remarks: string) => {
    const igst =
      (currentReturn.igst /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const cgst =
      (currentReturn.cgst /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const sgst =
      (currentReturn.sgst /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const cess =
      (currentReturn.cess /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const total_tax = igst + cgst + sgst + cess;

    const total_discount =
      (currentReturn.total_discount /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    return {
      product_group: currentReturn.product_group
        ? currentReturn.product_group
        : null,
      batch: currentReturn.batch.id,
      price_with_discount: to2Decimal(
        currentReturn.payable_amount / currentReturn.quantity
      ),
      price: to2Decimal(
        currentReturn.total_amount /
          (currentReturn.returned
            ? currentReturn.originalQuantity
            : currentReturn.quantity)
      ),
      product: currentReturn.product.id,
      quantity: quantity,
      remarks: remarks,
      igst: to2Decimal(igst),
      cgst: to2Decimal(cgst),
      sgst: to2Decimal(sgst),
      cess: to2Decimal(cess),
      discount_type: currentReturn.discount_type,
      discount_value: currentReturn.discount_value,
      total_tax: to2Decimal(total_tax),
      total_discount: to2Decimal(total_discount),
      total_amount:
        (currentReturn.payable_amount /
          (currentReturn.returned
            ? currentReturn.originalQuantity
            : currentReturn.quantity)) *
        quantity,
    };
  };

  const handleSubmit = (values, actions) => {
    if (values.quantity > currentReturn.quantity) {
      Toast(
        "Refund Quantity cannot be greater than the quantity of the product in the cart",
        "info"
      );
      return;
    } else if (values.quantity < 0) {
      Toast("Refund Quantity cannot be less than 0", "info");
      return;
    } else {
      const index = findRefundIndex();
      if (index !== -1) {
        const currentrefundItems = [...refundItems];
        currentrefundItems[index] = updateValues(
          values.quantity,
          values.remarks
        );
        setRefundItems(currentrefundItems);
      } else {
        setRefundItems([
          ...refundItems,
          updateValues(values.quantity, values.remarks),
        ]);
      }
      setModal(false);
    }
  };

  return (
    <Modal
      centered={true}
      title="Return Item"
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <Container>
        <Formik
          enableReinitialize={true}
          initialValues={
            findRefundIndex() !== -1
              ? {
                  quantity: refundItems[findRefundIndex()].quantity,
                  remarks: refundItems[findRefundIndex()].remarks,
                }
              : {
                  quantity: 0,
                  remarks: "",
                }
          }
          onSubmit={handleSubmit}
        >
          {(props) => {
            PropsRef.current = props;
            return (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <CustomInput
                      min={0}
                      max={currentReturn?.quantity}
                      step={0.001}
                      type="number"
                      name="quantity"
                      value={props.values.quantity}
                      label="Return Quantity"
                      placeholder="Enter Quantity to be returned"
                    />
                  </div>
                  <div className="col-12">
                    <CustomInput
                      type="text"
                      name="remarks"
                      value={props.values.remarks}
                      label="Product Return Remarks"
                      placeholder="Enter Remarks"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Button
                      text="Return"
                      btnClassNames="btn btn-dark"
                      type={BUTTON_CONSTANTS.SUBMIT}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        {/* <div className="col-12">
          <div className="form-group text-start">
            <label>Refund Quantity</label>
            <input
              type="number"
              max={currentReturn?.quantity}
              step={0.001}
              min={currentReturn?.product?.barcode_type === 5 ? 0.001 : 0}
              className="form-control rounded-lg"
              placeholder="Enter Quantity to be Refunded"
              value={quantity}
              onChange={(e) => {
                setQuantity(parseFloat(e.target.value));
              }}
            />
          </div>
          <div className="form-group text-start">
            <label>Product Refund Remarks</label>
            <input
              type="text"
              value={remarks}
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
              className="form-control rounded-lg"
              placeholder="Enter Remarks"
            />
          </div>
          <Button
            text="Refund"
            btnClassNames="btn btn-dark"
            type={BUTTON_CONSTANTS.BUTTON}
            onClickHandler={() => {}}
          />
        </div> */}
      </Container>
    </Modal>
  );
};

export { RefundModal };
