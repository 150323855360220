import { faTrash, faAdd, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ConfirmModal } from "components/ConfirmModal";
import { CustomInput } from "components/CustomInput";
import { Loader } from "components/Loader";
import {
  Button,
  Card,
  CardBody,
  Container,
  FontIcon,
  IconButton,
  MultiSelect,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddCreditNotePayment,
  FetchAllActivePOSPaymentModes,
  FetchCreditNoteDetails,
  resetCreditNoteState,
} from "store/POS";
import {
  AddOrderPaymentMode,
  DeleteOrderPaymentMode,
  FetchCurrentOrderDetails,
  resetOrderState,
} from "store/POS/orderSlice";
import {
  ACCESS_CONSTANTS,
  ADVANCE_ORDERS_CONSTANTS,
  BUTTON_CONSTANTS,
  CASHIER_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  IPayment,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  ROUTES,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  displayValue,
  formatDate,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  useScreenSize,
} from "utils";
import { AddCustomerModal } from "components";

const MultiplePay = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fromEdit = useLocation()?.state?.edit;

  const {
    branch: { selectedStore },
    creditNote: { currentCreditNote: creditDetails, status: creditNoteStatus },
    order: { status: orderStatus, currentOrder: orderDetails },
    posPaymentMode: { payment_mode: posPaymentModeData },
  } = useAppSelector((state) => state.root);

  const { id: orderId } = useParams();

  const location = useLocation();
  const isOrder = !location.pathname.includes("advance");

  const tableCols = isOrder
    ? CASHIER_CONSTANTS.PAYMENT_HEADER
    : CASHIER_CONSTANTS.ADVANCE_ORDER_PAYMENT_HEADER;

  const PropsRef = useRef<FormikProps<any>>();

  const ArrayHelperRef = useRef<FieldArrayRenderProps>();

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const [addNewCustomer, setAddNewCustomer] = useState<{
    state: boolean;
    customer_data: any;
  }>({ state: false, customer_data: null });

  const [currentCustomer, setCurrentCustomer] = useState<number>(0);

  const [cashierData, setCashierData] = useState<any>({});

  const [paymentModesToDelete, setPaymentModesToDelete] = useState<number[]>(
    []
  );

  const size = useScreenSize();

  const isAdvanceOrder =
    isOrder && orderDetails.id
      ? orderDetails?.returns?.[0]?.credit_type ===
        ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE
      : false;

  const isPayNow =
    isOrder && orderDetails.id
      ? (orderDetails.payments && orderDetails.payments.length === 0) ||
        isAdvanceOrder
      : false;

  const isPayLater =
    isOrder && orderDetails.id
      ? orderDetails.payments &&
        orderDetails.payments.length > 0 &&
        !isAdvanceOrder
      : false;

  const isAdvanceReceipt = creditDetails.id
    ? creditDetails.credit_type === ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE
    : false;

  const paidAmount = isPayLater
    ? orderDetails.id &&
      orderDetails.payments &&
      orderDetails.payments.reduce(
        (acc, curr) =>
          curr && curr.payment_mode.id !== POS_CONSTANTS.CREDIT_NOTE_ID
            ? acc + curr.amount
            : acc + 0,
        0
      )
    : isAdvanceReceipt && fromEdit
    ? creditDetails.id &&
      creditDetails.payments &&
      creditDetails.payments.reduce(
        (acc, curr) =>
          curr && curr.payment_mode.id !== POS_CONSTANTS.CREDIT_NOTE_ID
            ? acc + curr.amount
            : acc + 0,
        0
      )
    : 0;

  const creditAmount = isPayLater
    ? orderDetails.id &&
      orderDetails.payments &&
      orderDetails.payments.reduce((acc, curr) => {
        if (curr.payment_mode.id === POS_CONSTANTS.CREDIT_NOTE_ID) {
          return acc + curr.amount;
        }
      }, 0)
    : 0;

  const [balance, setBalance] = useState<number>(
    isPayNow
      ? orderDetails.payable_amount
      : isPayLater
      ? orderDetails.payable_amount - paidAmount
      : isAdvanceReceipt
      ? fromEdit
        ? creditDetails.refund_amount - paidAmount
        : creditDetails.refund_amount
      : 0
  );

  const calculateInitialValues = () => {
    if (isPayLater) {
      const paidPaymentModes: any[] =
        isOrder &&
        orderDetails.payments &&
        orderDetails.payments.length > 0 &&
        orderDetails.payments.map((payment: IPayment) => {
          if (payment.payment_mode.id !== POS_CONSTANTS.CREDIT_NOTE_ID) {
            return {
              ...payment,
              amount: payment.amount,
              payment_mode: [
                {
                  label: payment.payment_mode.payment_mode,
                  value: payment.payment_mode.id,
                },
              ],
            };
          }
        });

      to2Decimal(orderDetails.payable_amount - paidAmount) > 0 &&
        paidPaymentModes.push({
          amount: to2Decimal(orderDetails.payable_amount - paidAmount),
          payment_mode: [],
          new: true,
        });

      return {
        payment_modes: paidPaymentModes.filter((item) => item !== undefined),
      };
    } else if (isAdvanceOrder) {
      return {
        payment_modes: [
          {
            amount: orderDetails.payable_amount,
            payment_mode: [],
            new: true,
          },
        ],
      };
    } else if (isAdvanceReceipt) {
      if (fromEdit) {
        return {
          payment_modes: creditDetails.payments.map((payment: IPayment) => {
            if (payment.payment_mode !== POS_CONSTANTS.CREDIT_NOTE_ID) {
              return {
                ...payment,
                amount: payment.amount,
                payment_mode: [
                  {
                    label: posPaymentModeData.find(
                      (item, index) => item.id === payment.payment_mode
                    )?.payment_mode,
                    value: payment.payment_mode,
                  },
                ],
              };
            }
          }),
        };
      }
      return {
        payment_modes: [
          {
            amount: creditDetails.refund_amount,
            payment_mode: [],
            new: true,
          },
        ],
      };
    } else {
      return {
        payment_modes: [
          {
            amount: isOrder
              ? orderDetails.payable_amount
              : creditDetails.refund_amount,
            payment_mode: [],
            new: true,
          },
        ],
      };
    }
  };

  const isPaymentModeSelected = (values) => {
    const isSelected = true;
    for (const i of values.payment_modes) {
      if (
        (i && i.payment_mode.length === 0) ||
        (i && i.payment_mode[0] === null)
      ) {
        return false;
      }
    }

    return isSelected;
  };

  const calculateBalance = (values) => {
    const currentTotal = values.payment_modes.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );

    const currentBalance = isPayNow
      ? orderDetails.payable_amount - currentTotal
      : isPayLater
      ? // ? orderDetails.payable_amount - paidAmount - currentTotal
        orderDetails.payable_amount - currentTotal
      : isAdvanceReceipt
      ? creditDetails.refund_amount - currentTotal
      : 0;

    setBalance(currentBalance);
    return currentBalance;
  };

  const addPaymentMode = (formvalues) => {
    if (
      formvalues.payment_modes.some((item) => item.amount === 0) ||
      (isPayNow
        ? orderDetails.payable_amount
        : isPayLater
        ? // ? orderDetails.payable_amount - paidAmount
          orderDetails.payable_amount
        : isAdvanceReceipt
        ? creditDetails.refund_amount
        : 0) -
        formvalues.payment_modes.reduce((acc, item) => acc + item.amount, 0) <
        0
    ) {
      Toast(CASHIER_CONSTANTS.CORRECT_AMOUNT_MESSAGE, TOAST_CONSTANTS.WARNING);
    } else if (!isPaymentModeSelected(formvalues)) {
      Toast(
        CASHIER_CONSTANTS.PAYMENT_MODE_NOT_SELECTED_MESSAGE,
        TOAST_CONSTANTS.WARNING
      );
    } else {
      if (calculateBalance(formvalues) === 0) {
        Toast(CASHIER_CONSTANTS.ALREADY_PAID_FULL_AMOUNT, TOAST_CONSTANTS.INFO);
      } else {
        ArrayHelperRef.current.push({
          amount: to2Decimal(calculateBalance(formvalues)),
          payment_mode: "",
          new: true,
        });
      }
    }
  };

  const completePayment = (values) => {
    if (!isPaymentModeSelected(values)) {
      Toast(
        CASHIER_CONSTANTS.PAYMENT_MODE_NOT_SELECTED_MESSAGE,
        TOAST_CONSTANTS.WARNING
      );
    } else if (values?.payment_modes?.some((item) => item.amount === 0)) {
      Toast(CASHIER_CONSTANTS.CORRECT_AMOUNT_MESSAGE, TOAST_CONSTANTS.WARNING);
    } else {
      if (isAdvanceReceipt) {
        const dataToSend = {
          delete_payments: paymentModesToDelete,
          payments: values?.payment_modes?.map((item: any) => {
            const payemntMode = {
              payment_mode: item.payment_mode[0].value,
              amount: Number(item.amount),
              remarks: "",
              credit_note: creditDetails.id,
            };
            if (item.id > 0) {
              payemntMode["id"] = item.id;
            }
            return payemntMode;
          }),
        };
        dispatch(AddCreditNotePayment(dataToSend, navigate, fromEdit));
      } else {
        const dataToSend = {
          order_id: Number(orderId),
          // employee: logged_in_user.id,
          paid_amount: orderDetails.payable_amount,
          delete_payments: paymentModesToDelete,
          payments: values?.payment_modes?.map((item: any) => {
            const paymentMode = {
              order: Number(orderId),
              payment_mode: item?.payment_mode?.[0]?.value,
              amount: Number(item.amount),
              remarks: "",
              credit_note: "",
            };
            if (item.id > 0) {
              paymentMode["id"] = item.id;
            }
            return paymentMode;
          }),
        };
        dispatch(
          AddOrderPaymentMode({
            order: dataToSend,
            navigate: navigate,
            // navigateTo: ROUTES.CASHIER,
            edit: isPayLater,
            fromEdit: fromEdit,
          })
        );
      }
      // if (isPayNow) {
      //   const dataToSend = {
      //     order_id: Number(orderId),
      //     // employee: logged_in_user.id,
      //     paid_amount: orderDetails.payable_amount,
      //     payments: values?.payment_modes?.map((item: any) => ({
      //       order: Number(orderId),
      //       payment_mode: item.payment_mode[0].value,
      //       amount: Number(item.amount),
      //       remarks: "",
      //       credit_note: "",
      //     })),
      //   };
      //   dispatch(
      //     AddOrderPaymentMode({
      //       order: dataToSend,
      //       navigate: navigate,
      //       navigateTo: ROUTES.CASHIER,
      //     })
      //   );
      //   // console.log("dataToSend", dataToSend);
      // } else if (isPayLater) {
      //   //Return only newly added payments
      //   const newPayments = values?.payment_modes?.filter((item) => {
      //     if (!item.id) {
      //       return item;
      //     }
      //   });

      //   const dataToSend = {
      //     order_id: Number(orderId),
      //     // employee: logged_in_user.id,
      //     paid_amount: orderDetails.payable_amount,
      //     payments: newPayments.map((item: any) => ({
      //       order: Number(orderId),
      //       payment_mode: item.payment_mode[0].value,
      //       amount: Number(item.amount),
      //       remarks: "",
      //       credit_note: "",
      //     })),
      //   };

      //   dispatch(
      //     AddOrderPaymentMode({
      //       order: dataToSend,
      //       navigate: navigate,
      //       navigateTo: ROUTES.CASHIER,
      //     })
      //   );
      //   // console.log("dataToSend", dataToSend);
      // } else if (isAdvanceReceipt) {
      //   const dataToSend = {
      //     payments: values?.payment_modes?.map((item: any) => ({
      //       payment_mode: item.payment_mode[0].value,
      //       amount: Number(item.amount),
      //       remarks: "",
      //       credit_note: creditDetails.id,
      //     })),
      //   };
      //   dispatch(AddCreditNotePayment(dataToSend, navigate));
      // }
    }
  };

  const handleSubmit = (values: any) => {
    if (calculateBalance(PropsRef.current?.values) === 0) {
      completePayment(values);
    } else {
      if (isAdvanceReceipt) {
        Toast(CASHIER_CONSTANTS.FULL_AMOUNT_MESSAGE, TOAST_CONSTANTS.WARNING);
      } else {
        setConfirmModal(true);
      }
    }
  };

  useEffect(() => {
    const getData = () => {
      const data = Promise.all([
        isOrder
          ? dispatch(
              FetchCurrentOrderDetails(
                Number(orderId),
                selectedStore,
                setCurrentCustomer
              )
            )
          : dispatch(
              FetchCreditNoteDetails(Number(orderId), setCurrentCustomer)
            ),
      ]);
    };
    getData();
    dispatch(FetchAllActivePOSPaymentModes());
    return () => {
      dispatch(resetOrderState());
      dispatch(resetCreditNoteState());
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper text-sm">
      {/* {orderDetails.id !== undefined || creditDetails.id !== undefined ? ( */}
      {isPayNow || isPayLater || isAdvanceReceipt ? (
        <Container>
          <div className="d-flex flex-lg-row flex-column">
            <div
              className={`d-flex flex-sm-row flex-grow-1 flex-md-column col-lg-4 mt-2
              ${size?.width < 990 ? "order-0" : ""}
                `}
            >
              <Card>
                <CardBody>
                  <div className="d-flex flex-row justify-content-between my-1">
                    <h4>{CASHIER_CONSTANTS.SALES_SUMMARY}</h4>
                    <Button
                      text={GENERAL_CONSTANTS.BACK}
                      type={BUTTON_CONSTANTS.BUTTON}
                      btnClassNames="btn btn-dark btn-sm"
                      onClickHandler={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h6>
                        {CUSTOMERS_CONSTANTS.LABEL}{" "}
                        {orderDetails.id
                          ? orderDetails?.customer?.name
                          : creditDetails.id
                          ? creditDetails?.customer?.name
                          : ""}
                      </h6>
                    </div>
                    <h6 className="col-6 text-end">
                      {CASHIER_CONSTANTS.ORDER_DATE_LABEL}:{" "}
                      {isOrder
                        ? formatDate(orderDetails?.created_at)
                        : formatDate(creditDetails?.created_at)}
                    </h6>
                    <div className="col-12">
                      <div
                        className={`row overflow-y-scroll ${
                          size?.width < 990 ? "h-auto" : ""
                        } mt-2`}
                        style={{
                          maxHeight: `${size?.width > 990 ? "54vh" : "auto"}`,
                          height: `${size?.width > 990 ? "54vh" : "auto"}`,
                        }}
                      >
                        <Table>
                          <TableHeader
                            cols={tableCols}
                            tableHeaderClassNames="table-dark text-sm"
                          />
                          <TableBody>
                            {orderDetails.products ? (
                              orderDetails.products.map((product, index) => (
                                <tr key={index} className="text-sm">
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-wrap">
                                    {product.product.print_name}
                                  </td>
                                  <td className="text-center">
                                    {product.quantity}
                                  </td>
                                  <td className="text-end">
                                    {POS_CONSTANTS.RUPEE_SIGN}
                                    {product.payable_amount}
                                  </td>
                                </tr>
                              ))
                            ) : creditDetails.id ? (
                              <tr>
                                <td>{creditDetails.credit_note_code}</td>
                                <td>{creditDetails.refund_amount}</td>
                              </tr>
                            ) : (
                              <tr>
                                <td
                                  colSpan={tableCols.length}
                                  className="text-center"
                                >
                                  <Loader small={true} />
                                </td>
                              </tr>
                            )}
                          </TableBody>
                        </Table>
                      </div>
                      <div className="row mt-1">
                        {orderDetails.id && (
                          <>
                            {/* <div className="row">
                              <h6 className="col-6 text-start text-muted">
                                Total:
                              </h6>
                              <h6 className="col-6 text-end">
                                {POS_CONSTANTS.RUPEE_SIGN}{displayValue(orderDetails.bill_amount)}
                              </h6>
                            </div>
                            {bill_discount > 0 && (
                              <div className="row">
                                <h6 className="col-6 text-start text-muted">
                                  Total Discount:
                                </h6>
                                <h6 className="col-6 text-end">
                                  {POS_CONSTANTS.RUPEE_SIGN}
                                  {displayValue(
                                    to2Decimal(
                                      orderDetails.discount_type ===
                                        "percentage"
                                        ? (orderDetails.bill_amount *
                                            orderDetails.discount_value) /
                                            100
                                        : orderDetails.discount_value
                                    )
                                  )}
                                </h6>
                              </div>
                            )} */}
                            {orderDetails.roundoff > 0 && (
                              <div className="row">
                                <h6 className="col-6 text-start text-muted">
                                  {POS_CART_CONSTANTS.ROUND_OFF_LABEL}:
                                </h6>
                                <h6 className="col-6 text-end">
                                  {POS_CONSTANTS.RUPEE_SIGN}
                                  {orderDetails.roundoff}
                                </h6>
                              </div>
                            )}
                            {creditAmount > 0 && (
                              <div className="row">
                                <h6 className="col-6 text-start text-muted">
                                  {CASHIER_CONSTANTS.CREDIT_NOTE_AMOUNT}:
                                </h6>
                                <h6 className="col-6 text-end">
                                  {POS_CONSTANTS.RUPEE_SIGN}
                                  {creditAmount}
                                </h6>
                              </div>
                            )}
                          </>
                        )}
                        <hr />
                        <div className="row">
                          <h6 className="col-6 text-lg text-start">
                            {CASHIER_CONSTANTS.PAYABLE_AMOUNT}:
                          </h6>
                          <h6 className="col-6 text-lg text-end">
                            {POS_CONSTANTS.RUPEE_SIGN}
                            {displayValue(
                              orderDetails.id
                                ? orderDetails.payable_amount
                                : creditDetails.id
                                ? creditDetails.refund_amount
                                : 0
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div
              className={`d-flex flex-column flex-grow-1 col-lg-8 mt-2 ${
                size?.width < 990 ? "order-1" : ""
              }`}
              style={{ height: "calc(100vh - 100px)" }}
            >
              <div className="row">
                <Card>
                  <CardBody>
                    {/* Total Amount To Pay & Balance */}
                    <div className="row justify-content-between position-sticky">
                      <div className="col-6 text-start">
                        <h5>
                          {CASHIER_CONSTANTS.TOTAL_BILL_AMOUNT}:{" "}
                          {POS_CONSTANTS.RUPEE_SIGN}
                          {displayValue(
                            to2Decimal(
                              orderDetails.id
                                ? orderDetails?.payable_amount
                                : creditDetails.id
                                ? creditDetails?.refund_amount
                                : 0
                            )
                          )}
                        </h5>
                      </div>
                      <div className="col-6 text-end">
                        <h5>
                          {CASHIER_CONSTANTS.BALANCE_DUE}:{" "}
                          {POS_CONSTANTS.RUPEE_SIGN}
                          {displayValue(to2Decimal(balance))}
                        </h5>
                      </div>
                    </div>
                    {/* Payment amount & mode Form */}
                    <div
                      className="row overflow-y-scroll h-auto"
                      style={{ maxHeight: "calc(100vh - 300px)" }}
                    >
                      <div className="col-12">
                        <Formik
                          enableReinitialize={true}
                          initialValues={calculateInitialValues()}
                          // validationSchema={POSMultiplePaymentSchema}
                          onSubmit={handleSubmit}
                        >
                          {(props) => {
                            PropsRef.current = props;
                            return (
                              <Form>
                                <FieldArray
                                  name={CASHIER_CONSTANTS.PAYMENT_MODES}
                                  render={(arrayHelpers) => {
                                    ArrayHelperRef.current = arrayHelpers;
                                    return props.values?.payment_modes?.map(
                                      (payment_mode, index) =>
                                        payment_mode ? (
                                          <div className="row p-2" key={index}>
                                            <Card>
                                              <CardBody>
                                                <div className="row">
                                                  <div className="col-2 offset-10  text-end">
                                                    {!(
                                                      (
                                                        props.values
                                                          .payment_modes
                                                          .length === 1 &&
                                                        !fromEdit
                                                      )
                                                      //   ||
                                                      // !props.values
                                                      //   ?.payment_modes?.[index]
                                                      //   .new
                                                    ) && (
                                                      <Button
                                                        type={
                                                          BUTTON_CONSTANTS.BUTTON
                                                        }
                                                        // isDisabled={
                                                        //   props.values
                                                        //     .payment_modes
                                                        //     .length === 1 ||
                                                        //   !props.values
                                                        //     ?.payment_modes?.[
                                                        //     index
                                                        //   ].new
                                                        // }
                                                        text={
                                                          <FontIcon
                                                            icon={faTrash}
                                                          />
                                                        }
                                                        btnClassNames="btn btn-outline-danger"
                                                        onClickHandler={() => {
                                                          if (
                                                            props?.values
                                                              ?.payment_modes?.[
                                                              index
                                                            ]?.id > 0
                                                          ) {
                                                            setPaymentModesToDelete(
                                                              (prev) => {
                                                                return [
                                                                  ...prev,
                                                                  props?.values
                                                                    ?.payment_modes?.[
                                                                    index
                                                                  ]?.id,
                                                                ];
                                                              }
                                                            );
                                                            // dispatch(
                                                            //   DeleteOrderPaymentMode(
                                                            //     props?.values
                                                            //       ?.payment_modes?.[
                                                            //       index
                                                            //     ]?.id
                                                            //   )
                                                            // );
                                                          }
                                                          ArrayHelperRef.current?.remove(
                                                            index
                                                          );
                                                          setBalance(
                                                            (prev) =>
                                                              Number(prev) +
                                                              Number(
                                                                payment_mode.amount
                                                              )
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                {/* <div className="row">
                                                  <div className="col-md-6">
                                                    <CustomInput
                                                      step={0.01}
                                                      placeholder={
                                                        CASHIER_CONSTANTS.AMOUNT_PLACEHOLDER
                                                      }
                                                      discountType="Flat Discount"
                                                      withDiscount={false}
                                                      value={
                                                        props.values
                                                          .payment_modes[index]
                                                          .amount
                                                      }
                                                      isDisabled={
                                                        props.values
                                                          .payment_modes[index]
                                                          .id > 1
                                                      }
                                                      label={
                                                        CASHIER_CONSTANTS.AMOUNT_LABEL
                                                      }
                                                      name={`${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.AMOUNT}`}
                                                      type={
                                                        FORM_CONSTANTS.NUMBER
                                                      }
                                                      onBlurHandler={(e) => {
                                                        if (
                                                          (isPayNow
                                                            ? orderDetails.payable_amount
                                                            : isPayLater
                                                            ? orderDetails.payable_amount -
                                                              paidAmount
                                                            : isAdvanceReceipt
                                                            ? creditDetails.refund_amount
                                                            : 0) -
                                                            props.values.payment_modes.reduce(
                                                              (acc, item) => {
                                                                if (
                                                                  item.id > 0
                                                                ) {
                                                                  return (
                                                                    acc + 0
                                                                  );
                                                                } else {
                                                                  return (
                                                                    acc +
                                                                    item.amount
                                                                  );
                                                                }
                                                              },
                                                              0
                                                            ) <
                                                            0 ||
                                                          Number(
                                                            e.target.value
                                                          ) < 0
                                                        ) {
                                                          Toast(
                                                            CASHIER_CONSTANTS.CORRECT_AMOUNT_MESSAGE,
                                                            TOAST_CONSTANTS.WARNING
                                                          );
                                                          PropsRef.current?.setFieldValue(
                                                            `${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.AMOUNT}`,
                                                            0
                                                          );
                                                          setBalance(
                                                            (isPayNow
                                                              ? orderDetails.payable_amount
                                                              : isPayLater
                                                              ? orderDetails.payable_amount -
                                                                paidAmount
                                                              : isAdvanceReceipt
                                                              ? creditDetails.refund_amount
                                                              : 0) -
                                                              props.values.payment_modes.reduce(
                                                                (acc, item) => {
                                                                  if (
                                                                    item.id > 0
                                                                  ) {
                                                                    return (
                                                                      acc + 0
                                                                    );
                                                                  } else {
                                                                    return (
                                                                      acc +
                                                                      item.amount
                                                                    );
                                                                  }
                                                                },
                                                                0
                                                              ) +
                                                              props.values
                                                                .payment_modes[
                                                                index
                                                              ].amount
                                                          );
                                                        } else {
                                                          calculateBalance(
                                                            PropsRef.current
                                                              ?.values
                                                          );
                                                        }
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name={`${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.AMOUNT}`}
                                                      component={
                                                        FORM_CONSTANTS.ERROR_PARENT
                                                      }
                                                      className={
                                                        FORM_CONSTANTS.ERROR
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-6">
                                                    <MultiSelect
                                                      disabled={
                                                        props.values
                                                          .payment_modes[index]
                                                          .id > 1
                                                      }
                                                      label={
                                                        CASHIER_CONSTANTS.PAYMENT_MODE_LABEL
                                                      }
                                                      name={`${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.PAYMENT_MODE}`}
                                                      options={posPaymentModeData
                                                        .filter(
                                                          (item) =>
                                                            item.id !==
                                                            POS_CONSTANTS.CREDIT_NOTE_ID
                                                        )
                                                        .map(
                                                          (payment_mode) => ({
                                                            label:
                                                              payment_mode.payment_mode,
                                                            value:
                                                              payment_mode.id,
                                                          })
                                                        )}
                                                      onBlurHandler={() => {
                                                        calculateBalance(
                                                          PropsRef.current
                                                            ?.values
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div> */}
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <CustomInput
                                                      step={0.01}
                                                      placeholder={
                                                        CASHIER_CONSTANTS.AMOUNT_PLACEHOLDER
                                                      }
                                                      discountType="Flat Discount"
                                                      withDiscount={false}
                                                      value={
                                                        props.values
                                                          .payment_modes[index]
                                                          .amount
                                                      }
                                                      // isDisabled={
                                                      //   props.values
                                                      //     .payment_modes[index]
                                                      //     .id > 1
                                                      // }
                                                      label={
                                                        CASHIER_CONSTANTS.AMOUNT_LABEL
                                                      }
                                                      name={`${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.AMOUNT}`}
                                                      type={
                                                        FORM_CONSTANTS.NUMBER
                                                      }
                                                      onBlurHandler={(e) => {
                                                        if (
                                                          (isPayNow
                                                            ? orderDetails.payable_amount
                                                            : isPayLater
                                                            ? // ? orderDetails.payable_amount -
                                                              //   paidAmount
                                                              orderDetails.payable_amount
                                                            : isAdvanceReceipt
                                                            ? creditDetails.refund_amount
                                                            : 0) -
                                                            props.values.payment_modes.reduce(
                                                              (acc, item) => {
                                                                // if (
                                                                //   item.id > 0
                                                                // ) {
                                                                //   return (
                                                                //     acc + 0
                                                                //   );
                                                                // } else {
                                                                //   return (
                                                                //     acc +
                                                                //     item.amount
                                                                //   );
                                                                // }
                                                                return (
                                                                  acc +
                                                                  item.amount
                                                                );
                                                              },
                                                              0
                                                            ) <
                                                            0 ||
                                                          Number(
                                                            e.target.value
                                                          ) < 0
                                                        ) {
                                                          Toast(
                                                            CASHIER_CONSTANTS.CORRECT_AMOUNT_MESSAGE,
                                                            TOAST_CONSTANTS.WARNING
                                                          );
                                                          PropsRef.current?.setFieldValue(
                                                            `${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.AMOUNT}`,
                                                            0
                                                          );
                                                          setBalance(
                                                            (isPayNow
                                                              ? orderDetails.payable_amount
                                                              : isPayLater
                                                              ? // ? orderDetails.payable_amount -
                                                                //   paidAmount
                                                                orderDetails.payable_amount
                                                              : isAdvanceReceipt
                                                              ? creditDetails.refund_amount
                                                              : 0) -
                                                              props.values.payment_modes.reduce(
                                                                (acc, item) => {
                                                                  // if (
                                                                  //   item.id > 0
                                                                  // ) {
                                                                  //   return (
                                                                  //     acc + 0
                                                                  //   );
                                                                  // } else {
                                                                  //   return (
                                                                  //     acc +
                                                                  //     item.amount
                                                                  //   );
                                                                  // }
                                                                  return (
                                                                    acc +
                                                                    item.amount
                                                                  );
                                                                },
                                                                0
                                                              ) +
                                                              props.values
                                                                .payment_modes[
                                                                index
                                                              ].amount
                                                          );
                                                        } else {
                                                          calculateBalance(
                                                            PropsRef.current
                                                              ?.values
                                                          );
                                                        }
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name={`${CASHIER_CONSTANTS.PAYMENT_MODES}.${index}.${CASHIER_CONSTANTS.AMOUNT}`}
                                                      component={
                                                        FORM_CONSTANTS.ERROR_PARENT
                                                      }
                                                      className={
                                                        FORM_CONSTANTS.ERROR
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-8 d-flex flex-wrap flex-row align-items-center gap-2">
                                                    {posPaymentModeData
                                                      .filter(
                                                        (item) =>
                                                          item.id !==
                                                          POS_CONSTANTS.CREDIT_NOTE_ID
                                                      )
                                                      .map(
                                                        (mode, modeIndex) => (
                                                          <Button
                                                            key={modeIndex}
                                                            text={
                                                              mode.payment_mode ===
                                                              props?.values
                                                                ?.payment_modes?.[
                                                                index
                                                              ]
                                                                ?.payment_mode?.[0]
                                                                ?.label ? (
                                                                <div className="d-flex">
                                                                  <FontIcon
                                                                    icon={
                                                                      faCheck
                                                                    }
                                                                  />
                                                                  <div className="ms-1">
                                                                    {
                                                                      mode.payment_mode
                                                                    }
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                mode.payment_mode
                                                              )
                                                            }
                                                            type={
                                                              BUTTON_CONSTANTS.BUTTON
                                                            }
                                                            btnClassNames={`btn btn-${
                                                              mode.payment_mode ===
                                                              props?.values
                                                                ?.payment_modes?.[
                                                                index
                                                              ]
                                                                ?.payment_mode?.[0]
                                                                ?.label
                                                                ? "primary"
                                                                : "outline-primary"
                                                            }
                                                          }`}
                                                            // isDisabled={
                                                            //   props?.values
                                                            //     ?.payment_modes?.[
                                                            //     index
                                                            //   ]?.id > 1
                                                            // }
                                                            onClickHandler={() => {
                                                              const currentFormValues = {
                                                                ...props.values,
                                                                payment_modes: props.values.payment_modes.map(
                                                                  (
                                                                    item: any,
                                                                    i: number
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      index
                                                                    ) {
                                                                      return {
                                                                        ...item,
                                                                        payment_mode: [
                                                                          {
                                                                            label:
                                                                              mode.payment_mode,
                                                                            value:
                                                                              mode.id,
                                                                          },
                                                                        ],
                                                                      };
                                                                    } else {
                                                                      return item;
                                                                    }
                                                                  }
                                                                ),
                                                              };
                                                              props.setValues(
                                                                currentFormValues
                                                              );
                                                              if (
                                                                calculateBalance(
                                                                  currentFormValues
                                                                ) === 0 &&
                                                                !fromEdit
                                                              ) {
                                                                completePayment(
                                                                  currentFormValues
                                                                );
                                                              }
                                                              // if (
                                                              //   isOrder &&
                                                              //   isPayLater &&
                                                              //   to2Decimal(
                                                              //     orderDetails.payable_amount -
                                                              //       paidAmount
                                                              //   ) === 0
                                                              // ) {
                                                              //   Toast(
                                                              //     CASHIER_CONSTANTS.ALREADY_PAID_FULL_AMOUNT,
                                                              //     TOAST_CONSTANTS.INFO
                                                              //   );
                                                              // } else
                                                            }}
                                                          />
                                                        )
                                                      )}
                                                  </div>
                                                </div>
                                              </CardBody>
                                            </Card>
                                          </div>
                                        ) : null
                                    );
                                  }}
                                />
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                    {/* Add Payment Mode btn */}
                    <div className="d-flex flex-row col-md-3 text-start">
                      <IconButton
                        icon={faAdd}
                        btnTextSize="text-sm"
                        btnText={CASHIER_CONSTANTS.ADD_PAYMENT_MODE}
                        btnClassNames="btn btn-outline-primary w-100"
                        // isDisabled={
                        //   isOrder &&
                        //   isPayLater &&
                        //   to2Decimal(
                        //     orderDetails.payable_amount - paidAmount
                        //   ) === 0
                        // }
                        onClickHandler={() => {
                          addPaymentMode(PropsRef.current?.values);
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
              {/* Proceed Btn */}
              {(balance !== 0 || fromEdit) && (
                <div
                  className="row"
                  style={{ position: "absolute", bottom: 30, width: "100%" }}
                >
                  <Button
                    text={
                      fromEdit
                        ? GENERAL_CONSTANTS.CONFIRM_CHANGES
                        : GENERAL_CONSTANTS.PROCEED
                    }
                    type={BUTTON_CONSTANTS.BUTTON}
                    isDisabled={
                      to2Decimal(
                        orderDetails.id
                          ? orderDetails?.payable_amount
                          : creditDetails.id
                          ? creditDetails?.refund_amount
                          : 0
                      ) === balance
                      // isPayLater &&
                      // to2Decimal(orderDetails.payable_amount - paidAmount) === 0
                    }
                    loading={
                      orderStatus === STATUSES.LOADING ||
                      creditNoteStatus === STATUSES.LOADING
                    }
                    btnClassNames="btn btn-success"
                    onClickHandler={() => {
                      if (fromEdit) {
                        if (balance !== 0 && isAdvanceReceipt) {
                          Toast(
                            CASHIER_CONSTANTS.FULL_AMOUNT_MESSAGE,
                            TOAST_CONSTANTS.WARNING
                          );
                          return;
                        }
                        completePayment(PropsRef.current?.values);
                        return;
                      }
                      if (isAdvanceReceipt) {
                        Toast(
                          CASHIER_CONSTANTS.FULL_AMOUNT_MESSAGE,
                          TOAST_CONSTANTS.WARNING
                        );
                      } else {
                        if (!isPaymentModeSelected(PropsRef.current?.values)) {
                          Toast(
                            CASHIER_CONSTANTS.PAYMENT_MODE_NOT_SELECTED_MESSAGE,
                            TOAST_CONSTANTS.WARNING
                          );
                          return;
                        }
                        setConfirmModal(true);
                      }

                      // PropsRef.current?.submitForm();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      ) : (
        <div className="text-center">
          <Loader />
        </div>
      )}
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={GENERAL_CONSTANTS.CONFIRM_MODAL_MESSAGE}
        message={
          <div className="row">
            <p>
              {currentCustomer === ACCESS_CONSTANTS.CASH_SALES_ID &&
              balance !== 0
                ? CASHIER_CONSTANTS.PAY_LATER_CONFIRM_MESSAGE
                : CASHIER_CONSTANTS.PAY_LATER_MESSAGE}
            </p>
          </div>
        }
        rejectClick={() => {
          setConfirmModal(false);
        }}
        confirmClick={() => {
          if (
            currentCustomer === ACCESS_CONSTANTS.CASH_SALES_ID &&
            balance !== 0
          ) {
            setConfirmModal(false);
            setAddNewCustomer({ state: true, customer_data: "" });
            const dataToSend = {
              order_id: Number(orderId),
              // employee: logged_in_user.id,
              paid_amount: orderDetails.payable_amount,
              payments: PropsRef.current?.values?.payment_modes?.map(
                (item: any) => ({
                  order: Number(orderId),
                  payment_mode: item?.payment_mode?.[0]?.value,
                  amount: Number(item.amount),
                  remarks: "",
                  credit_note: "",
                })
              ),
            };
            setCashierData(dataToSend);
          } else {
            const newPayments = PropsRef.current?.values.payment_modes.filter(
              (item) => {
                if (!item.id) {
                  return item;
                }
              }
            );

            const dataToSend = {
              order_id: Number(orderId),
              // employee: logged_in_user.id,
              paid_amount: orderDetails.payable_amount,
              payments: newPayments.map((item: any) => ({
                order: Number(orderId),
                payment_mode: item.payment_mode[0].value,
                amount: Number(item.amount),
                remarks: "",
                credit_note: "",
              })),
            };
            // const dataToSend = {
            //   order_id: Number(orderId),
            //   // employee: logged_in_user.id,
            //   paid_amount: orderDetails.payable_amount,
            //   payments: PropsRef.current?.values?.payment_modes.map(
            //     (item: any) => ({
            //       order: Number(orderId),
            //       payment_mode: item.payment_mode[0].value,
            //       amount: Number(item.amount),
            //       remarks: "",
            //       credit_note: "",
            //     })
            //   ),
            // };
            setConfirmModal(false);
            dispatch(
              AddOrderPaymentMode({
                order: dataToSend,
                navigate: navigate,
                // navigateTo: ROUTES.CASHIER,
              })
            );
          }
        }}
      />
      {addNewCustomer.state && (
        <AddCustomerModal
          modal={addNewCustomer}
          setModal={setAddNewCustomer}
          cashierData={cashierData}
        />
      )}
    </div>
  );
};

export { MultiplePay };
