import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  FileUpload,
  Loader,
  MultiSelect,
  SelectStatus,
  SEODetailsform,
  TextArea,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { WidgetsContentDetails } from "pages/Ecommerce/Manage Widgets";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImage } from "store/commonSlice";
import {
  addNewCMSCategory,
  fetchCMSCategories,
  resetCategoryState,
  setCMSCategoryToEdit,
  updateExistingCMSCategory,
} from "store/Ecommerce";
import { getCategories } from "store/ManageProducts/productCategorySlice";
import {
  BUTTON_CONSTANTS,
  debounce,
  ECOMMERCE_CATEGORY_CONSTANTS,
  EcommerceCategorySchema,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  isValidImage,
  processWidgetData,
  ROLE_CONSTANTS,
  S3_FOLDER_CONSTANTS,
  ScrollToTop,
  SEO_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

const CategoryForm = () => {
  const {
    root: {
      common: { current_page },
      productCategory: {
        productCategory: categoryData,
        status: category_status,
      },
    },
    ecommerce: {
      category: { category: CMSCategory, status: CMSCategoryStatus },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [sorting, setSorting] = useState(false);

  const PropsRef = useRef<FormikProps<any>>(null);

  const [query, setQuery] = useState({
    type: ECOMMERCE_CATEGORY_CONSTANTS.QUERY_CATEGORY,
    value: "",
  });

  const initialValues = {
    product_category: "",
    icon: "",
    icon_alt: "",
    display_name: "",
    short_summary: "",

    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],

    widgets: [],

    meta_title: "",
    meta_description: "",
    meta_slug: "",
    meta_keywords: "",
  };

  const SetEditValues = (dataToUSe) => {
    const categoryToEdit = {
      product_category: [
        {
          value: dataToUSe?.product_category?.id,
          label: dataToUSe?.product_category?.category_name,
        },
      ],

      display_name: dataToUSe?.display_name,
      short_summary: dataToUSe?.short_summary,
      icon: dataToUSe?.icon,
      icon_alt: dataToUSe?.icon_alt,

      is_active: [
        {
          value: dataToUSe?.is_active,
          label: dataToUSe?.is_active
            ? GENERAL_CONSTANTS.ACTIVE
            : GENERAL_CONSTANTS.INACTIVE,
        },
      ],

      widgets:
        dataToUSe?.widgets && dataToUSe?.widgets?.length > 0
          ? dataToUSe?.widgets
              ?.map((widget) => {
                const widgetItem = {
                  ...widget,
                  properties: widget.content,
                  is_active: [
                    {
                      value: widget.is_active,
                      label: widget.is_active
                        ? GENERAL_CONSTANTS.ACTIVE
                        : GENERAL_CONSTANTS.INACTIVE,
                    },
                  ],
                };
                delete widgetItem["content"];
                return widgetItem;
              })
              .sort((a, b) => a.priority - b.priority)
          : [],

      meta_title: dataToUSe?.meta_title,
      meta_description: dataToUSe?.meta_description,
      meta_slug: dataToUSe?.meta_slug,
      meta_keywords: dataToUSe?.meta_keywords,
    };

    return JSON.parse(JSON.stringify(categoryToEdit));
  };

  const getCategoryOptions = useMemo(() => {
    return categoryData.map((category) => ({
      label: category.category_name,
      value: category.id,
    }));
  }, [categoryData.length]);

  const searchValues = (values) => {
    switch (values.type) {
      case ECOMMERCE_CATEGORY_CONSTANTS.QUERY_CATEGORY:
        dispatch(getCategories(true, current_page, values.value));
        break;

      default:
        break;
    }
  };

  const optimizeSearch = useCallback(debounce(searchValues), []);

  const onCategoryChange = (value, actions) => {
    if (actions.action === "clear") {
      PropsRef.current?.setFieldValue(
        ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME,
        ""
      );
      PropsRef.current?.setFieldValue(ECOMMERCE_CATEGORY_CONSTANTS.ALT, "");
      PropsRef.current?.setFieldValue(
        ECOMMERCE_CATEGORY_CONSTANTS.CATEGORY,
        []
      );
      PropsRef.current?.setFieldValue(SEO_CONSTANTS.TITLE, "");
      PropsRef.current?.setFieldValue(SEO_CONSTANTS.SLUG, "");
      return;
    }

    const selectedOption = Array.isArray(value) ? value : [value];

    PropsRef.current?.setFieldValue(
      ECOMMERCE_CATEGORY_CONSTANTS.CATEGORY,
      selectedOption
    );

    PropsRef.current?.setFieldValue(
      ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME,
      selectedOption?.[0]?.label
    );

    PropsRef.current?.setFieldValue(
      SEO_CONSTANTS.TITLE,
      selectedOption?.[0]?.label
    );

    PropsRef.current?.setFieldValue(
      ECOMMERCE_CATEGORY_CONSTANTS.ALT,
      selectedOption?.[0]?.label.replace(/ /g, "-").toLowerCase()
    );

    PropsRef.current?.setFieldValue(
      "meta_slug",
      selectedOption?.[0]?.label?.toLowerCase()?.replace(/ /g, "-")
    );
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      product_category: values.product_category?.[0]?.value,
      icon: values?.icon?.preview,
      is_active: Number(values?.is_active?.[0]?.value),
      widgets: processWidgetData(values?.widgets),
    };

    if (id) {
      dispatch(
        updateExistingCMSCategory({
          id: Number(id),
          categoryParams: { id: Number(id), ...dataToSend },
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewCMSCategory({
          categoryParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    ScrollToTop();
    if (id) {
      dispatch(
        fetchCMSCategories({
          id: Number(id),
        })
      );
    }

    return () => {
      dispatch(setCMSCategoryToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${ECOMMERCE_CATEGORY_CONSTANTS.ADD_HEADER}`}
          editValue={CMSCategory?.product_category?.[0]?.label}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={!sorting}
            initialValues={id ? SetEditValues(CMSCategory) : initialValues}
            validationSchema={EcommerceCategorySchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              const updateImage = (e, fieldName, value) => {
                props.setFieldValue(
                  fieldName,
                  Object.assign(e?.target?.files?.[0], {
                    preview: value,
                  })
                );
              };

              return (
                <Form>
                  <ScrollToFieldError />
                  {CMSCategoryStatus === STATUSES.LOADING &&
                    !props.isSubmitting && (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    )}
                  <div className="row">
                    <div className="col-8">
                      <div className="row">
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="d-flex justify-content-between">
                                <h4 className="card-title">
                                  {ECOMMERCE_CATEGORY_CONSTANTS.GENERAL_HEADER}
                                </h4>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="row">
                                <div className="col-md-12">
                                  <MultiSelect
                                    showNoOptionMsg={true}
                                    select={false}
                                    disabled={id !== undefined}
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.CATEGORY}
                                    label={
                                      ECOMMERCE_CATEGORY_CONSTANTS.CATEGORY_LABEL
                                    }
                                    options={getCategoryOptions}
                                    placeholder={
                                      ECOMMERCE_CATEGORY_CONSTANTS.CATEGORY_PLACEHOLDER
                                    }
                                    isLoading={
                                      category_status === STATUSES.LOADING
                                    }
                                    onFocusHandler={() => {
                                      dispatch(
                                        getCategories(true, 1, "", true)
                                      );
                                      setQuery({
                                        type:
                                          ECOMMERCE_CATEGORY_CONSTANTS.QUERY_CATEGORY,
                                        value: "",
                                      });
                                    }}
                                    onInputChangeHandler={(value) => {
                                      value &&
                                        setQuery({
                                          type:
                                            ECOMMERCE_CATEGORY_CONSTANTS.QUERY_CATEGORY,
                                          value,
                                        });
                                    }}
                                    onKeyDownHandler={(e) => {
                                      if (e.key === GENERAL_CONSTANTS.ENTER) {
                                        optimizeSearch(query);
                                      }
                                    }}
                                    onChangeHandler={onCategoryChange}
                                  />
                                  <ErrorMessage
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.CATEGORY}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <CustomInput
                                    isRequired={true}
                                    type={FORM_CONSTANTS.TEXT}
                                    name={
                                      ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME
                                    }
                                    label={
                                      ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME_LABEL
                                    }
                                    placeholder={
                                      ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME_PLACEHOLDER
                                    }
                                    value={props.values.display_name}
                                    onChangeHandler={(e) => {
                                      props.setFieldValue(
                                        ECOMMERCE_CATEGORY_CONSTANTS.ALT,
                                        e.target.value
                                          .replace(/ /g, "-")
                                          .toLowerCase()
                                      );
                                      props.setFieldValue(
                                        ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME,
                                        e.target.value
                                      );
                                      props.setFieldValue(
                                        SEO_CONSTANTS.TITLE,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={
                                      ECOMMERCE_CATEGORY_CONSTANTS.DISPLAY_NAME
                                    }
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectStatus
                                    props={props}
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.STATUS}
                                    label={
                                      ECOMMERCE_CATEGORY_CONSTANTS.STATUS_LABEL
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <FileUpload
                                    isDisabled={
                                      props.values?.product_category?.length ===
                                        0 ||
                                      props.values?.product_category?.[0] ===
                                        null
                                    }
                                    value={null}
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.ICON}
                                    label={
                                      ECOMMERCE_CATEGORY_CONSTANTS.ICON_LABEL
                                    }
                                    hideInput={
                                      (
                                        props.values?.icon?.preview ||
                                        props.values?.icon ||
                                        ""
                                      ).length > 0
                                    }
                                    preview={
                                      props.values?.icon?.preview ||
                                      props.values?.icon ||
                                      ""
                                    }
                                    multipleUpload={false}
                                    onChange={(e) => {
                                      if (isValidImage(e)) {
                                        dispatch(
                                          uploadImage({
                                            params: {
                                              folder_name:
                                                S3_FOLDER_CONSTANTS.CATEGORY_ICON,
                                              file_name:
                                                e?.target?.files?.[0]?.name,
                                              file: e?.target?.files?.[0],
                                            },
                                            updatePropsValues: (value) =>
                                              updateImage(
                                                e,
                                                ECOMMERCE_CATEGORY_CONSTANTS.ICON,
                                                value
                                              ),
                                          })
                                        );
                                      }
                                    }}
                                    deleteImage={() => {
                                      props.setFieldValue(
                                        ECOMMERCE_CATEGORY_CONSTANTS.ICON,
                                        ""
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.ICON}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    isDisabled={
                                      props.values?.product_category?.length ===
                                        0 ||
                                      props.values?.product_category?.[0] ===
                                        null
                                    }
                                    value={props.values.alt}
                                    type={FORM_CONSTANTS.TEXT}
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.ALT}
                                    label={
                                      ECOMMERCE_CATEGORY_CONSTANTS.ALT_LABEL
                                    }
                                    placeholder={
                                      ECOMMERCE_CATEGORY_CONSTANTS.ALT_PLACEHOLDER
                                    }
                                  />
                                  <ErrorMessage
                                    name={ECOMMERCE_CATEGORY_CONSTANTS.ALT}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <TextArea
                                    rows={3}
                                    name={"short_summary"}
                                    label={"Short summary"}
                                    placeholder={
                                      "Enter short summary for the category"
                                    }
                                  />
                                  <ErrorMessage
                                    name={"short_summary"}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <WidgetsContentDetails
                            props={props}
                            setSorting={setSorting}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Button
                            loading={
                              CMSCategoryStatus === STATUSES.LOADING &&
                              props.isSubmitting
                            }
                            type={BUTTON_CONSTANTS.BUTTON}
                            text={FORM_CONSTANTS.SUBMIT}
                            btnClassNames={
                              "btn btn-primary align-self-center w-25"
                            }
                            onClickHandler={() => {
                              props.submitForm();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <SEODetailsform compact={true} props={props} />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { CategoryForm as Ecommerce_CategoryForm };
