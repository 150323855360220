import { refreshAuthToken } from "apis/restApis";
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { capitalizeFirstLetter, Toast } from "./helper";
import { ROUTES } from "./constants";

export const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = async (error: AxiosError) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        let error_response: any = error.response.data;
        if (error_response && Object.keys(error_response.result).length > 0) {
          console.log(error_response.result);
          for (let msg in error_response.result) {
            Toast(
              capitalizeFirstLetter(error_response?.result?.[msg]?.[0]),
              "error"
            );
          }
        } else {
          Toast(
            error_response.message ? error_response.message : "Bad Request",
            "error"
          );
        }
        return Promise.reject(
          error_response.message
            ? error_response.message
            : "400 Error: Bad Request"
        );

      case 401:
        const refreshtoken = localStorage.getItem("refreshToken");
        if (refreshtoken) {
          try {
            const response: any = await refreshAuthToken({
              refresh_token: refreshtoken,
            });
            // Set new tokens
            localStorage.setItem("accessToken", response.result.access_token);
            localStorage.setItem("refreshToken", response.result.refresh_token);

            if (error.config) {
              error.config.headers.Authorization = `Bearer ${response.result.access_token}`;
              return axios(error.config);
            }
          } catch (error) {
            if (
              error ===
              "Invalid refresh token provided. Please obtain a new refresh token and try again"
            ) {
              Toast("Session Expired", "error");
              localStorage.clear();
              setTimeout(() => {
                window.location.href = ROUTES.LOGIN;
              }, 1000);
              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          }
        } else {
          Toast("Session Expired", "error");
          localStorage.clear();
          setTimeout(() => {
            window.location.href = ROUTES.LOGIN;
          }, 1000);
          return Promise.reject(error);
        }

      case 403:
        Toast("Forbidden", "error");
        return Promise.reject("403 Error: Forbidden");

      case 404:
        Toast("Page Not Found", "error");
        return Promise.reject("404 Error: The requested page was not found");

      case 417:
        return Promise.reject(error.response.data);

      case 500:
        Toast("Something went wrong on the server", "error");
        return Promise.reject(
          "Server Error: Something went wrong on the server"
        );

      case 504:
        Toast("Request Timeout", "error");
        return Promise.reject("504 Error: Request Timeout");

      case 502:
        Toast("Bad Gateway", "error");
        return Promise.reject("502 Error: Bad Gateway");

      default:
        // Handle other HTTP error statuses
        Toast("An unexpected error occurred", "error");
        return Promise.reject(
          `Unexpected HTTP Error: ${error.response.status}`
        );
    }
  } else {
    if (
      error.code === "ERR_NETWORK" &&
      error.message === "Network Error" &&
      error.status === null
    ) {
      // Handle network errors
      Toast("Please connect to the internet", "error");
      return Promise.reject(
        "Network Error: Please check your internet connection"
      );
    } else if (
      error.name === "CanceledError" &&
      error.message === "canceled" &&
      error.code === "ERR_CANCELED"
    ) {
      return Promise.reject(error);
    } else if (error.response.data && typeof error.response.data !== "string") {
      // Handle non-string response data
      return Promise.reject(error.response.data);
    }
  }
};

export const setupInterceptorsTo = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

setupInterceptorsTo.interceptors.request.use(onRequest, onRequestError);
setupInterceptorsTo.interceptors.response.use(onResponse, onResponseError);
