import React from "react";

interface OffCanvasProps {
  title: string;
  children: React.ReactNode;
  show: boolean;
  width?: any;
  onClickHandler: () => void;
}

const OffCanvas: React.FC<OffCanvasProps> = ({
  title,
  show,
  children,
  width,
  onClickHandler,
}) => {
  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${width} ${
          show ? "show" : "hide"
        } bs.offcanvas`}
        tabIndex={-1}
        id="offcanvas"
        data-bs-scroll="false"
        aria-labelledby="offcanvasLabel"
      >
        <div className="offcanvas-header">
          <h3 className="offcanvas-title" id="offcanvasLabel">
            {title}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={onClickHandler}
          ></button>
        </div>
        <div className="offcanvas-body">{children}</div>
      </div>
      {show && <div className="offcanvas-backdrop fade show"></div>}
    </>
  );
};

export { OffCanvas };
