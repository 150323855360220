import React, { useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Loader,
  OffCanvas,
} from "components";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { fetchWidgets } from "store/Ecommerce";
import { capitalizeWithSeperator } from "../../../utils/helper";
import {
  GENERAL_CONSTANTS,
  isJsonString,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
  WIDGET_CONSTANTS,
} from "utils";

const AddWidgetOffCanvas = ({ arrayHelpers, show, setShow }) => {
  const dispatch = useAppDispatch();

  const {
    ecommerce: {
      widget: { widgets, status: widgetStatus },
    },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!widgets || widgets.length === 0) {
      dispatch(fetchWidgets({ active: true }));
    }
  }, [dispatch, widgets]);

  const handleAddWidget = useCallback(
    (widget) => {
      if (isJsonString(widget.configuration_options)) {
        arrayHelpers.push({
          is_active: [
            {
              value: 1,
              label: GENERAL_CONSTANTS.ACTIVE,
            },
          ],
          widget_name: widget.widget_name,
          widget: widget.id,
          properties: JSON.parse(widget.configuration_options),
        });
        setShow(false);
      } else {
        Toast(
          "No configuration options found for this widget",
          TOAST_CONSTANTS.ERROR
        );
      }
    },
    [arrayHelpers, setShow]
  );

  return (
    <OffCanvas
      title={WIDGET_CONSTANTS.CARD_HEADER_BTN}
      show={show}
      onClickHandler={() => setShow(false)}
      width="w-50"
    >
      {show && (
        <div className="row row-cols-2 g-2">
          {widgetStatus === STATUSES.LOADING ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            widgets.map((widget, widgetIndex) => (
              <div className="col hover_img_container" key={widgetIndex}>
                <div className="hover_img_middle">
                  <Button
                    type="button"
                    btnClassNames="btn btn-primary btn-sm m-1"
                    onClickHandler={() => handleAddWidget(widget)}
                    text={WIDGET_CONSTANTS.CARD_HEADER_BTN}
                  />
                </div>
                <Card cardClassNames="hover_img">
                  <CardHeader>
                    <h4 className="card-title">{widget.widget_name}</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="card-content">
                      <p className="card-title d-flex flex-row justify-content-between mb-2">
                        <span className="text-muted mr-2">Widget Type</span>
                        {capitalizeWithSeperator(widget?.widget_type, "-") ||
                          "N/A"}
                      </p>
                      <p className="card-text d-flex flex-row flex-wrap justify-content-between">
                        <span className="text-muted mr-2">Description</span>
                        {widget.description || "No description available"}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))
          )}
        </div>
      )}
    </OffCanvas>
  );
};

export { AddWidgetOffCanvas };
