import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delCMSCategory,
  getCMSCategories,
  setCMSCategory,
  updateCMSCategory,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  ECOMMERCE_CATEGORY_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
  TEcommerceCategory,
  TGetParams,
  Toast,
  TOAST_CONSTANTS,
} from "utils";

const initialState = {
  categories: [] as TEcommerceCategory[],
  category: null as null | (TEcommerceCategory & { product_category: any }),
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchCMSCategories = createAsyncThunk(
  "ecommerce_category/fetchCMSCategories",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response: any = await getCMSCategories({
        id,
        active,
        pageNo,
        query,
      });
      // if (id) {
      //   const categoryToEdit = {
      //     product_category: [
      //       {
      //         value: response?.result?.product_category?.id,
      //         label: response?.result?.product_category?.category_name,
      //       },
      //     ],

      //     is_active: [
      //       {
      //         value: response?.result?.is_active,
      //         label: response?.result?.is_active
      //           ? GENERAL_CONSTANTS.ACTIVE
      //           : GENERAL_CONSTANTS.INACTIVE,
      //       },
      //     ],

      //     widgets:
      //       response.result?.widgets && response.result?.widgets?.length > 0
      //         ? response.result?.widgets
      //             ?.map((widget) => {
      //               const widgetItem = {
      //                 ...widget,
      //                 properties: widget.content,
      //                 is_active: [
      //                   {
      //                     value: widget.is_active,
      //                     label: widget.is_active
      //                       ? GENERAL_CONSTANTS.ACTIVE
      //                       : GENERAL_CONSTANTS.INACTIVE,
      //                   },
      //                 ],
      //               };
      //               delete widgetItem["content"];
      //               return widgetItem;
      //             })
      //             .sort((a, b) => a.priority - b.priority)
      //         : [],

      //     meta_title: response?.result?.meta_title,
      //     meta_description: response?.result?.meta_description,
      //     meta_slug: response?.result?.meta_slug,
      //     meta_keywords: response?.result?.meta_keywords,
      //   };

      //   return categoryToEdit;
      // } else {
      //   dispatch(updateValues(response));
      // }
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewCMSCategory = createAsyncThunk(
  "ecommerce_category/addNewCMSCategory",
  async (
    {
      categoryParams,
      actions,
      navigate,
    }: {
      categoryParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setCMSCategory(categoryParams);
      actions.resetForm();
      navigate(ECOMMERCE_CATEGORY_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingCMSCategory = createAsyncThunk(
  "ecommerce_category/updateExistingCMSCategory",
  async (
    {
      id,
      categoryParams,
      actions,
      navigate,
    }: {
      id: number;
      categoryParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateCMSCategory(id, categoryParams);
      actions.resetForm();
      navigate(ECOMMERCE_CATEGORY_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCMSCategory = createAsyncThunk(
  "ecommerce_category/deleteCMSCategory",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "category-delete" })
    );
    try {
      const response = await delCMSCategory(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonStatus({ state: STATUSES.IDLE, type: "category-delete" })
      );
    }
  }
);

const ecommerceCategorySlice = createSlice({
  name: "ecommerce_category",
  initialState,
  reducers: {
    setCMSCategoryToEdit: (state, action) => {
      state.category = action.payload;
    },
    resetCategoryState: (state) => {
      state.categories = [] as TEcommerceCategory[];
      state.category = null as
        | null
        | (TEcommerceCategory & {
            product_category: any;
          });
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCMSCategories.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.category = action.payload.result;
        } else if (action.meta.arg.active) {
          state.categories = action.payload.result;
        } else {
          state.categories = action.payload.result.results;
        }
      })
      .addCase(addNewCMSCategory.fulfilled, (state, action: any) => {
        state.categories.unshift(action.payload.result);
        state.categories = state.categories.slice(0, 10);
      })
      .addCase(updateExistingCMSCategory.fulfilled, (state, action: any) => {
        // Update the existing category in the categories array
        const index = state.categories.findIndex(
          (category) => category.id === action.payload.result.id
        );
        if (index !== -1) {
          state.categories[index] = action.payload.result;
        }
      })
      .addCase(deleteCMSCategory.fulfilled, (state, action: any) => {
        // update the deleted category from the categories array
        const index = state.categories.findIndex(
          (category) => category.id === action.meta.arg
        );
        if (index !== -1) {
          state.categories[index] = {
            ...state.categories[index],
            is_active: action.payload.result.is_active,
          };
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewCMSCategory.fulfilled,
          updateExistingCMSCategory.fulfilled,
          deleteCMSCategory.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCMSCategories.pending,
          addNewCMSCategory.pending,
          updateExistingCMSCategory.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setCMSCategoryToEdit,
  resetCategoryState,
} = ecommerceCategorySlice.actions;

export default ecommerceCategorySlice.reducer;
