import { createSlice } from "@reduxjs/toolkit";
import { BANK_CONSTANTS, GENERAL_CONSTANTS, STATUSES } from "utils/constants";
import {
  delBank,
  getActiveBank,
  getBank,
  getSpecificBank,
  setBank,
  updateBank,
} from "apis/restApis";
import { Bank, PaginationState, TGetParams, Toast } from "utils";
import { updateValues } from "store/commonSlice";
import { clear } from "console";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { getCity, getCountry, getState } from "store/locationSlice";

const initialState = {
  banks: [] as Bank[],
  bankToEdit: {} as Bank,
  status: STATUSES.IDLE as string,
  error: null,
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    getAllBanks: (state, action) => {
      state.banks = action.payload;
    },
    addBank: (state, action) => {
      state.banks.unshift(action.payload);
    },
    removeBank: (state, action) => {
      state.banks = state.banks.filter((bank) => bank.id !== action.payload);
    },
    editBank: (state, action) => {
      const { id } = action.payload;
      const index = state.banks.findIndex((bank) => bank.id === id);
      if (index !== -1) {
        state.banks[index] = action.payload;
      }
    },
    setBankToEdit: (state, action) => {
      state.bankToEdit = action.payload;
    },

    setBankStatus(state, action) {
      state.status = action.payload;
    },
    setBankError(state, action) {
      state.error = action.payload;
    },

    resetBankState: (state) => {
      state.banks = [] as Bank[];
      state.bankToEdit = {} as Bank;
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  addBank,
  getAllBanks,
  removeBank,
  editBank,
  setBankToEdit,

  setBankStatus,
  setBankError,

  resetBankState,
} = bankSlice.actions;

export default bankSlice.reducer;

export function getBanks({ id, active, pageNo = 1, query = "" }: TGetParams) {
  return async function getBanksThunk(dispatch, getState) {
    dispatch(setBankStatus(STATUSES.LOADING));
    const endpoint = id
      ? `/api/payment/banks/${id}/`
      : `/api/payment/banks/${active ? "active/" : ""}?${
          query ? `query=${query}&` : ""
        }page=${pageNo}`;
    // try{
    //   const response = await getBank(endpoint);
    //   dispatch(updateValues(response));
    //   dispatch(setBankStatus(STATUSES.IDLE));
    // }catch (error){
    //   dispatch(setBankStatus(STATUSES.ERROR));
    //   dispatch(setBankError(error.message));
    // }
    getBank(endpoint)
      .then((response: any) => {
        dispatch(updateValues(response));
        dispatch(getAllBanks(response.result.results));
      })
      .catch((error) => {
        dispatch(setBankStatus(STATUSES.ERROR));
        dispatch(setBankError(error.message));
      })
      .finally(() => {
        dispatch(setBankStatus(STATUSES.IDLE));
      });
  };
}

export function getIndividualBank(id: number, edit: boolean = false) {
  return async function getIndividualBankThunk(dispatch, getReduxState) {
    dispatch(setBankStatus(STATUSES.LOADING));
    getSpecificBank(id)
      .then((response: any) => {
        if (edit) {
          const bankToEdit = {
            bank_name: response.result?.bank_name,
            ifsc_code: response.result?.ifsc_code,
            branch_name: response.result?.branch_name,

            account_holder_name: response.result?.account_holder_name,
            account_number: response.result?.account_number,

            opening_credit_balance: response.result?.opening_credit_balance,
            opening_debit_balance: response.result?.opening_debit_balance,

            is_upi_available: response.result && [
              {
                value: response.result.is_upi_available,
                label: response.result.is_upi_available ? "Yes" : "No",
              },
            ],

            bank_address_line_1: response.result?.bank_address_line_1,
            bank_address_line_2: response.result?.bank_address_line_2,

            bank_country: response.result?.bank_country && [
              {
                value: response.result.bank_country.id,
                label: response.result.bank_country.country,
              },
            ],

            bank_state: response.result?.bank_state && [
              {
                value: response.result.bank_state.id,
                label: response.result.bank_state.state,
              },
            ],

            bank_city: response.result?.bank_city && [
              {
                value: response.result.bank_city.id,
                label: response.result.bank_city.city,
              },
            ],

            bank_pincode: response.result?.bank_pincode,

            is_active: [
              {
                value: response.result?.is_active,
                label:
                  response.result?.is_active === 1
                    ? GENERAL_CONSTANTS.ACTIVE
                    : GENERAL_CONSTANTS.INACTIVE,
              },
            ],
          };
          dispatch(setBankToEdit(bankToEdit));
        } else {
          dispatch(setBankToEdit(response.result));
        }
      })
      .catch((error) => {
        dispatch(setBankStatus(STATUSES.ERROR));
        dispatch(setBankError(error.message));
      })
      .finally(() => {
        dispatch(setBankStatus(STATUSES.IDLE));
      });
  };
}

export function addNewBank(
  bankData,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function addNewBankThunk(dispatch, getState) {
    dispatch(setBankStatus(STATUSES.LOADING));
    setBank(bankData)
      .then((response: any) => {
        dispatch(setBankStatus(STATUSES.LOADING));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(BANK_CONSTANTS.BANK_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        if (error.message) {
          dispatch(setBankStatus(STATUSES.ERROR));
          dispatch(setBankError(error.message));
          // Toast(`${error.message}`, "error");
        }
      })
      .finally(() => {
        dispatch(setBankStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingBank(
  id: number,
  bankParams: Object,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function updateExistingBankThunk(dispatch, getState) {
    dispatch(setBankStatus(STATUSES.LOADING));
    updateBank(id, bankParams)
      .then((response: any) => {
        dispatch(setBankStatus(STATUSES.LOADING));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(BANK_CONSTANTS.BANK_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setBankStatus(STATUSES.ERROR));
        dispatch(setBankError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setBankStatus(STATUSES.IDLE));
      });
  };
}

export function deleteBank(id: number) {
  return async function deleteBankThunk(dispatch, getState) {
    dispatch(setBankStatus(STATUSES.LOADING));
    delBank(id)
      .then((response: any) => {
        dispatch(editBank(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setBankStatus(STATUSES.ERROR));
        dispatch(setBankError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setBankStatus(STATUSES.IDLE));
      });
  };
}
