import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import {
  deleteCustomer,
  getCustomers,
} from "store/ManageCustomers/customerSlice";
import {
  ContentHeader,
  NoData,
  TableTools,
  Loader,
  Status,
  ConfirmModal,
} from "components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  CUSTOMERS_CONSTANTS,
  ROUTES_CONSTANTS,
  BUTTON_CONSTANTS,
  STATUSES,
  ACCESS_CONSTANTS,
} from "utils/constants";
import { Customer } from "utils/types";
import { updatePageNo } from "store/commonSlice";

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions, logged_in_user },
    common: { current_page, total_items, total_pages, page_size },
    customer: { customers: customerData, status },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState("");

  const [currentCustomer, setCurrentcustomer] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const customerPermission = permissions.find(
    (p) => p.module_name === "Manage Customer"
  );

  const tableCols = CUSTOMERS_CONSTANTS.CUSTOMERS_COLS;

  const update_page = (pageNo: number) => {
    dispatch(getCustomers(false, pageNo, query));
  };

  const avaryaEditAccess = ACCESS_CONSTANTS.AVARYA_FULL_ACCESS.includes(
    logged_in_user?.email
  );

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });
  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <ContentHeader pageHeader={CUSTOMERS_CONSTANTS.CUSTOMERS_HEADER} />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={customerPermission?.perm_add}
                path={CUSTOMERS_CONSTANTS.PATH}
                label={CUSTOMERS_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : customerData.length > 0 ? (
                    customerData.map((customer: Customer, index) => (
                      <tr key={index}>
                        <td>{page_size * (current_page - 1) + index + 1}</td>
                        <td>{customer.name}</td>
                        <td>{customer.email}</td>
                        <td>{customer.contact_number}</td>
                        <td>
                          <Status is_active={customer.is_active} />
                        </td>
                        <td>
                          <Button
                            text={<FontIcon icon={faPenToSquare} />}
                            type={BUTTON_CONSTANTS.BUTTON}
                            isDisabled={
                              !customerPermission?.perm_edit ||
                              (customer?.id ===
                                ACCESS_CONSTANTS.CASH_SALES_ID &&
                                !avaryaEditAccess)
                            }
                            btnClassNames={"btn btn-info btn-sm mr-2"}
                            onClickHandler={() =>
                              navigate(
                                ROUTES_CONSTANTS.EDIT + "/" + customer.id
                              )
                            }
                          />
                          {customer.is_active ? (
                            <Button
                              text={<FontIcon icon={faBan} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={
                                !customerPermission?.perm_delete ||
                                customer?.id === ACCESS_CONSTANTS.CASH_SALES_ID
                              }
                              btnClassNames={"btn btn-danger btn-sm"}
                              onClickHandler={() => {
                                setCurrentcustomer(Number(customer.id));
                                setConfirmModal(true);
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={CUSTOMERS_CONSTANTS.DELETE_CUSTOMER}
        message={CUSTOMERS_CONSTANTS.DELETE_CUSTOMER_MSG}
        confirmClick={() => {
          dispatch(deleteCustomer(currentCustomer));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { Customers };
