import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "utils";

import { getUserPermissions } from "store/authSlice";
import { generatePermissionRoutes } from "routes";
import { setScreenOrientation, setScreenSize } from "store/commonSlice";

export const App = () => {
  const dispatch = useAppDispatch();

  const {
    auth: { isLoggedIn, permissions: routePermissions },
    common: { portrait },
  } = useAppSelector((state) => state.root);

  useEffect(() => {
    dispatch(
      setScreenSize({
        width: window.screen.availWidth,
        height: window.screen.availHeight,
      })
    );
    window.screen.orientation.onchange = () => {
      dispatch(
        setScreenOrientation(
          window.screen.orientation.type === "portrait-primary"
        )
      );
    };
    if (isLoggedIn && routePermissions?.length === 0) {
      dispatch(getUserPermissions());
    }
  }, [dispatch, portrait]);

  const { customRoutes } = generatePermissionRoutes(
    Array.from(new Set(routePermissions))
  );

  return <RouterProvider router={customRoutes} />;
};
