import { Button, Card, CardBody, CustomInput, MultiSelect } from "components";
import { ErrorMessage, Form } from "formik";
import React from "react";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PAYMENT_TERMS_CONSTANTS,
} from "utils";

const PaymentTermForm = ({ props, edit }) => {
  return (
    <Form>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardBody>
              <div>
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  isRequired={true}
                  label={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_NAME_LABEL}
                  name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_NAME}
                  placeholder={
                    PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_NAME_PLACEHOLDER
                  }
                  value={props.values.term_name}
                />
                <ErrorMessage
                  name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div>
                <MultiSelect
                  select={false}
                  isRequired={true}
                  label={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_TYPE_LABEL}
                  name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_TYPE}
                  options={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_OPTIONS}
                  value={props.values.term_type}
                />
                <ErrorMessage
                  name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_TYPE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {props?.values?.term_type[0]?.label === "Credit" ? (
                <div>
                  <CustomInput
                    type={FORM_CONSTANTS.NUMBER}
                    label={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_DAYS_LABEL}
                    name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_DAYS}
                    placeholder={
                      PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_DAYS_PLACEHOLDER
                    }
                    value={props.values.term_days_after_delivery}
                  />
                  <ErrorMessage
                    name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_DAYS}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
              ) : null}
              {edit ? (
                <div>
                  <MultiSelect
                    select={false}
                    name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_STATUS}
                    label={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_STATUS_LABEL}
                    options={
                      PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_STATUS_OPTIONS
                    }
                    value={props.values.is_active}
                  />
                  <ErrorMessage
                    name={PAYMENT_TERMS_CONSTANTS.PAYMENT_TERM_STATUS}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </div>
        <div className="row">
          <Button
            type={BUTTON_CONSTANTS.SUBMIT}
            text={FORM_CONSTANTS.SUBMIT}
            btnClassNames={"btn btn-primary align-self-center w-25"}
          />
        </div>
      </div>
    </Form>
  );
};

export { PaymentTermForm };
