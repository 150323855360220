import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ScrollToTop,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils/helper";
import {
  deleteProductGroup,
  getProductGroups,
  removeProductGroup,
} from "store/ManageProducts/productGroupSlice";

import {
  ContentHeader,
  NoData,
  TableTools,
  Loader,
  Status,
  ConfirmModal,
} from "components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import {
  faBan,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BUTTON_CONSTANTS,
  PRODUCT_GROUPS_CONSTANTS,
  ROUTES_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { ProductGroup } from "utils";
import { updatePageNo } from "store/commonSlice";

const ProductGroups = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { current_page, total_items, total_pages, page_size },
    productGroup: { productGroups: productGroupData, status },
  } = useAppSelector((state) => state.root);

  const productGroupPermission = permissions.find(
    (p) => p.module_name === "Manage Product Groups"
  );
  const [query, setQuery] = useState("");

  const [currentGrp, setCurrentGrp] = useState<number>(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const update_page = (pageNo: number) => {
    dispatch(getProductGroups(false, pageNo, query));
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
    };
  }, [dispatch]);

  const tableCols = PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUPS_COLS;

  return (
    <div className="content-wrapper">
      <ContentHeader
        pageHeader={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUPS_HEADER}
      />
      <Container>
        <div className="col-12">
          <Card>
            <CardHeader>
              <TableTools
                setQuery={setQuery}
                permission={productGroupPermission?.perm_add}
                path={PRODUCT_GROUPS_CONSTANTS.PATH}
                label={PRODUCT_GROUPS_CONSTANTS.LABEL}
              />
            </CardHeader>
            <CardBody>
              <Table>
                <TableHeader cols={tableCols} />
                <TableBody>
                  {status === STATUSES.LOADING ? (
                    <tr>
                      <td colSpan={tableCols.length} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : productGroupData.length > 0 ? (
                    productGroupData.map(
                      (productGroup: ProductGroup, index) => (
                        <tr key={index}>
                          <td>{page_size * (current_page - 1) + index + 1}</td>
                          <td>{productGroup.print_hamper_name}</td>
                          <td>{productGroup.hamper_code}</td>
                          <td>
                            <Status is_active={productGroup.is_active} />
                          </td>
                          <td>
                            <Button
                              text={<FontIcon icon={faPenToSquare} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={!productGroupPermission?.perm_edit}
                              btnClassNames={"btn btn-info btn-sm mr-2"}
                              onClickHandler={() =>
                                navigate(
                                  ROUTES_CONSTANTS.EDIT + "/" + productGroup.id
                                )
                              }
                            />
                            {productGroup.is_active ? (
                              <Button
                                text={<FontIcon icon={faBan} />}
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={
                                  !productGroupPermission?.perm_delete
                                }
                                btnClassNames={"btn btn-danger btn-sm"}
                                onClickHandler={() => {
                                  setCurrentGrp(Number(productGroup.id));
                                  setConfirmModal(true);
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <NoData len={tableCols.length} />
                  )}
                </TableBody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination
                loading={status === STATUSES.LOADING}
                pageSize={page_size}
                totalItems={total_items}
                totalPages={total_pages}
                currentPage={current_page}
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
                pageChangeHandler={pageChangeHandler}
              />
            </CardFooter>
          </Card>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={PRODUCT_GROUPS_CONSTANTS.DELETE_GRP}
        message={PRODUCT_GROUPS_CONSTANTS.DELETE_GRP_MSG}
        confirmClick={() => {
          dispatch(deleteProductGroup(currentGrp));
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { ProductGroups };
