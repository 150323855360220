import { faCircleNotch, faPrint } from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  Button,
  FontIcon,
  Table,
  TableBody,
  TableHeader,
} from "components";
import React, { useState } from "react";
import { PrintCreditNote } from "store/POS";
import {
  STATUSES,
  BUTTON_CONSTANTS,
  IGetOrder,
  displayValue,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  ORDER_CONSTANTS,
  ADVANCE_ORDERS_CONSTANTS,
  POS_CONSTANTS,
} from "utils";

interface IProps {
  orderDetails: IGetOrder;
}

const OrderCreditNotes = ({ orderDetails }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    common: { status: commonStatus },
  } = useAppSelector((state) => state.root);

  const [activeBatchItem, setActiveBatchItem] = useState(null);

  const creditNoteItem = orderDetails.returns?.map((item, index) => {
    if (item.credit_type !== ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE) {
      return {
        id: index,
        title: item.credit_note_code,
        body: (
          <>
            <div className="d-flex flex-row px-0">
              <div className="col-1 offset-11 text-end">
                <Button
                  text={
                    commonStatus?.state === STATUSES.LOADING &&
                    commonStatus?.type ===
                      ADVANCE_ORDERS_CONSTANTS.LOADING_STATE ? (
                      <FontIcon
                        icon={faCircleNotch}
                        iconClassNames={"fa-solid fa-circle-notch fa-spin"}
                      />
                    ) : (
                      <FontIcon icon={faPrint} />
                    )
                  }
                  type={BUTTON_CONSTANTS.BUTTON}
                  btnClassNames="btn btn-info"
                  onClickHandler={() => {
                    dispatch(PrintCreditNote(item.id));
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row px-0">
              <Table>
                <TableHeader cols={ORDER_CONSTANTS.CREDIT_NOTE_TABLE_HEADER} />
                <TableBody>
                  {item.products.map((product, productIndex) => {
                    return (
                      <tr key={productIndex}>
                        <td>{productIndex + 1}</td>
                        <td>{product.product.product_code}</td>
                        <td>{product.product.print_name}</td>
                        <td>{product.quantity}</td>
                        <td>
                          {POS_CONSTANTS.RUPEE_SIGN}
                          {displayValue(to2Decimal(product.total_amount))}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={3}></td>
                    <td>{ORDER_CONSTANTS.TOTAL_AMOUNT}</td>
                    <td>
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(to2Decimal(item.refund_amount))}
                    </td>
                  </tr>
                </TableBody>
              </Table>
            </div>
          </>
        ),
      };
    } else {
      return null;
    }
  });

  return (
    <Accordion
      accordionItems={creditNoteItem}
      activeItem={activeBatchItem}
      id={"creditNote-toggle"}
    />
  );
};

export { OrderCreditNotes };
