import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  EMPLOYEE_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
} from "utils/constants";
import { Employee, PaginationState, TGetParams } from "utils/types";
import {
  delEmployee,
  delEmployeeStoreMapping,
  getEmployee,
  getSpecificEmployee,
  setEmployee,
  updateEmployee,
} from "apis/restApis";
import { setcommonError, updateValues } from "store/commonSlice";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Toast } from "utils";
import { FormikHelpers } from "formik";
import { getBanks } from "store/ManageBanks/bankSlice";
import { fetchRoles } from "store/ManageRoles/roleSlice";
import { getBranches } from "store/ManageBranches/branchSlice";
import { getCountry } from "store/locationSlice";
import { RootState } from "store/store";

const initialState = {
  employees: [] as Employee[],
  employeeToEdit: {} as Employee,
  status: STATUSES.IDLE as string,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    getAllEmployees: (state, action: PayloadAction<Employee[]>) => {
      state.employees = action.payload;
    },
    addEmployee: (state, action: PayloadAction<Employee>) => {
      state.employees.unshift(action.payload);
    },
    removeEmployee: (state, action: PayloadAction<number>) => {
      state.employees = state.employees.filter(
        (user) => user.id !== action.payload
      );
    },
    editEmployee: (state, action) => {
      if (action.payload.index !== -1) {
        state.employees[action.payload.index] = action.payload.employee;
      }
    },
    setEmployeeToEdit: (state, action) => {
      state.employeeToEdit = action.payload;
    },

    setEmployeeStatus: (state, action) => {
      state.status = action.payload;
    },

    resetEmployeeState: (state) => {
      state.employees = [] as Employee[];
      state.employeeToEdit = {} as Employee;
      state.status = STATUSES.IDLE;
    },
  },
});

export const {
  getAllEmployees,
  addEmployee,
  removeEmployee,
  editEmployee,
  setEmployeeToEdit,

  setEmployeeStatus,
  resetEmployeeState,
} = employeeSlice.actions;

export default employeeSlice.reducer;

export function getEmployees({
  id,
  active,
  pageNo = 1,
  query = "",
  store,
  role,
  edit,
}: TGetParams) {
  return async function getEmployeesThunk(dispatch: any) {
    dispatch(setEmployeeStatus(STATUSES.LOADING));
    // const endpoint = `/api/account/user/${
    //   id ? `${id}/` : `${active ? "active/" : ""}?`
    // }`;
    // const queryParams = new URLSearchParams();
    // if (query) {
    //   queryParams.append("query", query);
    // }
    // queryParams.append("page", pageNo.toString());
    // if (store) {
    //   queryParams.append("store[]", store.toString());
    // }
    // if (role) {
    //   queryParams.append("role[]", role.toString());
    // }
    // const queryString = queryParams.toString();
    // const finalEndpoint = endpoint + queryString;
    const endpoint = id
      ? `/api/account/user/${id}/`
      : `/api/account/user/${
          active
            ? "active/"
            : `?page=${pageNo}${query ? `&query=${query}` : ""}${
                store ? `&store[]=${store}` : ""
              }${role ? `&role[]=${role}` : ""}`
        }`;
    try {
      const response: any = await getEmployee(endpoint);
      if (id) {
        if (edit) {
          const getFormData = async () => {
            const data = await Promise.all([
              dispatch(fetchRoles({ active: true })),
              dispatch(getBranches({ active: true })),
              dispatch(getCountry()),
            ]);
          };
          getFormData();
          const dataToEdit = {
            username: response.result.username,
            password: response.result.password,

            first_name: response.result.first_name,
            last_name: response.result.last_name,

            date_joined: response.result.date_joined,
            email: response.result.email,
            mobile_number: response.result.mobile_number,
            pan: response.result.pan,

            role: response.result.role && [
              {
                value: response.result.role.id,
                label: response.result.role.role_name,
              },
            ],

            stores: response.result.stores?.map((store) => ({
              id: store.store.id,
              value: store.store.id,
              label: store.store.store_name,
            })),

            is_active: [
              {
                value: response.result.is_active,
                label:
                  response.result.is_active === 1
                    ? GENERAL_CONSTANTS.ACTIVE
                    : GENERAL_CONSTANTS.INACTIVE,
              },
            ],

            salary: response.result.salary,
            commissions: response.result.commissions,
            extra_wages: response.result.extra_wages,
            comments: response.result.comments,

            address: response.result.address && {
              ...response.result.address,
              address_line_1: response.result.address.address_line_1,
              address_line_2: response.result.address.address_line_2,

              city: response.result.address.city && [
                {
                  value: response.result.address.city.id,
                  label: response.result.address.city.city,
                },
              ],

              state: response.result.address.state && [
                {
                  value: response.result.address.state.id,
                  label: response.result.address.state.state,
                },
              ],

              country: response.result.address.country && [
                {
                  value: response.result.address.country.id,
                  label: response.result.address.country.country,
                },
              ],

              pincode: response.result.address.pincode,
            },

            bank_name: response.result.bank_name,
            bank_branch: response.result.bank_branch,
            ifsc_code: response.result.ifsc_code,
            account_no: response.result.account_no,
            account_holder: response.result.account_holder,
          };
          edit.setInitialBranches &&
            edit.setInitialBranches(
              response.result.stores?.map((store) => ({
                id: store.store.id,
                value: store.store.id,
                label: store.store.store_name,
              }))
            );
          dispatch(setEmployeeToEdit(dataToEdit));
        } else {
          dispatch(setEmployeeToEdit(response.result));
        }
      } else {
        dispatch(updateValues(response));
        dispatch(getAllEmployees(response.result.results));
      }
      dispatch(setEmployeeStatus(STATUSES.IDLE));
    } catch (error) {
      dispatch(setEmployeeStatus(STATUSES.ERROR));
      if (typeof error === "object") {
        dispatch(setcommonError(error.message));
      }
    }

    // getEmployee(finalEndpoint)
    //   .then((response: any) => {
    //     dispatch(updateValues(response));
    //     dispatch(getAllEmployees(response.result.results));
    //   })
    //   .catch((error) => {
    //     dispatch(setEmployeeStatus(STATUSES.ERROR));
    //     if (typeof error === "object") {
    //       dispatch(setcommonError(error.message));
    //     }
    //   })
    //   .finally(() => {
    //     dispatch(setEmployeeStatus(STATUSES.IDLE));
    //   });
  };
}

export function getIndividualEmployee(
  id: number,
  edit?: boolean,
  setInitialBranches?: React.Dispatch<React.SetStateAction<any[]>>
) {
  return async function getIndividualEmployeeThunk(
    dispatch: any,
    getState: any
  ) {
    dispatch(setEmployeeStatus(STATUSES.LOADING));
    getSpecificEmployee(id)
      .then((response: any) => {
        dispatch(setEmployeeToEdit(response.result));
        if (edit) {
          const getFormData = async () => {
            const data = await Promise.all([
              dispatch(getBanks({ active: true })),
              dispatch(fetchRoles({ active: true })),
              dispatch(getBranches({ active: true })),
              dispatch(getCountry()),
            ]);
          };
          getFormData();
          const dataToEdit = {
            username: response.result.username,
            password: response.result.password,

            first_name: response.result.first_name,
            last_name: response.result.last_name,

            date_joined: response.result.date_joined,
            email: response.result.email,
            mobile_number: response.result.mobile_number,
            pan: response.result.pan,

            role: response.result.role && [
              {
                value: response.result.role.id,
                label: response.result.role.role_name,
              },
            ],

            stores: response.result.stores?.map((store) => ({
              id: store.store.id,
              value: store.store.id,
              label: store.store.store_name,
            })),

            is_active: [
              {
                value: response.result.is_active,
                label:
                  response.result.is_active === 1
                    ? GENERAL_CONSTANTS.ACTIVE
                    : GENERAL_CONSTANTS.INACTIVE,
              },
            ],

            salary: response.result.salary,
            commissions: response.result.commissions,
            extra_wages: response.result.extra_wages,
            comments: response.result.comments,

            address: response.result.address && {
              ...response.result.address,
              address_line_1: response.result.address.address_line_1,
              address_line_2: response.result.address.address_line_2,

              city: response.result.address.city && [
                {
                  value: response.result.address.city.id,
                  label: response.result.address.city.city,
                },
              ],

              state: response.result.address.state && [
                {
                  value: response.result.address.state.id,
                  label: response.result.address.state.state,
                },
              ],

              country: response.result.address.country && [
                {
                  value: response.result.address.country.id,
                  label: response.result.address.country.country,
                },
              ],

              pincode: response.result.address.pincode,
            },

            bank_name: response.result.bank_name,
            bank_branch: response.result.bank_branch,
            ifsc_code: response.result.ifsc_code,
            account_no: response.result.account_no,
            account_holder: response.result.account_holder,
          };
          setInitialBranches &&
            setInitialBranches(
              response.result.stores?.map((store) => ({
                id: store.store.id,
                value: store.store.id,
                label: store.store.store_name,
              }))
            );
          dispatch(setEmployeeToEdit(dataToEdit));
        }
      })
      .catch((error) => {
        dispatch(setEmployeeStatus(STATUSES.ERROR));
        dispatch(setcommonError(error.message));
      })
      .finally(() => {
        dispatch(setEmployeeStatus(STATUSES.IDLE));
      });
  };
}

export function addNewEmployee(
  employee: Employee,
  actions: FormikHelpers<Employee>,
  navigate: NavigateFunction
) {
  return async function addNewEmployeeThunk(dispatch: any) {
    dispatch(setEmployeeStatus(STATUSES.LOADING));
    setEmployee(employee)
      .then((response: any) => {
        dispatch(addEmployee(response.result));
        navigate(EMPLOYEE_CONSTANTS.EMPLOYEE_NAVIGATE);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setEmployeeStatus(STATUSES.ERROR));
        if (typeof error === "object") {
          dispatch(setcommonError(error.message));
        }
      })
      .finally(() => {
        dispatch(setEmployeeStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingEmployee(
  id: number,
  employee: Employee,
  actions: FormikHelpers<Employee>,
  navigate: NavigateFunction
) {
  return async function updateExistingEmployeeThunk(
    dispatch: any,
    getState: any
  ) {
    dispatch(setEmployeeStatus(STATUSES.LOADING));
    updateEmployee(id, employee)
      .then((response: any) => {
        if (response.http_code === 200) {
          dispatch(getEmployees({}));
          navigate(EMPLOYEE_CONSTANTS.EMPLOYEE_NAVIGATE);
          actions.resetForm();
          actions.setSubmitting(false);
          Toast(`${response.message}`, "success");
        }
      })
      .catch((error) => {
        dispatch(setEmployeeStatus(STATUSES.ERROR));
        if (typeof error === "object") {
          dispatch(setcommonError(error.message));
        }
      })
      .finally(() => {
        dispatch(setEmployeeStatus(STATUSES.IDLE));
      });
  };
}

export function deleteEmployee(id: number) {
  return async function deleteEmployeeThunk(dispatch: any, getState: any) {
    const {
      common: { current_page },
    }: RootState["root"] = getState().root;
    dispatch(setEmployeeStatus(STATUSES.LOADING));
    delEmployee(id)
      .then((response: any) => {
        dispatch(setEmployeeStatus(STATUSES.LOADING));
        dispatch(getEmployees({ pageNo: current_page }));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setEmployeeStatus(STATUSES.ERROR));
        if (typeof error === "object") {
          dispatch(setcommonError(error.message));
        }
      })
      .finally(() => {
        dispatch(setEmployeeStatus(STATUSES.IDLE));
      });
  };
}

export function deleteEmployeeStoreMapping(delParams: {
  user_id: number;
  store_id: number;
}) {
  return async function deleteEmployeeStoreMappingThunk(dispatch: any) {
    delEmployeeStoreMapping(delParams)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setEmployeeStatus(STATUSES.ERROR));
        if (typeof error === "object") {
          dispatch(setcommonError(error.message));
        }
      });
  };
}
